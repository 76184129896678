import styled from '@emotion/styled';
import { Typography } from '@material-ui/core';
import LinedTable from 'components/linedTable';

import { LeagueScoreResults, LeagueScoreTotals } from '../../../queries/leagueScore';

type LeagueScoreTotalKeysMinusScore = keyof Omit<LeagueScoreTotals, 'score' | '__typename'>;

const Breakdown = ({ entityData }: { entityData: LeagueScoreResults | undefined }) => {
  const metrics: { name: LeagueScoreTotalKeysMinusScore; shortHand: string; modifier: number }[] = [
    { name: 'accountCreated', shortHand: 'AC', modifier: 4 },
    { name: 'welcomeCall', shortHand: 'WC', modifier: 8 },
    { name: 'permitSubmitted', shortHand: 'PS', modifier: 12 },
    { name: 'installCompleted', shortHand: 'IC', modifier: 16 },
  ];

  const totals: Omit<LeagueScoreTotals, 'score'> | undefined = entityData?.entity.profile.league.leagueScore.totals;
  const totalScore = entityData?.entity.profile.league.leagueScore.totals.score;

  return (
    <BreakdownContainer>
      <Typography variant="body1">SCORE BREAKDOWN</Typography>
      <LinedTable
        lineItems={metrics.map(metric => {
          return {
            left: <Typography variant="body1">{metric.shortHand}</Typography>,
            right: (
              <Calculation>
                <Typography variant="h5">{(totals && totals[metric.name]?.count) || 0}</Typography>
                <Typography variant="body2">x{metric.modifier}</Typography>
                <Typography variant="body2">=</Typography>
                <Typography variant="h5">{(totals && totals[metric.name]?.score) || 0}</Typography>
              </Calculation>
            ),
          };
        })}
      />
      <Total>
        <Typography variant="body1">TOTAL</Typography>
        <Typography variant="h4">{totalScore}</Typography>
      </Total>
    </BreakdownContainer>
  );
};

const BreakdownContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  max-width: 400px;

  ${p => p.theme.breakpoints.down('sm')} {
    margin: auto;
  }

  > p {
    font-weight: bold;
    text-align: left;
    margin-bottom: ${p => p.theme.spacing(1)}px;
  }

  > div p {
    text-align: center;
    font-size: 18px;
  }
`;

const Calculation = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin: 0 ${p => p.theme.spacing(2)}px;

  p {
    font-family: constructa;
    font-weight: normal;
    margin-bottom: ${p => p.theme.spacing(0.25)}px;
  }
`;

const Total = styled.div`
  display: flex;
  align-items: flex-end;
  margin: ${p => p.theme.spacing(1)}px ${p => p.theme.spacing(3)}px 0 auto;

  p {
    margin-right: ${p => p.theme.spacing(1)}px;
    margin-bottom: ${p => p.theme.spacing(0.25)}px;
  }
`;

export default Breakdown;
