import styled from '@emotion/styled';
import Typography from '@material-ui/core/Typography';
import StarIcon from '@material-ui/icons/Star';
import BlankProfile from 'assets/images/blank-profile.png';
import getOrganizationLogo from 'assets/orgLogos/orgLogoMappings';
import { VIEWS } from 'constants/views';
import { LeagueLevelName } from 'queries/athlete';
import { OrganizationLeagueLevelName } from 'queries/organizationOverview';
import { FC } from 'react';
import { getLevelLogo } from 'utilities/getLevelLogo';

import { Maybe } from '../../apollo/types';
import { LEAGUE_LEVEL_TO_DISPLAY, LEAGUE_LEVEL_TO_STARS } from '../../constants/leagueLevel';

type Props = {
  combineIsActive?: boolean;
  leagueLevel?: Maybe<LeagueLevelName> | Maybe<OrganizationLeagueLevelName>;
  orgId?: string;
  title?: string;
  // TODO: get these constants from the VIEWS constants, idk how
  type: string;
};

const DashboardProfile: FC<Props> = ({ combineIsActive, leagueLevel, orgId, title, type }) => {
  const OrgSvg = type === VIEWS.OFFICE && orgId && getOrganizationLogo(orgId);
  const logo = getLevelLogo(type, Boolean(combineIsActive), leagueLevel);
  return (
    <Contact>
      <NameContainer>
        {title && <Name variant="h4">{title.toUpperCase()}</Name>}
        {leagueLevel &&
          !combineIsActive &&
          type === VIEWS.ATHLETE &&
          [...Array(LEAGUE_LEVEL_TO_STARS[leagueLevel])].map((value, index: number) => (
            <StarIcon key={index} color="secondary" />
          ))}
      </NameContainer>
      <AvatarContainer>
        <Avatar>
          <div>
            {type === VIEWS.OFFICE && OrgSvg && <OrgSvg />}
            {type !== VIEWS.OFFICE && (logo ? <logo.Logo /> : <img src={BlankProfile} alt="Athlete avatar" />)}
          </div>
          <span />
        </Avatar>
        <Typography variant="body2">LEAGUE LEVEL</Typography>
        {!combineIsActive && (
          <Typography variant="body1">{leagueLevel && LEAGUE_LEVEL_TO_DISPLAY[leagueLevel].toUpperCase()}</Typography>
        )}
        {combineIsActive && <Typography variant="body1">COMBINE</Typography>}
      </AvatarContainer>
    </Contact>
  );
};

const Contact = styled.div`
  max-width: 110px;
  h4 {
    color: ${p => p.theme.palette.secondary.main};
  }
`;
const NameContainer = styled.div`
  position: absolute;
`;

const Name = styled(Typography)`
  ${p => p.theme.breakpoints.down('sm')} {
    padding-left: 4px;
  }
`;

const AvatarContainer = styled.div`
  width: 110px;
  text-align: center;
  margin-top: 86px;

  p {
    font-weight: bold;
    &:first-of-type {
      background-color: ${p => p.theme.palette.common.black};
    }
    &:last-of-type {
      font-size: 18px;
    }
  }
`;

const Avatar = styled.div`
  position: relative;
  img {
    width: 100%;
  }

  svg {
    margin: 5px;
    fill: ${p => p.theme.palette.common.white};
  }

  div {
    width: 60px;
    height: 60px;
    margin: auto;
    margin-bottom: 5px;
    margin-top: ${p => p.theme.spacing(2)}px;
    border-radius: 50%;
    overflow: hidden;
    background-color: ${p => p.theme.palette.background.default};
  }

  span {
    position: absolute;
    z-index: -1;
    top: 50%;
    bottom: -5px;
    left: 0;
    right: 0;
    background: repeating-linear-gradient(-45deg, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0) 4px, #fff 5px);
  }
`;

export default DashboardProfile;
