import { ReactComponent as LeagueLogo } from '../icons/LeagueLogo.svg';
import { ReactComponent as Atomic_Bakersfield } from './Atomic_Bakersfield.svg';
import { ReactComponent as Atomic_SanLuisObispo } from './Atomic_SanLuisObispo.svg';
import { ReactComponent as Atomic_ThousandOaks } from './Atomic_ThousandOaks.svg';
import { ReactComponent as Bloodline } from './Bloodline.svg';
import { ReactComponent as Bloodline_Grit } from './Bloodline_Grit.svg';
import { ReactComponent as Bloodline_Legion } from './Bloodline_Legion.svg';
import { ReactComponent as Bloodline_NewEngland } from './Bloodline_NewEngland.svg';
import { ReactComponent as Concord } from './Concord.svg';
import { ReactComponent as Core_DiabloValley } from './Core_DiabloValley.svg';
import { ReactComponent as Core_SantaRosa } from './Core_SantaRosa.svg';
import { ReactComponent as Core_SouthBay } from './Core_SouthBay.svg';
import { ReactComponent as Core_Stockton } from './Core_Stockton.svg';
import { ReactComponent as DCSouth } from './DCSouth.svg';
import { ReactComponent as Flow_PalmSprings } from './Flow_PalmSprings.svg';
import { ReactComponent as Flow_SDNorth } from './Flow_SDNorth.svg';
import { ReactComponent as Flow_SDSouth } from './Flow_SDSouth.svg';
import { ReactComponent as Flow_Temecula } from './Flow_Temecula.svg';
import { ReactComponent as Grit_LongIslandEast } from './Grit_LongIslandEast.svg';
import { ReactComponent as Grit_Nassau } from './Grit_Nassau.svg';
import { ReactComponent as Grit_NJNorth } from './Grit_NJNorth.svg';
import { ReactComponent as Grit_NYCNorth } from './Grit_NYCNorth.svg';
import { ReactComponent as Grit_NYCSouth } from './Grit_NYCSouth.svg';
import { ReactComponent as Grit_PhiladelphiaNorth } from './Grit_PhiladelphiaNorth.svg';
import { ReactComponent as Legion_Baltimore } from './Legion_Baltimore.svg';
import { ReactComponent as Legion_DCNorth } from './Legion_DCNorth.svg';
import { ReactComponent as Legion_Fairfax } from './Legion_Fairfax.svg';
import { ReactComponent as Legion_Tampa } from './Legion_Tampa.svg';
import { ReactComponent as NewEngland_BostonCentral } from './NewEngland_BostonCentral.svg';
import { ReactComponent as NewEngland_BostonNorth } from './NewEngland_BostonNorth.svg';
import { ReactComponent as NewEngland_BostonSouth } from './NewEngland_BostonSouth.svg';
import { ReactComponent as NewEngland_BostonWest } from './NewEngland_BostonWest.svg';
import { ReactComponent as NewEngland_NewHaven } from './NewEngland_NewHaven.svg';
import { ReactComponent as NewEngland_PioneerValley } from './NewEngland_PioneerValley.svg';
import { ReactComponent as Originals_AppleValley } from './Originals_AppleValley.svg';
import { ReactComponent as Originals_EastLA } from './Originals_EastLA.svg';
import { ReactComponent as Originals_OrangeCounty } from './Originals_OrangeCounty.svg';
import { ReactComponent as Originals_Riverside } from './Originals_Riverside.svg';
import { ReactComponent as Republic_Chico } from './Republic_Chico.svg';
import { ReactComponent as Republic_Fresno } from './Republic_Fresno.svg';
import { ReactComponent as Republic_Sacramento } from './Republic_Sacramento.svg';
import { ReactComponent as Republic_Visalia } from './Republic_Visalia.svg';
import { ReactComponent as Southwest_Albuquerque } from './Southwest_Albuquerque.svg';
import { ReactComponent as Southwest_AZEast } from './Southwest_AZEast.svg';
import { ReactComponent as Southwest_Denver } from './Southwest_Denver.svg';
import { ReactComponent as Southwest_Hawaii } from './Southwest_Hawaii.svg';
import { ReactComponent as Tribe } from './Tribe.svg';
import { ReactComponent as Tribe_Atomic } from './Tribe_Atomic.svg';
import { ReactComponent as Tribe_Core } from './Tribe_Core.svg';
import { ReactComponent as Tribe_Flow } from './Tribe_Flow.svg';
import { ReactComponent as Tribe_Originals } from './Tribe_Originals.svg';
import { ReactComponent as Tribe_Republic } from './Tribe_Republic.svg';
import { ReactComponent as WasatchSouth } from './WasatchSouth.svg';

const getOrganizationLogo = (id: string) => {
  if (!id) {
    return null;
  }

  switch (id) {
    case '9409':
      return Core_SantaRosa;
    case 'SUPERVISORY_ORGANIZATION-3-672':
      return Atomic_Bakersfield;
    case '61849':
    case 'SUPERVISORY_ORGANIZATION-3-836':
      return NewEngland_BostonNorth;
    case 'SUPERVISORY_ORGANIZATION-3-1679':
      return Tribe_Atomic;
    case 'SUPERVISORY_ORGANIZATION-3-881':
      return Flow_Temecula;
    case 'SUPERVISORY_ORGANIZATION-3-872':
      return Flow_PalmSprings;
    case 'SUPERVISORY_ORGANIZATION-3-1101':
    case '29133':
      return Grit_NJNorth;
    case 'SUPERVISORY_ORGANIZATION-3-904':
      return Flow_SDSouth;
    case '33926':
      return Grit_NYCSouth;
    case 'SUPERVISORY_ORGANIZATION-3-8870602f9cb2108c799b8afbbaf5ede7':
      return Legion_Baltimore;
    case 'SUPERVISORY_ORGANIZATION-3-849':
      return NewEngland_PioneerValley;
    case 'SUPERVISORY_ORGANIZATION-3-840':
      return Republic_Chico;
    case 'SUPERVISORY_ORGANIZATION-3-2416':
      return Grit_PhiladelphiaNorth;
    case 'SUPERVISORY_ORGANIZATION-3-1463':
    case 'SUPERVISORY_ORGANIZATION-3-793':
      return Originals_OrangeCounty;
    case '3061':
      return Concord;
    case 'SUPERVISORY_ORGANIZATION-3-865':
      return DCSouth;
    case 'SUPERVISORY_ORGANIZATION-3-1098':
      return Southwest_Denver;
    case 'SUPERVISORY_ORGANIZATION-3-2298':
      return Grit_NYCNorth;
    case 'SUPERVISORY_ORGANIZATION-3-877':
      return Republic_Sacramento;
    case 'SUPERVISORY_ORGANIZATION-3-2047':
      return Tribe_Core;
    case 'SUPERVISORY_ORGANIZATION-3-2398':
      return Southwest_Albuquerque;
    case 'SUPERVISORY_ORGANIZATION-3-1609':
      return Tribe_Republic;
    case 'SUPERVISORY_ORGANIZATION-3-874':
      return Originals_EastLA;
    case 'SUPERVISORY_ORGANIZATION-3-2e6a607a49f301d42b792126412fce83':
      return Tribe_Originals;
    case 'SUPERVISORY_ORGANIZATION-3-700':
      return Republic_Fresno;
    case 'SUPERVISORY_ORGANIZATION-3-2286':
      return Atomic_SanLuisObispo;
    case 'SUPERVISORY_ORGANIZATION-3-1602':
      return Bloodline_Legion;
    case 'SUPERVISORY_ORGANIZATION-3-1604':
      return Bloodline_NewEngland;
    case 'SUPERVISORY_ORGANIZATION-3-1024':
      return Legion_Fairfax;
    case 'SUPERVISORY_ORGANIZATION-3-2186':
      return Bloodline;
    case 'SUPERVISORY_ORGANIZATION-3-863':
      return Grit_LongIslandEast;
    case 'SUPERVISORY_ORGANIZATION-3-1991':
      return WasatchSouth;
    case 'SUPERVISORY_ORGANIZATION-3-2270':
      return Grit_Nassau;
    case 'SUPERVISORY_ORGANIZATION-3-675':
      return Core_SouthBay;
    case 'SUPERVISORY_ORGANIZATION-3-847':
    case 'SUPERVISORY_ORGANIZATION-3-98a4c9ecd1e101a0fa702c80b401635a':
      return NewEngland_BostonWest;
    case 'SUPERVISORY_ORGANIZATION-3-886':
      return Core_Stockton;
    case 'SUPERVISORY_ORGANIZATION-3-671':
      return Originals_AppleValley;
    case 'SUPERVISORY_ORGANIZATION-3-848':
      return NewEngland_BostonSouth;
    case 'SUPERVISORY_ORGANIZATION-3-2048':
      return Tribe_Flow;
    case 'SUPERVISORY_ORGANIZATION-3-980':
      return Core_DiabloValley;
    case 'SUPERVISORY_ORGANIZATION-3-1512':
      return Legion_Tampa;
    case 'SUPERVISORY_ORGANIZATION-3-722':
      return Atomic_ThousandOaks;
    case '58074':
      return Flow_SDNorth;
    case 'SUPERVISORY_ORGANIZATION-3-1404':
      return NewEngland_BostonCentral;
    case 'SUPERVISORY_ORGANIZATION-3-676':
      return Legion_DCNorth;
    case 'SUPERVISORY_ORGANIZATION-3-2049':
      return Tribe;
    case 'SUPERVISORY_ORGANIZATION-3-2096':
      return Republic_Visalia;
    case 'SUPERVISORY_ORGANIZATION-3-1601':
      return Bloodline_Grit;
    case 'SUPERVISORY_ORGANIZATION-3-831':
      return Southwest_AZEast;
    case 'SUPERVISORY_ORGANIZATION-3-1398': // HI-01 Oahu Solar
    case 'SUPERVISORY_ORGANIZATION-3-1519': // HI-02 Maui Solar
    case 'SUPERVISORY_ORGANIZATION-3-1014': // HI-03 Kona Solar
      return Southwest_Hawaii;
    case 'SUPERVISORY_ORGANIZATION-3-2185':
      return NewEngland_NewHaven;
    case 'SUPERVISORY_ORGANIZATION-3-2097':
      return Originals_Riverside;
    // case 'SUPERVISORY_ORGANIZATION-3-2392':
    //   return 'Triumph';
    // case 'SUPERVISORY_ORGANIZATION-3-977':
    //   return 'NV-03 Solar';
    // case 'SUPERVISORY_ORGANIZATION-3-1840':
    //   return 'NJ-11 West Connect Solar';
    // case 'SUPERVISORY_ORGANIZATION-3-1985':
    //   return 'Connect';
    // case 'SUPERVISORY_ORGANIZATION-3-1425':
    //   return 'NJ-08 South Connect Solar';
    // case 'SUPERVISORY_ORGANIZATION-3-1511':
    //   return 'IL-04 Wheeling Solar';
    // case 'SUPERVISORY_ORGANIZATION-3-2061':
    //   return 'BRANCH - TX - Dallas';
    // case 'SUPERVISORY_ORGANIZATION-3-1841':
    //   return 'NJ-12 East Connect Solar';
    case '59697': // Vivint solar org Id
    default:
      return LeagueLogo;
  }
};

export default getOrganizationLogo;
