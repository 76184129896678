import styled from '@emotion/styled';
import {
  athleteLevelLogos,
  combineLevelLogos,
  GOAL_TO_LEAGUE_LEVEL,
  LEAGUE_LEVEL_FLAGSHIP,
  LEAGUE_LEVEL_FRANCHISE,
  LEAGUE_LEVELS,
  ORG_LEAGUE_LEVELS,
  orgLevelLogos,
} from 'constants/leagueLevel';
import { VIEWS } from 'constants/views';
import { CombineUserResults, LeagueQuarterResult } from 'queries/athlete';
import { LeagueOrganizationQuarterResult } from 'queries/organizationOverview';

import mixpanel from '../../utilities/mixpanel';

type LogoContainerProps = {
  currentGoal?: boolean;
  hitCurrentGoal?: boolean;
  currentOrHigherLevel?: boolean;
  extraMargin?: boolean;
  flagshipLogo?: boolean;
  isOrg?: boolean;
};

const LeagueLevelFrequencies = ({
  combineIsActive = false,
  combine,
  currentQuarter,
  openDialog,
  type,
}: {
  combine?: CombineUserResults;
  combineIsActive?: boolean;
  currentQuarter?: LeagueQuarterResult | LeagueOrganizationQuarterResult;
  openDialog: () => void;
  type: string;
}) => {
  return (
    <Container type={type}>
      {type === VIEWS.ATHLETE &&
        !combineIsActive &&
        athleteLevelLogos.map((level, index) => {
          const currentGoalLevel =
            currentQuarter?.leagueLevelGoal && GOAL_TO_LEAGUE_LEVEL[currentQuarter?.leagueLevelGoal];
          const hitCurrentGoal = (currentQuarter?.leagueLevelGoal || 0) <= (currentQuarter?.leagueLevelPoints || 0);
          const currentOrHigherLevel =
            currentGoalLevel &&
            currentQuarter?.level !== LEAGUE_LEVEL_FRANCHISE &&
            LEAGUE_LEVELS.indexOf(currentGoalLevel) <= LEAGUE_LEVELS.indexOf(level.level);
          return (
            <LogoContainer
              key={index}
              currentGoal={level.level === currentGoalLevel}
              currentOrHigherLevel={currentOrHigherLevel || false}
              hitCurrentGoal={hitCurrentGoal}
              onClick={() => {
                openDialog();
                mixpanel.track('Opened League Level Freqencies');
              }}
            >
              <level.Logo />
            </LogoContainer>
          );
        })}
      {type === VIEWS.ATHLETE &&
        combineIsActive &&
        combineLevelLogos.map((level, index) => {
          const currentWeek = Math.floor((60 - (combine?.daysRemaining || 0)) / 7);
          const weekToCompare = index + 1;
          return (
            <LogoContainer
              key={index}
              currentGoal={weekToCompare === currentWeek}
              currentOrHigherLevel={weekToCompare >= currentWeek}
            >
              <level.Logo />
            </LogoContainer>
          );
        })}
      {type === VIEWS.OFFICE &&
        orgLevelLogos.map((level, index) => {
          const currentGoalLevel =
            currentQuarter?.leagueLevelGoal && GOAL_TO_LEAGUE_LEVEL[currentQuarter.leagueLevelGoal];
          const hitCurrentGoal = (currentQuarter?.leagueLevelGoal || 0) <= (currentQuarter?.leagueLevelPoints || 0);
          const currentOrHigherLevel =
            currentGoalLevel &&
            currentQuarter?.level !== LEAGUE_LEVEL_FLAGSHIP &&
            ORG_LEAGUE_LEVELS.indexOf(currentGoalLevel) <= ORG_LEAGUE_LEVELS.indexOf(level.level);
          return (
            <LogoContainer
              key={index}
              currentGoal={level.level === currentGoalLevel}
              currentOrHigherLevel={currentOrHigherLevel || false}
              extraMargin
              isOrg
              flagshipLogo={level.level === LEAGUE_LEVEL_FLAGSHIP}
              hitCurrentGoal={hitCurrentGoal}
              onClick={openDialog}
            >
              <level.Logo />
            </LogoContainer>
          );
        })}
    </Container>
  );
};

const Container = styled.div<{ type: string }>`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: auto;

  ${p => p.theme.breakpoints.down('sm')} {
    position: absolute;
    bottom: ${p => (p.type !== VIEWS.ATHLETE ? '-108px' : '-150px')};
    left: ${p => (p.type !== VIEWS.ATHLETE ? '0' : '30px')};
    right: 0;
  }
`;

export const LogoContainer = styled.div<LogoContainerProps>`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0px ${p => p.theme.spacing(1)}px;
  font-size: 0;
  cursor: pointer;
  ${p => p.isOrg && p.theme.breakpoints.down('sm')} {
    margin: 0px;
  }

  &::after {
    content: '';
    position: absolute;
    top: -5px;
    left: -5px;
    right: -5px;
    bottom: -5px;
    height: calc(100% + 10px);
    width: calc(100% + 10px);
    border-radius: 50%;
    border: 5px solid
      ${p => (p.currentGoal ? (p.hitCurrentGoal ? p.theme.palette.primary.light : p.theme.palette.info.main) : 'none')};
  }

  svg {
    width: 100%;
    max-width: 60px;
    fill: ${p => (p.flagshipLogo ? p.theme.palette.primary.light : p.theme.palette.common.white)};
    opacity: ${p => (p.currentOrHigherLevel ? 0.3 : 1)};
    margin: ${p => (p.extraMargin ? '10px' : '0px')};

    ${p => p.theme.breakpoints.down('sm')} {
      width: 45px;
    }
  }
`;

export default LeagueLevelFrequencies;
