import styled from '@emotion/styled';

import SunSpinner from './SunSpinner';

const FullScreenLoading = ({ text }: { text?: string }) => {
  return (
    <Container>
      <div>
        <SunSpinner sunSize={7} raySize={1} />
        <Title>{text || 'Loading...'}</Title>
      </div>
    </Container>
  );
};

const Container = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1500;
  background-color: ${p => p.theme.palette.background.default};
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
`;

const Title = styled.div`
  font-size: 24px;
`;

export default FullScreenLoading;
