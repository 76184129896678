import { useQuery } from '@apollo/client';
import styled from '@emotion/styled';
import { Typography } from '@material-ui/core';
import { PAST_FOURTEEN, PAST_SEVEN, PAST_TWENTY_ONE } from 'constants/trendsDuration';

import { ContainerLoading } from '../../../components/loading';
import { PastFourteen, PastSeven, PastTwentyOne } from '../../../queries/athleteTrends';
import { organizationPastFourteen, organizationPastSeven, orgPastTwentyOne } from '../../../queries/organizationTrends';
import ErrorComponent from '../../error-boundary/ErrorComponent';
import Graph from './Graph';

type Ranges = { [key: string]: { start: string; end: string } };

const OrgData = ({
  duration,
  entityId,
  period,
  ranges,
}: {
  duration: number;
  entityId: string;
  period: string;
  ranges: Ranges;
}) => {
  const sevenResult = useQuery<PastSeven>(organizationPastSeven, {
    variables: { entityId: entityId, ...ranges },
    skip: duration === PAST_FOURTEEN.value || duration === PAST_TWENTY_ONE.value,
  });
  const fourteenResult = useQuery<PastFourteen>(organizationPastFourteen, {
    variables: { entityId: entityId, ...ranges },
    skip: duration === PAST_SEVEN.value || duration === PAST_TWENTY_ONE.value,
  });
  const twentyOneResult = useQuery<PastTwentyOne>(orgPastTwentyOne, {
    variables: { entityId: entityId, ...ranges },
    skip: duration === PAST_SEVEN.value || duration === PAST_FOURTEEN.value,
  });

  return (
    <Container>
      <Typography variant="h6">{period}</Typography>
      {(sevenResult.error || fourteenResult.error || twentyOneResult.error) && <ErrorComponent />}
      {(sevenResult.loading || fourteenResult.loading || twentyOneResult.loading) && <ContainerLoading />}
      {((duration === PAST_SEVEN.value && sevenResult.data) ||
        (duration === PAST_FOURTEEN.value && fourteenResult.data) ||
        (duration === PAST_TWENTY_ONE.value && twentyOneResult.data)) && (
        <Graph
          data={sevenResult.data || fourteenResult.data || twentyOneResult.data}
          duration={duration}
          period={period}
          ranges={ranges}
        />
      )}
    </Container>
  );
};

const Container = styled.div`
  > h6 {
    text-align: center;
    font-weight: lighter;
    margin: ${p => p.theme.spacing(2)}px 0 ${p => p.theme.spacing(1)}px;
  }
`;

export default OrgData;
