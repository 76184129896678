function Dot({ selected = false }: { selected?: boolean }) {
  const opacity = selected ? 1 : 0.3;

  return (
    <svg width={8} height={8} viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle opacity={opacity} cx={4} cy={4} r={4} fill="#fff" />
    </svg>
  );
}

export default Dot;
