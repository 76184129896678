import styled from '@emotion/styled';
import { Typography } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { useState } from 'react';

import { ReactComponent as CrownIcon } from '../../assets/icons/crown.svg';
import { ReactComponent as CustomIcon } from '../../assets/icons/custom-calendar.svg';
import { ReactComponent as DayIcon } from '../../assets/icons/day-calendar.svg';
import { ReactComponent as MonthIcon } from '../../assets/icons/month-calendar.svg';
import { ReactComponent as QuarterIcon } from '../../assets/icons/quarter-calendar.svg';
import { ReactComponent as WeekIcon } from '../../assets/icons/week-calendar.svg';
import { CUSTOM, DAY, MONTH, QUARTER, TimePeriod, WEEK, YEAR } from '../../constants/periods';
import mixpanel from '../../utilities/mixpanel';
import PageHeader from '../page-header/PageHeader';
import { COMP_SCORE, LEAGUE_RANK } from '.';

const StyledButton = styled(Button)`
  min-width: auto;

  svg {
    fill: ${p => p.theme.palette.common.white};
  }

  ${p => p.theme.breakpoints.up('sm')} {
    svg {
      margin-right: ${p => p.theme.spacing(1)}px;
    }
  }

  ${p => p.theme.breakpoints.down('sm')} {
    p {
      display: none;
    }
  }
`;

const StyledMenu = styled(Menu)`
  svg {
    margin-right: ${p => p.theme.spacing(1)}px;
    fill: ${p => p.theme.palette.common.black};
  }

  .MuiMenu-list {
    background-color: ${p => p.theme.palette.text.primary};
  }
`;

const StyledMenuItem = styled(MenuItem)`
  background-color: ${p => p.theme.palette.text.primary};
  font-weight: bold;
  color: ${p => p.theme.palette.primary.main};

  &.Mui-selected {
    background-color: ${p => p.theme.palette.text.secondary};
  }
  &.Mui-selected:hover {
    background-color: ${p => p.theme.palette.text.secondary};
  }
  :hover {
    background-color: ${p => p.theme.palette.text.primary};
  }
`;

const Header = ({
  type,
  period,
  setType,
  setPeriod,
}: {
  type: string;
  period: string;
  setType: (arg0: string) => void;
  setPeriod: (arg0: TimePeriod) => void;
}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [selectedIndex, setSelectedIndex] = useState(0);

  const onChangeOptions = (selection: string) => {
    setType(selection);
    mixpanel.track(`Leaderboard type changed to ${selection}`);
  };

  const onChangePeriod = (selection: TimePeriod) => {
    setPeriod(selection);
    mixpanel.track(`Leaderboard period changed to ${selection}`);
  };

  const options = [
    { value: LEAGUE_RANK, display: 'LEAGUE RANK', onOptionClick: () => onChangeOptions(LEAGUE_RANK) },
    { value: COMP_SCORE, display: 'COMP SCORE', onOptionClick: () => onChangeOptions(COMP_SCORE) },
  ];

  let periods = [
    { value: DAY, display: DAY, onOptionClick: () => onChangePeriod(DAY) },
    { value: WEEK, display: WEEK, onOptionClick: () => onChangePeriod(WEEK) },
    { value: MONTH, display: MONTH, onOptionClick: () => onChangePeriod(MONTH) },
    { value: QUARTER, display: QUARTER, onOptionClick: () => onChangePeriod(QUARTER) },
    { value: YEAR, display: YEAR, onOptionClick: () => onChangePeriod(YEAR) },
    { value: CUSTOM, display: CUSTOM, onOptionClick: () => onChangePeriod(CUSTOM) },
  ];
  if (type === COMP_SCORE) {
    periods = [
      { value: DAY, display: DAY, onOptionClick: () => onChangePeriod(DAY) },
      { value: WEEK, display: WEEK, onOptionClick: () => onChangePeriod(WEEK) },
      { value: CUSTOM, display: CUSTOM, onOptionClick: () => onChangePeriod(CUSTOM) },
    ];
  }

  const handlePeriodClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleFilterItemClick = (event: React.MouseEvent<HTMLElement>, index: number) => {
    setSelectedIndex(index);
    setAnchorEl(null);
  };

  const periodIconMapping = (periodToMatch: string) => {
    switch (periodToMatch) {
      case DAY:
        return <DayIcon />;
      case MONTH:
        return <MonthIcon />;
      case QUARTER:
      case YEAR:
        return <QuarterIcon />;
      case CUSTOM:
        return <CustomIcon />;
      case WEEK:
      default:
        return <WeekIcon />;
    }
  };

  return (
    <PageHeader
      title="Leaderboard"
      icon={<CrownIcon style={{ fill: 'white' }} />}
      optionSelected={type}
      options={options}
      leftButtons={
        <>
          <StyledButton onClick={handlePeriodClick}>
            {periodIconMapping(period)}
            <Typography variant="body1">{period}</Typography>
          </StyledButton>
          <StyledMenu
            id="leaderboard-period-menu"
            elevation={0}
            anchorEl={anchorEl}
            keepMounted
            getContentAnchorEl={null}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            {periods.map((periodOption, index) => (
              <StyledMenuItem
                key={periodOption.value}
                selected={index === selectedIndex}
                onClick={event => {
                  handleFilterItemClick(event, index);
                  if (periodOption.onOptionClick) {
                    periodOption.onOptionClick();
                  }
                }}
              >
                {periodIconMapping(periodOption.value)}
                {periodOption.display}
              </StyledMenuItem>
            ))}
          </StyledMenu>
        </>
      }
    />
  );
};

export default Header;
