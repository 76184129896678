import { useQuery } from '@apollo/client';
import styled from '@emotion/styled';
import { CircularProgress, Typography } from '@material-ui/core';
import { ReactComponent as MagnifyingGlass } from 'assets/icons/magnifying-glass.svg';
import { ReactComponent as Cancel } from 'assets/icons/x.svg';
import throttle from 'lodash/debounce';
import { ChangeEvent, useCallback, useEffect, useRef, useState } from 'react';

import { Maybe } from '../../apollo/types';
import { SalesEntitySearchResult, scorecardSearch, ScorecardSearchResults } from '../../queries/scorecardSearch';
import mixpanel from '../../utilities/mixpanel';

type SearchExpandedProps = {
  searchexpanded: number | undefined;
};

const processData = (data: ScorecardSearchResults) => {
  if (!data) return null;
  const {
    salesEntitiesByTerm: { entities },
  } = data;

  const results: { [key: string]: SalesEntitySearchResult[] } = entities.reduce(
    (acc: { [key: string]: SalesEntitySearchResult[] }, entity) => {
      const { type } = entity;
      if (acc && entity.type && acc[entity.type]) {
        acc[type].push(entity);
      } else {
        acc[type] = [entity];
      }
      return acc;
    },
    {},
  );

  return results;
};

const ScorecardSearch = ({
  handleClickSearchResult,
}: {
  handleClickSearchResult: (arg0: Maybe<SalesEntitySearchResult> | undefined) => void;
}) => {
  const node = useRef<HTMLHeadingElement>(null);
  const [searchExpanded, setSearchExpanded] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [queryValue, setQueryValue] = useState('');
  const { loading, error, data } = useQuery(scorecardSearch, {
    variables: { term: queryValue },
    skip: queryValue.length < 3,
  });

  useEffect(() => {
    if (data) {
      mixpanel.track(
        `Scorecard search data received: ${data?.salesEntitiesByTerm?.entities.length || 0} results returned`,
      );
    }
  }, [data]);

  const searchResults = processData(data);

  const throttledSetQuery = useCallback(
    throttle(
      value => {
        setQueryValue(value);
        mixpanel.track(`Scorecard search query: ${value}`);
      },
      500,
      { leading: false },
    ),
    [],
  );

  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    setSearchValue(e.target.value);
    throttledSetQuery(e.target.value);
  };

  const clearSearch = () => {
    setSearchExpanded(false);
    setSearchValue('');
  };

  const handleClick = (e: any) => {
    if (node?.current?.contains(e.target)) {
      setSearchExpanded(true);
      return;
    }
    setSearchExpanded(false);
    setSearchValue('');
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClick);
    return () => {
      document.removeEventListener('mousedown', handleClick);
    };
  }, []);

  return (
    <SearchContainer ref={node}>
      <SearchInput
        type="search"
        value={searchValue}
        searchexpanded={searchExpanded ? 1 : undefined}
        onChange={onChange}
      />
      <SearchTypography variant="body1" searchexpanded={searchExpanded ? 1 : undefined}>
        SEARCH
      </SearchTypography>
      <MagnifyingGlassSVG searchexpanded={searchExpanded ? 1 : undefined} />
      <CancelSVG searchexpanded={searchExpanded ? 1 : undefined} onClick={clearSearch} />
      <SearchResults searchexpanded={searchExpanded ? 1 : undefined}>
        {error && !data && <SearchError>Something went wrong. Please search again</SearchError>}
        {loading && !data && <SearchProgress size={25} />}
        {searchResults && Object.keys(searchResults).length === 0 && searchResults.constructor === Object && (
          <SearchEmpty>No results. Please search again.</SearchEmpty>
        )}
        {data &&
          searchValue &&
          searchResults &&
          Object.values(searchResults).map((results, index) => (
            <ResultGroup key={index}>
              <Typography variant="body1">{Object.keys(searchResults)[index]}</Typography>
              {results.map((result, i) => (
                <div
                  onClick={() => {
                    handleClickSearchResult(result);
                    clearSearch();
                    mixpanel.track(`Scorecard clicked search result: ${result.name}`);
                  }}
                  key={i}
                >
                  <Typography variant="body1">{result.name}</Typography>
                  <Typography variant="body1">{result.parent?.name}</Typography>
                </div>
              ))}
            </ResultGroup>
          ))}
      </SearchResults>
    </SearchContainer>
  );
};

const SearchContainer = styled.div`
  position: relative;
  cursor: pointer;
`;

const SearchTypography = styled(Typography)<SearchExpandedProps>`
  cursor: pointer;
  opacity: ${p => (p.searchexpanded ? 0 : 1)};
  transition: opacity 500ms ease-out 0s;
  transition-delay: ${p => (p.searchexpanded ? '0s' : '300ms')};

  ${p => p.theme.breakpoints.down('sm')} {
    display: none;
  }
`;

const SearchInput = styled.input<SearchExpandedProps>`
  position: absolute;
  z-index: 2;
  right: 0;
  top: 50%;
  width: ${p => (p.searchexpanded ? '150px' : '60px')};
  padding: 7px 20px 7px 5px;
  background-color: inherit;
  border: ${p => (p.searchexpanded ? '1px' : '0px')} solid ${p => p.theme.palette.common.white};
  border-radius: 3px;
  transform: translateY(-50%);
  transition: 300ms ease-in-out;
  color: ${p => p.theme.palette.common.white};
  font-family: Trade Gothic Condensed;
  font-weight: 400;
  font-size: 16px;

  // custom cancel button so we can style it
  ::-webkit-search-cancel-button,
  ::-webkit-search-decoration {
    -webkit-appearance: none;
    appearance: none;
  }

  ${p => p.theme.breakpoints.down('sm')} {
    width: ${p => (p.searchexpanded ? '100px' : '0')};
  }
`;

const MagnifyingGlassSVG = styled(MagnifyingGlass)<SearchExpandedProps>`
  position: absolute;
  left: -20px;
  height: 15px;
  width: 15px;
  fill: ${p => p.theme.palette.common.white};
  top: 50%;
  // animation, delay on fade back in
  opacity: ${p => (p.searchexpanded ? 0 : 1)};
  transform: translateY(-50%);
  transition: opacity 500ms ease-out 0s;
  transition-delay: ${p => (p.searchexpanded ? '0s' : '300ms')};
`;

const CancelSVG = styled(Cancel)<SearchExpandedProps>`
  position: absolute;
  z-index: ${p => (p.searchexpanded ? 3 : 0)};
  right: 7px;
  height: 13px;
  width: 13px;
  fill: ${p => p.theme.palette.common.white};
  top: 50%;
  // animation, delay on fade initial display
  opacity: ${p => (p.searchexpanded ? 1 : 0)};
  transform: translateY(-50%);
  transition: opacity 300ms ease-out;
  transition-delay: ${p => (p.searchexpanded ? '300ms' : '0s')};
`;

const SearchResults = styled.div<SearchExpandedProps>`
  position: absolute;
  z-index: 2;
  right: 0;
  top: calc(100% + 10px);
  width: ${p => (p.searchexpanded ? '320px' : '60px')};
  background-color: ${p => p.theme.palette.common.white};
  color: ${p => p.theme.palette.common.black};
  border-radius: 4px;

  p {
    padding: ${p => p.theme.spacing(1)}px ${p => p.theme.spacing(2)}px;
    text-transform: uppercase;
    font-weight: bolder;
  }
`;

const SearchProgress = styled(CircularProgress)`
  margin: 10px;
`;

const SearchError = styled.div`
  padding: ${p => p.theme.spacing(1)}px ${p => p.theme.spacing(2)}px;
  color: ${p => p.theme.palette.error.main};
`;

const SearchEmpty = styled.div`
  padding: ${p => p.theme.spacing(1)}px ${p => p.theme.spacing(2)}px;
`;

const ResultGroup = styled.div`
  &:first-of-type {
    padding: ${p => p.theme.spacing(1)}px 0;
  }
  > p:first-of-type {
    background-color: ${p => p.theme.palette.grey[300]};
    cursor: default;
  }

  p {
    padding: ${p => p.theme.spacing(1)}px ${p => p.theme.spacing(2)}px;
    text-transform: uppercase;
    font-weight: bolder;
  }

  > div {
    display: flex;
    justify-content: space-between;

    &:hover {
      background-color: ${p => p.theme.palette.grey[100]};
    }
    p:last-of-type {
      text-align: right;
    }
  }
`;

export default ScorecardSearch;
