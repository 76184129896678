import styled from '@emotion/styled';
import { Typography } from '@material-ui/core';
import { ReactComponent as Cancel } from 'assets/icons/x.svg';

import mixpanel from '../../utilities/mixpanel';

const ScorecardBanner = ({ bannerDisplay, clearScorecard }: { bannerDisplay?: string; clearScorecard: () => void }) => {
  return (
    <Banner>
      <Typography variant="body1">VIEWING {bannerDisplay}</Typography>
      <Cancel
        onClick={() => {
          clearScorecard();
          mixpanel.track('Scorecard banner removed');
        }}
      />
    </Banner>
  );
};

const Banner = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: ${p => p.theme.spacing(0.5)}px ${p => p.theme.spacing(1.5)}px;
  background-color: ${p => p.theme.palette.success.main};
  font-size: 18px;

  p {
    text-transform: uppercase;
  }

  svg {
    height: 20px;
    width: 20px;
    fill: ${p => p.theme.palette.common.white};
    margin-left: ${p => p.theme.spacing(1.5)}px;
    padding: ${p => p.theme.spacing(0.5)}px;
    cursor: pointer;
  }
`;

export default ScorecardBanner;
