import { gql } from '@apollo/client';

import { Maybe, Scalars } from '../apollo/types';

export type SalesEntity = {
  id: Scalars['String'];
  primaryDisplayName: Scalars['String'];
};

export type SalesReportRow = {
  entity: SalesEntity;
};

export type SalesReportRowConnection = {
  rows?: Maybe<Array<Maybe<SalesReportRow>>>;
};

export type AllStatsReportRow = SalesReportRow & {
  __typename?: 'AllStatsReportRow';
  entity: SalesEntity;
  leagueScore: Scalars['Float'];
  accountCreated: Scalars['Int'];
  welcomeCall: Scalars['Int'];
  /** @deprecated Use `leagueLevelPoints` instead */
  permitSubmittedPoints: Scalars['Float'];
  leagueLevelPoints: Scalars['Float'];
  permitSubmitted: Scalars['Int'];
  installScheduled: Scalars['Int'];
  installComplete: Scalars['Int'];
  kilowattsInstalled: Scalars['Float'];
  permitSubmittedPercentage: Scalars['Float'];
};

export type AllStatsReportRowConnection = SalesReportRowConnection & {
  __typename?: 'AllStatsReportRowConnection';
  rows?: { [key: string]: unknown }[];
  totals: AllStatsReportTotals;
};

export type AllStatsReportTotals = {
  __typename?: 'AllStatsReportTotals';
  leagueScore: Scalars['Float'];
  accountCreated: Scalars['Int'];
  welcomeCall: Scalars['Int'];
  /** @deprecated Use `leagueLevelPoints` instead */
  permitSubmittedPoints: Scalars['Float'];
  leagueLevelPoints: Scalars['Float'];
  permitSubmitted: Scalars['Int'];
  installScheduled: Scalars['Int'];
  installComplete: Scalars['Int'];
  kilowattsInstalled: Scalars['Float'];
  permitSubmittedPercentage: Scalars['Float'];
};

export type AllStatsReportResult = {
  activeSalesReport: {
    rowsConnection: AllStatsReportRowConnection;
  };
  inactiveSalesReport: {
    rowsConnection: AllStatsReportRowConnection;
  };
};

export const athleteAllStatsReport = gql`
  query IndividualAllStatsReport(
    $range: DateRange!
    $entityId: String!
    $subEntityLevel: SalesReportSubOrganization!
    $rowFilter: SalesReportRowFilter!
    $reportType: SalesReportType!
  ) {
    activeSalesReport: salesReport(
      reportType: $reportType
      range: $range
      entityId: $entityId
      subEntityLevel: $subEntityLevel
      rowFilter: $rowFilter
      active: true
    ) {
      rowsConnection {
        ... on AllStatsReportRowConnection {
          rows {
            entity {
              id
              primaryDisplayName
              ... on SalesEntityIndividual {
                secondaryDisplayName
                leagueLevel
                fullName
              }
            }
            ...allStatsReportRow
          }
          totals {
            ...allStatsReportTotals
          }
        }
      }
    }
    inactiveSalesReport: salesReport(
      reportType: $reportType
      range: $range
      entityId: $entityId
      subEntityLevel: $subEntityLevel
      rowFilter: $rowFilter
      active: false
    ) {
      rowsConnection {
        ... on AllStatsReportRowConnection {
          totals {
            ...allStatsReportTotals
          }
        }
      }
    }
  }
  fragment allStatsReportRow on AllStatsReportRow {
    leagueScore
    accountCreated
    welcomeCall
    leagueLevelPoints
    permitSubmitted
    installScheduled
    installComplete
    kilowattsInstalled
    permitSubmittedPercentage
  }
  fragment allStatsReportTotals on AllStatsReportTotals {
    leagueScore
    accountCreated
    welcomeCall
    leagueLevelPoints
    permitSubmitted
    installScheduled
    installComplete
    kilowattsInstalled
    permitSubmittedPercentage
  }
`;

export const orgAllStatsReport = gql`
  query OrganizationAllStatsReport(
    $range: DateRange!
    $entityId: String!
    $subEntityLevel: SalesReportSubOrganization!
    $rowFilter: SalesReportRowFilter!
    $reportType: SalesReportType!
  ) {
    activeSalesReport: salesReport(
      reportType: $reportType
      range: $range
      entityId: $entityId
      subEntityLevel: $subEntityLevel
      rowFilter: $rowFilter
      active: true
    ) {
      rowsConnection {
        ... on AllStatsReportRowConnection {
          totals {
            ...allStatsReportTotals
          }
          rows {
            entity {
              id
              primaryDisplayName
              ... on SalesEntityOrganization {
                leagueLevel
              }
            }
            ...allStatsReportRow
          }
        }
      }
    }
    inactiveSalesReport: salesReport(
      reportType: $reportType
      range: $range
      entityId: $entityId
      subEntityLevel: $subEntityLevel
      rowFilter: $rowFilter
      active: false
    ) {
      rowsConnection {
        ... on AllStatsReportRowConnection {
          totals {
            ...allStatsReportTotals
          }
          rows {
            entity {
              id
              primaryDisplayName
              ... on SalesEntityOrganization {
                leagueLevel
              }
            }
            ...allStatsReportRow
          }
        }
      }
    }
  }
  fragment allStatsReportRow on AllStatsReportRow {
    leagueScore
    accountCreated
    welcomeCall
    leagueLevelPoints
    permitSubmitted
    installScheduled
    installComplete
    kilowattsInstalled
    permitSubmittedPercentage
  }
  fragment allStatsReportTotals on AllStatsReportTotals {
    leagueScore
    accountCreated
    welcomeCall
    leagueLevelPoints
    permitSubmitted
    installScheduled
    installComplete
    kilowattsInstalled
    permitSubmittedPercentage
  }
`;
