import styled from '@emotion/styled';
import { ListItem, ListItemIcon as ListItemIconMui, ListItemText as ListItemTextMui } from '@material-ui/core';
import { ComponentType, FC, SVGProps } from 'react';
import { NavLink as LinkRRD } from 'react-router-dom';

type Props = {
  text: string;
  to: string;
  onClick(): void;
  Icon: ComponentType<SVGProps<SVGSVGElement>>;
};

const NavItem: FC<Props> = props => {
  const { text, to, Icon, onClick } = props;

  return (
    <Link to={to} activeClassName="selected" onClick={onClick}>
      <ListItem button data-testid={NAV_ITEM_TEST_ID}>
        <ListItemIcon>
          <Icon />
        </ListItemIcon>
        <ListItemText primary={text} />
      </ListItem>
    </Link>
  );
};

const ListItemText = styled(ListItemTextMui)`
  text-transform: uppercase;
`;

const ListItemIcon = styled(ListItemIconMui)`
  min-width: 38px;
`;

const Link = styled(LinkRRD)`
  text-decoration: none;
  color: ${p => p.theme.palette.text.primary};

  path {
    fill: ${p => p.theme.palette.text.primary};
  }

  &.selected {
    color: ${p => p.theme.palette.secondary.main};

    path {
      fill: ${p => p.theme.palette.secondary.main};
    }
  }
`;

export const NAV_ITEM_TEST_ID = 'NAV_ITEM_TEST_ID';

export default NavItem;
