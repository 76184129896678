import { ThemeProvider } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import { makeStyles } from '@material-ui/core/styles';
import { DatePicker, KeyboardDatePicker } from '@material-ui/pickers';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import clsx from 'clsx';
import { DateTime, Interval } from 'luxon';
import { useEffect, useState } from 'react';
import { startDateOfLifetime } from 'utilities/dates';

import { createTheme } from './index';
import { CalendarType } from './types';

const useStyles = makeStyles(theme => ({
  dayWrapper: {
    position: 'relative',
  },
  day: {
    width: 36,
    height: 36,
    fontSize: theme.typography.caption.fontSize,
    margin: '0 2px',
    color: 'inherit',
  },
  customDayHighlight: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: '2px',
    right: '2px',
    border: `1px solid ${theme.palette.secondary.main}`,
    borderRadius: '50%',
  },
  nonCurrentMonthDay: {
    color: theme.palette.text.disabled,
  },
  futureDate: {
    color: '#676767',
  },
  highlightNonCurrentMonthDay: {
    color: '#676767',
  },
  highlight: {
    background: theme.palette.primary.main,
    color: theme.palette.common.white,
  },
  firstHighlight: {
    extend: 'highlight',
    borderTopLeftRadius: '50%',
    borderBottomLeftRadius: '50%',
  },
  endHighlight: {
    extend: 'highlight',
    borderTopRightRadius: '50%',
    borderBottomRightRadius: '50%',
  },
}));

const WeekPicker: CalendarType = ({ color, selectedRange, onChange, outlined = false }) => {
  const theme = createTheme({ outlined, color: color || '#fff' });
  const classes = useStyles();
  const [selectedDate, handleDateChange] = useState<MaterialUiPickersDate>(
    DateTime.fromISO(selectedRange.start).startOf('week'),
  );

  const CalendarComponent = outlined ? KeyboardDatePicker : DatePicker;

  useEffect(() => {
    const newWeek = DateTime.fromISO(selectedRange.start).startOf('week');
    handleDateChange(newWeek);
  }, [selectedRange]);

  const handleWeekChange = (date: MaterialUiPickersDate) => {
    if (date) {
      handleDateChange(date.startOf('week'));
      onChange({
        start: date.startOf('week').toISODate(),
        end: date.endOf('week').toISODate(),
      });
    }
  };

  const formatWeekSelectLabel = (date: MaterialUiPickersDate, invalidLabel: string) => {
    return date ? `Week of ${date.toFormat('MMM d')}` : invalidLabel;
  };

  const renderWrappedWeekDay = (
    date: MaterialUiPickersDate,
    selected: MaterialUiPickersDate,
    dayInCurrentMonth: boolean,
  ) => {
    if (!date || !selected) {
      return <div></div>;
    }
    const start = selected.startOf('week');
    const end = selected.endOf('week');

    const interval = Interval.fromDateTimes(start, end);

    const dayIsBetween = interval.contains(date);

    const isFirstDay = date.day === start.day;
    const isLastDay = date.day === end.day;

    const wrapperClassName = clsx({
      [classes.highlight]: dayIsBetween,
      [classes.firstHighlight]: isFirstDay,
      [classes.endHighlight]: isLastDay,
    });

    const dayClassName = clsx(classes.day, {
      [classes.nonCurrentMonthDay]: !dayInCurrentMonth,
      [classes.highlightNonCurrentMonthDay]: !dayInCurrentMonth && dayIsBetween,
    });

    return (
      <div className={wrapperClassName}>
        <IconButton className={dayClassName}>
          <span> {date.day} </span>
        </IconButton>
      </div>
    );
  };
  return (
    <ThemeProvider theme={theme}>
      <CalendarComponent
        value={selectedDate}
        label={outlined && 'DATES'}
        inputVariant="outlined"
        onChange={handleWeekChange}
        renderDay={renderWrappedWeekDay}
        labelFunc={formatWeekSelectLabel}
        minDate={new Date(startDateOfLifetime())}
        disableFuture
      />
    </ThemeProvider>
  );
};

export default WeekPicker;
