import styled from '@emotion/styled';
import { VIEWS } from 'constants/views';
import { CombineUserResults, LeagueQuarterResult } from 'queries/athlete';
import { LeagueOrganizationQuarterResult } from 'queries/organizationOverview';
import { useState } from 'react';

import Pace from '../../components/pace';
import LeagueLevelDialog from './LeagueLevelDialog';
import LeagueLevelFrequencies from './LeagueLevelFrequencies';

type League = {
  combine?: CombineUserResults;
  currentQuarter: LeagueQuarterResult;
};

type OrgLeague = {
  currentQuarter: LeagueOrganizationQuarterResult;
  combine?: undefined;
};

const DashboardLevels = ({
  entityId,
  type,
  league,
  combineIsActive = false,
}: {
  entityId?: string;
  league?: League | OrgLeague;
  type: string;
  combineIsActive?: boolean;
}) => {
  const [openDialog, setOpenDialog] = useState(false);

  const isOrg = type !== VIEWS.ATHLETE;
  return (
    <Container isOrg={isOrg}>
      <Pace
        combine={league?.combine}
        combineIsActive={combineIsActive}
        currentQuarter={league?.currentQuarter}
        type={type}
      />
      <LeagueLevelFrequencies
        combine={league?.combine}
        combineIsActive={combineIsActive}
        currentQuarter={league?.currentQuarter}
        openDialog={() => setOpenDialog(true)}
        type={type}
      />
      <LeagueLevelDialog
        entityId={entityId}
        type={type}
        leagueLevel={league?.currentQuarter?.level}
        isDialogOpen={openDialog}
        closeDialog={() => setOpenDialog(false)}
      />
    </Container>
  );
};

const Container = styled.div<{ isOrg: boolean }>`
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: center;
  align-items: center;
  padding: ${p => p.theme.spacing(0.5)}px ${p => p.theme.spacing(1)}px;
  ${p => p.theme.breakpoints.down('sm')} {
    position: absolute;
    top: 25%;
    left: ${p => (p.isOrg ? '20%' : '25%')};
  }
`;

export default DashboardLevels;
