import { useQuery } from '@apollo/client';
import styled from '@emotion/styled';
import { Typography } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import { ReactComponent as Combine } from 'assets/icons/combine.svg';
import Carousel from 'components/carousel';
import LinedTable from 'components/linedTable';
import { ContainerLoading } from 'components/loading';
import { athleteLevelLogos, LEAGUE_LEVEL_TO_DISPLAY } from 'constants/leagueLevel';
import ErrorComponent from 'pages/error-boundary/ErrorComponent';

import employeeByBadgeIdQuery, { EmployeeByBadgeIdResult } from '../../queries/employeeByBadgeId';
import { startDateOfCurrentQuarter } from '../../utilities/dates';
import useWindowSize from '../../utilities/windowSize';
import officeInfo from './officeInfo.json';

const AthleteInfoComponent = ({
  givenName,
  familyName,
  leagueLevel,
  workLocation,
}: {
  givenName?: string;
  familyName?: string;
  leagueLevel?: string;
  workLocation?: string;
}) => (
  <AthleteInfo>
    {givenName && <Typography variant="h4">{givenName}</Typography>}
    {familyName && <Typography variant="h3">{familyName}</Typography>}
    {leagueLevel && <Typography variant="h3">{leagueLevel && LEAGUE_LEVEL_TO_DISPLAY[leagueLevel]}</Typography>}
    {workLocation && <Typography variant="h6">{workLocation}</Typography>}
  </AthleteInfo>
);

const RepInfoComponent = ({ email, hireDate }: { email?: string; hireDate?: string }) => (
  <ExtraInfo>
    <Typography variant="body1">REP INFO</Typography>
    <LinedTable
      lineItems={[
        {
          left: <Typography variant="body1">EMAIL</Typography>,
          right: <Typography variant="body1">{email}</Typography>,
        },
        {
          left: <Typography variant="body1">DATE RECRUITED</Typography>,
          right: <Typography variant="body1">{hireDate}</Typography>,
        },
      ]}
    />
  </ExtraInfo>
);

const OfficeInfoComponent = () => (
  <ExtraInfo>
    <Typography variant="body1">OFFICE INFO</Typography>
    <LinedTable
      lineItems={officeInfo.map(item => ({
        left: <Typography variant="body1">{item.name.toUpperCase()}</Typography>,
        right: (
          <div>
            <Typography variant="caption">{item.phone}</Typography>
          </div>
        ),
      }))}
    />
  </ExtraInfo>
);

const ScorecardProfile = ({ badgeId }: { badgeId: string }) => {
  const theme = useTheme();
  const [width] = useWindowSize();
  const { loading, error, data } = useQuery<EmployeeByBadgeIdResult>(employeeByBadgeIdQuery, {
    variables: {
      badgeId: badgeId,
      startOfQuarter: startDateOfCurrentQuarter(),
    },
    fetchPolicy: 'cache-first',
  });

  if (error) {
    return <ErrorComponent />;
  }

  if (loading && !data) {
    return <ContainerLoading />;
  }

  const getBackgroundSvg = (level?: string, combineActive?: boolean) => {
    if (combineActive) {
      return { Logo: Combine };
    }
    return athleteLevelLogos.find(x => x.level === level);
  };

  const BackgroundSvg = getBackgroundSvg(
    data?.employee?.salesProfile?.league?.currentQuarter?.level,
    data?.employee?.salesProfile?.league?.combine.isActive,
  );

  const leagueLevel = data?.employee.salesProfile.league.currentQuarter?.level;
  const givenName = data?.employee.contact.givenName;
  const familyName = data?.employee.contact.familyName;
  const workLocation = data?.employee.workLocation;
  const hireDate = data?.employee.hireDate;
  const email = data?.employee.contact.email;

  return (
    <Container>
      {width > theme.breakpoints.values.lg && (
        <Wrapper>
          <AthleteInfoComponent
            givenName={givenName}
            familyName={familyName}
            leagueLevel={leagueLevel}
            workLocation={workLocation}
          />
          <div>
            <RepInfoComponent email={email} hireDate={hireDate} />
            <OfficeInfoComponent />
          </div>
        </Wrapper>
      )}
      {width <= theme.breakpoints.values.lg && (
        <CarouselContainer>
          <Carousel
            title="Athlete Profile"
            pages={[
              <SliderPage key="athlete-info">
                <AthleteInfoComponent
                  givenName={givenName}
                  familyName={familyName}
                  leagueLevel={leagueLevel}
                  workLocation={workLocation}
                />
              </SliderPage>,
              <SliderPage key="rep-info">
                <RepInfoComponent email={email} hireDate={hireDate} />
              </SliderPage>,
              <SliderPage key="office-info">
                <OfficeInfoComponent />
              </SliderPage>,
            ]}
            centerMode={false}
          />
        </CarouselContainer>
      )}
      {BackgroundSvg && <BackgroundSvg.Logo />}
    </Container>
  );
};

const Container = styled.div`
  position: relative;
  overflow: hidden;

  > svg {
    position: absolute;
    top: -50px;
    left: -50px;
    fill: black;
    width: 500px;
    height: 500px;
    z-index: -1;
    opacity: 0.3;
  }
`;

const Wrapper = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: ${p => p.theme.spacing(10)}px;

  > div {
    flex: 1;
  }
`;

const AthleteInfo = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  text-transform: uppercase;

  h4 {
    font-family: constructa;
    font-weight: lighter;
  }

  h3:first-of-type,
  h4 {
    color: ${p => p.theme.palette.primary.light};
  }

  h3:nth-of-type(2) {
    margin-top: ${p => p.theme.spacing(3)}px;
    letter-spacing: 10px;
  }

  h6 {
    font-weight: lighter;
  }
`;

const ExtraInfo = styled.div`
  max-width: 500px;
  margin: auto;
  padding: ${p => p.theme.spacing(2)}px;
  word-break: break-word;

  > p:first-of-type {
    margin-bottom: ${p => p.theme.spacing(0.5)}px;
  }

  p {
    margin: 0;
    font-weight: bold;
  }
`;

const CarouselContainer = styled.div`
  .slick-track {
    display: flex;
  }
  // some special styling to get the heights all the same
  .slick-track .slick-slide {
    display: flex;
    height: auto;
    margin: 10px 0;

    > div {
      width: 100%;
    }

    > div > div {
      height: 100%;

      > div {
        height: 100%;
      }
    }
  }
`;

const SliderPage = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export default ScorecardProfile;
