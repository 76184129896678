import styled from '@emotion/styled';
import { AppBar as AppBarMui, IconButton, Toolbar, Typography } from '@material-ui/core';
import { Menu } from '@material-ui/icons';
import { ReactComponent as Door } from 'assets/icons/door.svg';
import { ReactComponent as Tachometer } from 'assets/icons/tachometer.svg';
import { BASE_URLS } from 'constants/baseUrls';
import { FC } from 'react';
import { provideEnvironment } from 'utilities/env';

type Props = {
  onDrawerToggle(): void;
};

const TopBar: FC<Props> = props => {
  const env = provideEnvironment();

  const splatLink = BASE_URLS.SPLAT[env];
  const doorsLink = `${splatLink}doors`;

  return (
    <AppBar position="fixed">
      <Toolbar variant="dense">
        <MenuButton color="inherit" aria-label="open drawer" edge="start" onClick={props.onDrawerToggle}>
          <Menu color="primary" />
        </MenuButton>
        <Container>
          <Title variant="h6" noWrap color="primary">
            League Live
          </Title>
          <RightIcons>
            <IconLink href={doorsLink} target="_blank">
              <Door fontSize={36} />
              <Typography component="span" variant="h6" noWrap color="primary">
                Doors
              </Typography>
            </IconLink>
            <IconLink href={splatLink} target="_blank">
              <Tachometer fontSize={36} />
              <Typography component="span" variant="h6" noWrap color="primary">
                Sales Dashboard
              </Typography>
            </IconLink>
          </RightIcons>
        </Container>
      </Toolbar>
    </AppBar>
  );
};

const AppBar = styled(AppBarMui)`
  background-color: ${p => p.theme.palette.common.white};
  z-index: ${p => p.theme.zIndex.drawer + 1};
`;

const MenuButton = styled(IconButton)`
  margin-right: ${p => p.theme.spacing(2)};
  ${p => p.theme.breakpoints.up('sm')} {
    display: none;
  }
`;

const Title = styled(Typography)`
  font-size: 24px;
  font-family: 'Proximity Nova';
`;

const Container = styled.div`
  display: flex;
  flex-grow: 1;
  justify-content: space-between;
  align-items: center;
`;

const RightIcons = styled.div`
  display: flex;
  align-items: center;
`;

const IconLink = styled.a`
  display: flex;
  align-items: center;
  margin-right: 30px;
  margin-left: 5px;
  text-decoration: none;
  span {
    font-size: 18px;
  }

  ${p => p.theme.breakpoints.up('sm')} {
    margin-left: 0px;
    margin-right: 26px;
  }

  > span {
    display: none;
    ${p => p.theme.breakpoints.up('sm')} {
      display: inherit;
    }
  }
`;

export default TopBar;
