import styled from '@emotion/styled';
import { Typography } from '@material-ui/core';
import { LeagueRanking } from 'apollo/types';
import { VIEWS } from 'constants/views';

import { EntityRanking, LeagueScoreResults } from '../../../queries/leagueScore';
import { getOrdinalNumber } from '../../../utilities/numbers';

type LeagueRankingMinusTypename = keyof Omit<LeagueRanking, '__typename' | 'highestLeagueLevel'>;

const Summary = ({
  entityData,
  period,
  type,
}: {
  entityData: LeagueScoreResults | undefined;
  period: string;
  type: string;
}) => {
  const athleteType = type === VIEWS.ATHLETE;

  const rankingLevels: { name: LeagueRankingMinusTypename; shortHand: string }[] = [
    { name: 'leagueLevel', shortHand: 'LL' },
    { name: 'office', shortHand: 'TEAM' },
    { name: 'region', shortHand: 'REGION' },
    { name: 'division', shortHand: 'COAST' },
    { name: 'company', shortHand: 'COMPANY' },
  ];

  const rankings: EntityRanking | undefined | false = athleteType && entityData?.entity?.profile?.entityRanking;

  return (
    <SummaryContainer>
      <Typography variant="h6">{period}</Typography>
      <Typography variant="h2">{entityData?.entity.profile.league.leagueScore.totals.score || 0}</Typography>
      {athleteType && (
        <Rankings>
          {rankingLevels.map(rank => {
            return (
              <div key={rank.name}>
                <Circle>
                  <Typography variant="h6">
                    {rankings ? (
                      <>
                        {rankings[rank.name]}
                        <small>{getOrdinalNumber(rankings[rank.name])}</small>
                      </>
                    ) : (
                      '-'
                    )}
                  </Typography>
                </Circle>
                <Typography variant="caption">{rank.shortHand}</Typography>
              </div>
            );
          })}
        </Rankings>
      )}
    </SummaryContainer>
  );
};

const SummaryContainer = styled.div`
  text-align: center;

  > h6 {
    font-weight: lighter;
    letter-spacing: 2px;
  }

  h2 {
    font-size: 70px;
  }
`;

const Rankings = styled.div`
  display: flex;
  justify-content: space-between;
  margin: ${p => p.theme.spacing(1.5)}px auto;
  max-width: 450px;

  ${p => p.theme.breakpoints.up('md')} {
    > div {
      margin: 0 ${p => p.theme.spacing(1.5)}px;
    }
  }
`;

const Circle = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 45px;
  width: 45px;
  margin-bottom: ${p => p.theme.spacing(1)}px;
  border-radius: 50%;
  border: 3px solid ${p => p.theme.palette.grey[700]};

  h6 {
    font-family: constructa;
    font-weight: normal;
  }

  small {
    font-size: 12px;
  }
`;

export default Summary;
