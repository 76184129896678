import styled from '@emotion/styled';
import { MenuOptions } from 'components/dropdown';
import MaterialTable, { Column, MTableBody, MTableToolbar } from 'material-table';
import { ReactElement } from 'react';

import { Black } from '../../styles/constants/colors';
import useWindowSize from '../../utilities/windowSize';
import FilterDropDown from './FilterDropDown';
import TableIcons from './TableIcons';

const Bar = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: ${p => p.theme.spacing(2)}px;

  div[class*='searchField'] {
    padding-left: 0;
  }

  > div {
    ${p => p.theme.breakpoints.down('md')} {
      display: flex;
      flex-wrap: wrap;
    }
  }
`;
const StyledMTableToolbar = styled(MTableToolbar)`
  width: 30%;
`;

const FilterTable = ({
  columns,
  data,
  title,
  filterOptions,
  filterSelected,
  totalsRow,
  onRowClick,
}: {
  columns: Column<any>[];
  data: { [key: string]: unknown }[] | undefined;
  title?: string | React.ReactElement<any, string | React.JSXElementConstructor<any>> | undefined;
  filterOptions?: MenuOptions[] | undefined;
  filterSelected?: string | undefined;
  totalsRow?: ReactElement;
  onRowClick: (
    event?: React.MouseEvent<Element, MouseEvent> | undefined,
    rowData?: any,
    toggleDetailPanel?: (panelIndex?: number | undefined) => void,
  ) => void;
}) => {
  const [width] = useWindowSize();

  if (!data) {
    return null;
  }

  return (
    <MaterialTable
      columns={columns}
      data={data}
      icons={TableIcons}
      title={title}
      onRowClick={onRowClick}
      localization={{
        toolbar: {
          searchPlaceholder: 'SEARCH',
        },
      }}
      options={{
        padding: 'dense',
        fixedColumns: {
          left: width < 900 ? 2 : 0,
          right: 0,
        },
        showEmptyDataSourceMessage: false,
        tableLayout: width < 900 ? 'fixed' : 'auto',
        paging: false,
        draggable: false,
        emptyRowsWhenPaging: false,
        rowStyle: (_rowData, rowNum) => ({
          backgroundColor: rowNum % 2 === 0 ? Black.LIGHT : Black.LIGHTER,
        }),
      }}
      components={{
        Toolbar: props => (
          <Bar>
            <StyledMTableToolbar {...props} />
            {filterOptions && <FilterDropDown filterSelected={filterSelected} options={filterOptions} />}
          </Bar>
        ),
        Body: props => (
          <>
            <MTableBody {...props} />
            {totalsRow}
          </>
        ),
      }}
    />
  );
};
export default FilterTable;
