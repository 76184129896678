import { useQuery } from '@apollo/client';
import styled from '@emotion/styled';
import { Typography } from '@material-ui/core';

import { ContainerLoading } from '../../../components/loading';
import SectionHeader from '../../../components/section-header';
import { METRIC_ABBREVIATIONS } from '../../../constants/metrics';
import { VIEWS } from '../../../constants/views';
import {
  athleteAverages,
  AthleteAveragesResult,
  organizationAverages,
  OrganizationAveragesResult,
} from '../../../queries/weeklyAverage';
import { range, startDateOfCurrentQuarter } from '../../../utilities/dates';
import ErrorComponent from '../../error-boundary/ErrorComponent';

const WeeklyAverage = ({ entityId, type }: { entityId: string; type: string }) => {
  const orgType = type !== VIEWS.ATHLETE;
  const athleteType = type === VIEWS.ATHLETE;
  const { data: orgData, error: orgError, loading: orgLoading } = useQuery<OrganizationAveragesResult>(
    organizationAverages,
    {
      variables: {
        entityId: entityId,
        rangeForQuarter: range.quarter(),
        quarterStartDate: startDateOfCurrentQuarter(),
      },
      skip: athleteType,
    },
  );
  const { data: athleteData, error: athleteError, loading: athleteLoading } = useQuery<AthleteAveragesResult>(
    athleteAverages,
    {
      variables: {
        entityId: entityId,
        rangeForQuarter: range.quarter(),
        quarterStartDate: startDateOfCurrentQuarter(),
      },
      skip: !athleteType,
    },
  );

  const league = orgData ? orgData?.entity.currentQuarter.league : athleteData?.entity.currentQuarter.league;
  const performanceIndicators = orgData
    ? orgData?.entity.currentQuarter.performanceIndicators
    : athleteData?.entity.currentQuarter.performanceIndicators;

  const metrics: { [key: string]: { total: number } } = {
    ...performanceIndicators,
    leagueScore: {
      total: league?.leagueScore?.totals?.score || 0,
    },
  };

  return (
    <div>
      <SectionHeader title="Weekly Average" />
      {(orgType && orgError) || (athleteType && athleteError && <ErrorComponent />)}
      {(orgType && orgLoading && !orgData) || (athleteType && athleteLoading && !athleteData && <ContainerLoading />)}
      <Content>
        {metrics &&
          Object.keys(metrics).length > 0 &&
          Object.keys(metrics).map(metric => {
            if (!METRIC_ABBREVIATIONS.hasOwnProperty(metric)) return null;
            return (
              <Metric key={METRIC_ABBREVIATIONS[metric]}>
                <Circle>
                  <Typography variant="h4">{metrics[metric].total || 0}</Typography>
                </Circle>
                <Typography variant="body2">{METRIC_ABBREVIATIONS[metric]}</Typography>
              </Metric>
            );
          })}
      </Content>
    </div>
  );
};

const Content = styled.div`
  display: flex;
  max-width: 1000px;
  margin: auto;
  padding: ${p => p.theme.spacing(3)}px ${p => p.theme.spacing(5)}px;
  overflow-x: scroll;

  ${p => p.theme.breakpoints.down('sm')} {
    padding: ${p => p.theme.spacing(3)}px;
  }
`;
const Metric = styled.div`
  padding: 0px ${p => p.theme.spacing(3)}px;
  text-align: center;
  margin: auto;

  ${p => p.theme.breakpoints.down('sm')} {
    padding: 0 ${p => p.theme.spacing(1)}px;
  }
`;
const Circle = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 75px;
  width: 75px;
  margin-bottom: ${p => p.theme.spacing(1)}px;
  border-radius: 50%;
  border: 6px solid ${p => p.theme.palette.grey[700]};

  h4 {
    font-family: constructa;
    font-weight: normal;
  }
`;

export default WeeklyAverage;
