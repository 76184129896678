import styled from '@emotion/styled';
import { GOAL_TO_LEAGUE_LEVEL } from 'constants/leagueLevel';
import { VIEWS } from 'constants/views';
import { CombineUserResults, LeagueQuarterResult } from 'queries/athlete';
import { LeagueOrganizationQuarterResult } from 'queries/organizationOverview';
import { getLevelLogo } from 'utilities/getLevelLogo';

import { ReactComponent as LeagueLogo } from '../../assets/icons/LeagueLogo.svg';
import { generateArc, polarToCartesian } from './Circle';
import { determinePaceColor, determineStatusColor } from './PaceColors';
import Summary from './Summary';
import WhiteRabbit from './WhiteRabbit';

type LogoProps = {
  smallerSize?: boolean;
};

const svgSize = 256;
const sideOffset = 15; // This keeps the edge of the circle offset from the side of the svg so that the pace dots aren't cut off.
const center = svgSize / 2;
const circleStrokeWidth = 12;
const radius = (svgSize - sideOffset) / 2;
const actualPaceSize = 12; // Radius of the actual pace dot

const PaceDot = ({ position, color }: { position: { x: number; y: number }; color: string }) => (
  <circle cx={position.x} cy={position.y} r={actualPaceSize} fill={color} />
);

const Pace = ({
  combine,
  combineIsActive = false,
  currentQuarter,
  type,
}: {
  combine?: CombineUserResults;
  combineIsActive?: boolean;
  currentQuarter?: LeagueQuarterResult | LeagueOrganizationQuarterResult;
  type: string;
}) => {
  let goal, actual;
  if (combineIsActive && type === VIEWS.ATHLETE) {
    goal = combine?.progress?.goal;
    actual = combine?.progress?.actual;
  } else {
    goal = currentQuarter?.leagueLevelGoal;
    actual = currentQuarter?.leagueLevelPoints;
  }

  const pace = currentQuarter?.leagueLevelPointsPace;
  const goalPace = currentQuarter?.leagueLevelGoalPace;

  const progress = goal && actual && goal > 0 ? (actual < 0 ? 0 : actual) / goal : 0;
  const actualPaceAsPercentage = goal && pace && goal > 0 ? (pace < 0 ? 0 : pace) / goal : 0;
  const goalPaceAsPercentage = goal && goalPace && goalPace / goal;

  const paceColor = determinePaceColor(currentQuarter?.status);
  const statusColor = determineStatusColor(currentQuarter?.status);

  const arc = generateArc(center, center, radius - circleStrokeWidth, 0, Math.min(359, progress * 360));
  const actualPacePosition = polarToCartesian(center, center, radius - circleStrokeWidth, actualPaceAsPercentage * 360);
  const whiteRabbitPosition =
    goalPaceAsPercentage && polarToCartesian(center, center, radius - circleStrokeWidth, goalPaceAsPercentage * 360);

  const currentGoalLevel = goal && GOAL_TO_LEAGUE_LEVEL[goal];

  const logo = getLevelLogo(type, combineIsActive, currentGoalLevel);

  return (
    <Container>
      <Wrapper>
        <svg viewBox={`0 0 ${svgSize} ${svgSize}`} width={150} height={150}>
          <circle
            cx={center}
            cy={center}
            r={radius - circleStrokeWidth}
            fill="none"
            stroke="gray"
            strokeWidth={circleStrokeWidth}
          />
          <path d={arc} stroke={statusColor} fill="none" strokeWidth={circleStrokeWidth} strokeLinecap="round" />
          {pace && <PaceDot position={actualPacePosition} color={paceColor} />}
          {pace && whiteRabbitPosition && (
            <WhiteRabbit position={whiteRabbitPosition} number={(goalPace && Math.ceil(goalPace)) || 0} />
          )}
        </svg>
        {logo && (
          <LogoContainer smallerSize={type === VIEWS.OFFICE}>
            <logo.Logo />
          </LogoContainer>
        )}
        {!logo && (
          <DefaultLogoContainer smallerSize>
            <LeagueLogo height="30px" width="30px" />
          </DefaultLogoContainer>
        )}
      </Wrapper>
      <Summary
        actual={actual}
        combineIsActive={combineIsActive}
        goal={goal}
        pace={pace}
        paceColor={paceColor}
        statusColor={statusColor}
        type={type}
      />
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: center;
  align-items: center;
`;

const Wrapper = styled.div`
  position: relative;
`;

const LogoContainer = styled.div<LogoProps>`
  position: absolute;
  top: ${p => (p.smallerSize ? '35px' : '25px')};
  left: 50%;
  transform: translateX(-50%);

  svg {
    width: ${p => (p.smallerSize ? '90px' : '100px')};
    height: ${p => (p.smallerSize ? '90px' : '100px')};
    fill: ${p => p.theme.palette.common.white};
  }
`;

const DefaultLogoContainer = styled(LogoContainer)`
  svg {
    width: 80px;
    height: 80px;
  }
`;

export default Pace;
