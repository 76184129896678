import { gql } from '@apollo/client';

import { Maybe, Scalars } from '../apollo/types';

export enum PaceStatus {
  OnPace = 'ON_PACE',
  AbovePace = 'ABOVE_PACE',
  BelowPace = 'BELOW_PACE',
}

export type CombineProgress = {
  __typename?: 'CombineProgress';
  actual?: Maybe<Scalars['Int']>;
  status?: Maybe<PaceStatus>;
  goal?: Maybe<Scalars['Int']>;
  actualPace?: Maybe<Scalars['Float']>;
  goalPace?: Maybe<Scalars['Float']>;
};

export type CombineUserResults = {
  __typename?: 'CombineUserResults';
  isActive: Scalars['Boolean'];
  startDate?: Maybe<Scalars['String']>;
  daysRemaining?: Maybe<Scalars['Int']>;
  progress?: Maybe<CombineProgress>;
};

export enum LeagueLevelName {
  Combine = 'COMBINE',
  Club = 'CLUB',
  Letterman = 'LETTERMAN',
  Starter = 'STARTER',
  AllStar = 'ALL_STAR',
  AllAmerican = 'ALL_AMERICAN',
  Franchise = 'FRANCHISE',
}

export type LeagueQuarterResult = {
  __typename?: 'LeagueQuarterResult';
  level?: Maybe<LeagueLevelName>;
  score?: Maybe<Scalars['Float']>;
  status: Scalars['String'];
  /** @deprecated This field is too specific and rigid. Use `leagueLevelPoints` instead */
  permitSubmittedPoints?: Maybe<Scalars['Float']>;
  /** @deprecated This field is too specific and rigid. Use `leagueLevelPointsPace` instead */
  permitSubmittedPointsPace?: Maybe<Scalars['Float']>;
  /** @deprecated This field is too specific and rigid. Use `leagueLevelGoal` instead */
  permitSubmittedPointsGoal?: Maybe<Scalars['Float']>;
  leagueLevelPoints?: Maybe<Scalars['Float']>;
  leagueLevelPointsPace?: Maybe<Scalars['Float']>;
  leagueLevelGoal?: Maybe<Scalars['Float']>;
  leagueLevelGoalPace?: Maybe<Scalars['Float']>;
};

export type AthleteResult = {
  entity: {
    __typename: string;
    salesMetrics: {
      competitionScoreBySalesMetricPeriod: {
        score: number;
      };
      league: {
        combine: CombineUserResults;
        currentQuarter: LeagueQuarterResult;
      };
      performanceIndicatorsV2: {
        metrics: {
          accountCreated: {
            total: number;
          };
          welcomeCall: {
            total: number;
          };
        };
      };
    };
    salesProfile: {
      ranking: {
        leagueLevel: number;
        __typename: string;
      };
    };
  };
};

export default gql`
  query overviewForAthlete(
    $currentWeekStartDate: String!
    $currentQuarterStartDate: String!
    $rangeForWeek: DateRange!
  ) {
    entity: employee {
      salesMetrics {
        league {
          combine {
            daysRemaining
            progress {
              goal
              actual
              status
              actualPace
              goalPace
            }
          }
          currentQuarter: leagueQuarterResult(quarterDate: $currentQuarterStartDate) {
            level
            score
            status
            leagueLevelPoints
            leagueLevelPointsPace
            leagueLevelGoal
            leagueLevelGoalPace
          }
        }
        competitionScoreBySalesMetricPeriod(salesMetricPeriod: WEEK, startDate: $currentWeekStartDate) {
          score
        }
        performanceIndicatorsV2 {
          metrics: byRange(range: $rangeForWeek) {
            accountCreated {
              total
            }
            welcomeCall {
              total
            }
          }
        }
      }
      salesProfile {
        ranking(salesMetricPeriod: QUARTER, startDate: $currentQuarterStartDate) {
          leagueLevel
        }
      }
    }
  }
`;
