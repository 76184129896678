import styled from '@emotion/styled';
import { ReactNode } from 'react';

type LineItem = {
  left: ReactNode | string;
  right: ReactNode | string;
};

const LinedTable = ({ lineItems }: { lineItems: LineItem[] }) => {
  return (
    <>
      {lineItems.map((item, index) => (
        <Wrapper key={index}>
          <div>{item.left}</div>
          <div>{item.right}</div>
        </Wrapper>
      ))}
    </>
  );
};

const Wrapper = styled.div`
  display: flex;
  border-bottom: 1px solid ${p => p.theme.palette.common.white};

  > div {
    flex: 2;
    padding: ${p => p.theme.spacing(0.5)}px ${p => p.theme.spacing(1)}px;

    &:first-of-type {
      flex: 1;
      text-align: right;
      border-right: 1px solid ${p => p.theme.palette.common.white};
    }
  }
`;

export default LinedTable;
