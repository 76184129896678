import { useQuery } from '@apollo/client';
import styled from '@emotion/styled';
import { Typography } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import CloseIcon from '@material-ui/icons/Close';
import { ContainerLoading } from 'components/loading';
import { LEAGUE_LEVEL_FROM_CAMEL, LEAGUE_LEVEL_TO_DISPLAY, LEAGUE_LEVEL_TO_GOAL } from 'constants/leagueLevel';
import { VIEWS } from 'constants/views';
import {
  athleteLeagueLevelFrequencies,
  AthleteLeagueLevelFrequenciesResult,
  orgLeagueLevelFrequencies,
  OrgLeagueLevelFrequenciesResult,
} from 'queries/leagueLevelFrequencies';
import { OrganizationLeagueLevelName } from 'queries/organizationOverview';
import { startDateOfCurrentQuarter } from 'utilities/dates';

import { Maybe } from '../../apollo/types';
import { LeagueLevelName } from '../../queries/athlete';
import mixpanel from '../../utilities/mixpanel';
import ErrorComponent from '../error-boundary/ErrorComponent';

type Levels = {
  [key: string]: number;
};

type RowProps = {
  highlight: boolean;
};

const LeagueLevelDialog = ({
  closeDialog,
  entityId,
  isDialogOpen,
  leagueLevel,
  type,
}: {
  closeDialog: () => void;
  entityId?: string;
  isDialogOpen: boolean;
  leagueLevel?: Maybe<LeagueLevelName> | Maybe<OrganizationLeagueLevelName>;
  type: string;
}) => {
  const { data: orgData, error: orgError, loading: orgLoading } = useQuery<OrgLeagueLevelFrequenciesResult>(
    orgLeagueLevelFrequencies,
    {
      variables: {
        entityId: entityId,
        startOfQuarter: startDateOfCurrentQuarter(),
      },
      skip: type === VIEWS.ATHLETE,
    },
  );
  const {
    data: athleteData,
    error: athleteError,
    loading: athleteLoading,
  } = useQuery<AthleteLeagueLevelFrequenciesResult>(athleteLeagueLevelFrequencies, {
    variables: {
      startOfQuarter: startDateOfCurrentQuarter(),
    },
    skip: type !== VIEWS.ATHLETE,
  });

  if ((type === VIEWS.OFFICE && orgError) || (type === VIEWS.ATHLETE && athleteError)) {
    return (
      <Dialog open={isDialogOpen} keepMounted onClose={closeDialog}>
        <ErrorComponent />
      </Dialog>
    );
  }

  if ((type === VIEWS.OFFICE && orgLoading && !orgData) || (type === VIEWS.ATHLETE && athleteLoading && !athleteData)) {
    return (
      <Dialog open={isDialogOpen} keepMounted onClose={closeDialog}>
        <ContainerLoading />
      </Dialog>
    );
  }

  const athleteLevels: Levels = athleteData?.entity?.salesProfile?.league?.leagueLevelFrequencies || {};
  const orgLevels: Levels = orgData?.entity?.salesProfile?.league?.leagueLevelFrequencies || {};
  const levels = type === VIEWS.OFFICE ? orgLevels : athleteLevels;

  return (
    <Dialog
      open={isDialogOpen}
      maxWidth="md"
      keepMounted
      onClose={() => {
        closeDialog();
        mixpanel.track('Closed League Level Freqencies');
      }}
      aria-labelledby="League Level Frequencies"
      aria-describedby="How many times athlete or office has hit league levels"
    >
      <Title>
        <DialogTitle>LEAGUE LEVEL MAP</DialogTitle>
        <CloseIcon
          onClick={() => {
            closeDialog();
            mixpanel.track('Closed League Level Freqencies');
          }}
          data-testid="close-icon"
        />
      </Title>
      <DialogContent>
        {Object.keys(levels).map((key: string) => {
          if (LEAGUE_LEVEL_FROM_CAMEL[key]) {
            return (
              <Row
                key={key}
                highlight={
                  leagueLevel
                    ? LEAGUE_LEVEL_TO_DISPLAY[leagueLevel] === LEAGUE_LEVEL_TO_DISPLAY[LEAGUE_LEVEL_FROM_CAMEL[key]]
                    : false
                }
              >
                <div>
                  <div>
                    <Typography variant="h4">{LEAGUE_LEVEL_TO_DISPLAY[LEAGUE_LEVEL_FROM_CAMEL[key]]}</Typography>
                    <Typography variant="h6">x{levels[key]}</Typography>
                  </div>
                  <Typography variant="h6">{LEAGUE_LEVEL_TO_GOAL[LEAGUE_LEVEL_FROM_CAMEL[key]]} ICP</Typography>
                </div>
              </Row>
            );
          }
          return null;
        })}
      </DialogContent>
    </Dialog>
  );
};

const Title = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  svg {
    cursor: pointer;
    margin-right: ${p => p.theme.spacing(3)}px;
  }
`;

const Row = styled.div<RowProps>`
  position: relative;
  min-width: 600px;
  margin: ${p => p.theme.spacing(5)}px 0;
  padding-bottom: ${p => p.theme.spacing(2)}px;
  border-bottom: 1px solid ${p => (p.highlight ? p.theme.palette.primary.light : p.theme.palette.common.white)};
  color: ${p => (p.highlight ? p.theme.palette.primary.light : p.theme.palette.common.white)};

  &:first-of-type {
    margin-top: 0;
  }

  > div {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;

    > div {
      display: flex;
      align-items: flex-end;

      h6 {
        margin-left: ${p => p.theme.spacing(2)}px;
      }
    }

    h6 {
      line-height: 1;
      font-weight: normal;
    }
  }

  h4 {
    font-family: Mystique;
  }

  ${p => p.theme.breakpoints.down('sm')} {
    min-width: 300px;
  }
`;

export default LeagueLevelDialog;
