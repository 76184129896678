import { DAY, MONTH, QUARTER, WEEK, YEAR } from 'constants/periods';
import { DateTime } from 'luxon';

const getRangesForDuration = (period: string, duration: number) => {
  const ranges: { [key: string]: { start: string; end: string } } = {};

  if (period === DAY) {
    [...Array(duration)].forEach((val, index) => {
      const numOfDaysAgo = DateTime.now().minus({ days: index });
      ranges[`range${index + 1}`] = {
        start: numOfDaysAgo.startOf('day').toISODate(),
        end: numOfDaysAgo.endOf('day').toISODate(),
      };
    });
  }
  if (period === WEEK) {
    [...Array(duration)].forEach((val, index) => {
      const numOfWeeksAgo = DateTime.now().minus({ weeks: index });
      ranges[`range${index + 1}`] = {
        start: numOfWeeksAgo.startOf('week').toISODate(),
        end: numOfWeeksAgo.endOf('week').toISODate(),
      };
    });
  }
  if (period === MONTH) {
    [...Array(duration)].forEach((val, index) => {
      const numOfMonthsAgo = DateTime.now().minus({ months: index });
      ranges[`range${index + 1}`] = {
        start: numOfMonthsAgo.startOf('month').toISODate(),
        end: numOfMonthsAgo.endOf('month').toISODate(),
      };
    });
  }
  if (period === QUARTER) {
    [...Array(duration)].forEach((val, index) => {
      const numOfQuartersAgo = DateTime.now().minus({ quarters: index });
      ranges[`range${index + 1}`] = {
        start: numOfQuartersAgo.startOf('quarter').toISODate(),
        end: numOfQuartersAgo.endOf('quarter').toISODate(),
      };
    });
  }
  if (period === YEAR) {
    [...Array(duration)].forEach((val, index) => {
      const numOfYearsAgo = DateTime.now().minus({ years: index });
      ranges[`range${index + 1}`] = {
        start: numOfYearsAgo.startOf('year').toISODate(),
        end: numOfYearsAgo.endOf('year').toISODate(),
      };
    });
  }
  return ranges;
};

const getDisplayRanges = (period: string, ranges: { [key: string]: { start: string; end: string } }) => {
  const rangesArray = Object.values(ranges).map(value => {
    if (period === MONTH) {
      const month = DateTime.fromISO(value.start).monthShort;
      const year = DateTime.fromISO(value.start).year;
      return `${month}'${String(year).slice(-2)}`;
    }
    if (period === QUARTER) {
      const quarterNumber = DateTime.fromISO(value.start).quarter;
      const year = DateTime.fromISO(value.start).year;
      return `Q${quarterNumber}'${String(year).slice(-2)}`;
    }
    if (period === YEAR) {
      return String(DateTime.fromISO(value.start).year);
    }
    // day and week format
    const day = DateTime.fromISO(value.start).day;
    const month = DateTime.fromISO(value.start).month;
    return `${month}/${day}`;
  });

  return rangesArray;
};

export { getDisplayRanges, getRangesForDuration };
