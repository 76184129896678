import Rollbar from 'rollbar';

let environment;
switch (window.location.host) {
  case 'league-live.sunrun.com':
    environment = 'production';
    break;
  case 'majstg-league-live.sunrun.com':
    environment = 'majstg';
    break;
  case 'localhost:3000':
    environment = 'local';
    break;
  default:
    environment = 'dev';
}

export default new Rollbar({
  enabled: environment !== 'local',
  accessToken: '6efb157c540548068214a7d43d498f53', // https://rollbar.com/Sunrun/league-live/settings/general/
  captureUncaught: true,
  captureUnhandledRejections: true,
  payload: {
    environment: environment,
    client: {
      javascript: {
        source_map_enabled: true,
        guess_uncaught_frames: true,
      },
    },
  },
});
