import realMixpanel from 'mixpanel-browser';

import config from '../config';
import { FullEmployee } from '../queries/employee';

// delete window.mixpanel; // remove from global scope, force importing from here

let mixpanel: any;
if (config && config.MIXPANEL_KEY) {
  mixpanel = realMixpanel;
  mixpanel.init(config.MIXPANEL_KEY);
} else {
  // defaults for local and dev so app doesn't crash
  mixpanel = {
    identify: () => null,
    register: () => null,
    reset: () => null,
    track: () => null,
  };
}

const trackUser = (user?: { [key: string]: FullEmployee } | null | undefined) => {
  try {
    if (user) {
      const person = {
        BadgeId: user.employee.badgeId,
        'Full Name': user.employee.contact.fullName,
        'Phone Number': user.employee.contact.phone,
        Email: user.employee.contact.email,
      };

      mixpanel.register(person);
      mixpanel.people.set({ $name: person['Full Name'], $email: person.Email });
      return mixpanel.identify(user.employee.contact.email);
    }
  } catch (e) {
    // don't let mixpanel crash the app
    return null;
  }
};

export default mixpanel;
export { trackUser };
