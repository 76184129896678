export enum Yellow {
  MAIN = '#F2C94C',
  LIGHT = '#FFE366',
  DARK = '#D9B033',
}

export enum Gold {
  MAIN = '#D4BF6A',
  LIGHT = '#EED984',
  DARK = '#7B7046',
}

export enum Black {
  MAIN = '#191919',
  LIGHT = '#222222',
  LIGHTER = '#2E2E2E',
  DARK = '#111111',
}

export enum Blue {
  MAIN = '#2F80ED',
}

export enum Green {
  MAIN = '#27AE60',
}

export enum Red {
  MAIN = '#F44336',
  LIGHT = '#E57373',
  DARK = '#D32F2F',
}

export enum Globals {
  BLACK = '#000000',
  WHITE = '#FFFFFF',
  GRAY = '#E5E5E5',
  DARK_WHITE = '#EBEBEB',
}

export enum Text {
  BLACK = '#000000',
  WHITE = '#FFFFFF',
}
