import { gql } from '@apollo/client';

export type OrgLeagueLevelFrequenciesResult = {
  entity: {
    salesProfile: {
      league: {
        currentQuarter: {
          level: string;
        };
        leagueLevelFrequencies: {
          d1: number;
          d2: number;
          d3: number;
          flagship: number;
          minor: number;
        };
      };
    };
  };
};

export const orgLeagueLevelFrequencies = gql`
  query metrics($entityId: String!, $startOfQuarter: String!) {
    entity: salesOrganizationByOrganizationId(organizationId: $entityId) {
      salesProfile {
        league {
          currentQuarter: leagueQuarterResult(quarterDate: $startOfQuarter) {
            level
          }
          leagueLevelFrequencies {
            minor
            d3
            d2
            d1
            flagship
          }
        }
      }
    }
  }
`;

export type AthleteLeagueLevelFrequenciesResult = {
  entity: {
    salesProfile: {
      league: {
        currentQuarter: {
          level: string;
        };
        leagueLevelFrequencies: {
          allAmerican: number;
          allStar: number;
          club: number;
          franchise: number;
          letterman: number;
          starter: number;
        };
      };
    };
  };
};

export const athleteLeagueLevelFrequencies = gql`
  query metrics($startOfQuarter: String!) {
    entity: employee {
      salesProfile {
        league {
          currentQuarter: leagueQuarterResult(quarterDate: $startOfQuarter) {
            level
          }
          leagueLevelFrequencies {
            club
            letterman
            starter
            allStar
            allAmerican
            franchise
          }
        }
      }
    }
  }
`;
