import styled from '@emotion/styled';
import { Typography } from '@material-ui/core';
import LinedTable from 'components/linedTable';
import { VIEWS } from 'constants/views';
import { CombineUserResults, LeagueLevelPointsBreakdown, LeagueQuarterResult } from 'queries/leagueLevel';

import Pace from '../../../components/pace';

type LeagueLevelPointsKeysMinusTotals = keyof Omit<LeagueLevelPointsBreakdown, 'total' | '__typename'>;

const Level = ({
  entityId,
  combine,
  quarterResult,
  breakdown,
  title,
  type,
}: {
  entityId: string;
  combine?: CombineUserResults;
  quarterResult?: LeagueQuarterResult;
  breakdown?: LeagueLevelPointsBreakdown | null;
  title: string;
  type: string;
}) => {
  const metrics: { name: LeagueLevelPointsKeysMinusTotals; shortHand: string }[] = [
    { name: 'ppaAndLease', shortHand: 'PPA' },
    { name: 'cashAndLoan', shortHand: 'LOAN' },
  ];

  return (
    <Container>
      <div>
        <Typography variant="h6">{title}</Typography>
        <Pace combine={combine} combineIsActive={combine?.isActive} currentQuarter={quarterResult} type={type} />
      </div>
      {type === VIEWS.ATHLETE && (
        <BreakdownContainer>
          <Typography variant="body1">IC POINTS BREAKDOWN</Typography>
          <LinedTable
            lineItems={metrics.map(metric => {
              return {
                left: <Typography variant="body1">{metric.shortHand}</Typography>,
                right: (
                  <Calculation>
                    <Typography variant="h5">{(breakdown && breakdown[metric.name]?.count) || 0}</Typography>
                    <Typography variant="body2">x{(breakdown && breakdown[metric.name]?.modifier) || 1}</Typography>
                    <Typography variant="body2">=</Typography>
                    <Typography variant="h5">{(breakdown && breakdown[metric.name]?.score) || 0}</Typography>
                  </Calculation>
                ),
              };
            })}
          />
          <Total>
            <Typography variant="body1">TOTAL IC POINTS</Typography>
            <Typography variant="h4">{breakdown?.total?.score || 0}</Typography>
          </Total>
        </BreakdownContainer>
      )}
    </Container>
  );
};

const Container = styled.div`
  h4,
  h5 {
    font-family: constructa;
    font-weight: normal;
  }

  > div {
    padding: ${p => p.theme.spacing(2)}px;

    > h6 {
      text-align: center;
      font-weight: lighter;
      margin-bottom: ${p => p.theme.spacing(2)}px;
    }
  }

  ${p => p.theme.breakpoints.up('md')} {
    display: flex;
    justify-content: center;
    align-items: center;

    > div {
      padding: ${p => p.theme.spacing(2)}px ${p => p.theme.spacing(4)}px;
    }
  }
`;

const BreakdownContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  max-width: 400px;

  ${p => p.theme.breakpoints.down('sm')} {
    margin: auto;
  }

  > p {
    font-weight: bold;
    text-align: left;
    margin-bottom: ${p => p.theme.spacing(1)}px;
  }

  > div p {
    text-align: center;
    font-size: 18px;
  }
`;

const Calculation = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin: 0 ${p => p.theme.spacing(2)}px;

  p {
    font-family: constructa;
    font-weight: normal;
    margin-bottom: ${p => p.theme.spacing(0.25)}px;
  }
`;

const Total = styled.div`
  display: flex;
  align-items: flex-end;
  margin: ${p => p.theme.spacing(1)}px ${p => p.theme.spacing(3)}px 0 auto;

  p {
    margin-right: ${p => p.theme.spacing(1)}px;
    margin-bottom: ${p => p.theme.spacing(0.25)}px;
  }
`;

export default Level;
