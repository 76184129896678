import { PACE } from 'constants/pace';
import { Blue, Globals, Gold, Green } from 'styles/constants/colors';

const status = {
  [PACE.STATUS.BELOW_PACE]: Gold.MAIN,
  [PACE.STATUS.ON_PACE]: Green.MAIN,
  [PACE.STATUS.ABOVE_PACE]: Blue.MAIN,
  BELOW_PACE: Gold.MAIN,
  ON_PACE: Green.MAIN,
  ABOVE_PACE: Blue.MAIN,
};

export const determineStatusColor = (paceStatus?: string) => {
  return paceStatus ? status[paceStatus] : Globals.WHITE;
};

export const determinePaceColor = (paceStatus?: string) => {
  return paceStatus === PACE.STATUS.ABOVE_PACE ? Gold.MAIN : determineStatusColor(paceStatus);
};
