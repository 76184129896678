import { ThemeOptions } from '@material-ui/core';

import { Black, Blue, Globals, Gold, Green, Red, Yellow } from '../constants/colors';

export const LIGHT_THEME_OPTIONS: ThemeOptions = {
  palette: {
    type: 'light',
    primary: {
      main: Black.MAIN,
      contrastText: Globals.WHITE,
      light: Gold.MAIN,
    },
    secondary: {
      main: Gold.MAIN,
      dark: Gold.DARK,
    },
    background: {
      default: Black.LIGHT,
      paper: Black.DARK,
    },
    divider: '#979797',
    text: {
      primary: Globals.WHITE,
      secondary: Globals.GRAY,
      disabled: 'rgba(255,255,255,0.2)',
      hint: 'rgba(0,0,0,0.3)',
    },
    warning: {
      main: Yellow.MAIN,
    },
    error: {
      main: Red.MAIN,
    },
    success: {
      main: Blue.MAIN,
    },
    info: {
      main: Green.MAIN,
    },
  },
  typography: {
    fontFamily: 'Trade Gothic Condensed',
    button: {
      fontFamily: 'Trade Gothic Condensed',
      color: Globals.WHITE,
    },
    subtitle1: {
      fontFamily: 'Trade Gothic Condensed',
    },
    subtitle2: {
      fontFamily: 'Trade Gothic Condensed',
    },
    body1: {
      fontFamily: 'Trade Gothic Condensed',
    },
    body2: {
      fontFamily: 'Trade Gothic Condensed',
    },
    caption: {
      fontFamily: 'Trade Gothic Condensed',
    },
    overline: {
      fontFamily: 'Trade Gothic Condensed',
    },
    h1: {
      fontFamily: 'Trade Gothic Condensed',
      fontWeight: 700,
    },
    h2: {
      fontFamily: 'Trade Gothic Condensed',
      fontWeight: 700,
    },
    h3: {
      fontFamily: 'Trade Gothic Condensed',
      fontWeight: 700,
    },
    h4: {
      fontFamily: 'Trade Gothic Condensed',
      fontWeight: 700,
    },
    h5: {
      fontFamily: 'Trade Gothic Condensed',
      fontWeight: 700,
    },
    h6: {
      fontFamily: 'Trade Gothic Condensed',
      fontWeight: 700,
      fontSize: '1.5rem',
    },
  },
  overrides: {
    MuiIconButton: {
      root: {
        color: Globals.WHITE,
      },
    },
    MuiToolbar: {
      root: {
        width: '100%',
      },
    },
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    /* @ts-ignore */
    MuiPickersToolbar: {
      toolbar: {
        backgroundColor: 'lime',
        color: Black.MAIN,
      },
    },
    MuiPickersCalendarHeader: {
      switchHeader: {
        color: Black.MAIN,
      },
    },
    MuiPickersDay: {
      day: {
        color: Black.MAIN,
      },
      daySelected: {
        backgroundColor: Black.MAIN,
      },
      dayDisabled: {
        color: Black.MAIN,
      },
      current: {
        color: Black.MAIN,
      },
    },
    MuiPickersModal: {
      dialogAction: {
        color: Black.MAIN,
      },
    },
    MuiListItem: {
      root: {
        fontSize: '1rem',
      },
    },
  },
};
