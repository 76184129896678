import { ApolloClient, ApolloLink, from, HttpLink, InMemoryCache, NormalizedCacheObject } from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import { CachePersistor } from 'apollo-cache-persist';
import { PersistedData, PersistentStorage } from 'apollo-cache-persist/types';
import employeeQuery from 'queries/employee';

import { BASE_URLS } from '../constants/baseUrls';
import { provideEnvironment } from '../utilities/env';
import { trackUser } from '../utilities/mixpanel';
import Rollbar from '../utilities/rollbar';
import resolvers from './resolvers';

const APOLLO_CACHE_VERSION = '1';
const APOLLO_CACHE_VERSION_KEY = 'APOLLO_CACHE_VERSION_KEY';

const CACHE_INIT_DATA = {
  employee: {
    __typename: 'Employee',
    badgeId: null,
    groups: [],
    salesOrganization: {
      __typename: 'SalesOrganization',
      ancestryConnection: {
        __typename: 'AncestryConnection',
        organizations: [],
      },
    },
    salesProfile: {
      league: {
        __typename: 'LeagueMetrics',
        combine: {
          __typename: 'CombineUserMetrics',
          isActive: false,
          startDate: null,
        },
      },
      __typename: 'SalesProfile',
      primarySellingLocation: {
        __typename: 'SalesOrganization',
        ancestryConnection: {
          __typename: 'AncestryConnection',
          organizations: [],
        },
      },
      organizationLevels: [],
    },
    contact: {
      __typename: 'Contact',
      email: null,
      fullName: null,
      familyName: null,
      givenName: null,
      phone: null,
    },
  },
};

const linkForErrors = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    graphQLErrors.forEach(({ message, path }) => {
      Rollbar.error(`${message}`, { queryPath: path && path.length > 0 && path.join(', ') });
    });
  }

  if (networkError) {
    const env = provideEnvironment();
    const errorString = JSON.stringify(networkError);
    const error = JSON.parse(errorString).result?.errors[0];
    const authError = error?.code === 'UNAUTHENTICATED';

    if (authError) {
      Rollbar.error(`Unauthenticated User`, {
        errMessage: error.message,
        errStack: error.stack,
      });
      window.parent.postMessage('login redirect', '*');
    }

    if (!authError) {
      Rollbar.error(`Network error: ${error}`);
    }
  }
});

const httpLink = (env: keyof typeof BASE_URLS.SALES_METRICS) => {
  return new HttpLink({
    uri: BASE_URLS.SALES_METRICS[env] || 'https://sales-metrics-gateway-b5i70zx.uc.gateway.dev/salesMetricsGraphQL', // default to prd
  });
};

const authMiddleware = (token: string) => {
  return new ApolloLink((operation, forward) => {
    operation.setContext({ headers: { Authorization: `Bearer ${token}` } });

    return forward(operation);
  });
};

const analyticsMiddleware = new ApolloLink((operation, forward) => {
  return forward(operation).map(response => {
    // track user data only if it's the employee query
    if (operation.operationName === 'Employee') {
      trackUser(response.data);
      Rollbar.configure({
        payload: {
          person: {
            id: response?.data?.employee?.badgeId || 'no badgeId',
            email: response?.data?.employee?.contact?.email || 'no email',
          },
        },
      });
    }
    return response;
  });
});

export const createApolloClientAsync = async (token: string, env: keyof typeof BASE_URLS.SALES_METRICS) => {
  const cache = new InMemoryCache();
  const client = new ApolloClient({
    link: from([authMiddleware(token), analyticsMiddleware, linkForErrors, httpLink(env)]),
    cache,
    resolvers,
    defaultOptions: {
      watchQuery: {
        fetchPolicy: 'cache-and-network',
      },
    },
  });

  const persistor = new CachePersistor({
    cache,
    key: 'PERSISTED_APOLLO_CACHE',
    storage: window.localStorage as PersistentStorage<PersistedData<NormalizedCacheObject>>,
  });

  const currentVersion = localStorage.getItem(APOLLO_CACHE_VERSION_KEY);

  if (currentVersion === APOLLO_CACHE_VERSION) {
    await persistor.restore();
    return client;
  }

  await persistor.purge();
  client.writeQuery({ query: employeeQuery, data: CACHE_INIT_DATA });
  localStorage.setItem(APOLLO_CACHE_VERSION_KEY, APOLLO_CACHE_VERSION);

  return client;
};
