import styled from '@emotion/styled';
import { Typography } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import { VIEWS } from 'constants/views';
import { useEffect, useState } from 'react';

import { Maybe } from '../../../apollo/types';
import { WinLossWeekV2 } from '../../../queries/weeklyWins';

type TypographyColorProps = {
  wincolor: string;
};

const dummyObject = {
  accountCreated: { total: 0, win: false },
  welcomeCall: { total: 0, win: false },
  win: false,
};

const WinsTableData = ({ data, type }: { data?: Maybe<Maybe<WinLossWeekV2>[]> | undefined; type: string }) => {
  const { palette } = useTheme();
  const [modifiedData, setModifiedData] = useState<Maybe<Maybe<WinLossWeekV2>[]> | undefined>(null);
  const WHITE = palette.common.white;
  const GOLD = palette.secondary.main;

  // filling in missing data to populate full table
  useEffect(() => {
    const populateMissingTableData = (queryData?: Maybe<Maybe<WinLossWeekV2>[]>) => {
      const newData = queryData && [...queryData];
      if (newData && newData.length >= 12) {
        return newData;
      }
      if (newData && newData.length < 12) {
        const difference = 12 - newData.length;
        for (let i = 0; i < difference; i++) {
          newData.push(dummyObject);
        }
        return newData;
      }
      if (!newData) {
        const emptyData = [];
        for (let i = 0; i < 12; i++) {
          emptyData.push(dummyObject);
        }
        return emptyData;
      }
    };

    const extraData = populateMissingTableData(data);
    setModifiedData(extraData);
  }, [data]);

  return (
    <Table>
      <Header>
        <div>
          <Typography variant="body1">WK</Typography>
        </div>
        {type === VIEWS.ATHLETE && (
          <>
            <div>
              <Typography variant="body1">AC</Typography>
            </div>
            <div>
              <Typography variant="body1">WC</Typography>
            </div>
            <div>
              <Typography variant="body1">WIN</Typography>
            </div>
          </>
        )}
        {type !== VIEWS.ATHLETE && (
          <div>
            <Typography variant="body1">LS</Typography>
          </div>
        )}
      </Header>
      {modifiedData?.map((week, index) => {
        return (
          <Row key={index}>
            <div>
              <Typography variant="h6">{index + 1}</Typography>
            </div>
            {type === VIEWS.ATHLETE && (
              <>
                <div>
                  <TypographyColor wincolor={week?.win ? GOLD : WHITE} variant="body1">
                    {week?.accountCreated?.total}
                  </TypographyColor>
                </div>
                <div>
                  <TypographyColor wincolor={week?.win ? GOLD : WHITE} variant="body1">
                    {week?.welcomeCall?.total}
                  </TypographyColor>
                </div>
                <div>
                  <TypographyColor wincolor={week?.win ? GOLD : WHITE} variant="body1">
                    {week?.win ? 1 : '-'}
                  </TypographyColor>
                </div>
              </>
            )}
            {type !== VIEWS.ATHLETE && (
              <div>
                <TypographyColor wincolor={week?.win ? GOLD : WHITE} variant="body1">
                  {week?.leagueScore?.total}
                </TypographyColor>
              </div>
            )}
          </Row>
        );
      })}
    </Table>
  );
};

const Table = styled.div`
  max-width: 320px;
  margin: auto;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  border-bottom: 1px solid ${p => p.theme.palette.common.white};

  div {
    flex: 1;
    text-align: center;
  }
`;

const Row = styled.div`
  display: flex;
  justify-content: space-between;
  max-height: 32px;
  text-align: center;
  border-bottom: 1px solid ${p => p.theme.palette.common.white};

  h6 {
    font-family: constructa;
    font-weight: normal;
  }

  p {
    font-size: 18px;
  }

  div {
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 1;
    padding: 0 ${p => p.theme.spacing(0)}px;
    border-right: 1px solid ${p => p.theme.palette.common.white};

    &:last-of-type {
      border-right: none;
    }
  }
`;

const TypographyColor = styled(Typography)<TypographyColorProps>`
  color: ${p => p.wincolor};
`;

export default WinsTableData;
