import { gql } from '@apollo/client';

import { Maybe, Scalars } from '../apollo/types';

export type LeagueScoreTotal = {
  count?: Maybe<Scalars['Int']>;
  score?: Maybe<Scalars['Float']>;
};

export type LeagueScoreTotals = {
  __typename?: 'LeagueScoreTotals';
  score?: Maybe<Scalars['Float']>;
  accountCreated?: Maybe<LeagueScoreTotal>;
  welcomeCall?: Maybe<LeagueScoreTotal>;
  permitSubmitted?: Maybe<LeagueScoreTotal>;
  /** @deprecated Use \"installCompleted\" instead. */
  installScheduled?: Maybe<LeagueScoreTotal>;
  installCompleted?: Maybe<LeagueScoreTotal>;
};

export type EntityRanking = {
  leagueLevel: number;
  office: number;
  region: number;
  division: number;
  company: number;
};

export type LeagueScoreResults = {
  entity: {
    profile: {
      league: {
        leagueScore: {
          totals: {
            score: number;
            accountCreated: LeagueScoreTotal;
            welcomeCall: LeagueScoreTotal;
            permitSubmitted: LeagueScoreTotal;
            installCompleted: LeagueScoreTotal;
          };
        };
      };
      entityRanking?: EntityRanking;
    };
  };
};

export const athleteLeagueScore = gql`
  query athleteScore($entityId: String!, $period: SalesMetricPeriod!, $startDate: String!) {
    entity: employeeByBadgeId(badgeId: $entityId) {
      profile: salesProfile {
        league {
          leagueScore(salesMetricPeriod: $period, startDate: $startDate) {
            totals {
              score
              accountCreated {
                count
                score
              }
              welcomeCall {
                count
                score
              }
              permitSubmitted {
                count
                score
              }
              installCompleted {
                count
                score
              }
            }
          }
        }
        entityRanking: ranking(salesMetricPeriod: $period, startDate: $startDate) {
          leagueLevel
          office
          region
          division
          company
        }
      }
    }
  }
`;

export const organizationLeagueScore = gql`
  query organizationScore($entityId: String!, $period: SalesMetricPeriod!, $startDate: String!) {
    entity: salesOrganizationByOrganizationId(organizationId: $entityId) {
      profile: salesProfile {
        league {
          leagueScore(salesMetricPeriod: $period, startDate: $startDate) {
            totals {
              score
              accountCreated {
                count
                score
              }
              welcomeCall {
                count
                score
              }
              permitSubmitted {
                count
                score
              }
              installCompleted {
                count
                score
              }
            }
          }
        }
      }
    }
  }
`;
