import styled from '@emotion/styled';

type SunProps = {
  sunSize: number;
};

type RayProps = {
  sunSize: number;
  raySize: number;
  rotate: string;
  delay: number;
};

export const Sun = styled.div<SunProps>`
  position: relative;
  width: ${p => p.sunSize * (2 / 3)}em;
  height: ${p => p.sunSize * (2 / 3)}em;
  border-radius: 50%;
  padding: 0;
  list-style: none;
  margin: 50px auto;
  background-color: ${p => p.theme.palette.secondary.main};
`;

export const Ray = styled.div<RayProps>`
  position: absolute;
  top: 50%;
  left: 50%;
  margin: -${p => p.raySize / 2}em;
  width: ${p => p.raySize}em;
  height: ${p => p.raySize}em;
  transform: rotate(${p => p.rotate}deg) translate(${p => p.sunSize / 2}em) rotate(-${p => p.rotate}deg);
  &:after {
    content: '';
    position: absolute;
    left: 25%;
    width: ${p => p.raySize / 2}em;
    height: 100%;
    transform: rotate(calc(${p => p.rotate}deg + 90deg));
    border-radius: ${p => p.raySize}em;
    animation: rayOfSunshine 2s ${p => p.delay * 2}s steps(1) infinite;

    @keyframes rayOfSunshine {
      0% {
        opacity: 0.25;
        background-color: white;
      }
      50% {
        opacity: 1;
        background-color: ${p => p.theme.palette.secondary.main};
      }
    }
  }
`;

const SunSpinner = ({ sunSize, raySize }: { sunSize: number; raySize: number }) => {
  return (
    <Sun sunSize={sunSize}>
      <Ray rotate="0" sunSize={sunSize} raySize={raySize} delay={-0.96} />
      <Ray rotate="45" sunSize={sunSize} raySize={raySize} delay={-0.84} />
      <Ray rotate="90" sunSize={sunSize} raySize={raySize} delay={-0.72} />
      <Ray rotate="135" sunSize={sunSize} raySize={raySize} delay={-0.6} />
      <Ray rotate="180" sunSize={sunSize} raySize={raySize} delay={-0.48} />
      <Ray rotate="225" sunSize={sunSize} raySize={raySize} delay={-0.36} />
      <Ray rotate="270" sunSize={sunSize} raySize={raySize} delay={-0.24} />
      <Ray rotate="315" sunSize={sunSize} raySize={raySize} delay={-0.12} />
    </Sun>
  );
};

export default SunSpinner;
