import memoizeOne from 'memoize-one';

import devConfig from './config.dev';
import localConfig from './config.local';
import prodConfig from './config.prod';
import stageConfig from './config.stage';

const getConfigPure = (hostname: string) => {
  switch (hostname) {
    case devConfig.CLIENT_HOST:
      return devConfig;
    case stageConfig.CLIENT_HOST:
      return stageConfig;
    case prodConfig.CLIENT_HOST:
      return prodConfig;
    default:
      return localConfig;
  }
};

export const getConfig = memoizeOne(getConfigPure);

const getConfigFromWindowHostname = () => getConfig(window.location.hostname);

export default getConfigFromWindowHostname();
