import { gql } from '@apollo/client';

import { Maybe, Scalars } from '../apollo/types';

export type CompetitionScoreMetric = {
  __typename?: 'CompetitionScoreMetric';
  multiplier?: Maybe<Scalars['Int']>;
  count?: Maybe<Scalars['Int']>;
  score?: Maybe<Scalars['Int']>;
};

export type CompetitionMilestoneBreakdown = {
  __typename?: 'CompetitionMilestoneBreakdown';
  ppaAndLease?: Maybe<CompetitionScoreMetric>;
  loanAndCash?: Maybe<CompetitionScoreMetric>;
  total?: Maybe<CompetitionScoreMetric>;
};

export type CompetitionMetrics = {
  __typename?: 'CompetitionMetrics';
  score?: Maybe<Scalars['Int']>;
  /** @deprecated Use leagueCompetition instead. */
  rank?: Maybe<Scalars['Int']>;
  accountCreated?: Maybe<CompetitionMilestoneBreakdown>;
  welcomeCall?: Maybe<CompetitionMilestoneBreakdown>;
};

export type CompetitionScoreResult = {
  entity: {
    day: { competitionScoreBySalesMetricPeriod: CompetitionMetrics };
    week: { competitionScoreBySalesMetricPeriod: CompetitionMetrics };
    month: { competitionScoreBySalesMetricPeriod: CompetitionMetrics };
    quarter: { competitionScoreBySalesMetricPeriod: CompetitionMetrics };
    year: { competitionScoreBySalesMetricPeriod: CompetitionMetrics };
    lifetime: { competitionScoreBySalesMetricPeriod: CompetitionMetrics };
  };
};

export const athleteCompetitionScore = gql`
  query CompetitionScore(
    $entityId: String!
    $startOfToday: String!
    $startOfThisWeek: String!
    $startOfThisMonth: String!
    $startOfThisQuarter: String!
    $startOfThisYear: String!
    $startOfLifetime: String!
  ) {
    entity: employeeByBadgeId(badgeId: $entityId) {
      day: salesMetrics {
        competitionScoreBySalesMetricPeriod(salesMetricPeriod: DAY, startDate: $startOfToday) {
          ...athleteCompetitionMetrics
        }
      }
      week: salesMetrics {
        competitionScoreBySalesMetricPeriod(salesMetricPeriod: WEEK, startDate: $startOfThisWeek) {
          ...athleteCompetitionMetrics
        }
      }
      month: salesMetrics {
        competitionScoreBySalesMetricPeriod(salesMetricPeriod: MONTH, startDate: $startOfThisMonth) {
          ...athleteCompetitionMetrics
        }
      }
      quarter: salesMetrics {
        competitionScoreBySalesMetricPeriod(salesMetricPeriod: QUARTER, startDate: $startOfThisQuarter) {
          ...athleteCompetitionMetrics
        }
      }
      year: salesMetrics {
        competitionScoreBySalesMetricPeriod(salesMetricPeriod: YEAR, startDate: $startOfThisYear) {
          ...athleteCompetitionMetrics
        }
      }
      lifetime: salesMetrics {
        competitionScoreBySalesMetricPeriod(salesMetricPeriod: LIFETIME, startDate: $startOfLifetime) {
          ...athleteCompetitionMetrics
        }
      }
    }
  }
  fragment athleteCompetitionMetrics on CompetitionMetrics {
    score
    accountCreated {
      ...athleteCompetitionBreakdown
    }
    welcomeCall {
      ...athleteCompetitionBreakdown
    }
  }
  fragment athleteCompetitionBreakdown on CompetitionMilestoneBreakdown {
    ppaAndLease {
      ...athleteCompetitionMetric
    }
    loanAndCash {
      ...athleteCompetitionMetric
    }
    total {
      ...athleteCompetitionMetric
    }
  }
  fragment athleteCompetitionMetric on CompetitionScoreMetric {
    multiplier
    count
    score
  }
`;

export const organizationCompetitionScore = gql`
  query CompetitionScore(
    $entityId: String!
    $startOfToday: String!
    $startOfThisWeek: String!
    $startOfThisMonth: String!
    $startOfThisQuarter: String!
    $startOfThisYear: String!
    $startOfLifetime: String!
  ) {
    entity: salesOrganizationByOrganizationId(organizationId: $entityId) {
      day: salesMetrics {
        competitionScoreBySalesMetricPeriod(salesMetricPeriod: DAY, startDate: $startOfToday) {
          ...orgCompetitionMetrics
        }
      }
      week: salesMetrics {
        competitionScoreBySalesMetricPeriod(salesMetricPeriod: WEEK, startDate: $startOfThisWeek) {
          ...orgCompetitionMetrics
        }
      }
      month: salesMetrics {
        competitionScoreBySalesMetricPeriod(salesMetricPeriod: MONTH, startDate: $startOfThisMonth) {
          ...orgCompetitionMetrics
        }
      }
      quarter: salesMetrics {
        competitionScoreBySalesMetricPeriod(salesMetricPeriod: QUARTER, startDate: $startOfThisQuarter) {
          ...orgCompetitionMetrics
        }
      }
      year: salesMetrics {
        competitionScoreBySalesMetricPeriod(salesMetricPeriod: YEAR, startDate: $startOfThisYear) {
          ...orgCompetitionMetrics
        }
      }
      lifetime: salesMetrics {
        competitionScoreBySalesMetricPeriod(salesMetricPeriod: LIFETIME, startDate: $startOfLifetime) {
          ...orgCompetitionMetrics
        }
      }
    }
  }
  fragment orgCompetitionMetrics on CompetitionMetrics {
    score
    accountCreated {
      ...orgCompetitionBreakdown
    }
    welcomeCall {
      ...orgCompetitionBreakdown
    }
  }
  fragment orgCompetitionBreakdown on CompetitionMilestoneBreakdown {
    ppaAndLease {
      ...orgCompetitionMetric
    }
    loanAndCash {
      ...orgCompetitionMetric
    }
    total {
      ...orgCompetitionMetric
    }
  }
  fragment orgCompetitionMetric on CompetitionScoreMetric {
    multiplier
    count
    score
  }
`;
