import { useQuery } from '@apollo/client';
import { ReactComponent as TachometerIcon } from 'assets/icons/tachometer.svg';
import { ContainerLoading } from 'components/loading';
import PageHeader from 'pages/page-header/PageHeader';
import employeeQuery, { EmployeeResult } from 'queries/employee';
import { FC, useEffect, useState } from 'react';

import { VIEWS } from '../../constants/views';
import mixpanel from '../../utilities/mixpanel';
import ErrorComponent from '../error-boundary/ErrorComponent';
import LeagueTV from './LeagueTV';
import OverviewEmployee from './OverviewEmployee';
import OverviewOrganization from './OverviewOrganization';

const Dashboard: FC = () => {
  const { loading, error, data } = useQuery<EmployeeResult>(employeeQuery, { fetchPolicy: 'cache-first' });
  const [dashboardView, setDashboardView] = useState(VIEWS.ATHLETE);
  const primaryOrg = data?.employee?.salesProfile?.primarySellingLocation?.ancestryConnection?.organizations.find(
    org => org.type === VIEWS.OFFICE,
  );

  const menuOptions = [
    {
      value: VIEWS.ATHLETE,
      display: 'My Stats',
      onOptionClick: () => {
        setDashboardView(VIEWS.ATHLETE);
        mixpanel.track('Changed Dashboard view to My Stats');
      },
    },
    {
      value: VIEWS.OFFICE,
      display: `${primaryOrg?.displayName || 'Office'}`,
      onOptionClick: () => {
        setDashboardView(VIEWS.OFFICE);
        mixpanel.track(`Changed Dashboard view to ${primaryOrg?.displayName || 'Office'}`);
      },
    },
  ];

  useEffect(() => {
    mixpanel.track('Viewed Dashboard page');
  }, []);

  if (error) {
    return <ErrorComponent />;
  }

  if (loading && !data) {
    return <ContainerLoading />;
  }

  return (
    <div>
      <PageHeader title="Dashboard" icon={<TachometerIcon fill="white" />} options={menuOptions} />
      {data && dashboardView === VIEWS.ATHLETE && (
        <OverviewEmployee
          fullName={data.employee?.contact?.fullName}
          badgeId={data.employee?.badgeId}
          combineIsActive={data.employee?.salesProfile.league.combine.isActive}
        />
      )}
      {data && dashboardView === VIEWS.OFFICE && (
        <OverviewOrganization orgName={primaryOrg?.displayName} orgId={primaryOrg?.id} />
      )}
      <LeagueTV />
    </div>
  );
};

export default Dashboard;
