import { ReactComponent as CombineLogo } from 'assets/icons/combine.svg';
import { athleteLevelLogos, orgLevelLogos } from 'constants/leagueLevel';
import { VIEWS } from 'constants/views';

export const getLevelLogo = (
  logoType: string,
  combineIsActive: boolean,
  currentGoalLevel: string | 0 | null | undefined,
) => {
  if (logoType === VIEWS.ATHLETE && combineIsActive) {
    return { Logo: CombineLogo };
  }
  if (logoType === VIEWS.OFFICE || logoType === 'TEAM') {
    return orgLevelLogos.find(level => level.level === currentGoalLevel);
  }
  return athleteLevelLogos.find(level => level.level === currentGoalLevel);
};
