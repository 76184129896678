import { useQuery } from '@apollo/client';
import styled from '@emotion/styled';
import { ReactNode } from 'react';

import Carousel from '../../../components/carousel';
import { ContainerLoading } from '../../../components/loading';
import SectionHeader from '../../../components/section-header';
import { ALL_TIME, DAY, MONTH, QUARTER, WEEK, YEAR } from '../../../constants/periods';
import { VIEWS } from '../../../constants/views';
import { athleteContractType, ContractTypeResults, organizationContractType } from '../../../queries/contractType';
import { range } from '../../../utilities/dates';
import ErrorComponent from '../../error-boundary/ErrorComponent';
import DonutChart from './DonutChart';

const ContractType = ({ entityId, type }: { entityId: string; type: string }) => {
  let pagesArray: ReactNode[] = [];
  const dates = {
    rangeForDay: range.day(),
    rangeForWeek: range.week(),
    rangeForMonth: range.month(),
    rangeForQuarter: range.quarter(),
    rangeForYear: range.year(),
    rangeForAllTime: range.allTime(),
  };

  const orgType = type !== VIEWS.ATHLETE;
  const athleteType = type === VIEWS.ATHLETE;
  const { data: orgData, error: orgError, loading: orgLoading } = useQuery<ContractTypeResults>(
    organizationContractType,
    {
      variables: { entityId: entityId, ...dates },
      skip: athleteType,
    },
  );
  const { data: athleteData, error: athleteError, loading: athleteLoading } = useQuery<ContractTypeResults>(
    athleteContractType,
    {
      variables: { entityId: entityId, ...dates },
      skip: !athleteType,
    },
  );

  const entityData = orgData ? orgData : athleteData;

  const aggregations = [
    { period: DAY, data: entityData?.entity.salesMetrics.currentDay },
    { period: WEEK, data: entityData?.entity.salesMetrics.currentWeek },
    { period: MONTH, data: entityData?.entity.salesMetrics.currentMonth },
    { period: QUARTER, data: entityData?.entity.salesMetrics.currentQuarter },
    { period: YEAR, data: entityData?.entity.salesMetrics.currentYear },
    { period: ALL_TIME, data: entityData?.entity.salesMetrics.lifetime },
  ];

  pagesArray = aggregations.map((agg, index) => {
    return (
      <div key={index}>
        <DonutChart period={agg.period} data={agg.data} />
      </div>
    );
  });

  return (
    <div>
      <SectionHeader title="Contract Type" />
      {(orgType && orgError) || (athleteType && athleteError && <ErrorComponent />)}
      {(orgType && orgLoading && !orgData) || (athleteType && athleteLoading && !athleteData && <ContainerLoading />)}
      <CarouselContainer>
        <Carousel pages={pagesArray} title="Contract Type" />
      </CarouselContainer>
    </div>
  );
};

const CarouselContainer = styled.div`
  ${p => p.theme.breakpoints.up('md')} {
    padding: 20px;
  }
`;

export default ContractType;
