import { gql } from '@apollo/client';

import { Maybe, Scalars } from '../apollo/types';

export type MilestoneMetrics = {
  __typename?: 'MilestoneMetrics';
  total?: Maybe<Scalars['Float']>;
};

export type PerformanceIndicators = {
  __typename?: 'PerformanceIndicators';
  accountCreated?: Maybe<MilestoneMetrics>;
  welcomeCall?: Maybe<MilestoneMetrics>;
  permitSubmitted?: Maybe<MilestoneMetrics>;
  installScheduled?: Maybe<MilestoneMetrics>;
  install?: Maybe<MilestoneMetrics>;
};

export type PastRanges = { [key: string]: PerformanceIndicators };

export type PastSeven = {
  entity: {
    salesMetrics: PastRanges;
  };
};

export type PastFourteen = {
  entity: {
    salesMetrics: PastRanges;
  };
};

export type PastTwentyOne = {
  entity: {
    salesMetrics: PastRanges;
  };
};

export const athletePastSeven = gql`
  query metrics(
    $entityId: String!
    $range1: DateRange!
    $range2: DateRange!
    $range3: DateRange!
    $range4: DateRange!
    $range5: DateRange!
    $range6: DateRange!
    $range7: DateRange!
  ) {
    entity: employeeByBadgeId(badgeId: $entityId) {
      salesMetrics {
        range1: performanceIndicators(range: $range1) {
          ...keyPerformanceIndicatorsAthleteSeven
        }
        range2: performanceIndicators(range: $range2) {
          ...keyPerformanceIndicatorsAthleteSeven
        }
        range3: performanceIndicators(range: $range3) {
          ...keyPerformanceIndicatorsAthleteSeven
        }
        range4: performanceIndicators(range: $range4) {
          ...keyPerformanceIndicatorsAthleteSeven
        }
        range5: performanceIndicators(range: $range5) {
          ...keyPerformanceIndicatorsAthleteSeven
        }
        range6: performanceIndicators(range: $range6) {
          ...keyPerformanceIndicatorsAthleteSeven
        }
        range7: performanceIndicators(range: $range7) {
          ...keyPerformanceIndicatorsAthleteSeven
        }
      }
    }
  }
  fragment keyPerformanceIndicatorsAthleteSeven on PerformanceIndicators {
    accountCreated {
      total
    }
    welcomeCall {
      total
    }
    permitSubmitted {
      total
    }
    installScheduled {
      total
    }
    install {
      total
    }
  }
`;

export const athletePastFourteen = gql`
  query metrics(
    $entityId: String!
    $range1: DateRange!
    $range2: DateRange!
    $range3: DateRange!
    $range4: DateRange!
    $range5: DateRange!
    $range6: DateRange!
    $range7: DateRange!
    $range8: DateRange!
    $range9: DateRange!
    $range10: DateRange!
    $range11: DateRange!
    $range12: DateRange!
    $range13: DateRange!
    $range14: DateRange!
  ) {
    entity: employeeByBadgeId(badgeId: $entityId) {
      salesMetrics {
        range1: performanceIndicators(range: $range1) {
          ...keyPerformanceIndicatorsAthleteFourteen
        }
        range2: performanceIndicators(range: $range2) {
          ...keyPerformanceIndicatorsAthleteFourteen
        }
        range3: performanceIndicators(range: $range3) {
          ...keyPerformanceIndicatorsAthleteFourteen
        }
        range4: performanceIndicators(range: $range4) {
          ...keyPerformanceIndicatorsAthleteFourteen
        }
        range5: performanceIndicators(range: $range5) {
          ...keyPerformanceIndicatorsAthleteFourteen
        }
        range6: performanceIndicators(range: $range6) {
          ...keyPerformanceIndicatorsAthleteFourteen
        }
        range7: performanceIndicators(range: $range7) {
          ...keyPerformanceIndicatorsAthleteFourteen
        }
        range8: performanceIndicators(range: $range8) {
          ...keyPerformanceIndicatorsAthleteFourteen
        }
        range9: performanceIndicators(range: $range9) {
          ...keyPerformanceIndicatorsAthleteFourteen
        }
        range10: performanceIndicators(range: $range10) {
          ...keyPerformanceIndicatorsAthleteFourteen
        }
        range11: performanceIndicators(range: $range11) {
          ...keyPerformanceIndicatorsAthleteFourteen
        }
        range12: performanceIndicators(range: $range12) {
          ...keyPerformanceIndicatorsAthleteFourteen
        }
        range13: performanceIndicators(range: $range13) {
          ...keyPerformanceIndicatorsAthleteFourteen
        }
        range14: performanceIndicators(range: $range14) {
          ...keyPerformanceIndicatorsAthleteFourteen
        }
      }
    }
  }
  fragment keyPerformanceIndicatorsAthleteFourteen on PerformanceIndicators {
    accountCreated {
      total
    }
    welcomeCall {
      total
    }
    permitSubmitted {
      total
    }
    installScheduled {
      total
    }
    install {
      total
    }
  }
`;

export const athletePastTwentyOne = gql`
  query metrics(
    $entityId: String!
    $range1: DateRange!
    $range2: DateRange!
    $range3: DateRange!
    $range4: DateRange!
    $range5: DateRange!
    $range6: DateRange!
    $range7: DateRange!
    $range8: DateRange!
    $range9: DateRange!
    $range10: DateRange!
    $range11: DateRange!
    $range12: DateRange!
    $range13: DateRange!
    $range14: DateRange!
    $range15: DateRange!
    $range16: DateRange!
    $range17: DateRange!
    $range18: DateRange!
    $range19: DateRange!
    $range20: DateRange!
    $range21: DateRange!
  ) {
    entity: employeeByBadgeId(badgeId: $entityId) {
      salesMetrics {
        range1: performanceIndicators(range: $range1) {
          ...keyPerformanceIndicatorsAthleteTwentyOne
        }
        range2: performanceIndicators(range: $range2) {
          ...keyPerformanceIndicatorsAthleteTwentyOne
        }
        range3: performanceIndicators(range: $range3) {
          ...keyPerformanceIndicatorsAthleteTwentyOne
        }
        range4: performanceIndicators(range: $range4) {
          ...keyPerformanceIndicatorsAthleteTwentyOne
        }
        range5: performanceIndicators(range: $range5) {
          ...keyPerformanceIndicatorsAthleteTwentyOne
        }
        range6: performanceIndicators(range: $range6) {
          ...keyPerformanceIndicatorsAthleteTwentyOne
        }
        range7: performanceIndicators(range: $range7) {
          ...keyPerformanceIndicatorsAthleteTwentyOne
        }
        range8: performanceIndicators(range: $range8) {
          ...keyPerformanceIndicatorsAthleteTwentyOne
        }
        range9: performanceIndicators(range: $range9) {
          ...keyPerformanceIndicatorsAthleteTwentyOne
        }
        range10: performanceIndicators(range: $range10) {
          ...keyPerformanceIndicatorsAthleteTwentyOne
        }
        range11: performanceIndicators(range: $range11) {
          ...keyPerformanceIndicatorsAthleteTwentyOne
        }
        range12: performanceIndicators(range: $range12) {
          ...keyPerformanceIndicatorsAthleteTwentyOne
        }
        range13: performanceIndicators(range: $range13) {
          ...keyPerformanceIndicatorsAthleteTwentyOne
        }
        range14: performanceIndicators(range: $range14) {
          ...keyPerformanceIndicatorsAthleteTwentyOne
        }
        range15: performanceIndicators(range: $range15) {
          ...keyPerformanceIndicatorsAthleteTwentyOne
        }
        range16: performanceIndicators(range: $range16) {
          ...keyPerformanceIndicatorsAthleteTwentyOne
        }
        range17: performanceIndicators(range: $range17) {
          ...keyPerformanceIndicatorsAthleteTwentyOne
        }
        range18: performanceIndicators(range: $range18) {
          ...keyPerformanceIndicatorsAthleteTwentyOne
        }
        range19: performanceIndicators(range: $range19) {
          ...keyPerformanceIndicatorsAthleteTwentyOne
        }
        range20: performanceIndicators(range: $range20) {
          ...keyPerformanceIndicatorsAthleteTwentyOne
        }
        range21: performanceIndicators(range: $range21) {
          ...keyPerformanceIndicatorsAthleteTwentyOne
        }
      }
    }
  }
  fragment keyPerformanceIndicatorsAthleteTwentyOne on PerformanceIndicators {
    accountCreated {
      total
    }
    welcomeCall {
      total
    }
    permitSubmitted {
      total
    }
    installScheduled {
      total
    }
    install {
      total
    }
  }
`;
