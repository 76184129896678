import AppBar from '@material-ui/core/AppBar';
import IconButton from '@material-ui/core/IconButton';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import DropDownMenu, { MenuOptions } from 'components/dropdown';
import { ReactNode } from 'react';

const PageHeader = ({
  title,
  icon,
  options,
  optionSelected,
  leftButtons,
}: {
  title: string;
  icon: React.ReactNode;
  options?: MenuOptions[];
  optionSelected?: string;
  leftButtons?: ReactNode;
}) => {
  return (
    <AppBar position="static" color="inherit">
      <Toolbar>
        <IconButton edge="start" color="inherit" aria-label="menu">
          {icon}
        </IconButton>
        <Typography variant="h6">{title.toUpperCase()}</Typography>
        <div style={{ flexGrow: 1 }} />
        {options && options.length > 0 && <DropDownMenu optionSelected={optionSelected} options={options} />}
        <div style={{ flexGrow: 1 }} />
        {leftButtons}
      </Toolbar>
    </AppBar>
  );
};

export default PageHeader;
