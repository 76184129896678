import { gql } from '@apollo/client';

export type AthleteAveragesResult = {
  entity: {
    currentQuarter: {
      league: {
        leagueScore: {
          totals: { score: number };
        };
      };
      performanceIndicators: {
        accountCreated: { total: number };
        welcomeCall: { total: number };
        permitSubmitted: { total: number };
        installScheduled: { total: number };
        install: { total: number };
        kilowattsInstalled: { total: number };
      };
    };
  };
};

export type OrganizationAveragesResult = {
  entity: {
    currentQuarter: {
      league: {
        leagueScore: {
          totals: { score: number };
        };
      };
      performanceIndicators: {
        accountCreated: { total: number };
        welcomeCall: { total: number };
        permitSubmitted: { total: number };
        installScheduled: { total: number };
        install: { total: number };
        kilowattsInstalled: { total: number };
      };
    };
  };
};

export const athleteAverages = gql`
  query WeeklyAverages($entityId: String!, $rangeForQuarter: DateRange!, $quarterStartDate: String!) {
    entity: employeeByBadgeId(badgeId: $entityId) {
      currentQuarter: salesMetrics {
        league {
          leagueScore(salesMetricPeriod: QUARTER, startDate: $quarterStartDate) {
            totals {
              score
            }
          }
        }
        performanceIndicators(range: $rangeForQuarter) {
          accountCreated {
            ...metrics
          }
          welcomeCall {
            ...metrics
          }
          permitSubmitted {
            ...metrics
          }
          installScheduled {
            ...metrics
          }
          install {
            ...metrics
          }
          kilowattsInstalled {
            ...metrics
          }
        }
      }
    }
  }
  fragment metrics on MilestoneMetrics {
    total
  }
`;

export const organizationAverages = gql`
  query WeeklyAverages($entityId: String!, $rangeForQuarter: DateRange!, $quarterStartDate: String!) {
    entity: salesOrganizationByOrganizationId(organizationId: $entityId) {
      currentQuarter: salesMetrics {
        league {
          leagueScore(salesMetricPeriod: QUARTER, startDate: $quarterStartDate) {
            totals {
              score
            }
          }
        }
        performanceIndicators(range: $rangeForQuarter) {
          accountCreated {
            ...metrics
          }
          welcomeCall {
            ...metrics
          }
          permitSubmitted {
            ...metrics
          }
          installScheduled {
            ...metrics
          }
          install {
            ...metrics
          }
          kilowattsInstalled {
            ...metrics
          }
        }
      }
    }
  }
  fragment metrics on MilestoneMetrics {
    total
  }
`;
