import styled from '@emotion/styled';
import Carousel from 'components/carousel';
import { ReactNode } from 'react';
import {
  startDateOfCurrentDay,
  startDateOfCurrentMonth,
  startDateOfCurrentQuarter,
  startDateOfCurrentWeek,
  startDateOfCurrentYear,
} from 'utilities/dates';

import SectionHeader from '../../../components/section-header';
import { DAY, MONTH, QUARTER, WEEK, YEAR } from '../../../constants/periods';
import Score from './Score';

const LeagueScore = ({ entityId, type }: { entityId: string; type: string }) => {
  let pagesArray: ReactNode[] = [];

  const aggregations = [
    { period: DAY, startDate: startDateOfCurrentDay() },
    { period: WEEK, startDate: startDateOfCurrentWeek() },
    { period: MONTH, startDate: startDateOfCurrentMonth() },
    { period: QUARTER, startDate: startDateOfCurrentQuarter() },
    { period: YEAR, startDate: startDateOfCurrentYear() },
  ];

  pagesArray = aggregations.map(agg => {
    return (
      <div key={agg.period}>
        <Score entityId={entityId} period={agg.period} startDate={agg.startDate} type={type} />
      </div>
    );
  });

  return (
    <div>
      <SectionHeader title="League Score" />
      <CarouselContainer>
        <Carousel pages={pagesArray} title="League Score" />
      </CarouselContainer>
    </div>
  );
};

const CarouselContainer = styled.div`
  ${p => p.theme.breakpoints.up('md')} {
    padding: 20px;
  }
`;

export default LeagueScore;
