import styled from '@emotion/styled';
import { ReactComponent as PlayButton } from 'assets/icons/play-button.svg';
import { ReactComponent as RightTriangle } from 'assets/icons/right-triangle.svg';
import LeagueTVImage from 'assets/images/league-tv.png';
import { LEAGUE_TV_LINK } from 'constants/links';
import PageHeader from 'pages/page-header/PageHeader';

const LeagueTV = () => {
  return (
    <Container>
      <PageHeader title="League TV" icon={<RightTriangle />} />
      <Link href={LEAGUE_TV_LINK} target="_blank" rel="noreferrer">
        <img src={LeagueTVImage} alt="League TV" />
        <PlayButtonIcon />
      </Link>
    </Container>
  );
};

const Container = styled.div`
  position: relative;
  img {
    width: 100%;
  }
`;

const Link = styled.a`
  &:hover {
    svg {
      fill: ${p => p.theme.palette.secondary.main};
    }
  }
`;

const PlayButtonIcon = styled(PlayButton)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  fill: ${p => p.theme.palette.common.white};
  transition: fill 200ms;
`;

export default LeagueTV;
