import { useQuery } from '@apollo/client';
import styled from '@emotion/styled';
import { Typography } from '@material-ui/core';
import getOrganizationLogo from 'assets/orgLogos/orgLogoMappings';
import { ContainerLoading } from 'components/loading';
import ErrorComponent from 'pages/error-boundary/ErrorComponent';
import organizationByIdQuery, { OrganizationByIdResult } from 'queries/organizationById';

import { startDateOfCurrentQuarter } from '../../utilities/dates';

const ScorecardOrganizationProfile = ({ orgId }: { orgId: string }) => {
  const { loading, error, data } = useQuery<OrganizationByIdResult>(organizationByIdQuery, {
    variables: {
      organizationId: orgId,
      quarterDate: startDateOfCurrentQuarter(),
    },
    fetchPolicy: 'cache-and-network',
  });

  if (error) {
    return <ErrorComponent />;
  }

  if (loading && !data) {
    return <ContainerLoading />;
  }

  const OrgSvg = orgId && getOrganizationLogo(orgId);
  const managers = data?.salesOrganizationByOrganizationId.managers;

  const mapManager = ({ email, fullName }: { email?: string; fullName?: string }) => {
    if (fullName) {
      return (
        <Typography variant="body1" key={email}>
          {fullName && fullName.toUpperCase()}
        </Typography>
      );
    }
  };

  return (
    <Container>
      <Content>
        <Title>
          <Typography variant="h3">{data?.salesOrganizationByOrganizationId.displayName.toUpperCase()}</Typography>
        </Title>
        {managers && managers.length > 0 && (
          <Managers>
            <Typography variant="body1">MANAGERS</Typography>
            <div>{managers.map(mapManager)}</div>
          </Managers>
        )}
      </Content>
      {OrgSvg && <OrgSvg />}
    </Container>
  );
};

const Container = styled.div`
  position: relative;
  overflow: hidden;
  padding: ${p => p.theme.spacing(10)}px;

  > svg {
    position: absolute;
    top: -50px;
    left: -50px;
    fill: black;
    width: 500px;
    height: 500px;
    z-index: -1;
    opacity: 0.3;
  }
`;

const Content = styled.div`
  margin: auto;

  ${p => p.theme.breakpoints.up('md')} {
    display: flex;
    justify-content: space-around;
    align-items: center;
  }
`;

const Title = styled.div`
  flex: 2;

  h3 {
    text-align: center;
    color: ${p => p.theme.palette.primary.light};
  }
`;

const Managers = styled.div`
  flex: 1;
  text-align: center;

  > p:first-of-type {
    color: ${p => p.theme.palette.primary.light};
  }

  ${p => p.theme.breakpoints.down('md')} {
    margin-top: ${p => p.theme.spacing(3)}px;
  }

  > div {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

    p {
      flex: 0 0 50%;
      padding: 10px;
    }
  }
`;

export default ScorecardOrganizationProfile;
