import { gql } from '@apollo/client';

import { Maybe, Scalars } from '../apollo/types';

export type HeadCount = {
  active?: Maybe<Scalars['Int']>;
  productive?: Maybe<Scalars['Int']>;
};

export type HeadcountResult = {
  entity: {
    salesProfile: {
      headCount: HeadCount;
    };
  };
};

export const orgHeadcount = gql`
  query orgHeadCount($entityId: String!) {
    entity: salesOrganizationByOrganizationId(organizationId: $entityId) {
      salesProfile {
        headCount {
          productive
          active
        }
      }
    }
  }
`;
