import { gql } from '@apollo/client';

export type EmployeeByBadgeIdResult = {
  employee: {
    hireDate: string;
    workLocation: string;
    contact: {
      givenName: string;
      familyName: string;
      email: string;
    };
    salesProfile: {
      league: {
        combine: {
          isActive: boolean;
        };
        currentQuarter: {
          level: string;
        };
      };
    };
  };
};

export default gql`
  query EmployeeInformation($badgeId: String!, $startOfQuarter: String!) {
    employee: employeeByBadgeId(badgeId: $badgeId) {
      hireDate
      workLocation
      contact {
        givenName
        familyName
        email
      }
      salesProfile {
        league {
          combine {
            isActive
          }
          currentQuarter: leagueQuarterResult(quarterDate: $startOfQuarter) {
            level
          }
        }
      }
    }
  }
`;
