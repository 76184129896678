import { useQuery } from '@apollo/client';
import styled from '@emotion/styled';
import { CircularProgress, Typography } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import { makeStyles } from '@material-ui/core/styles';
import { DayPicker, MonthPicker, YearPicker } from 'components/calendar';
import CustomPicker from 'components/calendar/CustomRangePicker';
import QuarterPicker from 'components/calendar/QuarterPicker';
import WeekPicker from 'components/calendar/WeekPicker';
import { CUSTOM, DAY, MONTH, QUARTER, TimePeriod, WEEK, YEAR } from 'constants/periods';
import { SalesReportType } from 'queries/customerReports';
import { useEffect, useState } from 'react';
import { range } from 'utilities/dates';

import {
  ALL_STATS,
  ALL_STATS_LEASE,
  ALL_STATS_PPA,
  ALL_STATS_SPA,
  getCorrectOrgType,
  LEVEL_UP_INDIVIDUAL,
  LEVEL_UP_ORGANIZATION,
  MY_RECRUITS,
  organizationLevels,
  WEEKLY_WINS,
} from '../../constants/breakdown';
import { Organizations } from '../../queries/employee';
import { organizationNames, OrganizationNamesResult } from '../../queries/organizationNames';
import { Black } from '../../styles/constants/colors';
import mixpanel from '../../utilities/mixpanel';
import { CustomizeOptions, NewReport } from '.';
import SaveReport from './SaveReport';

const useStyles = makeStyles(theme => ({
  list: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.common.black,
    textTransform: 'uppercase',
    fontWeight: 'bolder',
  },
  form: {
    '& label': {
      color: theme.palette.grey[600],
    },
    '& .MuiMenu-list': {
      backgroundColor: theme.palette.common.white,
    },
    '& .MuiOutlinedInput-root': {
      color: theme.palette.grey[400],
      backgroundColor: theme.palette.common.white,

      '& fieldset': {
        borderColor: theme.palette.grey[400],
      },
      '&:hover fieldset': {
        borderColor: theme.palette.grey[800],
      },
      '&.Mui-focused fieldset': {
        borderColor: theme.palette.grey[800],
      },
      '& .MuiOutlinedInput-input': {
        textTransform: 'uppercase',
        color: theme.palette.common.black,
        fontWeight: 'bolder',
      },
    },
  },
}));

const periods: TimePeriod[] = [DAY, WEEK, MONTH, QUARTER, YEAR, CUSTOM];
const rangeMap: {
  [key: string]: {
    start: string;
    end: string;
  };
} = {
  [DAY]: range.day(),
  [WEEK]: range.week(),
  [MONTH]: range.month(),
  [QUARTER]: range.quarter(),
  [YEAR]: range.year(),
  [CUSTOM]: range.day(),
};

const periodOptionsByReportTypeMap: Record<SalesReportType, TimePeriod[]> = {
  [ALL_STATS]: periods,
  [ALL_STATS_LEASE]: periods,
  [ALL_STATS_PPA]: periods,
  [ALL_STATS_SPA]: periods,
  [WEEKLY_WINS]: [WEEK],
  [LEVEL_UP_INDIVIDUAL]: [QUARTER],
  [LEVEL_UP_ORGANIZATION]: [QUARTER],
  [MY_RECRUITS]: periods,
  // TODO: discuss inclusion of Edit on type
  [SalesReportType.EDIT]: periods,
};

const CustomizeModal = ({
  customizeOptions,
  onClose,
  open,
  orgLevels,
  organizations,
  setCustomizeOptions,
  setSelectedAreaName,
  reportNames,
  saveNewReport,
  clearHistory,
  reportType,
}: {
  customizeOptions: CustomizeOptions;
  clearHistory: () => void;
  onClose: () => void;
  open: boolean;
  orgLevels: string[] | undefined;
  organizations: Organizations[] | undefined;
  setCustomizeOptions: (arg0: CustomizeOptions) => void;
  setSelectedAreaName: (arg0: string) => void;
  reportNames?: string[];
  saveNewReport: (arg0: NewReport) => void;
  reportType: SalesReportType;
}) => {
  const classes = useStyles();
  const [openSaveReport, setOpenSaveReport] = useState(false);
  const [level, setLevel] = useState(customizeOptions.level);
  const [levelError, setLevelError] = useState('');
  const [area, setArea] = useState(customizeOptions.area);
  const [areaError, setAreaError] = useState('');
  const [period, setPeriod] = useState(customizeOptions.period);
  const [dates, setDates] = useState(customizeOptions.dates);
  const [view, setView] = useState(customizeOptions.view);
  const [viewError, setViewError] = useState('');
  const [viewOptions, setViewOptions] = useState<string[]>([]);
  const [ancestorId, setAncestorId] = useState('');
  const [orgNamesError, setOrgNamesError] = useState('');
  const { loading, error, data } = useQuery<OrganizationNamesResult>(organizationNames, {
    variables: {
      ancestorId: ancestorId,
      type: level,
    },
    skip: !ancestorId || !level,
  });

  const periodOptions = periodOptionsByReportTypeMap[reportType];
  const isNotRecruitType = reportType !== SalesReportType.MyRecruits;

  const updateOptionsBasedOnLevel = (levelValue: string) => {
    // determine area list by setting ancestorId for query
    const associatedOrg = organizations?.find(org => org.type === getCorrectOrgType(levelValue));
    if (associatedOrg?.parent.id) {
      setAncestorId(associatedOrg?.parent.id);
    }
    // determine view list
    const orgIndex = organizationLevels.indexOf(levelValue);
    const boundedIndex = Math.min(orgIndex + 1, organizationLevels.length - 1);
    const views = organizationLevels.slice(boundedIndex);
    setViewOptions(views);
  };

  useEffect(() => {
    if (customizeOptions.level) {
      setLevel(customizeOptions.level);
      updateOptionsBasedOnLevel(customizeOptions.level);
    }
  }, [customizeOptions.level]);

  // if options change, update
  useEffect(() => {
    setArea(customizeOptions.area);
    setPeriod(customizeOptions.period);
    setDates(customizeOptions.dates);
    setView(customizeOptions.view);
  }, [
    customizeOptions.area,
    customizeOptions.period,
    customizeOptions.dates.start,
    customizeOptions.dates.end,
    customizeOptions.view,
  ]);

  useEffect(() => {
    const noData = data?.salesOrganizationsByAncestor?.edges?.length === 0;
    if (error || noData) {
      setOrgNamesError('No areas available. Please try again later.');
    }
  }, [error, data]);

  const handleChangeLevel = (e: React.ChangeEvent<{ value: unknown }>) => {
    const levelValue = e.target.value as string;
    setLevel(levelValue);
    // reset affected vields
    setLevelError('');
    setArea('');
    setView('');
    updateOptionsBasedOnLevel(levelValue);
  };

  const resetValues = () => {
    setLevel('');
    setArea('');
    setPeriod(DAY);
    setView('');
    setAncestorId('');
  };

  const submit = () => {
    // form validation
    if (!level || !area || !view) {
      if (!level) setLevelError('Please select a level');
      if (!area) setAreaError('Please select an area');
      if (!view) setViewError('Please select a view');
      return;
    }
    clearHistory();
    setCustomizeOptions({ level, area, period, dates, view });
    const findAreaName = areaData?.find(x => x.node.id === area);
    if (findAreaName?.node?.displayName) {
      setSelectedAreaName(findAreaName.node.displayName.toUpperCase());
    }
    mixpanel.track('Breakdown custom options saved', {
      Level: level,
      Area: findAreaName?.node.displayName?.toUpperCase(),
      Period: period,
      'Start Date': dates.start,
      'End Date': dates.end,
      View: view,
    });
    onClose();
  };

  const openSaveReportModal = () => {
    // form validation
    if (!level || !area || !view) {
      if (!level) setLevelError('Please select a level');
      if (!area) setAreaError('Please select an area');
      if (!view) setViewError('Please select a view');
      return;
    }
    setOpenSaveReport(true);
  };

  const areaData = data?.salesOrganizationsByAncestor?.edges;

  return (
    <>
      <Dialog onClose={onClose} open={open} fullWidth>
        <Container>
          <Typography variant="h6">CUSTOMIZE REPORT</Typography>
          <InputContainer>
            {isNotRecruitType && (
              <FormControl variant="outlined" classes={{ root: classes.form }}>
                <InputLabel>LEVEL</InputLabel>
                <Select
                  value={level}
                  onChange={handleChangeLevel}
                  label="LEVEL"
                  MenuProps={{ classes: { list: classes.list } }}
                >
                  {orgLevels &&
                    orgLevels.map(orgLevel => (
                      <MenuItem key={orgLevel} value={orgLevel}>
                        {orgLevel}
                      </MenuItem>
                    ))}
                </Select>
                {levelError && <InputError>{levelError}</InputError>}
              </FormControl>
            )}
            {isNotRecruitType && (
              <FormControl variant="outlined" classes={{ root: classes.form }}>
                <InputLabel>
                  {loading && <CircularProgress size={15} />}
                  {!loading && 'AREA'}
                </InputLabel>
                <Select
                  value={area}
                  onChange={(e: React.ChangeEvent<{ value: unknown }>) => {
                    setAreaError('');
                    setArea(e.target.value as string);
                  }}
                  label="AREA"
                  disabled={!data || loading}
                  MenuProps={{ classes: { list: classes.list } }}
                >
                  {!areaData ||
                    (areaData.length === 0 && (
                      <MenuItem value="">
                        <em>None</em>
                      </MenuItem>
                    ))}
                  {areaData?.map(org => (
                    <MenuItem key={org.node.id} value={org.node.id}>
                      {org.node.displayName}
                    </MenuItem>
                  ))}
                </Select>
                {areaError && <InputError>{areaError}</InputError>}
                {orgNamesError && <ErrorContainer>{orgNamesError}</ErrorContainer>}
              </FormControl>
            )}
            <DateContainer>
              <FormControl variant="outlined" classes={{ root: classes.form }}>
                <InputLabel>PERIOD</InputLabel>
                <Select
                  value={period}
                  onChange={(e: React.ChangeEvent<{ value: unknown }>) => {
                    const newPeriod = e.target.value as TimePeriod;
                    const newRange = rangeMap[newPeriod] || range.day();

                    setPeriod(newPeriod);
                    setDates(newRange);
                  }}
                  label="PERIOD"
                  MenuProps={{ classes: { list: classes.list } }}
                >
                  {periodOptions.map(option => (
                    <MenuItem key={option} value={option}>
                      {option}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              {period === DAY && <DayPicker color={Black.MAIN} outlined onChange={setDates} selectedRange={dates} />}
              {period === WEEK && <WeekPicker color={Black.MAIN} outlined onChange={setDates} selectedRange={dates} />}
              {period === MONTH && (
                <MonthPicker color={Black.MAIN} outlined onChange={setDates} selectedRange={dates} />
              )}
              {period === QUARTER && <QuarterPicker outlined onChange={setDates} selectedRange={dates} />}
              {period === YEAR && <YearPicker color={Black.MAIN} outlined onChange={setDates} selectedRange={dates} />}
              {period === CUSTOM && <CustomPicker outlined onChange={setDates} selectedRange={dates} />}
            </DateContainer>
            {isNotRecruitType && (
              <FormControl variant="outlined" classes={{ root: classes.form }}>
                <InputLabel>VIEW</InputLabel>
                <Select
                  value={view}
                  onChange={(e: React.ChangeEvent<{ value: unknown }>) => {
                    setViewError('');
                    setView(e.target.value as string);
                  }}
                  label="VIEW"
                  MenuProps={{ classes: { list: classes.list } }}
                >
                  {!viewOptions ||
                    (viewOptions.length === 0 && (
                      <MenuItem value="">
                        <em>None</em>
                      </MenuItem>
                    ))}
                  {viewOptions?.length > 0 &&
                    viewOptions.map(option => (
                      <MenuItem key={option} value={option}>
                        {option}
                      </MenuItem>
                    ))}
                </Select>
                {viewError && <InputError>{viewError}</InputError>}
              </FormControl>
            )}
          </InputContainer>
          <ButtonContainer>
            <Button variant="contained" onClick={() => submit()}>
              See Results
            </Button>
            <Button onClick={() => resetValues()}>Reset</Button>
            <Button onClick={openSaveReportModal} disabled={period === CUSTOM}>
              Save Custom Report
            </Button>
          </ButtonContainer>
        </Container>
      </Dialog>
      <SaveReport
        onClose={() => setOpenSaveReport(false)}
        open={openSaveReport}
        reportNames={reportNames}
        report={{
          level: level,
          areaId: area,
          area: areaData?.find(x => x.node.id === area)?.node.displayName,
          period: period,
          view: view,
          name: '',
        }}
        saveNewReport={saveNewReport}
      />
    </>
  );
};

const Container = styled.div`
  padding: ${p => p.theme.spacing(2)}px;
  background-color: ${p => p.theme.palette.common.white};
  color: ${p => p.theme.palette.common.black};
`;

const InputContainer = styled.div`
  > div {
    width: 100%;
    margin: ${p => p.theme.spacing(1)}px 0;
  }
`;

const InputError = styled.div`
  color: ${p => p.theme.palette.error.main};
`;

const DateContainer = styled.div`
  display: flex;

  input {
    text-align: left;
  }

  div[class*='adornedEnd'] {
    padding-right: 0;
  }

  > div {
    flex: 3;

    &:last-of-type {
      flex: 4;
      margin-left: ${p => p.theme.spacing(1)}px;
    }
  }
`;

const ErrorContainer = styled.div`
  color: ${p => p.theme.palette.error.main};
`;

const ButtonContainer = styled.div`
  display: flex;
  margin-top: ${p => p.theme.spacing(2)}px;

  button {
    color: ${p => p.theme.palette.common.black};
    font-weight: bold;

    &:first-of-type {
      background-color: ${p => p.theme.palette.success.main};
      color: ${p => p.theme.palette.common.white};
    }

    &:not(:first-of-type) {
      color: ${p => p.theme.palette.success.main};
    }
    &.Mui-disabled {
      color: ${p => p.theme.palette.text.disabled};
    }
  }
`;

export default CustomizeModal;
