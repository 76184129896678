import { gql } from '@apollo/client';

type AboveBelowSalesStanding = {
  ACCOUNT_CREATED: {
    count: {
      total: number;
    };
  };
  LEAGUE_SCORE: {
    rank: number;
  };
  CUSTOMER_APPROVED_PLAN: {
    count: {
      total: number;
    };
  };
  WELCOME_CALL: {
    count: {
      total: number;
    };
  };
  node: {
    badgeId: string;
    contact: {
      fullName: string;
    };
    salesProfile: {
      league: {
        currentQuarter: {
          level: string;
        };
      };
    };
  };
};

export type LeaderboardQuickViewResult = {
  employee: {
    badgeId: string;
    contact: {
      fullName: string;
    };
    salesProfile: {
      league: {
        currentQuarter: {
          level: string;
        };
      };
      leagueCompetition: {
        ACCOUNT_CREATED: {
          count: {
            total: number;
          };
        };
        LEAGUE_SCORE: {
          rank: number;
        };
        CUSTOMER_APPROVED_PLAN: {
          count: {
            total: number;
          };
        };
        WELCOME_CALL: {
          count: {
            total: number;
          };
        };
        salesStanding: {
          above: {
            edges: AboveBelowSalesStanding[];
          };
          below: {
            edges: AboveBelowSalesStanding[];
          };
        };
      };
    };
  };
};

export default gql`
  query LeaderBoardQuickView($startOfQuarter: String!) {
    employee {
      contact {
        fullName
      }
      badgeId
      salesProfile {
        league {
          currentQuarter: leagueQuarterResult(quarterDate: $startOfQuarter) {
            level
          }
        }
        leagueCompetition(scope: OFFICE, period: { step: QUARTER, range: { start: $startOfQuarter } }) {
          LEAGUE_SCORE: metricStanding(type: LEAGUE_SCORE) {
            rank
          }
          ACCOUNT_CREATED: metricStanding(type: ACCOUNT_CREATED) {
            count {
              total
            }
          }
          WELCOME_CALL: metricStanding(type: WELCOME_CALL) {
            count {
              total
            }
          }
          CUSTOMER_APPROVED_PLAN: metricStanding(type: PERMIT_SUBMITTED) {
            count {
              total
            }
          }
          salesStanding(type: LEAGUE_SCORE) {
            above(first: 5) {
              edges {
                LEAGUE_SCORE: metricStanding(type: LEAGUE_SCORE) {
                  rank
                }
                ACCOUNT_CREATED: metricStanding(type: ACCOUNT_CREATED) {
                  count {
                    total
                  }
                }
                WELCOME_CALL: metricStanding(type: WELCOME_CALL) {
                  count {
                    total
                  }
                }
                CUSTOMER_APPROVED_PLAN: metricStanding(type: PERMIT_SUBMITTED) {
                  count {
                    total
                  }
                }
                node {
                  badgeId
                  contact {
                    fullName
                  }
                  salesProfile {
                    league {
                      currentQuarter: leagueQuarterResult(quarterDate: $startOfQuarter) {
                        level
                      }
                    }
                  }
                }
              }
            }
            below(first: 5) {
              edges {
                LEAGUE_SCORE: metricStanding(type: LEAGUE_SCORE) {
                  rank
                }
                ACCOUNT_CREATED: metricStanding(type: ACCOUNT_CREATED) {
                  count {
                    total
                  }
                }
                WELCOME_CALL: metricStanding(type: WELCOME_CALL) {
                  count {
                    total
                  }
                }
                CUSTOMER_APPROVED_PLAN: metricStanding(type: PERMIT_SUBMITTED) {
                  count {
                    total
                  }
                }
                node {
                  badgeId
                  contact {
                    fullName
                  }
                  salesProfile {
                    league {
                      currentQuarter: leagueQuarterResult(quarterDate: $startOfQuarter) {
                        level
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;
