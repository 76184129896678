import styled from '@emotion/styled';
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import FilterListIcon from '@material-ui/icons/FilterList';
import { useEffect, useState } from 'react';

import DropDownMenu from '../../components/dropdown';
import {
  ALL_AMERICAN,
  ALL_STAR,
  CLUB,
  COMBINE,
  DM,
  FRANCHISE,
  LETTERMAN,
  REGIONAL_DIRECTORS,
  STARTER,
} from '../../constants/breakdown';
import { PERMISSIONS } from '../../constants/permissions';
import { Organizations } from '../../queries/employee';
import mixpanel from '../../utilities/mixpanel';
import useWindowSize from '../../utilities/windowSize';
import { LEADERS, RIVALS } from '.';

type ToggleProps = {
  selected?: number;
};

const Filters = ({
  groups,
  leagueLevel,
  organizationName,
  organizations,
  setLeagueLevel,
  setOrganizationName,
  setVariety,
  variety,
}: {
  groups: string[];
  leagueLevel: string | null;
  organizationName: string;
  variety: string;
  organizations: Organizations[] | undefined;
  setLeagueLevel: (arg: string | null) => void;
  setOrganizationName: (arg: string) => void;
  setVariety: (arg: string) => void;
}) => {
  const [width] = useWindowSize();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [selectedIndex, setSelectedIndex] = useState(0);

  const onChangeLevel = (selection: string | null) => {
    setLeagueLevel(selection);
    mixpanel.track(`Leaderboard filter level changed to ${selection ? selection : 'All Athletes'}`);
  };

  const onChangeVariety = (selection: string) => {
    setVariety(selection);
    mixpanel.track(`Leaderboard variety changed to ${selection}`);
  };

  const leagueLevels = [
    { name: 'All Athletes', value: null, onOptionClick: () => onChangeLevel(null) },
    { name: 'Combine', value: COMBINE, onOptionClick: () => onChangeLevel(COMBINE) },
    { name: 'Club', value: CLUB, onOptionClick: () => onChangeLevel(CLUB) },
    { name: 'Letterman', value: LETTERMAN, onOptionClick: () => onChangeLevel(LETTERMAN) },
    { name: 'Starter', value: STARTER, onOptionClick: () => onChangeLevel(STARTER) },
    { name: 'All-Star', value: ALL_STAR, onOptionClick: () => onChangeLevel(ALL_STAR) },
    { name: 'All-American', value: ALL_AMERICAN, onOptionClick: () => onChangeLevel(ALL_AMERICAN) },
    { name: 'Franchise', value: FRANCHISE, onOptionClick: () => onChangeLevel(FRANCHISE) },
  ];
  const varietyToggles = [
    { name: LEADERS, onOptionClick: () => onChangeVariety(LEADERS) },
    { name: RIVALS, onOptionClick: () => onChangeVariety(RIVALS) },
  ];

  const leagueLevelsPlusManagers = [
    ...leagueLevels,
    { name: 'DM', value: DM, onOptionClick: () => onChangeLevel(DM) },
    { name: 'Regional Directors', value: REGIONAL_DIRECTORS, onOptionClick: () => onChangeLevel(REGIONAL_DIRECTORS) },
  ];

  let leagueLevelFilters = leagueLevels;
  if (groups && (groups.includes(PERMISSIONS.COMPANY) || groups.includes(PERMISSIONS.CONFERENCE))) {
    leagueLevelFilters = leagueLevelsPlusManagers;
  }

  const handleFilterClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleFilterItemClick = (event: React.MouseEvent<HTMLElement>, index: number) => {
    setSelectedIndex(index);
    setAnchorEl(null);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    if (leagueLevel) {
      const filterIndex = leagueLevelFilters.findIndex(x => x.value === leagueLevel);
      setSelectedIndex(filterIndex);
    }
  }, [leagueLevel, groups]);

  const options = organizations?.map(org => ({
    value: org.id,
    display: org.displayName,
    onOptionClick: () => {
      setOrganizationName(org.displayName);
      mixpanel.track(`Leaderboard organization changed to ${org.displayName}`);
    },
  }));

  return (
    <>
      <StyledFilters>
        <StyledDropdownMenu>{options && options.length > 0 && <DropDownMenu options={options} />}</StyledDropdownMenu>
        <StyledRightSide>
          {width > 900 && (
            <ButtonGroup color="secondary" size="small" aria-label="outlined primary button group">
              {varietyToggles.map(toggle => (
                <StyledButton
                  selected={variety === toggle.name ? 1 : 0}
                  key={toggle.name}
                  onClick={toggle.onOptionClick}
                >
                  {toggle.name}
                </StyledButton>
              ))}
            </ButtonGroup>
          )}
          <IconButton onClick={handleFilterClick}>
            <FilterListIcon />
          </IconButton>
          <StyledMenu
            id="header-menu"
            elevation={0}
            anchorEl={anchorEl}
            keepMounted
            getContentAnchorEl={null}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            {leagueLevelFilters.map((option, index) => (
              <StyledMenuItem
                key={option.value}
                selected={index === selectedIndex}
                onClick={event => {
                  handleFilterItemClick(event, index);
                  if (option.onOptionClick) {
                    option.onOptionClick();
                  }
                }}
              >
                {option.name.toUpperCase()}
              </StyledMenuItem>
            ))}
          </StyledMenu>
        </StyledRightSide>
      </StyledFilters>
      {width <= 900 && (
        <StyledTogglesMobile>
          {varietyToggles.map(toggle => (
            <StyledToggleMobile
              key={toggle.name}
              onClick={toggle.onOptionClick}
              selected={variety === toggle.name ? 1 : 0}
            >
              {toggle.name}
            </StyledToggleMobile>
          ))}
        </StyledTogglesMobile>
      )}
    </>
  );
};

const StyledFilters = styled.div`
  display: flex;
  justify-content: space-between;
  padding: ${p => p.theme.spacing(1)}px ${p => p.theme.spacing(3)}px;
  background-color: ${p => p.theme.palette.background.paper};
`;

const StyledDropdownMenu = styled.div`
  span {
    font-size: 20px;
    font-weight: bold;
  }
`;

const StyledRightSide = styled.div`
  display: flex;
  align-items: center;
`;

const StyledButton = styled(Button)<ToggleProps>`
  background-color: ${p => (p.selected ? p.theme.palette.secondary.main : '')};
  color: ${p => (p.selected ? p.theme.palette.primary.main : '')};

  &:hover {
    background-color: ${p => (p.selected ? p.theme.palette.secondary.main : '')};
  }
`;

const StyledTogglesMobile = styled.div`
  display: flex;
  background-color: ${p => p.theme.palette.background.paper};
`;

const StyledToggleMobile = styled.div<ToggleProps>`
  flex: 1;
  padding-bottom: ${p => p.theme.spacing(0.5)}px;
  cursor: pointer;
  font-size: 16px;
  text-align: center;
  font-weight: ${p => (p.selected ? 'bold' : 'normal')};
  border-bottom-width: 2px;
  border-bottom-style: solid;
  border-bottom-color: ${p => (p.selected ? p.theme.palette.common.white : p.theme.palette.background.paper)};
`;

const StyledMenu = styled(Menu)`
  .MuiMenu-list {
    background-color: ${p => p.theme.palette.text.primary};
  }
`;

const StyledMenuItem = styled(MenuItem)`
  background-color: ${p => p.theme.palette.text.primary};
  font-weight: bold;
  color: ${p => p.theme.palette.primary.main};
  &.Mui-selected {
    background-color: ${p => p.theme.palette.text.secondary};
  }
  &.Mui-selected:hover {
    background-color: ${p => p.theme.palette.text.secondary};
  }
  :hover {
    background-color: ${p => p.theme.palette.text.primary};
  }
`;

export default Filters;
