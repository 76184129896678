import { useQuery } from '@apollo/client';
import styled from '@emotion/styled';
import { Typography } from '@material-ui/core';
import { fixedPrecision } from 'utilities/numbers';

import { ContainerLoading } from '../../../components/loading';
import SectionHeader from '../../../components/section-header';
import {
  athleteAverages,
  AthleteAveragesResult,
  LeagueScore,
  PerformanceIndicators,
} from '../../../queries/athleteAverages';
import { range, startDateOfCurrentQuarter } from '../../../utilities/dates';
import ErrorComponent from '../../error-boundary/ErrorComponent';

const AthleteAverage = ({ entityId }: { entityId: string }) => {
  const { data, error, loading } = useQuery<AthleteAveragesResult>(athleteAverages, {
    variables: {
      entityId: entityId,
      rangeForQuarter: range.quarter(),
      quarterStartDate: startDateOfCurrentQuarter(),
    },
  });

  const metrics: { name: keyof PerformanceIndicators; shortHand: string }[] = [
    { name: 'accountCreated', shortHand: 'AC' },
    { name: 'welcomeCall', shortHand: 'WC' },
    { name: 'permitSubmitted', shortHand: 'PS' },
    { name: 'installScheduled', shortHand: 'IS' },
    { name: 'install', shortHand: 'IC' },
    { name: 'kilowattsInstalled', shortHand: 'kWI' },
  ];

  const leagueScore: LeagueScore | undefined = data?.entity.salesMetrics.league.leagueScore;
  const performanceIndicators: PerformanceIndicators | undefined = data?.entity.salesMetrics.performanceIndicators;

  return (
    <div>
      {error && <ErrorComponent />}
      {loading && !data && <ContainerLoading />}
      <SectionHeader title="PER ATHLETE AVERAGE" />
      <Container>
        <Metric>
          <Typography variant="h3">{(leagueScore && fixedPrecision(leagueScore.totals?.score)) || 0}</Typography>
          <Typography variant="body2">LS</Typography>
        </Metric>
        {metrics.map(metric => (
          <Metric key={metric.shortHand}>
            <Typography variant="h3">
              {(performanceIndicators && fixedPrecision(performanceIndicators[metric.name]?.total)) || 0}
            </Typography>
            <Typography variant="body2">{metric.shortHand}</Typography>
          </Metric>
        ))}
      </Container>
    </div>
  );
};

const Container = styled.div`
  display: flex;
  max-width: 1000px;
  margin: auto;
  padding: ${p => p.theme.spacing(3)}px ${p => p.theme.spacing(5)}px;
  overflow-x: scroll;

  h3 {
    font-family: constructa;
    font-weight: lighter;
  }
`;

const Metric = styled.div`
  text-align: center;
  padding: 0 ${p => p.theme.spacing(3)}px;
  margin: auto;
`;

export default AthleteAverage;
