import { ReactComponent as AllAmerican } from 'assets/icons/all-american.svg';
import { ReactComponent as AllStar } from 'assets/icons/all-star.svg';
import { ReactComponent as Club } from 'assets/icons/club.svg';
import { ReactComponent as Flagship } from 'assets/icons/flagship.svg';
import { ReactComponent as Franchise } from 'assets/icons/franchise.svg';
import { ReactComponent as Letterman } from 'assets/icons/letterman.svg';
import { ReactComponent as Minors } from 'assets/icons/minors.svg';
import { ReactComponent as OfficeD1 } from 'assets/icons/office-d1.svg';
import { ReactComponent as OfficeD2 } from 'assets/icons/office-d2.svg';
import { ReactComponent as OfficeD3 } from 'assets/icons/office-d3.svg';
import { ReactComponent as Starter } from 'assets/icons/starter.svg';
import { ReactComponent as Week1 } from 'assets/icons/week-1.svg';
import { ReactComponent as Week2 } from 'assets/icons/week-2.svg';
import { ReactComponent as Week3 } from 'assets/icons/week-3.svg';
import { ReactComponent as Week4 } from 'assets/icons/week-4.svg';
import { ReactComponent as Week5 } from 'assets/icons/week-5.svg';
import { ReactComponent as Week6 } from 'assets/icons/week-6.svg';
import { ReactComponent as Week7 } from 'assets/icons/week-7.svg';
import { ReactComponent as Week8 } from 'assets/icons/week-8.svg';

export const COMBINE = {
  DISPLAY: 'Combine',
  KEY: 'COMBINE',
};
export const LEAGUE_LEVEL_CLUB = 'CLUB';
export const LEAGUE_LEVEL_LETTERMAN = 'LETTERMAN';
export const LEAGUE_LEVEL_STARTER = 'STARTER';
export const LEAGUE_LEVEL_ALL_STAR = 'ALL_STAR';
export const LEAGUE_LEVEL_ALL_AMERICAN = 'ALL_AMERICAN';
export const LEAGUE_LEVEL_FRANCHISE = 'FRANCHISE';

export const LEAGUE_LEVEL_MINOR = 'MINOR';
export const LEAGUE_LEVEL_D3 = 'D3';
export const LEAGUE_LEVEL_D2 = 'D2';
export const LEAGUE_LEVEL_D1 = 'D1';
export const LEAGUE_LEVEL_FLAGSHIP = 'FLAGSHIP';
export const LEAGUE_LEVEL_FLAGSHIP_PLUS = 'FLAGSHIP_PLUS';

export const WEEK_1 = 'WEEK_1';
export const WEEK_2 = 'WEEK_2';
export const WEEK_3 = 'WEEK_3';
export const WEEK_4 = 'WEEK_4';
export const WEEK_5 = 'WEEK_5';
export const WEEK_6 = 'WEEK_6';
export const WEEK_7 = 'WEEK_7';
export const WEEK_8 = 'WEEK_8';

export const LEAGUE_LEVELS = [
  LEAGUE_LEVEL_CLUB,
  LEAGUE_LEVEL_LETTERMAN,
  LEAGUE_LEVEL_STARTER,
  LEAGUE_LEVEL_ALL_STAR,
  LEAGUE_LEVEL_ALL_AMERICAN,
  LEAGUE_LEVEL_FRANCHISE,
];

export const ORG_LEAGUE_LEVELS = [
  LEAGUE_LEVEL_MINOR,
  LEAGUE_LEVEL_D3,
  LEAGUE_LEVEL_D2,
  LEAGUE_LEVEL_D1,
  LEAGUE_LEVEL_FLAGSHIP,
];

export const COMBINE_LEAGUE_LEVELS = [WEEK_1, WEEK_2, WEEK_3, WEEK_4, WEEK_5, WEEK_6, WEEK_7, WEEK_8];

export const LEAGUE_LEVEL_TO_DISPLAY = {
  [COMBINE.KEY]: COMBINE.DISPLAY,
  [LEAGUE_LEVEL_CLUB]: 'Club',
  [LEAGUE_LEVEL_LETTERMAN]: 'Letterman',
  [LEAGUE_LEVEL_STARTER]: 'Starter',
  [LEAGUE_LEVEL_ALL_STAR]: 'All-Star',
  [LEAGUE_LEVEL_ALL_AMERICAN]: 'All-American',
  [LEAGUE_LEVEL_FRANCHISE]: 'Franchise',
  [LEAGUE_LEVEL_MINOR]: 'Minor',
  [LEAGUE_LEVEL_D3]: 'DIVISION 3',
  [LEAGUE_LEVEL_D2]: 'DIVISION 2',
  [LEAGUE_LEVEL_D1]: 'DIVISION 1',
  [LEAGUE_LEVEL_FLAGSHIP]: 'Flagship',
};

export const LEAGUE_LEVEL_TO_GOAL: { [key: string]: number } = {
  [LEAGUE_LEVEL_CLUB]: 0,
  [LEAGUE_LEVEL_LETTERMAN]: 5,
  [LEAGUE_LEVEL_STARTER]: 10,
  [LEAGUE_LEVEL_ALL_STAR]: 15,
  [LEAGUE_LEVEL_ALL_AMERICAN]: 20,
  [LEAGUE_LEVEL_FRANCHISE]: 25,
  [LEAGUE_LEVEL_MINOR]: 0,
  [LEAGUE_LEVEL_D3]: 1000,
  [LEAGUE_LEVEL_D2]: 4000,
  [LEAGUE_LEVEL_D1]: 7000,
  [LEAGUE_LEVEL_FLAGSHIP]: 10000,
};

export const LEAGUE_LEVEL_TO_STARS: { [key: string]: number } = {
  [COMBINE.KEY]: 0,
  [LEAGUE_LEVEL_CLUB]: 1,
  [LEAGUE_LEVEL_LETTERMAN]: 2,
  [LEAGUE_LEVEL_STARTER]: 3,
  [LEAGUE_LEVEL_ALL_STAR]: 4,
  [LEAGUE_LEVEL_ALL_AMERICAN]: 5,
  [LEAGUE_LEVEL_FRANCHISE]: 6,
  TOTAL_STARS: 6,
};

export const LEAGUE_LEVEL_FROM_CAMEL: { [key: string]: string } = {
  club: LEAGUE_LEVEL_CLUB,
  letterman: LEAGUE_LEVEL_LETTERMAN,
  starter: LEAGUE_LEVEL_STARTER,
  allStar: LEAGUE_LEVEL_ALL_STAR,
  allAmerican: LEAGUE_LEVEL_ALL_AMERICAN,
  franchise: LEAGUE_LEVEL_FRANCHISE,
  minor: LEAGUE_LEVEL_MINOR,
  d3: LEAGUE_LEVEL_D3,
  d2: LEAGUE_LEVEL_D2,
  d1: LEAGUE_LEVEL_D1,
  flagship: LEAGUE_LEVEL_FLAGSHIP,
};

export const GOAL_TO_LEAGUE_LEVEL: { [key: number]: string } = {
  5: LEAGUE_LEVEL_LETTERMAN,
  7: LEAGUE_LEVEL_LETTERMAN,
  10: LEAGUE_LEVEL_STARTER,
  14: LEAGUE_LEVEL_STARTER,
  15: LEAGUE_LEVEL_ALL_STAR,
  20: LEAGUE_LEVEL_ALL_AMERICAN,
  21: LEAGUE_LEVEL_ALL_AMERICAN,
  25: LEAGUE_LEVEL_FRANCHISE,
  38: LEAGUE_LEVEL_FRANCHISE,
  1000: LEAGUE_LEVEL_D3,
  4000: LEAGUE_LEVEL_D2,
  7000: LEAGUE_LEVEL_D1,
  10000: LEAGUE_LEVEL_FLAGSHIP,
};

// Logos matched up with levels
export const athleteLevelLogos = [
  { Logo: Club, level: LEAGUE_LEVEL_CLUB },
  { Logo: Letterman, level: LEAGUE_LEVEL_LETTERMAN },
  { Logo: Starter, level: LEAGUE_LEVEL_STARTER },
  { Logo: AllStar, level: LEAGUE_LEVEL_ALL_STAR },
  { Logo: AllAmerican, level: LEAGUE_LEVEL_ALL_AMERICAN },
  { Logo: Franchise, level: LEAGUE_LEVEL_FRANCHISE },
];

export const orgLevelLogos = [
  { Logo: Minors, level: LEAGUE_LEVEL_MINOR },
  { Logo: OfficeD3, level: LEAGUE_LEVEL_D3 },
  { Logo: OfficeD2, level: LEAGUE_LEVEL_D2 },
  { Logo: OfficeD1, level: LEAGUE_LEVEL_D1 },
  { Logo: Flagship, level: LEAGUE_LEVEL_FLAGSHIP },
];

export const combineLevelLogos = [
  { Logo: Week1, level: WEEK_1 },
  { Logo: Week2, level: WEEK_2 },
  { Logo: Week3, level: WEEK_3 },
  { Logo: Week4, level: WEEK_4 },
  { Logo: Week5, level: WEEK_5 },
  { Logo: Week6, level: WEEK_6 },
  { Logo: Week7, level: WEEK_7 },
  { Logo: Week8, level: WEEK_8 },
];
