import { useQuery } from '@apollo/client';
import styled from '@emotion/styled';
import { ContainerLoading } from 'components/loading';
import { orgLevelLogos } from 'constants/leagueLevel';
import { VIEWS } from 'constants/views';
import ErrorComponent from 'pages/error-boundary/ErrorComponent';
import organizationQuery, { OrganizationLeagueLevelName, OrganizationQueryResults } from 'queries/organizationOverview';
import { FC } from 'react';

import { Maybe } from '../../apollo/types';
import { range, startDateOfCurrentQuarter, startDateOfCurrentWeek } from '../../utilities/dates';
import DashboardLevels from './DashboardLevels';
import DashboardProfile from './DashboardProfile';
import DashboardStats from './DashboardStats';

type Props = {
  orgId?: string;
  orgName?: string;
};

const OverviewOrganization: FC<Props> = ({ orgId, orgName }) => {
  const { loading, error, data } = useQuery<OrganizationQueryResults>(organizationQuery, {
    variables: {
      entityId: orgId,
      currentWeekStartDate: startDateOfCurrentWeek(),
      currentQuarterStartDate: startDateOfCurrentQuarter(),
      rangeForWeek: range.week(),
    },
    fetchPolicy: 'cache-first',
  });

  if (error) {
    return <ErrorComponent />;
  }

  if (loading && !data) {
    return <ContainerLoading />;
  }

  const getBackgroundSvg = (level?: Maybe<OrganizationLeagueLevelName>) => {
    return orgLevelLogos.find(x => x.level === level);
  };

  const BackgroundSvg = getBackgroundSvg(data?.entity?.salesMetrics?.league?.currentQuarter?.level);

  return (
    <Container>
      <DashboardProfile
        leagueLevel={data?.entity?.salesMetrics?.league?.currentQuarter?.level}
        orgId={orgId}
        title={orgName}
        type={VIEWS.OFFICE}
      />
      <DashboardLevels entityId={orgId} league={data?.entity?.salesMetrics?.league} type={VIEWS.OFFICE} />
      <DashboardStats
        type={VIEWS.OFFICE}
        compScore={data?.entity?.salesMetrics?.competitionScoreBySalesMetricPeriod.score}
        leagueScore={data?.entity?.salesMetrics?.league?.currentQuarter?.score}
        performanceIndicators={data?.entity?.salesMetrics?.performanceIndicatorsV2?.metrics}
      />
      {BackgroundSvg && <BackgroundSvg.Logo />}
    </Container>
  );
};

const Container = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  padding: ${p => p.theme.spacing(5)}px ${p => p.theme.spacing(3)}px;

  ${p => p.theme.breakpoints.down('sm')} {
    margin-bottom: 90px; // absolutely positioned level frequencies at smaller sizes
    padding: ${p => p.theme.spacing(5)}px 0px;
  }

  > svg {
    position: absolute;
    top: -50px;
    left: -50px;
    fill: black;
    width: 100%;
    height: 500px;
    z-index: -1;
    opacity: 0.3;
  }
`;

export default OverviewOrganization;
