import Navigation from 'components/navigation';
import React from 'react';
import ReactDOM from 'react-dom';

import Providers from './providers';
import reportWebVitals from './reportWebVitals';
const hybridToken = process.env.REACT_APP_HYBRID_TOKEN as string;

ReactDOM.render(
  <React.StrictMode>
    <Providers>
      <Navigation />
    </Providers>
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
