import { gql } from '@apollo/client';

import { Scalars } from '../apollo/types';

export type Organizations = {
  displayName: string;
  id: string;
  type: string;
  parent: {
    id: string;
  };
};

export type FullEmployee = {
  badgeId: string;
  groups: string[];
  contact: {
    email: string;
    familyName: string;
    fullName: string;
    givenName: string;
    phone: string;
  };
  salesProfile: {
    league: {
      combine: {
        isActive: boolean;
        startDate: string;
      };
    };
    primarySellingLocation: {
      ancestryConnection: {
        organizations: Organizations[];
      };
    };
    organizationLevels: Array<Scalars['String']>;
  };
};

export type EmployeeResult = {
  employee: FullEmployee;
  salesPauseInterval?: {
    isPaused: boolean;
    isCompetition: boolean;
  };
};

export const fullEmployeeQueryByBadgeId = gql`
  query EmployeeInformation($badgeId: String!) {
    employee: employeeByBadgeId(badgeId: $badgeId) {
      badgeId
      groups
      contact {
        fullName
        familyName
        givenName
        phone
        email
      }
      salesOrganization {
        ancestryConnection {
          organizations {
            id
            displayName
            type
            primaryLocation
          }
        }
      }
      salesProfile {
        league {
          combine {
            isActive
            startDate
          }
        }
        primarySellingLocation {
          ancestryConnection {
            organizations {
              id
              displayName
              type
              parent {
                id
              }
            }
          }
        }
        organizationLevels
      }
    }
  }
`;

export default gql`
  query Employee {
    employee {
      badgeId
      groups
      contact {
        fullName
        familyName
        givenName
        phone
        email
      }
      salesOrganization {
        ancestryConnection {
          organizations {
            id
            displayName
            type
            primaryLocation
          }
        }
      }
      salesProfile {
        league {
          combine {
            isActive
            startDate
          }
        }
        primarySellingLocation {
          ancestryConnection {
            organizations {
              id
              displayName
              type
              parent {
                id
              }
            }
          }
        }
        organizationLevels
      }
    }
    salesPauseInterval {
      isPaused
      isCompetition
    }
  }
`;
