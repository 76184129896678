import { gql } from '@apollo/client';

import { Maybe, Scalars } from '../apollo/types';

export type SalesOrganization = {
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
};

export type SalesOrganizationDescendantsEdge = {
  node: SalesOrganization;
};

export type SalesOrganizationDescendantsConnection = {
  edges?: Maybe<Array<SalesOrganizationDescendantsEdge>>;
};

export type OrganizationNamesResult = {
  salesOrganizationsByAncestor?: Maybe<SalesOrganizationDescendantsConnection>;
};

export const organizationNames = gql`
  query salesOrganizationNames($ancestorId: ID!, $type: String!) {
    salesOrganizationsByAncestor(ancestorId: $ancestorId, type: $type) {
      edges {
        node {
          displayName
          name
          id
        }
      }
    }
  }
`;
