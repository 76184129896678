import styled from '@emotion/styled';
import { Button, Drawer as DrawerMui, isWidthUp, List, Toolbar, withWidth } from '@material-ui/core';
import { Breakpoint } from '@material-ui/core/styles/createBreakpoints';
import { ReactComponent as BookOpenIcon } from 'assets/icons/book-open.svg';
import { ReactComponent as BulbIcon } from 'assets/icons/bulb.svg';
import { ReactComponent as ChartIcon } from 'assets/icons/chart.svg';
import { ReactComponent as NewsIcon } from 'assets/icons/news.svg';
import { ReactComponent as PollIcon } from 'assets/icons/poll.svg';
import { ReactComponent as ScorecardIcon } from 'assets/icons/scorecard.svg';
import { ReactComponent as TachometerIcon } from 'assets/icons/tachometer.svg';
import { Routes } from 'constants/routes';
import { OneContext } from 'providers/OneProvider';
import { FC, useContext } from 'react';

import NavItem from './nav-item';

type Props = {
  isOpen: boolean;
  drawerWidth: number;
  width: Breakpoint;
  onClose(): void;
};

const SideBar: FC<Props> = props => {
  const { isOpen, drawerWidth, onClose } = props;
  const isDesktopOrTablet = isWidthUp('sm', props.width);
  const {
    state: { isOne },
  } = useContext(OneContext);

  return (
    <>
      {isOne && (
        <OneMenu>
          <NavItem text="Dashboard" Icon={TachometerIcon} to={Routes.DASHBOARD} onClick={onClose} />
          <NavItem text="Leaderboard" Icon={ChartIcon} to={Routes.LEADERBOARD} onClick={onClose} />
          <NavItem text="Breakdown" Icon={PollIcon} to={Routes.BREAKDOWN} onClick={onClose} />
          <NavItem text="Scorecard" Icon={ScorecardIcon} to={Routes.SCORECARD} onClick={onClose} />
          <NavItem text="Glossary" Icon={BookOpenIcon} to={Routes.GLOSSARY} onClick={onClose} />
          <OneStyledButton onClick={() => window.open('https://app.tiled.co/all', '_blank')} startIcon={<NewsIcon />}>
            MY MEDIA
          </OneStyledButton>
          <OneStyledButton
            onClick={() => window.open('https://app.tiled.co/microapp/5e18b02d9d32f5757f725cf1', '_blank')}
            startIcon={<BulbIcon />}
          >
            THE TRADE
          </OneStyledButton>
        </OneMenu>
      )}
      {!isOne && (
        <Drawer
          variant={isDesktopOrTablet ? 'persistent' : 'temporary'}
          open={isOpen || isDesktopOrTablet}
          width={drawerWidth}
          ModalProps={{ keepMounted: !isDesktopOrTablet }}
          onClose={onClose}
        >
          {isDesktopOrTablet && <Toolbar />}
          <DrawerContainer>
            <List>
              <NavItem text="Dashboard" Icon={TachometerIcon} to={Routes.DASHBOARD} onClick={onClose} />
              <NavItem text="Leaderboard" Icon={ChartIcon} to={Routes.LEADERBOARD} onClick={onClose} />
              <NavItem text="Breakdown" Icon={PollIcon} to={Routes.BREAKDOWN} onClick={onClose} />
              <NavItem text="Scorecard" Icon={ScorecardIcon} to={Routes.SCORECARD} onClick={onClose} />
              <NavItem text="Glossary" Icon={BookOpenIcon} to={Routes.GLOSSARY} onClick={onClose} />
              <StyledButton onClick={() => window.open('https://app.tiled.co/all', '_blank')} startIcon={<NewsIcon />}>
                MY MEDIA
              </StyledButton>
              <StyledButton
                onClick={() => window.open('https://app.tiled.co/microapp/5e18b02d9d32f5757f725cf1', '_blank')}
                startIcon={<BulbIcon />}
              >
                THE TRADE
              </StyledButton>
            </List>
          </DrawerContainer>
        </Drawer>
      )}
    </>
  );
};

const OneMenu = styled.nav`
  display: flex;
`;

const StyledButton = styled(Button)`
  width: 100%;
  font-size: 1rem;
  justify-content: flex-start;
  padding-left: 16px;
  padding-right: 16px;

  span .MuiButton-startIcon {
    margin-left: 0px;
    margin-right: 0px;
  }
  span span svg {
    fill: ${p => p.theme.palette.common.white};
    margin-right: 14px;
  }
`;

const OneStyledButton = styled(StyledButton)`
  width: initial;
`;

const Drawer = styled(DrawerMui)<{ width: number }>`
  width: ${p => p.width}px;

  .MuiPaper-root {
    width: ${p => p.width}px;
    border-right: none;
  }
`;

const DrawerContainer = styled.div`
  overflow: auto;
`;

export default withWidth()(SideBar);
