import { useQuery } from '@apollo/client';
import styled from '@emotion/styled';
import { ReactNode } from 'react';

import Carousel from '../../../components/carousel';
import { ContainerLoading } from '../../../components/loading';
import SectionHeader from '../../../components/section-header';
import { ALL_TIME, DAY, MONTH, QUARTER, WEEK, YEAR } from '../../../constants/periods';
import { VIEWS } from '../../../constants/views';
import {
  athleteCompetitionScore,
  CompetitionScoreResult,
  organizationCompetitionScore,
} from '../../../queries/competitionScore';
import {
  startDateOfCurrentDay,
  startDateOfCurrentMonth,
  startDateOfCurrentQuarter,
  startDateOfCurrentWeek,
  startDateOfCurrentYear,
  startDateOfLifetime,
} from '../../../utilities/dates';
import ErrorComponent from '../../error-boundary/ErrorComponent';
import Score from './Score';

const LeagueLevel = ({ entityId, type }: { entityId: string; type: string }) => {
  let pagesArray: ReactNode[] = [];
  const dates = {
    startOfToday: startDateOfCurrentDay(),
    startOfThisWeek: startDateOfCurrentWeek(),
    startOfThisMonth: startDateOfCurrentMonth(),
    startOfThisQuarter: startDateOfCurrentQuarter(),
    startOfThisYear: startDateOfCurrentYear(),
    startOfLifetime: startDateOfLifetime(),
  };

  const orgType = type !== VIEWS.ATHLETE;
  const athleteType = type === VIEWS.ATHLETE;
  const { data: orgData, error: orgError, loading: orgLoading } = useQuery<CompetitionScoreResult>(
    organizationCompetitionScore,
    {
      variables: { entityId: entityId, ...dates },
      skip: athleteType,
    },
  );
  const { data: athleteData, error: athleteError, loading: athleteLoading } = useQuery<CompetitionScoreResult>(
    athleteCompetitionScore,
    {
      variables: { entityId: entityId, ...dates },
      skip: !athleteType,
    },
  );

  const entityData = orgData ? orgData : athleteData;

  const aggregations = [
    { period: DAY, data: entityData?.entity.day.competitionScoreBySalesMetricPeriod },
    { period: WEEK, data: entityData?.entity.week.competitionScoreBySalesMetricPeriod },
    { period: MONTH, data: entityData?.entity.month.competitionScoreBySalesMetricPeriod },
    { period: QUARTER, data: entityData?.entity.quarter.competitionScoreBySalesMetricPeriod },
    { period: YEAR, data: entityData?.entity.year.competitionScoreBySalesMetricPeriod },
    { period: ALL_TIME, data: entityData?.entity.lifetime.competitionScoreBySalesMetricPeriod },
  ];

  pagesArray = aggregations.map((agg, index) => {
    return (
      <div key={index}>
        <Score period={agg.period} data={agg.data} />
      </div>
    );
  });

  return (
    <div>
      <SectionHeader title="Competition Score" />
      {(orgType && orgError) || (athleteType && athleteError && <ErrorComponent />)}
      {(orgType && orgLoading && !orgData) || (athleteType && athleteLoading && !athleteData && <ContainerLoading />)}
      <CarouselContainer>
        <Carousel pages={pagesArray} title="Competition Score" />
      </CarouselContainer>
    </div>
  );
};

const CarouselContainer = styled.div`
  ${p => p.theme.breakpoints.up('md')} {
    padding: 20px;
  }
`;

export default LeagueLevel;
