import styled from '@emotion/styled';
import { Typography } from '@material-ui/core';
import { ReactNode } from 'react';

const SectionHeader = ({
  title,
  leftContent,
  rightContent,
  noSides = true,
}: {
  title: string;
  leftContent?: ReactNode;
  rightContent?: ReactNode;
  noSides?: boolean;
}) => {
  return (
    <Container>
      {!noSides && <div>{leftContent}</div>}
      <Title>
        <Typography variant="h6">{title.toUpperCase()}</Typography>
      </Title>
      {!noSides && <div>{rightContent}</div>}
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  width: 100%;
  padding: ${p => p.theme.spacing(2)}px;
  border-top: 1px solid ${p => p.theme.palette.common.white};
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

  > div {
    flex: 1;
  }
`;

const Title = styled.div`
  text-align: center;

  h6 {
    font-size: 18px;
    font-weight: lighter;
    letter-spacing: 2px;
  }
`;

export default SectionHeader;
