import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { useEffect } from 'react';

import GlossaryIcon from '../../assets/icons/GlossaryIcon';
import mixpanel from '../../utilities/mixpanel';
import PageHeader from '../page-header/PageHeader';
import definitions from './definitions.json';

const Glossary = () => {
  useEffect(() => {
    mixpanel.track('Viewed Glossary page');
  }, []);

  return (
    <div>
      <PageHeader title="GLOSSARY" icon={<GlossaryIcon />} />
      {definitions.map(({ title, description }) => (
        <Accordion
          onChange={(event, expanded) => {
            if (expanded) {
              mixpanel.track(`Opened glossary accordion ${title}`);
            }
          }}
        >
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography>{title.toUpperCase()}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>{description}</Typography>
          </AccordionDetails>
        </Accordion>
      ))}
    </div>
  );
};

export default Glossary;
