function GlossaryIcon() {
  return (
    <svg width={20} height={19} viewBox="0 0 20 19" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M19 0h-7a2.98 2.98 0 00-2 .78A2.98 2.98 0 008 0H1a1 1 0 00-1 1v15a1 1 0 001 1h5.758c.526 0 1.042.214 1.414.586l1.121 1.121c.009.009.021.012.03.021.086.079.182.149.294.196h.002a.996.996 0 00.762 0h.002c.112-.047.208-.117.294-.196.009-.009.021-.012.03-.021l1.121-1.121A2.015 2.015 0 0113.242 17H19a1 1 0 001-1V1a1 1 0 00-1-1zM6.758 15H2V2h6c.552 0 1 .449 1 1v12.689A4.032 4.032 0 006.758 15zM18 15h-4.758c-.799 0-1.584.246-2.242.689V3c0-.551.448-1 1-1h6v13z"
        fill="#fff"
      />
    </svg>
  );
}

export default GlossaryIcon;
