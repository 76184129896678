import styled from '@emotion/styled';
import { Typography } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import FormControl from '@material-ui/core/FormControl';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import { useState } from 'react';

import { NewReport } from '.';

const useStyles = makeStyles(theme => ({
  form: {
    marginBottom: theme.spacing(2),
    width: '100%',
    '& label': {
      color: theme.palette.grey[600],
    },
    '& .MuiMenu-list': {
      backgroundColor: theme.palette.common.white,
    },
    '& .MuiFormHelperText-contained': {
      marginLeft: '5px',
    },
    '& .MuiOutlinedInput-root': {
      color: theme.palette.grey[600],
      backgroundColor: theme.palette.common.white,

      '& fieldset': {
        borderColor: theme.palette.grey[600],
      },
      '&:hover fieldset': {
        borderColor: theme.palette.grey[600],
      },
      '&.Mui-focused fieldset': {
        borderColor: theme.palette.grey[600],
      },
      '& .MuiOutlinedInput-input': {
        textTransform: 'uppercase',
        color: theme.palette.common.black,
        fontWeight: 'bolder',
      },
    },
  },
}));

const SaveReport = ({
  onClose,
  open,
  report,
  reportNames,
  saveNewReport,
}: {
  onClose: () => void;
  open: boolean;
  report: NewReport;
  reportNames?: string[];
  saveNewReport: (arg0: NewReport) => void;
}) => {
  const classes = useStyles();
  const [reportName, setReportName] = useState('');
  const [reportNameError, setReportNameError] = useState('');

  const handleChangeReportName = (e: React.ChangeEvent<{ value: unknown }>) => {
    setReportName(e.target.value as string);
    setReportNameError('');
  };

  const onSubmit = () => {
    // form validation
    if (!reportName) {
      setReportNameError('Please enter a report name.');
      return;
    }

    if (reportName.trim().length < 6 || reportName.trim().length > 50) {
      setReportNameError('Report names must be more than 5 characters and less than 50.');
      return;
    }

    const isDuplicate = reportNames && reportNames.some(name => name.toUpperCase() === reportName.toUpperCase());
    if (isDuplicate) {
      setReportNameError('Report names must be unique. This report name is already in use.');
      return;
    }
    saveNewReport({
      level: report.level,
      area: report.area,
      areaId: report.areaId,
      period: report.period,
      view: report.view,
      name: reportName.toUpperCase(),
    });
    onClose();
  };

  return (
    <Dialog onClose={onClose} open={open}>
      <Container>
        <Typography variant="h6">SAVE CUSTOM REPORT</Typography>
        <ReportDetails>
          <FormControl variant="outlined" classes={{ root: classes.form }}>
            <TextField
              value={reportName}
              onChange={handleChangeReportName}
              required
              id="outlined-required"
              label="REPORT NAME"
              variant="outlined"
              helperText={reportNameError}
              error={Boolean(reportNameError)}
            />
          </FormControl>
          <Typography variant="body2">REPORT DETAILS</Typography>
          {report?.level && <Typography variant="body1">LEVEL: {report.level}</Typography>}
          {report?.area && <Typography variant="body1">AREA: {report.area}</Typography>}
          {report?.period && <Typography variant="body1">PERIOD: {report.period}</Typography>}
          {report?.view && <Typography variant="body1">VIEW: {report.view}</Typography>}
        </ReportDetails>
        <ButtonContainer>
          <Button variant="contained" onClick={onSubmit}>
            Save & Run
          </Button>
          <Button onClick={onClose}>Cancel</Button>
        </ButtonContainer>
      </Container>
    </Dialog>
  );
};

const Container = styled.div`
  padding: ${p => p.theme.spacing(2)}px;
  background-color: ${p => p.theme.palette.common.white};
  color: ${p => p.theme.palette.common.black};
  width: 400px;
  max-width: 100%;
`;

const ReportDetails = styled.div`
  margin: ${p => p.theme.spacing(2)}px 0 ${p => p.theme.spacing(5)}px;
  text-transform: uppercase;

  p {
    margin-left: ${p => p.theme.spacing(1.5)}px;
    &:first-of-type {
      margin-bottom: ${p => p.theme.spacing(1)}px;
    }
    &:not(:first-of-type) {
      font-weight: bold;
    }
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  margin-top: ${p => p.theme.spacing(2)}px;

  button {
    color: ${p => p.theme.palette.common.black};
    font-weight: bold;

    &:first-of-type {
      background-color: ${p => p.theme.palette.success.main};
      color: ${p => p.theme.palette.common.white};
    }

    &:not(:first-of-type) {
      color: ${p => p.theme.palette.success.main};
    }
  }
`;

export default SaveReport;
