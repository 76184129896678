import { useQuery } from '@apollo/client';
import styled from '@emotion/styled';
import { Toolbar } from '@material-ui/core';
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';
import SideBar from 'components/side-bar';
import TopBar from 'components/top-bar';
import { OFFICE } from 'constants/breakdown';
import { Routes } from 'constants/routes';
import Breakdown from 'pages/breakdown';
import Dashboard from 'pages/dashboard';
import Glossary from 'pages/glossary';
import Leaderboard from 'pages/leaderboard';
import Scorecard from 'pages/scorecard';
import { OneContext } from 'providers/OneProvider';
import employeeQuery, { EmployeeResult, Organizations } from 'queries/employee';
import { FC, useCallback, useContext, useState } from 'react';
import { useEffect } from 'react';
import { BrowserRouter as Router, Redirect, Route, Switch } from 'react-router-dom';

import { BASE_URLS } from '../../constants/baseUrls';
import { provideEnvironment } from '../../utilities/env';
import mixpanel from '../../utilities/mixpanel';
import Rollbar from '../../utilities/rollbar';
import BlockedBigsMessage from '../blockedBigsMessage';

const DRAWER_WIDTH = 200;

const Navigation: FC = () => {
  const [isDrawerOpen, setIsDrawerOpen] = useState<boolean>(false);
  const [unauthenticatedError, setUnauthenticatedError] = useState<boolean>(false);
  const [office, setOffice] = useState<Organizations | null | undefined | string>('empty');
  const env = provideEnvironment();
  const {
    state: { isOne },
  } = useContext(OneContext);

  const handleDrawerToggle = useCallback(() => {
    setIsDrawerOpen(value => !value);
    mixpanel.track(`Toggled main sidebar, set to ${isDrawerOpen ? 'open' : 'closed'}`);
  }, []);
  // load the employee data here, so all other pages can just access it from the cache
  const { error, data, loading } = useQuery<EmployeeResult>(employeeQuery, { fetchPolicy: 'cache-and-network' });

  useEffect(() => {
    if (error) {
      const errorString = error?.networkError && JSON.stringify(error.networkError);
      const firstError = errorString && JSON.parse(errorString).result?.errors[0];
      const authError = firstError?.code === 'UNAUTHENTICATED';

      if (authError) {
        setUnauthenticatedError(true);
      }
    }
  }, [error]);

  useEffect(() => {
    if (data) {
      const organizations = data?.employee.salesProfile.primarySellingLocation?.ancestryConnection?.organizations;
      const employeeOffice = organizations ? organizations.find(org => org.type === OFFICE) : null;
      setOffice(employeeOffice);
      if (!employeeOffice) {
        Rollbar.warn('No individual stats');
      }
    }
  }, [data]);

  const isPaused = data?.salesPauseInterval?.isPaused;

  return (
    <Router>
      {!isOne && <TopBar onDrawerToggle={handleDrawerToggle} />}
      <SideBar isOpen={isDrawerOpen} drawerWidth={DRAWER_WIDTH} onClose={handleDrawerToggle} />

      <Content isOne={isOne}>
        <Toolbar variant="dense" />
        {unauthenticatedError && !loading && (
          <StyledError>
            <Typography variant="h6">Unauthenticated</Typography>
            <Typography variant="body1">League Live cannot authenticate you.{!isOne && ' Please log out.'}</Typography>
            {!isOne && (
              <Typography variant="body1">
                <Link href={`${BASE_URLS.SPLAT[env]}login`} target="_parent" color="secondary">
                  Click here
                </Link>{' '}
                and log out if this page does not automatically redirect you.
              </Typography>
            )}
          </StyledError>
        )}
        {!office && !unauthenticatedError && !loading && (
          <StyledError>
            <Typography variant="h6">We don’t have any stats for you yet</Typography>
            <Typography variant="body1">
              Come back after your first AC (Account Created = utility bill submitted or agreement signed). If you
              believe this to be an error please reach out to sales analytics
            </Typography>
          </StyledError>
        )}
        {!unauthenticatedError && office && (
          <Switch>
            <Route path={Routes.DASHBOARD}>
              <Dashboard />
            </Route>
            <Route path={Routes.LEADERBOARD}>{isPaused ? <BlockedBigsMessage /> : <Leaderboard />}</Route>
            <Route path={Routes.BREAKDOWN}>{isPaused ? <BlockedBigsMessage /> : <Breakdown />}</Route>
            <Route path={Routes.SCORECARD}>{isPaused ? <BlockedBigsMessage /> : <Scorecard />}</Route>
            <Route path={Routes.GLOSSARY}>
              <Glossary />
            </Route>
            <Route>
              <Redirect to={Routes.DASHBOARD} />
            </Route>
          </Switch>
        )}
      </Content>
    </Router>
  );
};

const Content = styled.main<{ isOne: boolean }>`
  flex-grow: 1;

  ${p => p.theme.breakpoints.up('sm')} {
    flex-grow: 1;
    margin-left: ${({ isOne }) => (isOne ? '0' : `${DRAWER_WIDTH}px`)};
  }
`;

const StyledError = styled.div`
  flex: 1;
  padding: ${p => p.theme.spacing(6)}px ${p => p.theme.spacing(3)}px;
  text-align: center;
  max-width: 850px;
  margin: auto;

  p {
    font-size: 24px;
  }
`;

export default Navigation;
