import get from 'lodash/get';

export const polarToCartesian = (x = 0, y = 0, radius = 0, degree = 0, offset = 0) => {
  const radian = ((degree - 90) * Math.PI) / 180;

  return {
    x: x + (radius - offset) * Math.cos(radian),
    y: y + (radius - offset) * Math.sin(radian),
  };
};

export const generateArc = (x = 0, y = 0, radius = 0, startAngle = 0, endAngle = 0, offset = 0) => {
  const start = polarToCartesian(x, y, radius, endAngle, offset);
  const end = polarToCartesian(x, y, radius, startAngle, offset);
  const largeArcFlag = endAngle - startAngle <= 180 ? 0 : 1;

  return `M ${get(start, 'x', 0)},${get(start, 'y', 0)} A ${radius},${radius} 0 ${largeArcFlag} 0 ${get(
    end,
    'x',
    0,
  )} ${get(end, 'y', 0)}`;
};
