import { useQuery } from '@apollo/client';
import styled from '@emotion/styled';
import { useEffect, useState } from 'react';

import IncrementalPicker from '../../components/calendar/IncrementalPicker';
import { ContainerLoading } from '../../components/loading';
import { CLUB, OFFICE } from '../../constants/breakdown';
import { ALL_TIME, CUSTOM, DAY, MONTH, QUARTER, TimePeriod, WEEK, YEAR } from '../../constants/periods';
import employeeQuery, { EmployeeResult } from '../../queries/employee';
import { range } from '../../utilities/dates';
import mixpanel from '../../utilities/mixpanel';
import ErrorComponent from '../error-boundary/ErrorComponent';
import CompScore from './comp-score';
import Filters from './Filters';
import Header from './Header';
import LeagueRank from './league-rank';

export const LEAGUE_RANK = 'LEAGUE_RANK';
export const COMP_SCORE = 'COMP_SCORE';
export const LEADERS = 'LEADERS';
export const RIVALS = 'RIVALS';

const Leaderboard = () => {
  const [type, setType] = useState(LEAGUE_RANK);
  const [period, setPeriod] = useState<TimePeriod>(WEEK);
  const [dates, setDates] = useState(range.week());
  const [organizationName, setOrganizationName] = useState(OFFICE);
  const [leagueLevel, setLeagueLevel] = useState<string | null>(null);
  const [variety, setVariety] = useState(LEADERS);

  const { loading, error, data } = useQuery<EmployeeResult>(employeeQuery, { fetchPolicy: 'cache-first' });

  const isPaused = data?.salesPauseInterval?.isCompetition; // is there a current competition? hide company

  const organizations = isPaused
    ? data?.employee.salesProfile.primarySellingLocation.ancestryConnection.organizations.filter(
        x => x.type !== 'COMPANY',
      )
    : data?.employee.salesProfile.primarySellingLocation.ancestryConnection.organizations;

  console.log(organizations, data?.employee.salesProfile.primarySellingLocation.ancestryConnection.organizations);
  const scope = organizations?.find(org => org.displayName === organizationName)?.type;

  const onPeriodUpdate = (newPeriod: TimePeriod) => {
    const timeMap: {
      [key in TimePeriod]: {
        start: string;
        end: string;
      };
    } = {
      [DAY]: range.day(),
      [WEEK]: range.week(),
      [MONTH]: range.month(),
      [QUARTER]: range.quarter(),
      [YEAR]: range.year(),
      [CUSTOM]: range.week(),
      [ALL_TIME]: range.allTime(),
    };

    const newDates = timeMap[newPeriod];

    setDates(newDates);
    setPeriod(newPeriod);
  };

  useEffect(() => {
    mixpanel.track('Viewed Leaderboard page');
  }, []);

  return (
    <>
      <Header type={type} period={period} setType={setType} setPeriod={onPeriodUpdate} />
      <Bar>
        <IncrementalPicker period={period} onChange={setDates} dateRange={dates} />
      </Bar>
      {error && <ErrorComponent />}
      {loading && !data && <ContainerLoading />}
      {data && (
        <>
          <Filters
            groups={data?.employee.groups}
            leagueLevel={leagueLevel}
            organizations={organizations}
            organizationName={organizationName}
            setLeagueLevel={setLeagueLevel}
            setVariety={setVariety}
            setOrganizationName={setOrganizationName}
            variety={variety}
          />
          {type === LEAGUE_RANK && (
            <LeagueRank period={period} dates={dates} scope={scope} leagueLevel={leagueLevel} variety={variety} />
          )}
          {type === COMP_SCORE && (
            <CompScore period={period} dates={dates} scope={scope} leagueLevel={leagueLevel} variety={variety} />
          )}
        </>
      )}
    </>
  );
};

const Bar = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${p => p.theme.palette.background.paper};
`;

export default Leaderboard;
