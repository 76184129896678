import styled from '@emotion/styled';

import SunSpinner from './SunSpinner';

const ContainerLoading = ({ text }: { text?: string }) => {
  return (
    <Container>
      <div>
        <SunSpinner sunSize={7} raySize={1} />
        <Title>{text || 'Loading...'}</Title>
      </div>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
`;

const Title = styled.div`
  font-size: 24px;
`;

export default ContainerLoading;
