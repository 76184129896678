import { gql } from '@apollo/client';

import { Maybe, Scalars } from '../apollo/types';

export enum LeagueLevelName {
  Combine = 'COMBINE',
  Club = 'CLUB',
  Letterman = 'LETTERMAN',
  Starter = 'STARTER',
  AllStar = 'ALL_STAR',
  AllAmerican = 'ALL_AMERICAN',
  Franchise = 'FRANCHISE',
}

export enum OrganizationLeagueLevelName {
  Minor = 'MINOR',
  D3 = 'D3',
  D2 = 'D2',
  D1 = 'D1',
  Flagship = 'FLAGSHIP',
}

export enum PaceStatus {
  OnPace = 'ON_PACE',
  AbovePace = 'ABOVE_PACE',
  BelowPace = 'BELOW_PACE',
}

export type SalesEntity = {
  id: Scalars['String'];
  primaryDisplayName: Scalars['String'];
  secondaryDisplayName?: Scalars['String'];
};

export type SalesReportRow = {
  entity: SalesEntity;
};

export type SalesReportRowConnection = {
  rows?: Maybe<Array<Maybe<SalesReportRow>>>;
};

export type LevelUpIndividualReportRow = SalesReportRow & {
  __typename?: 'LevelUpIndividualReportRow';
  entity: SalesEntity;
  actual: Scalars['Float'];
  goal: Scalars['Float'];
  leagueLevelGoal: LeagueLevelName;
  pace?: Maybe<Scalars['Float']>;
  paceStatus?: Maybe<PaceStatus>;
  leagueLevel: LeagueLevelName;
};

export type LevelUpIndividualReportRowConnection = SalesReportRowConnection & {
  __typename?: 'LevelUpIndividualReportRowConnection';
  rows?: Maybe<Array<Maybe<LevelUpIndividualReportRow>>>;
};

export type LevelUpOrganizationReportRow = SalesReportRow & {
  __typename?: 'LevelUpOrganizationReportRow';
  entity: SalesEntity;
  actual: Scalars['Float'];
  goal: Scalars['Float'];
  leagueLevelGoal: OrganizationLeagueLevelName;
  pace?: Maybe<Scalars['Float']>;
  paceStatus?: Maybe<PaceStatus>;
  leagueLevel: OrganizationLeagueLevelName;
};

export type LevelUpOrganizationReportRowConnection = SalesReportRowConnection & {
  __typename?: 'LevelUpOrganizationReportRowConnection';
  rows?: Maybe<Array<Maybe<LevelUpOrganizationReportRow>>>;
};

export type LevelUpReportResult = {
  levelUpReport: {
    rowsConnection: LevelUpIndividualReportRowConnection | LevelUpOrganizationReportRowConnection;
  };
};

export const AthleteLevelUpQuery = gql`
  query AthleteLevelUpReport(
    $entityId: String!
    $range: DateRange!
    $subEntityLevel: SalesReportSubOrganization!
    $rowFilter: SalesReportRowFilter!
  ) {
    levelUpReport: salesReport(
      reportType: LEVEL_UP_INDIVIDUAL
      range: $range
      entityId: $entityId
      subEntityLevel: $subEntityLevel
      rowFilter: $rowFilter
      active: true
    ) {
      rowsConnection {
        ... on LevelUpIndividualReportRowConnection {
          rows {
            entity {
              id
              primaryDisplayName
              ... on SalesEntityIndividual {
                secondaryDisplayName
                fullName
              }
            }
            actual
            goal
            leagueLevel
            leagueLevelGoal
            pace
            paceStatus
          }
        }
      }
    }
  }
`;

export const TeamLevelUpQuery = gql`
  query TeamLevelUpReport(
    $entityId: String!
    $range: DateRange!
    $subEntityLevel: SalesReportSubOrganization!
    $rowFilter: SalesReportRowFilter!
  ) {
    levelUpReport: salesReport(
      reportType: LEVEL_UP_ORGANIZATION
      range: $range
      entityId: $entityId
      subEntityLevel: $subEntityLevel
      rowFilter: $rowFilter
      active: true
    ) {
      rowsConnection {
        ... on LevelUpOrganizationReportRowConnection {
          rows {
            entity {
              id
              primaryDisplayName
            }
            actual
            goal
            leagueLevelGoal
            leagueLevel
            pace
            paceStatus
          }
        }
      }
    }
  }
`;
