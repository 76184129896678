import { gql } from '@apollo/client';

import { Maybe, Scalars } from '../apollo/types';

export enum OrganizationLeagueLevelName {
  Minor = 'MINOR',
  D3 = 'D3',
  D2 = 'D2',
  D1 = 'D1',
  Flagship = 'FLAGSHIP',
}

export type LeagueOrganizationQuarterResult = {
  __typename?: 'LeagueOrganizationQuarterResult';
  level?: Maybe<OrganizationLeagueLevelName>;
  score?: Maybe<Scalars['Float']>;
  status: Scalars['String'];
  /** @deprecated This field is too specific and rigid. Use `leagueLevelPoints` instead */
  permitSubmittedPoints?: Maybe<Scalars['Float']>;
  /** @deprecated This field is too specific and rigid. Use `leagueLevelPointsPace` instead */
  permitSubmittedPointsPace?: Maybe<Scalars['Float']>;
  /** @deprecated This field is too specific and rigid. Use `leagueLevelGoal` instead */
  permitSubmittedPointsGoal?: Maybe<Scalars['Float']>;
  leagueLevelPoints?: Maybe<Scalars['Float']>;
  leagueLevelPointsPace?: Maybe<Scalars['Float']>;
  leagueLevelGoal?: Maybe<Scalars['Float']>;
  leagueLevelGoalPace?: Maybe<Scalars['Float']>;
};

export type OrganizationQueryResults = {
  entity: {
    displayName: string;
    salesMetrics: {
      league: {
        currentQuarter: LeagueOrganizationQuarterResult;
      };
      competitionScoreBySalesMetricPeriod: {
        score: number;
      };
      performanceIndicatorsV2: {
        metrics: {
          accountCreated: {
            total: number;
          };
          welcomeCall: {
            total: number;
          };
        };
      };
    };
  };
};

export default gql`
  query overviewForOrganization(
    $entityId: String!
    $currentWeekStartDate: String!
    $currentQuarterStartDate: String!
    $rangeForWeek: DateRange!
  ) {
    entity: salesOrganizationByOrganizationId(organizationId: $entityId) {
      displayName
      salesMetrics {
        league {
          currentQuarter: leagueQuarterResult(quarterDate: $currentQuarterStartDate) {
            level
            score
            status
            leagueLevelPoints
            leagueLevelPointsPace
            leagueLevelGoal
            leagueLevelGoalPace
          }
        }
        competitionScoreBySalesMetricPeriod(salesMetricPeriod: WEEK, startDate: $currentWeekStartDate) {
          score
        }
        performanceIndicatorsV2 {
          metrics: byRange(range: $rangeForWeek) {
            accountCreated {
              total
            }
            welcomeCall {
              total
            }
          }
        }
      }
    }
  }
`;
