import { useQuery } from '@apollo/client';
import styled from '@emotion/styled';
import { Typography } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import Carousel from 'components/carousel';
import { fixedPrecision } from 'utilities/numbers';

import { ContainerLoading } from '../../../components/loading';
import SectionHeader from '../../../components/section-header';
import { VIEWS } from '../../../constants/views';
import { athleteCareerMetrics, CareerMetricsResults, orgCareerMetrics } from '../../../queries/careerMetrics';
import { getPastQuarterDisplay, range } from '../../../utilities/dates';
import useWindowSize from '../../../utilities/windowSize';
import ErrorComponent from '../../error-boundary/ErrorComponent';

const CareerAlltime = ({ entityId, type }: { entityId: string; type: string }) => {
  const theme = useTheme();
  const [width] = useWindowSize();
  const orgType = type !== VIEWS.ATHLETE;
  const athleteType = type === VIEWS.ATHLETE;
  const { data: orgData, error: orgError, loading: orgLoading } = useQuery<CareerMetricsResults>(orgCareerMetrics, {
    variables: {
      entityId: entityId,
      rangeForAllTime: range.allTime(),
    },
    skip: athleteType,
  });
  const { data: athleteData, error: athleteError, loading: athleteLoading } = useQuery<CareerMetricsResults>(
    athleteCareerMetrics,
    {
      variables: {
        entityId: entityId,
        rangeForAllTime: range.allTime(),
      },
      skip: !athleteType,
    },
  );

  if ((orgType && orgError) || (athleteType && athleteError)) {
    return (
      <div>
        <SectionHeader title="CAREER ALL-TIME NUMBER" />
        <ErrorComponent />
      </div>
    );
  }

  if ((orgType && orgLoading && !orgData) || (athleteType && athleteLoading && !athleteData)) {
    return (
      <div>
        <SectionHeader title="CAREER ALL-TIME NUMBER" />
        <ContainerLoading />
      </div>
    );
  }

  const entityData = orgData ? orgData : athleteData;

  const lifetime = entityData?.entity.lifetime;
  const byRange = lifetime?.performanceIndicatorsV2.byRange;

  const metrics = entityData?.entity.metrics;
  const careerBest = metrics?.performanceIndicatorsV2.careerBest;

  const careerNumbersArray = [
    <div key="career-alltime">
      <SectionHeader title="CAREER ALL-TIME NUMBER" noSides />
      <Content>
        <div>
          <Circle>
            <Typography variant="h4">{fixedPrecision(byRange?.install?.total) || 0}</Typography>
          </Circle>
          <Typography variant="body2">IC</Typography>
        </div>
        <div>
          <Circle>
            <Typography variant="h4">{fixedPrecision(byRange?.permitSubmitted?.total) || 0}</Typography>
          </Circle>
          <Typography variant="body2">PS</Typography>
        </div>
        <div>
          <Circle>
            <Typography variant="h4">{fixedPrecision(byRange?.kilowattsInstalled?.total) || 0}</Typography>
          </Circle>
          <Typography variant="body2">kWI</Typography>
        </div>
      </Content>
    </div>,
    <div key="career-best-quarter">
      <SectionHeader title="CAREER BEST QUARTER" noSides />
      <Content>
        <div>
          <Circle>
            <Typography variant="h4">{fixedPrecision(careerBest?.install?.total?.value) || 0}</Typography>
          </Circle>
          <Typography variant="body2">{getPastQuarterDisplay(careerBest?.install?.total?.date)}</Typography>
          <Typography variant="body2">IC</Typography>
        </div>
        <div>
          <Circle>
            <Typography variant="h4">{fixedPrecision(careerBest?.permitSubmitted?.total?.value) || 0}</Typography>
          </Circle>
          <Typography variant="body2">{getPastQuarterDisplay(careerBest?.permitSubmitted?.total?.date)}</Typography>
          <Typography variant="body2">PS</Typography>
        </div>
        <div>
          <Circle>
            <Typography variant="h4">{fixedPrecision(careerBest?.kilowattsInstalled?.total?.value) || 0}</Typography>
          </Circle>
          <Typography variant="body2">{getPastQuarterDisplay(careerBest?.kilowattsInstalled?.total?.date)}</Typography>
          <Typography variant="body2">kWI</Typography>
        </div>
      </Content>
    </div>,
  ];

  // carousel on mobile
  if (width <= theme.breakpoints.values.md) {
    return (
      <CarouselContainer>
        <Carousel pages={careerNumbersArray} title="Career Metrics" />
      </CarouselContainer>
    );
  }
  return <Container>{careerNumbersArray}</Container>;
};

const Container = styled.div`
  display: flex;

  > div {
    flex: 1;
  }
`;

const CarouselContainer = styled.div`
  text-align: center;

  h6 {
    text-align: center;
    font-weight: lighter;
  }
`;

const Content = styled.div`
  display: flex;
  justify-content: center;
  margin: ${p => p.theme.spacing(2)}px 0;

  > div {
    margin: 0 ${p => p.theme.spacing(2)}px;
    text-align: center;

    ${p => p.theme.breakpoints.down('sm')} {
      margin: 0 ${p => p.theme.spacing(1)}px;
    }
  }
`;

const Circle = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100px;
  width: 100px;
  margin-bottom: ${p => p.theme.spacing(1)}px;
  border-radius: 50%;
  border: 6px solid ${p => p.theme.palette.success.main};

  h4 {
    font-family: constructa;
    font-weight: normal;
  }

  ${p => p.theme.breakpoints.down('sm')} {
    height: 80px;
    width: 80px;

    h4 {
      font-size: 24px;
    }
  }
`;

export default CareerAlltime;
