import { EventTracker } from '@devexpress/dx-react-chart';
import {
  ArgumentAxis,
  Chart,
  LineSeries,
  ScatterSeries,
  Tooltip,
  ValueAxis,
} from '@devexpress/dx-react-chart-material-ui';
import styled from '@emotion/styled';
import { Typography } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import { useTheme } from '@material-ui/core/styles';
import { PAST_SEVEN, PAST_TWENTY_ONE } from 'constants/trendsDuration';
import { useState } from 'react';

import { PastFourteen, PastSeven, PastTwentyOne } from '../../../queries/athleteTrends';
import mixpanel from '../../../utilities/mixpanel';
import { getDisplayRanges } from './helpers';

type StyledButtonProps = {
  filled: number;
};

type ChartProps = {
  duration: number;
};

type Ranges = { [key: string]: { start: string; end: string } };

const Point = ({ arg, val, color }: { arg: number; val: number; color: string }) => {
  return (
    <path
      fill={color}
      transform={`translate(${arg} ${val})`}
      d="M3.949327084834294,0A3.949327084834294,3.949327084834294,0,1,1,-3.949327084834294,0A3.949327084834294,3.949327084834294,0,1,1,3.949327084834294,0"
    />
  );
};

const LineWithPoint = (props: LineSeries.SeriesProps) => (
  <>
    <LineSeries.Path {...props} />
    <ScatterSeries.Path {...props} pointComponent={Point} />
  </>
);

const Arrow = (props: Tooltip.ArrowProps) => {
  return (
    <ArrowContainer>
      <Tooltip.Arrow {...props} />
    </ArrowContainer>
  );
};

const Content = (props: Tooltip.ContentProps) => {
  return <Typography variant="body1">{props.text}</Typography>;
};

const Sheet = (props: Tooltip.SheetProps) => {
  return (
    <SheetContainer>
      <Tooltip.Sheet {...props} />
    </SheetContainer>
  );
};

const Graph = ({
  data,
  duration,
  period,
  ranges,
}: {
  data: PastSeven | PastFourteen | PastTwentyOne | undefined;
  duration: number;
  period: string;
  ranges: Ranges;
}) => {
  const [viewMetric, setViewMetric] = useState('AC');
  const metrics = ['AC', 'WC', 'PS', 'IS', 'IC'];
  const theme = useTheme();
  const rangeArray = getDisplayRanges(period, ranges);

  const chartData = rangeArray
    .map((range, index) => ({
      range: range,
      AC: data?.entity.salesMetrics[`range${index + 1}`].accountCreated?.total || 0,
      PS: data?.entity.salesMetrics[`range${index + 1}`].permitSubmitted?.total || 0,
      IC: data?.entity.salesMetrics[`range${index + 1}`].install?.total || 0,
      WC: data?.entity.salesMetrics[`range${index + 1}`].welcomeCall?.total || 0,
      IS: data?.entity.salesMetrics[`range${index + 1}`].installScheduled?.total || 0,
    }))
    .reverse();

  return (
    <div>
      <ButtonContainer>
        <ButtonGroup color="secondary" aria-label="outlined secondary button group">
          {metrics.map(metric => {
            return (
              <StyledButton
                key={metric}
                onClick={() => {
                  setViewMetric(metric);
                  mixpanel.track(`Trends metric changed to ${metric}`);
                }}
                filled={viewMetric === metric ? 1 : 0}
              >
                <Typography variant="body1">{metric === 'PS' ? 'CAP' : metric}</Typography>
              </StyledButton>
            );
          })}
        </ButtonGroup>
      </ButtonContainer>
      <ChartContainer duration={duration}>
        <Chart data={chartData} height={400}>
          <ArgumentAxis showGrid tickFormat={() => tick => tick} />
          <ValueAxis />
          <LineSeries
            valueField={viewMetric}
            argumentField="range"
            color={theme.palette.secondary.main}
            seriesComponent={LineWithPoint}
          />
          <EventTracker />
          <Tooltip sheetComponent={Sheet} arrowComponent={Arrow} contentComponent={Content} />
        </Chart>
      </ChartContainer>
    </div>
  );
};

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  margin: ${p => p.theme.spacing(2)}px ${p => p.theme.spacing(1)}px;
`;

const StyledButton = styled(Button)<StyledButtonProps>`
  background-color: ${p => (p.filled > 0 ? p.theme.palette.secondary.main : '')};
  color: ${p => (p.filled > 0 ? p.theme.palette.primary.main : '')};

  &:hover {
    background-color: ${p => (p.filled > 0 ? p.theme.palette.secondary.main : '')};
  }

  ${p => p.theme.breakpoints.up('sm')} {
    padding: ${p => p.theme.spacing(0)}px ${p => p.theme.spacing(4)}px;
  }
`;

const ChartContainer = styled.div<ChartProps>`
  max-width: 800px;
  margin: auto;

  // graph lines
  .Component-root-4,
  .Component-root-5,
  .Component-root-6 {
    stroke: ${p => p.theme.palette.grey[700]};
  }

  .Component-root-3 {
    font-family: constructa;
    font-weight: bold;
    letter-spacing: 2px;

    ${p => p.theme.breakpoints.up('sm')} {
      font-size: 16px;
    }
  }

  // hiding some x-axis labels on smaller screens and higher durations
  #bottom-axis-container {
    .Component-root-3 {
      ${p => p.theme.breakpoints.up(700)} {
        display: ${p => (p.duration !== PAST_TWENTY_ONE.value ? 'block' : 'none')};
        &:nth-of-type(2n) {
          display: ${p => (p.duration === PAST_TWENTY_ONE.value ? 'block' : '')};
        }
      }
      ${p => p.theme.breakpoints.down(700)} {
        display: ${p => (p.duration !== PAST_SEVEN.value ? 'none' : 'block')};
        &:nth-of-type(3n) {
          display: ${p => (p.duration !== PAST_SEVEN.value ? 'block' : '')};
        }
      }
    }
  }
`;

const ArrowContainer = styled.div`
  > div {
    &:after {
      background-color: ${p => p.theme.palette.common.white};
      color: ${p => p.theme.palette.common.black};
    }
  }
`;

const SheetContainer = styled.div`
  > div {
    background-color: ${p => p.theme.palette.common.white};
    color: ${p => p.theme.palette.common.black};
  }
`;

export default Graph;
