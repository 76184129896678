import { PERMISSIONS } from '../constants/permissions';
import { VIEWS } from '../constants/views';
import { Organizations } from '../queries/employee';

const getReadPermissions = (groups?: string[], orgLevels?: Organizations[]) => {
  if (!groups || !orgLevels) {
    return null;
  }

  const userCanSeeCompany = groups.includes(PERMISSIONS.COMPANY);
  const userCanSeeDivision = groups.includes(PERMISSIONS.CONFERENCE);
  const filterBy = [VIEWS.ATHLETE, VIEWS.OFFICE, VIEWS.REGION];

  if (userCanSeeCompany) {
    return orgLevels;
  }

  if (userCanSeeDivision) {
    filterBy.push(VIEWS.DIVISION);
  }

  return orgLevels.filter(option => filterBy.includes(option.type));
};

export default getReadPermissions;
