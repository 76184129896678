import { useMutation } from '@apollo/client';
import { useQuery } from '@apollo/client';
import styled from '@emotion/styled';
import IconButton from '@material-ui/core/IconButton';
import Snackbar from '@material-ui/core/Snackbar';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import CloseIcon from '@material-ui/icons/Close';
import { ReactComponent as ScorecardIcon } from 'assets/icons/scorecard.svg';
import { ContainerLoading } from 'components/loading';
import { parseAsync } from 'json2csv';
import employeeQuery, { EmployeeResult } from 'queries/employee';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { range } from 'utilities/dates';
import { v4 } from 'uuid';

import IncrementalPicker from '../../components/calendar/IncrementalPicker';
import { organizationLevels } from '../../constants/breakdown';
import { ALL_STATS, ATHLETE, LEVEL_UP, MY_RECRUITS, WEEKLY_WINS } from '../../constants/breakdown';
import { Routes } from '../../constants/routes';
import {
  CustomSalesReport,
  getSavedReports,
  GetSavedReportsResult,
  SalesMetricPeriod,
  SalesReportSubOrganization,
  SalesReportType,
  updateReports as updateReportsQuery,
} from '../../queries/customerReports';
import mixpanel from '../../utilities/mixpanel';
import AllStats from './all-stats';
import CustomizeModal from './CustomizeModal';
import Header from './Header';
import LevelUp from './level-up';
import getReportFilters from './reportFilters';
import WeeklyWins from './weekly-wins';

export type CustomizeOptions = {
  level: string | undefined;
  area: string | undefined;
  period: string;
  dates: { start: string; end: string };
  view: string;
};

export type NewReport = {
  level?: string;
  area?: string | null;
  areaId?: string;
  period?: string;
  view?: string;
  name: string;
};

type OptionsWithArea<T> = T & { areaName: string };

const Breakdown = () => {
  const history = useHistory();
  const { data: employeeResult } = useQuery<EmployeeResult>(employeeQuery, { fetchPolicy: 'cache-first' });

  const [updateReports, { data: updateReportsData, loading: updateReportsLoading }] = useMutation(updateReportsQuery);
  const { data, error, loading } = useQuery<GetSavedReportsResult>(getSavedReports);

  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [openCustomizeModal, setOpenCustomizeModal] = useState(false);
  const [selectedAreaName, setSelectedAreaName] = useState('');
  const [reportName, setReportName] = useState('');
  const [reportType, setReportType] = useState<SalesReportType>(SalesReportType.AllStats);
  const [reportsArray, setReportsArray] = useState<CustomSalesReport[]>();
  const [customizeOptions, setCustomizeOptions] = useState<CustomizeOptions>();
  const [displayBackButton, setDisplayBackButton] = useState(false);
  const [reportData, setReportData] = useState<any[]>([]);
  const [previousOptions, setPreviousOptions] = useState<OptionsWithArea<CustomizeOptions>[]>();

  const onClickUpdateReports = (newReports: CustomSalesReport[]) => {
    const newArray = newReports.map(({ __typename, ...keepAttrs }) => keepAttrs);
    updateReports({ variables: { clientMutationId: v4(), reports: newArray } });
  };

  const onScoreCardNavigation = (navigationEntityId: string, navigationFullName: string, navigationType: string) => {
    const state: { navigationEntityId: string; navigationFullName: string; navigationType: string } = {
      navigationEntityId,
      navigationFullName,
      navigationType,
    };
    history.push(Routes.SCORECARD, state);
  };

  const renderButtonCell = (view: string) => (tableData: {
    entity: {
      id: string;
      primaryDisplayName: string;
      secondaryDisplayName?: string;
      leagueLevel?: string;
      fullName?: string;
    };
  }) => {
    const id = tableData.entity.id;
    const name = tableData.entity.fullName || tableData.entity.primaryDisplayName;
    return (
      <IconButton onClick={() => onScoreCardNavigation(id, name, view)}>
        <ScorecardIcon fill="#fff" />
      </IconButton>
    );
  };

  const saveNewReport = (newReport: NewReport) => {
    const reportWithAddedProperties = {
      ...newReport,
      visible: true,
      type: 'USER_DEFINED',
      salesReportType: reportType,
    };
    const newArray = reportsArray && [
      reportWithAddedProperties,
      ...reportsArray.map(({ __typename, ...keepAttrs }) => keepAttrs),
    ];

    mixpanel.track('Breakdown new report saved', {
      'New report level': newReport.level,
      'New report area': newReport.area,
      'New report period': newReport.period,
      'New report view': newReport.view,
      'New report name': newReport.name,
    });
    updateReports({ variables: { clientMutationId: v4(), reports: newArray } });
    setOpenCustomizeModal(false);
  };

  const filterOptions = getReportFilters(reportType, customizeOptions?.view, employeeResult?.employee.groups);

  const updateFirstReport = (firstReport?: CustomSalesReport) => {
    if (firstReport) {
      const firstReportDates = range[firstReport.period.toLowerCase()]();
      clearHistory();
      setReportName(firstReport.name);
      setReportType(firstReport.salesReportType);
      setSelectedAreaName(firstReport.area.toUpperCase());
      setCustomizeOptions({
        level: firstReport.level,
        area: firstReport.areaId,
        period: firstReport.period,
        dates: firstReportDates,
        view: firstReport.view,
      });
    }
  };

  const clearHistory = () => {
    setPreviousOptions([]);
    setDisplayBackButton(false);
  };

  const onDownloadClick = () => {
    if (reportData) {
      const reportDataForCSV = reportData.map(row => ({ ...row.entity, ...row }));
      parseAsync(reportDataForCSV, { fields: Object.keys(reportDataForCSV[0]) }).then(csv => {
        window.open(`data:text/csv;charset=utf-8,${encodeURIComponent(csv)}`);
      });
    }
  };

  const onRowClick = (
    event?: React.MouseEvent<Element, MouseEvent> | undefined,
    rowData?: any,
    toggleDetailPanel?: (panelIndex?: number | undefined) => void,
  ) => {
    if (customizeOptions?.view === ATHLETE) {
      return;
    }

    mixpanel.track('Breakdown drilldown clicked');
    const currentOptions: CustomizeOptions = Object.assign({}, customizeOptions);
    const previousOptionsCopy = previousOptions && [...previousOptions];
    const orgIndex = currentOptions?.view && organizationLevels.indexOf(currentOptions.view);
    const newView = organizationLevels[Number(orgIndex) + 1];
    const newAreaName = rowData.entity.fullName || rowData.entity.primaryDisplayName;

    setDisplayBackButton(true);
    setPreviousOptions(previousOptionsCopy?.concat({ ...currentOptions, areaName: selectedAreaName }));
    setSelectedAreaName(newAreaName.toUpperCase());
    setCustomizeOptions({
      period: currentOptions.period,
      dates: currentOptions.dates,
      level: currentOptions.view,
      area: rowData.entity.id,
      view: newView,
    });
  };

  const drilldownGoBack = () => {
    mixpanel.track('Breakdown back button clicked');
    const previousOptionsCopy = previousOptions && [...previousOptions];
    const last = previousOptionsCopy && previousOptionsCopy.pop();
    if (previousOptionsCopy && previousOptionsCopy.length <= 0) {
      setDisplayBackButton(false);
    }
    if (last?.areaName) {
      setSelectedAreaName(last?.areaName.toUpperCase());
    }
    setPreviousOptions(previousOptionsCopy);
    setCustomizeOptions(last);
  };

  // set options and reports array as soon as the reports come back in the query
  useEffect(() => {
    if (data && !loading) {
      const reports = data.viewerCustomSalesReports.reports;
      updateFirstReport(reports.find((x: CustomSalesReport) => x.visible)); // first visbile report
      setReportsArray(reports);
    }
  }, [data, loading]);

  // set options and reports array after user updates reports in modal
  useEffect(() => {
    if (updateReportsData && !updateReportsLoading) {
      const reports = updateReportsData.userUpdateCustomSalesReports.reports;
      updateFirstReport(reports.find((x: CustomSalesReport) => x.visible)); // first visbile report
      setReportsArray(reports);
    }
  }, [updateReportsData, updateReportsLoading]);

  useEffect(() => {
    // display small snackbar if there is a problem getting reports
    if (error) {
      setOpenSnackbar(true);
    }
  }, [error]);

  useEffect(() => {
    mixpanel.track('Viewed Breakdown page');
  }, []);

  const onDateChange = (dateRange: { start: string; end: string }) => {
    const currentOptions: CustomizeOptions = Object.assign({}, customizeOptions);
    setCustomizeOptions({
      ...currentOptions,
      dates: dateRange,
    });
  };

  useEffect(() => {
    // if my report update to its defaults
    if (reportType === SalesReportType.MyRecruits) {
      const newOptions: CustomizeOptions = {
        dates: range.quarter(),
        period: SalesMetricPeriod.Quarter,
        view: SalesReportSubOrganization.Athlete,
        level: SalesReportSubOrganization.Athlete,
        area: employeeResult?.employee.badgeId,
      };
      setCustomizeOptions(newOptions);
    }
  }, [reportType]);

  return (
    <>
      <Header
        reports={reportsArray}
        setReportType={setReportType}
        setReportName={setReportName}
        reportName={reportName}
        onClickUpdateReports={onClickUpdateReports}
        openCustomizeModal={() => setOpenCustomizeModal(true)}
        setSelectedAreaName={setSelectedAreaName}
        setCustomizeOptions={setCustomizeOptions}
        clearHistory={clearHistory}
        onDownloadClick={onDownloadClick}
        disableDownload={reportData?.length === 0}
      />
      {(!customizeOptions || updateReportsLoading || loading) && <ContainerLoading />}
      {customizeOptions && !loading && !updateReportsLoading && (
        <>
          <Bar>
            {displayBackButton && previousOptions && previousOptions.length > 0 && (
              <DrilldownBackButton onClick={drilldownGoBack}>
                <ArrowBackIosIcon fontSize="small" />
                {`BACK TO ${previousOptions && previousOptions[previousOptions.length - 1].areaName}`}
              </DrilldownBackButton>
            )}
            <IncrementalPicker
              period={customizeOptions.period}
              onChange={onDateChange}
              dateRange={customizeOptions.dates}
            />
            {displayBackButton && previousOptions && previousOptions.length > 0 && <div />}
          </Bar>
          {(reportType.includes(ALL_STATS) || reportType === MY_RECRUITS) && (
            <AllStats
              areaName={selectedAreaName}
              customizeOptions={customizeOptions}
              filterOptions={filterOptions}
              reportType={reportType}
              renderButtonCell={renderButtonCell}
              onRowClick={onRowClick}
              onReportDataLoaded={setReportData}
            />
          )}
          {reportType.includes(WEEKLY_WINS) && (
            <WeeklyWins
              areaName={selectedAreaName}
              customizeOptions={customizeOptions}
              filterOptions={filterOptions}
              reportType={reportType}
              renderButtonCell={renderButtonCell}
              onRowClick={onRowClick}
              onReportDataLoaded={setReportData}
            />
          )}
          {reportType.includes(LEVEL_UP) && (
            <LevelUp
              range={customizeOptions.dates}
              areaName={selectedAreaName}
              customizeOptions={customizeOptions}
              filterOptions={filterOptions}
              reportType={reportType}
              renderButtonCell={renderButtonCell}
              onRowClick={onRowClick}
              onReportDataLoaded={setReportData}
            />
          )}
          <CustomizeModal
            customizeOptions={customizeOptions}
            open={openCustomizeModal}
            clearHistory={clearHistory}
            onClose={() => setOpenCustomizeModal(false)}
            orgLevels={employeeResult?.employee.salesProfile.organizationLevels}
            organizations={
              employeeResult?.employee.salesProfile.primarySellingLocation.ancestryConnection.organizations
            }
            setSelectedAreaName={setSelectedAreaName}
            setCustomizeOptions={setCustomizeOptions}
            reportNames={reportsArray && reportsArray.map(x => x.name)}
            saveNewReport={saveNewReport}
            reportType={reportType}
          />
        </>
      )}
      {error && (
        <StyledSnackbar
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          open={openSnackbar}
          autoHideDuration={6000}
          onClose={() => setOpenSnackbar(false)}
          message="Unable to get custom reports. Please try again later."
          action={
            <>
              <IconButton size="small" aria-label="close" color="inherit" onClick={() => setOpenSnackbar(false)}>
                <CloseIcon fontSize="small" />
              </IconButton>
            </>
          }
        />
      )}
    </>
  );
};

const Bar = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  background-color: ${p => p.theme.palette.background.paper};
`;

const DrilldownBackButton = styled.div`
  display: flex;
  text-align: left;
  padding-left: ${p => p.theme.spacing(2.5)}px;
  cursor: pointer;
`;

const StyledSnackbar = styled(Snackbar)`
  > div {
    color: ${p => p.theme.palette.common.white};
    background-color: ${p => p.theme.palette.error.main};
  }
`;

export default Breakdown;
