import { useQuery } from '@apollo/client';
import styled from '@emotion/styled';
import Carousel from 'components/carousel';
import { VIEWS } from 'constants/views';
import { athleteLeagueLevel, LeagueLevelResult, organizationLeagueLevel } from 'queries/leagueLevel';
import { ReactNode } from 'react';
import { getPastQuarter, getPastQuarterDisplay, startDateOfCurrentQuarter } from 'utilities/dates';

import { ContainerLoading } from '../../../components/loading';
import SectionHeader from '../../../components/section-header';
import ErrorComponent from '../../error-boundary/ErrorComponent';
import Level from './Level';

const LeagueLevel = ({ entityId, type }: { entityId: string; type: string }) => {
  let pagesArray: ReactNode[] = [];
  const quarterDates = {
    quarterDateForCurrentQuarter: startDateOfCurrentQuarter(),
    quarterDateForPreviousQuarter: getPastQuarter(1),
    quarterDateForTwoQuartersAgo: getPastQuarter(2),
    quarterDateForThreeQuartersAgo: getPastQuarter(3),
  };

  const orgType = type !== VIEWS.ATHLETE;
  const athleteType = type === VIEWS.ATHLETE;
  const { data: orgData, error: orgError, loading: orgLoading } = useQuery<LeagueLevelResult>(organizationLeagueLevel, {
    variables: { entityId: entityId, ...quarterDates },
    skip: athleteType,
  });
  const { data: athleteData, error: athleteError, loading: athleteLoading } = useQuery<LeagueLevelResult>(
    athleteLeagueLevel,
    {
      variables: { entityId: entityId, ...quarterDates },
      skip: !athleteType,
    },
  );

  const entityData = orgData ? orgData : athleteData;

  const aggregations = [
    {
      title: getPastQuarterDisplay(quarterDates.quarterDateForCurrentQuarter),
      quarterResult: entityData?.entity.profile.league.leagueQuarterResultForCurrentQuarter,
      breakdown: entityData?.entity.profile.league.leagueQuarterResultForCurrentQuarter?.leagueLevelPointsBreakdown,
      combine: entityData?.entity.salesMetrics?.league.combine,
    },
    {
      title: getPastQuarterDisplay(quarterDates.quarterDateForPreviousQuarter),
      quarterResult: entityData?.entity.profile.league.leagueQuarterResultForPreviousQuarter,
      breakdown: entityData?.entity.profile.league.leagueQuarterResultForPreviousQuarter?.leagueLevelPointsBreakdown,
      combine: entityData?.entity.salesMetrics?.league.combine,
    },
    {
      title: getPastQuarterDisplay(quarterDates.quarterDateForTwoQuartersAgo),
      quarterResult: entityData?.entity.profile.league.leagueQuarterResultForTwoQuartersAgo,
      breakdown: entityData?.entity.profile.league.leagueQuarterResultForTwoQuartersAgo?.leagueLevelPointsBreakdown,
      combine: entityData?.entity.salesMetrics?.league.combine,
    },
    {
      title: getPastQuarterDisplay(quarterDates.quarterDateForThreeQuartersAgo),
      quarterResult: entityData?.entity.profile.league.leagueQuarterResultForThreeQuartersAgo,
      breakdown: entityData?.entity.profile.league.leagueQuarterResultForThreeQuartersAgo?.leagueLevelPointsBreakdown,
      combine: entityData?.entity.salesMetrics?.league.combine,
    },
  ];

  pagesArray = aggregations.map((agg, index) => {
    return (
      <div key={index}>
        <Level
          combine={agg.combine}
          entityId={entityId}
          quarterResult={agg.quarterResult}
          breakdown={agg.breakdown}
          title={agg.title}
          type={type}
        />
      </div>
    );
  });

  return (
    <div>
      <SectionHeader title="League Level" />
      {(orgType && orgError) || (athleteType && athleteError && <ErrorComponent />)}
      {(orgType && orgLoading && !orgData) || (athleteType && athleteLoading && !athleteData && <ContainerLoading />)}
      <CarouselContainer>
        <Carousel pages={pagesArray} title="League Level" />
      </CarouselContainer>
    </div>
  );
};

const CarouselContainer = styled.div`
  ${p => p.theme.breakpoints.up('md')} {
    padding: 20px;
  }
`;

export default LeagueLevel;
