import { useQuery } from '@apollo/client';
import styled from '@emotion/styled';
import Typography from '@material-ui/core/Typography';
import { ReactComponent as Crown } from 'assets/icons/crown.svg';
import BlankProfile from 'assets/images/blank-profile.png';
import Carousel from 'components/carousel';
import { ContainerLoading } from 'components/loading';
import ErrorComponent from 'pages/error-boundary/ErrorComponent';
import PageHeader from 'pages/page-header/PageHeader';
import leaderboardQuickViewQuery, { LeaderboardQuickViewResult } from 'queries/leaderboardQuickView';
import { FC, ReactNode } from 'react';
import { startDateOfCurrentQuarter } from 'utilities/dates';
import { getLevelLogo } from 'utilities/getLevelLogo';

type CarouselProps = {
  employeeIndex?: number;
};

const LeaderboardQuickView: FC = () => {
  let pagesArray: ReactNode[] = [];
  let employeeIndex;
  const { loading, error, data } = useQuery<LeaderboardQuickViewResult>(leaderboardQuickViewQuery, {
    variables: {
      startOfQuarter: startDateOfCurrentQuarter(),
    },
  });

  if (error) {
    return <ErrorComponent />;
  }

  if (loading && !data) {
    return <ContainerLoading />;
  }

  if (data) {
    const employee = data?.employee;
    const leagueCompetition = data?.employee?.salesProfile?.leagueCompetition;
    const salesStanding = data.employee.salesProfile.leagueCompetition.salesStanding;

    const allCompetitors = [
      ...salesStanding.above.edges,
      {
        employeebadgeId: employee?.badgeId,
        LEAGUE_SCORE: leagueCompetition?.LEAGUE_SCORE,
        ACCOUNT_CREATED: leagueCompetition?.ACCOUNT_CREATED,
        WELCOME_CALL: leagueCompetition?.WELCOME_CALL,
        CUSTOMER_APPROVED_PLAN: leagueCompetition?.CUSTOMER_APPROVED_PLAN,
        node: employee,
      },
      ...salesStanding.below.edges,
    ];

    employeeIndex = allCompetitors.findIndex(x => x.node?.badgeId === employee?.badgeId);

    pagesArray = allCompetitors.map((competitor, index) => {
      const logo = getLevelLogo('ATHLETE', false, competitor.node.salesProfile?.league?.currentQuarter?.level);
      return (
        <div key={index}>
          <Competitor>
            <Profile>
              <AvatarContainer>
                <Rank>
                  <Typography variant="h4">{competitor.LEAGUE_SCORE?.rank || ''}</Typography>
                </Rank>
                <Avatar>
                  {logo ? <logo.Logo fill="#ffffff" /> : <img src={BlankProfile} alt="Competitor avatar" />}
                </Avatar>
              </AvatarContainer>
              <Typography variant="button">{competitor.node.contact.fullName || competitor.node.badgeId}</Typography>
            </Profile>
            <Metrics>
              <Typography variant="caption">{competitor.ACCOUNT_CREATED?.count.total || 0}AC</Typography>
              <Typography variant="caption">{competitor.WELCOME_CALL?.count.total || 0}WC</Typography>
              <Typography variant="caption">{competitor.CUSTOMER_APPROVED_PLAN?.count.total || 0}PS</Typography>
            </Metrics>
          </Competitor>
        </div>
      );
    });
  }

  if (pagesArray.length > 0) {
    return (
      <div>
        <PageHeader title="Leaderboard - QuickView" icon={<CrownIcon />} />
        <CarouselContainer employeeIndex={employeeIndex}>
          <Carousel
            pages={pagesArray}
            title="Leaderboard Quickview"
            infinite
            centerMode
            initialSlide={employeeIndex}
            slidesToShow={5}
            responsiveSettings={[
              {
                breakpoint: 960,
                settings: {
                  slidesToShow: 3,
                },
              },
              {
                breakpoint: 700,
                settings: {
                  slidesToShow: 1,
                  centerMode: false,
                },
              },
              {
                breakpoint: 600,
                settings: {
                  slidesToShow: 3,
                  centerPadding: '10px',
                  centerMode: false,
                },
              },
              {
                breakpoint: 359,
                settings: {
                  slidesToShow: 1,
                  centerMode: false,
                },
              },
            ]}
          />
        </CarouselContainer>
      </div>
    );
  }

  return null;
};

const CrownIcon = styled(Crown)`
  fill: ${p => p.theme.palette.common.white};
`;

const CarouselContainer = styled.div<CarouselProps>`
  .slick-track {
    display: flex;
  }
  // some special styling to get the heights all the same
  .slick-track .slick-slide {
    display: flex;
    height: auto;
    margin: 10px 0;

    > div {
      width: 100%;
    }

    > div > div {
      height: 100%;

      > div {
        height: 100%;
      }
    }
  }

  [data-index='${p => p.employeeIndex}'] {
    > div > div > div {
      background-color: ${p => p.theme.palette.secondary.dark};
      transform: scale(1.15);
      z-index: 20;
    }
  }
`;

const Competitor = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #2e2e2e;
  padding: 5px 15px;
  margin: 0 1px;
  transition: transform 200ms, margin 200ms;
`;

const Profile = styled.div`
  margin-bottom: ${p => p.theme.spacing(1)}px;
  text-align: center;

  p {
    line-height: 1;
  }
`;

const AvatarContainer = styled.div`
  position: relative;
`;

const Rank = styled.div`
  position: absolute;
  top: 10px;
  left: calc(50% - 60px);

  h4 {
    font-family: Constructa;
    font-weight: normal;
  }

  ${p => p.theme.breakpoints.between(700, 960)} {
    top: 5px;
    left: calc(50% - 55px);
  }

  ${p => p.theme.breakpoints.between(359, 600)} {
    top: 0px;
    left: calc(50% - 50px);
  }
`;

const Avatar = styled.div`
  width: 65px;
  height: 65px;
  margin: auto;
  margin-bottom: 5px;
  margin-top: ${p => p.theme.spacing(2)}px;
  border-radius: 50%;
  overflow: hidden;

  img {
    width: 100%;
  }

  ${p => p.theme.breakpoints.between(700, 960)} {
    width: 50px;
    height: 50px;
  }

  ${p => p.theme.breakpoints.between(359, 600)} {
    width: 45px;
    height: 45px;
  }
`;

const Metrics = styled.div`
  display: flex;
  justify-content: space-around;
  margin-top: auto;

  span {
    font-size: 20px;
    ${p => p.theme.breakpoints.down('sm')} {
      font-size: 14px;
    }
  }
`;

export default LeaderboardQuickView;
