import styled from '@emotion/styled';
import Typography from '@material-ui/core/Typography';
import { VIEWS } from 'constants/views';
import { LeagueLevelName } from 'queries/athlete';
import { FC } from 'react';

import { Maybe } from '../../apollo/types';
import { LEAGUE_LEVEL_TO_DISPLAY } from '../../constants/leagueLevel';
import { daysLeftInQuarter, getQuarterNumber } from '../../utilities/dates';
import { getOrdinalNumber } from '../../utilities/numbers';

type PerformanceIndicators = {
  accountCreated: {
    total: number;
  };
  welcomeCall: {
    total: number;
  };
};

type Props = {
  combineIsActive?: boolean;
  combineDaysRemaining?: Maybe<number>;
  compScore?: number;
  leagueLevel?: Maybe<LeagueLevelName>;
  leagueRank?: Maybe<number>;
  leagueScore?: Maybe<number>;
  performanceIndicators?: PerformanceIndicators;
  type: string;
};

const DashboardStats: FC<Props> = ({
  combineIsActive,
  combineDaysRemaining,
  compScore,
  leagueLevel,
  leagueRank,
  leagueScore,
  performanceIndicators,
  type,
}) => {
  const daysLeft = combineIsActive ? combineDaysRemaining : daysLeftInQuarter();
  const leagueRankFloor = leagueRank && Math.floor(leagueRank);
  const ordinalLeagueRank = (leagueRankFloor && `${leagueRankFloor}${getOrdinalNumber(leagueRankFloor)}`) || '-';
  const displayLeagueLevel = leagueLevel && LEAGUE_LEVEL_TO_DISPLAY[leagueLevel];

  return (
    <Container>
      <Section>
        <Headers variant="body2">{combineIsActive ? 'GRADUATION' : 'QTR'}</Headers>
        <Stats>
          <Typography variant="h5">{daysLeft && Math.floor(daysLeft)}</Typography>
          <Typography variant="caption">
            {combineIsActive ? 'DAYS LEFT' : `DAYS LEFT IN Q${getQuarterNumber()}`}
          </Typography>
        </Stats>
      </Section>
      {!combineIsActive && (
        <Section>
          <Headers variant="body2">LEAGUE SCORE</Headers>
          <Stats>
            <Typography variant="h5">{leagueScore || '-'}</Typography>
          </Stats>
        </Section>
      )}
      {type === VIEWS.ATHLETE && !combineIsActive && (
        <Section>
          <Headers variant="body2">LEAGUE RANK</Headers>
          <Stats>
            <Typography variant="h5">{ordinalLeagueRank}</Typography>
            {displayLeagueLevel && <Typography variant="caption">{displayLeagueLevel.toUpperCase()}</Typography>}
          </Stats>
        </Section>
      )}
      <Section>
        <Headers variant="body2">COMP SCORE</Headers>
        <Stats>
          <StatsContainer>
            <div>
              <Typography variant="caption">AC</Typography>
              <Typography variant="subtitle2">{performanceIndicators?.accountCreated.total || '-'}</Typography>
            </div>
            <div>
              <Typography variant="h5">{compScore}</Typography>
              <Typography variant="caption">SCORE</Typography>
            </div>
            <div>
              <Typography variant="caption">WC</Typography>
              <Typography variant="subtitle2">{performanceIndicators?.welcomeCall.total || '-'}</Typography>
            </div>
          </StatsContainer>
        </Stats>
      </Section>
    </Container>
  );
};

const Container = styled.div`
  text-align: center;
`;

const Section = styled.div`
  display: flex;
  flex-direction: column;
  width: 100px;
`;

const Headers = styled(Typography)`
  padding: 2px 0;
  background-color: ${p => p.theme.palette.primary.main};
`;

const Stats = styled.div`
  flex: 1;
  min-height: 50px;
  padding: ${p => p.theme.spacing(1)}px;
  background: repeating-linear-gradient(
    -45deg,
    rgba(0, 0, 0, 0),
    rgba(0, 0, 0, 0) 2px,
    ${p => p.theme.palette.text.disabled} 4px
  );
`;

const StatsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
`;

export default DashboardStats;
