import { useQuery } from '@apollo/client';
import styled from '@emotion/styled';
import { Typography } from '@material-ui/core';
import { determineStatusColor } from 'components/pace/PaceColors';
import { COMBINE, LEAGUE_LEVEL_CLUB, LEAGUE_LEVEL_TO_STARS } from 'constants/leagueLevel';
import { DateTime } from 'luxon';
import { useEffect, useState } from 'react';
import { Black, Gold } from 'styles/constants/colors';
import { getLevelLogo } from 'utilities/getLevelLogo';

import FilterTable from '../../../components/filter-table';
import { ContainerLoading } from '../../../components/loading';
import { ALL_ATHLETES } from '../../../constants/breakdown';
import { VIEWS } from '../../../constants/views';
import { AthleteLevelUpQuery, LevelUpReportResult, TeamLevelUpQuery } from '../../../queries/levelUpReport';
import { fixedPrecision } from '../../../utilities/numbers';
import ErrorComponent from '../../error-boundary/ErrorComponent';
import { CustomizeOptions } from '..';

const leagueLevelToDisplay = (level: string) => level.toUpperCase().replace(/_/g, ' ');

const LevelUp = ({
  range,
  areaName,
  customizeOptions,
  filterOptions,
  renderButtonCell,
  onRowClick,
  onReportDataLoaded,
}: {
  range: { start: string; end: string };
  areaName: string | undefined;
  filterOptions: { name: string; value: string }[] | undefined;
  customizeOptions: CustomizeOptions;
  reportType: string;
  renderButtonCell: (
    view: string,
  ) => (tableData: {
    entity: {
      id: string;
      primaryDisplayName: string;
      secondaryDisplayName?: string | undefined;
      leagueLevel?: string | undefined;
      fullName?: string | undefined;
    };
  }) => JSX.Element;
  onRowClick: (
    event?: React.MouseEvent<Element, MouseEvent> | undefined,
    rowData?: any,
    toggleDetailPanel?: (panelIndex?: number | undefined) => void,
  ) => void;
  onReportDataLoaded: (data: any) => void;
}) => {
  const [rowFilter, setRowFilter] = useState(ALL_ATHLETES);
  const { start, end } = range;
  const beginningOfQuarter = DateTime.fromISO(start).startOf('quarter');
  const endOfQuarter = DateTime.fromISO(end).endOf('quarter');
  const today = DateTime.local();
  const lengthOfQuarter = endOfQuarter.diff(beginningOfQuarter, 'days').toObject().days || 92;
  const daysSinceStart = today.diff(beginningOfQuarter, 'days').toObject().days || 0;
  const goalPaceRatio = daysSinceStart / lengthOfQuarter;
  const orgType = customizeOptions.view !== VIEWS.ATHLETE;
  const athleteType = customizeOptions.view === VIEWS.ATHLETE;

  const variables = {
    range: customizeOptions.dates,
    entityId: customizeOptions.area,
    subEntityLevel: customizeOptions.view,
    rowFilter: rowFilter,
  };

  const { data: athleteData, error: athleteError, loading: athleteLoading } = useQuery<LevelUpReportResult>(
    AthleteLevelUpQuery,
    {
      variables: variables,
      skip: orgType,
    },
  );

  const { data: orgData, error: orgError, loading: orgLoading } = useQuery<LevelUpReportResult>(TeamLevelUpQuery, {
    variables: variables,
    skip: athleteType,
  });

  const entityData = orgData ? orgData : athleteData;
  const loading = (orgType && orgLoading && !orgData) || (athleteType && athleteLoading && !athleteData);

  useEffect(() => {
    if (entityData && !loading) {
      onReportDataLoaded(entityData?.levelUpReport.rowsConnection.rows);
    }
  }, [entityData, loading]);

  if ((orgType && orgError) || (athleteType && athleteError)) {
    return <ErrorComponent />;
  }

  if (loading) {
    return <ContainerLoading />;
  }

  const editable = entityData?.levelUpReport.rowsConnection.rows?.map(o => ({ ...o }));

  const formattedFilters =
    filterOptions &&
    filterOptions.map(option => ({
      display: option.name,
      value: option.value,
      onOptionClick: () => setRowFilter(option.value),
    }));

  return (
    <FilterTable
      onRowClick={onRowClick}
      data={editable}
      columns={[
        {
          width: 110,
          title: <div>{customizeOptions.view}</div>,
          render: data => (
            <Title>
              <Typography variant="body1">
                {data.entity.primaryDisplayName} {data.entity.secondaryDisplayName}
              </Typography>
            </Title>
          ),
          customSort: (a, b) =>
            a.entity.primaryDisplayName && b.entity.primaryDisplayName
              ? a.entity.primaryDisplayName.localeCompare(b.entity.primaryDisplayName)
              : 0,
          customFilterAndSearch: (term, rowData) => rowData.entity.primaryDisplayName.indexOf(term) !== -1,
        },
        {
          width: 30,
          render: renderButtonCell(customizeOptions.view),
        },
        {
          width: 100,
          cellStyle: {
            paddingRight: 0,
            paddingLeft: 0,
            paddingTop: 36,
            textAlign: 'right',
            direction: 'rtl',
            whiteSpace: 'nowrap',
          },
          render: data => {
            const level = data.leagueLevelGoal === COMBINE.KEY ? COMBINE.KEY : data.leagueLevel;

            return <Typography variant="button">{leagueLevelToDisplay(level)}</Typography>;
          },
        },
        {
          title: 'Progress',
          headerStyle: {
            textAlign: 'center',
            paddingLeft: 36,
          },
          width: 280,
          render: data => {
            const { tableData, paceStatus, actual, goal, pace } = data;
            const isDark = tableData.id % 2 === 0;
            const color = determineStatusColor(paceStatus);

            const actualRatio = Math.min(actual / goal, 1);
            const paceRatio = Math.min(pace / goal, 1);
            const showGoalPaceBar = goalPaceRatio < 1;

            return (
              <ProgressCellContainer>
                <StatsContainer>
                  <StyledTypography variant="button">current: {actual}</StyledTypography>
                  <StyledTypography variant="button">pace: {fixedPrecision(pace)}</StyledTypography>
                  <StyledTypography variant="button">goal: {goal}</StyledTypography>
                </StatsContainer>
                <EmptyContainer>
                  <SolidContainer color={color} actualRatio={actualRatio} />
                  <Stripes color={color} paceRatio={paceRatio} />
                  <GoalPace isDark={isDark} showGoalPaceBar={showGoalPaceBar} goalPaceRatio={goalPaceRatio} />
                </EmptyContainer>
              </ProgressCellContainer>
            );
          },
          customSort: (a, b) => {
            const aRatio = Math.min(a.actual / a.goal, 1);
            const bRatio = Math.min(b.actual / b.goal, 1);

            return aRatio - bRatio;
          },
        },
        {
          width: 70,
          cellStyle: {
            paddingTop: 36,
            paddingRight: 0,
            paddingLeft: 0,
            alignItems: 'flex-end',
            whiteSpace: 'nowrap',
          },
          render: data => {
            const goalLevel = data.leagueLevelGoal === COMBINE.KEY ? LEAGUE_LEVEL_CLUB : data.leagueLevelGoal;

            return (
              <Typography align="left" variant="button">
                {leagueLevelToDisplay(goalLevel)}
              </Typography>
            );
          },
        },
        {
          title: 'Goal',
          width: 60,
          headerStyle: {
            textAlign: 'center',
            paddingLeft: 36,
          },
          cellStyle: {
            textAlign: 'center',
          },
          render: data => {
            const isGold = data.actual >= data.goal;
            const goalLevel = data.leagueLevelGoal === COMBINE.KEY ? LEAGUE_LEVEL_CLUB : data.leagueLevelGoal;
            const Logo = getLevelLogo(customizeOptions.view, false, goalLevel);

            return Logo && <Logo.Logo height={40} width={40} fill={isGold ? Gold.MAIN : 'rgba(255,255,255,0.46)'} />;
          },
          customSort: (a, b) => {
            const aLevel = LEAGUE_LEVEL_TO_STARS[a.leagueLevelGoal];
            const bLevel = LEAGUE_LEVEL_TO_STARS[b.leagueLevelGoal];

            return aLevel - bLevel;
          },
        },
      ]}
      title={areaName}
      filterOptions={formattedFilters}
      filterSelected={rowFilter}
    />
  );
};

const StyledTypography = styled(Typography)`
  font-weight: bold;
`;

const ProgressCellContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const StatsContainer = styled.div`
  height: 18px;
  margin-bottom: 5px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const Title = styled.div`
  p {
    text-transform: uppercase;
  }
`;

const GoalPace = styled.div<{ isDark: boolean; showGoalPaceBar: boolean; goalPaceRatio: number }>`
  position: absolute;
  display: ${p => (p.showGoalPaceBar ? 'inherit' : 'none')};
  box-shadow: 1px 0px 0px 2px ${p => (p.isDark ? Black.LIGHT : Black.LIGHTER)},
    -1px 0px 0px 2px ${p => (p.isDark ? Black.LIGHT : Black.LIGHTER)};
  height: 18px;
  top: -2px;
  left: ${p => p.goalPaceRatio * 100}%;
  width: 1px;
  background: #fff;
`;

const EmptyContainer = styled.div`
  position: relative;
  height: 12px;
  width: 100%;
  background: #444444;
`;

const SolidContainer = styled.div<{ color: string; actualRatio: number }>`
  position: absolute;
  height: 12px;
  width: ${p => p.actualRatio * 100}%;
  background: ${p => p.color};
`;

const Stripes = styled.div<{ color: string; paceRatio: number }>`
  height: 12px;
  position: absolute;
  width: ${p => p.paceRatio * 100}%;
  background: repeating-linear-gradient(
    -45deg,
    transparent 0px,
    transparent 5px,
    ${p => p.color} -18px,
    ${p => p.color} 10px
  );
`;

export default LevelUp;
