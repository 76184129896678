import styled from '@emotion/styled';
import { Typography } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DeleteIcon from '@material-ui/icons/DeleteOutline';
import DragHandleIcon from '@material-ui/icons/DragHandle';
import VisibilityIcon from '@material-ui/icons/VisibilityOutlined';
import { CustomSalesReport } from 'queries/customerReports';
import { useEffect, useState } from 'react';
import { DragDropContext, Draggable, Droppable, DropResult } from 'react-beautiful-dnd';

import mixpanel from '../../utilities/mixpanel';

type ReportProps = {
  visibility: number;
};

const CustomReportsModal = ({
  onClose,
  onClickUpdateReports,
  open,
  reports,
}: {
  onClickUpdateReports: (arg0: CustomSalesReport[]) => void;
  onClose: () => void;
  open: boolean;
  reports: CustomSalesReport[] | undefined;
}) => {
  const [updatedReports, setUpdatedReports] = useState(reports);
  const [openConfirmationModal, setOpenConfirmationModal] = useState(false);
  const [reportToDelete, setReportToDelete] = useState<{ name: string; index: number } | null>();

  useEffect(() => {
    if (reports) {
      setUpdatedReports(reports);
    }
  }, [reports]);

  const deleteReport = (index: number) => {
    mixpanel.track(`Breakdown report ${updatedReports && updatedReports[index].name} deleted`);
    const newReports = updatedReports && [...updatedReports];
    newReports?.splice(index, 1);
    setUpdatedReports(newReports);
  };

  const hideShowReport = (index: number, currentVisibility: boolean) => {
    const newReports = updatedReports && [...updatedReports];
    if (newReports) {
      let reportToModfiy = newReports[index];
      if (reportToModfiy) {
        reportToModfiy = { ...reportToModfiy, visible: !currentVisibility };
      }
      mixpanel.track(`Breakdown report ${reportToModfiy?.name} ${reportToModfiy?.visible ? 'shown' : 'hidden'}`);
      const newArray = [...newReports.slice(0, index), reportToModfiy, ...newReports.slice(index + 1)];
      setUpdatedReports(newArray);
    }
  };

  const closeReportsModal = () => {
    onClose();
    setUpdatedReports(reports); // reset reports to original reports, basically reverting all user chnages
    mixpanel.track('Breakdown edit reports modal closed');
  };

  const handleOnDragEnd = (result: DropResult) => {
    if (!result.destination) return;
    const newReports = updatedReports && [...updatedReports];
    mixpanel.track('Breakdown reports rearranged');
    if (newReports) {
      const [reorderedItem] = newReports.splice(result.source.index, 1);
      newReports.splice(result.destination.index, 0, reorderedItem);
      setUpdatedReports(newReports);
    }
  };

  const onSubmit = () => {
    if (updatedReports) {
      onClose();
      onClickUpdateReports(updatedReports);
      mixpanel.track('Breakdown edit reports saved');
    }
  };

  const onCloseConfirmationModal = () => {
    setOpenConfirmationModal(false);
    setReportToDelete(null);
    mixpanel.track('Breakdown confirmation modal closed');
  };

  return (
    <>
      <Dialog onClose={onClose} open={open} onBackdropClick={closeReportsModal}>
        <Container>
          <HeaderContainer>
            <Typography variant="h6">EDIT SAVED REPORTS</Typography>
            <Typography variant="body1">DRAG TO REORDER REPORTS</Typography>
          </HeaderContainer>
          <DragDropContext onDragEnd={handleOnDragEnd}>
            <Droppable droppableId="reports">
              {provided => (
                <ReportsContainer {...provided.droppableProps} ref={provided.innerRef}>
                  {updatedReports &&
                    updatedReports.map((report: CustomSalesReport, index: number) => (
                      <Draggable key={index} draggableId={`${report.salesReportType}-${index}`} index={index}>
                        {draggableProvided => (
                          <Report
                            visibility={+report.visible}
                            ref={draggableProvided.innerRef}
                            {...draggableProvided.draggableProps}
                            {...draggableProvided.dragHandleProps}
                          >
                            <div>
                              <DraggableIcon />
                              <Typography variant="body1">{report.name}</Typography>
                            </div>
                            <Icons>
                              {report.type === 'USER_DEFINED' && (
                                <DeleteIcon
                                  onClick={() => {
                                    setOpenConfirmationModal(true);
                                    setReportToDelete({ name: report.name, index: index });
                                  }}
                                />
                              )}
                              <VisibilityIcon onClick={() => hideShowReport(index, report.visible)} />
                            </Icons>
                          </Report>
                        )}
                      </Draggable>
                    ))}
                  {provided.placeholder}
                </ReportsContainer>
              )}
            </Droppable>
          </DragDropContext>
          <ButtonContainer>
            <Button variant="contained" onClick={() => onSubmit()}>
              Save
            </Button>
            <Button onClick={closeReportsModal}>Cancel</Button>
          </ButtonContainer>
        </Container>
      </Dialog>
      <Dialog
        onClose={onCloseConfirmationModal}
        onBackdropClick={onCloseConfirmationModal}
        open={openConfirmationModal}
      >
        <Container>
          <HeaderContainer>
            <Typography variant="h6">ARE YOU SURE YOU WANT TO DELETE THIS REPORT?</Typography>
            {reportToDelete?.name && <Typography variant="body1">{reportToDelete?.name}</Typography>}
          </HeaderContainer>
          {reportToDelete && (
            <ConfirmationButtonContainer>
              <Button
                variant="contained"
                onClick={() => {
                  deleteReport(reportToDelete?.index);
                  onCloseConfirmationModal();
                }}
              >
                Delete
              </Button>
              <Button onClick={onCloseConfirmationModal}>Cancel</Button>
            </ConfirmationButtonContainer>
          )}
        </Container>
      </Dialog>
    </>
  );
};

const Container = styled.div`
  padding: ${p => p.theme.spacing(2)}px 0;
  background-color: ${p => p.theme.palette.common.white};
  color: ${p => p.theme.palette.common.black};
`;

const ReportsContainer = styled.div`
  width: 500px;
  max-width: 100%;
  margin: ${p => p.theme.spacing(2)}px 0;
`;

const Report = styled.div<ReportProps>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-transform: uppercase;
  padding: ${p => p.theme.spacing(1)}px;
  background-color: ${p => p.theme.palette.common.white};
  border-bottom: 1px solid ${p => p.theme.palette.grey[700]};
  color: ${p => (p.visibility ? p.theme.palette.common.black : p.theme.palette.grey[400])};

  div {
    display: flex;
  }

  p {
    font-weight: bold;
  }
`;

const DraggableIcon = styled(DragHandleIcon)`
  margin-right: ${p => p.theme.spacing(1)}px;
  cursor: grab;
`;

const Icons = styled.div`
  display: flex;
  align-items: center;
  margin-left: ${p => p.theme.spacing(4)}px;

  svg {
    margin-left: ${p => p.theme.spacing(1)}px;
  }
`;

const HeaderContainer = styled.div`
  padding: 0 ${p => p.theme.spacing(3)}px;
`;

const ButtonContainer = styled.div`
  display: flex;
  margin-top: ${p => p.theme.spacing(2)}px;
  padding: 0 ${p => p.theme.spacing(2)}px;

  button {
    color: ${p => p.theme.palette.common.black};
    font-weight: bold;

    &:first-of-type {
      background-color: ${p => p.theme.palette.success.main};
      color: ${p => p.theme.palette.common.white};
    }

    &:not(:first-of-type) {
      color: ${p => p.theme.palette.success.main};
    }
  }
`;

const ConfirmationButtonContainer = styled(ButtonContainer)`
  button {
    &:first-of-type {
      background-color: ${p => p.theme.palette.error.main};
    }
  }
`;

export default CustomReportsModal;
