import { gql } from '@apollo/client';

import { Maybe, Scalars } from '../apollo/types';

export enum LeagueLevelName {
  Combine = 'COMBINE',
  Club = 'CLUB',
  Letterman = 'LETTERMAN',
  Starter = 'STARTER',
  AllStar = 'ALL_STAR',
  AllAmerican = 'ALL_AMERICAN',
  Franchise = 'FRANCHISE',
}

export type LeagueLevelPointsBreakdownTotal = {
  count?: Maybe<Scalars['Int']>;
  score?: Maybe<Scalars['Float']>;
};

export type LeagueLevelPointsBreakdownDetail = {
  __typename?: 'LeagueScoreDetail';
  count?: Maybe<Scalars['Int']>;
  modifier?: Maybe<Scalars['Float']>;
  score?: Maybe<Scalars['Float']>;
};

export type LeagueLevelPointsBreakdown = {
  __typename?: 'LeagueScore';
  ppaAndLease?: Maybe<LeagueLevelPointsBreakdownDetail>;
  cashAndLoan?: Maybe<LeagueLevelPointsBreakdownDetail>;
  total?: Maybe<LeagueLevelPointsBreakdownTotal>;
};

export type LeagueQuarterResult = {
  __typename?: 'LeagueQuarterResult';
  level?: Maybe<LeagueLevelName>;
  score?: Maybe<Scalars['Float']>;
  status: Scalars['String'];
  /** @deprecated This field is too specific and rigid. Use `leagueLevelPoints` instead */
  permitSubmittedPoints?: Maybe<Scalars['Float']>;
  /** @deprecated This field is too specific and rigid. Use `leagueLevelPointsPace` instead */
  permitSubmittedPointsPace?: Maybe<Scalars['Float']>;
  /** @deprecated This field is too specific and rigid. Use `leagueLevelGoal` instead */
  permitSubmittedPointsGoal?: Maybe<Scalars['Float']>;
  leagueLevelPoints?: Maybe<Scalars['Float']>;
  leagueLevelPointsPace?: Maybe<Scalars['Float']>;
  leagueLevelGoal?: Maybe<Scalars['Float']>;
  leagueLevelGoalPace?: Maybe<Scalars['Float']>;
  leagueLevelPointsBreakdown?: Maybe<LeagueLevelPointsBreakdown>;
};

export type CombineProgress = {
  __typename?: 'CombineProgress';
  actual?: Maybe<Scalars['Int']>;
  goal?: Maybe<Scalars['Int']>;
};

export type CombineUserResults = {
  __typename?: 'CombineUserResults';
  isActive: Scalars['Boolean'];
  progress?: Maybe<CombineProgress>;
};

export type LeagueLevelResult = {
  entity: {
    salesMetrics: {
      league: {
        combine: CombineUserResults;
      };
    };
    profile: {
      league: {
        __typename: 'LeagueMetrics';
        leagueQuarterResultForCurrentQuarter: LeagueQuarterResult;
        leagueQuarterResultForPreviousQuarter: LeagueQuarterResult;
        leagueQuarterResultForThreeQuartersAgo: LeagueQuarterResult;
        leagueQuarterResultForTwoQuartersAgo: LeagueQuarterResult;
      };
    };
  };
};

export const athleteLeagueLevel = gql`
  query LeagueLevel(
    $entityId: String!
    $quarterDateForCurrentQuarter: String!
    $quarterDateForPreviousQuarter: String!
    $quarterDateForTwoQuartersAgo: String!
    $quarterDateForThreeQuartersAgo: String!
  ) {
    entity: employeeByBadgeId(badgeId: $entityId) {
      salesMetrics {
        league {
          combine {
            isActive
            progress {
              goal
              actual
            }
          }
        }
      }
      profile: salesProfile {
        league {
          leagueQuarterResultForCurrentQuarter: leagueQuarterResult(quarterDate: $quarterDateForCurrentQuarter) {
            ...leagueQuarterResultsAthlete
          }
          leagueQuarterResultForPreviousQuarter: leagueQuarterResult(quarterDate: $quarterDateForPreviousQuarter) {
            ...leagueQuarterResultsAthlete
          }
          leagueQuarterResultForTwoQuartersAgo: leagueQuarterResult(quarterDate: $quarterDateForTwoQuartersAgo) {
            ...leagueQuarterResultsAthlete
          }
          leagueQuarterResultForThreeQuartersAgo: leagueQuarterResult(quarterDate: $quarterDateForThreeQuartersAgo) {
            ...leagueQuarterResultsAthlete
          }
        }
      }
    }
  }
  fragment leagueQuarterResultsAthlete on LeagueQuarterResult {
    level
    status
    leagueLevelPoints
    leagueLevelPointsPace
    leagueLevelGoal
    leagueLevelGoalPace
    leagueLevelPointsBreakdown {
      ppaAndLease {
        count
        modifier
        score
      }
      cashAndLoan {
        count
        modifier
        score
      }
      total {
        count
        score
      }
    }
  }
`;

export const organizationLeagueLevel = gql`
  query LeagueLevel(
    $entityId: String!
    $quarterDateForCurrentQuarter: String!
    $quarterDateForPreviousQuarter: String!
    $quarterDateForTwoQuartersAgo: String!
    $quarterDateForThreeQuartersAgo: String!
  ) {
    entity: salesOrganizationByOrganizationId(organizationId: $entityId) {
      profile: salesProfile {
        league {
          leagueQuarterResultForCurrentQuarter: leagueQuarterResult(quarterDate: $quarterDateForCurrentQuarter) {
            ...leagueQuarterResultsOrg
          }
          leagueQuarterResultForPreviousQuarter: leagueQuarterResult(quarterDate: $quarterDateForPreviousQuarter) {
            ...leagueQuarterResultsOrg
          }
          leagueQuarterResultForTwoQuartersAgo: leagueQuarterResult(quarterDate: $quarterDateForTwoQuartersAgo) {
            ...leagueQuarterResultsOrg
          }
          leagueQuarterResultForThreeQuartersAgo: leagueQuarterResult(quarterDate: $quarterDateForThreeQuartersAgo) {
            ...leagueQuarterResultsOrg
          }
        }
      }
    }
  }
  fragment leagueQuarterResultsOrg on LeagueOrganizationQuarterResult {
    level
    status
    leagueLevelPoints
    leagueLevelPointsPace
    leagueLevelGoal
    leagueLevelGoalPace
  }
`;
