import { gql } from '@apollo/client';

import { Scalars } from '../apollo/types';

export enum SalesReportType {
  AllStats = 'ALL_STATS',
  AllStatsLease = 'ALL_STATS_LEASE',
  AllStatsPpa = 'ALL_STATS_PPA',
  AllStatsSpa = 'ALL_STATS_SPA',
  LevelUpIndividual = 'LEVEL_UP_INDIVIDUAL',
  LevelUpOrganization = 'LEVEL_UP_ORGANIZATION',
  WeeklyWins = 'WEEKLY_WINS',
  MyRecruits = 'MY_RECRUITS',
  EDIT = 'EDIT',
}

export enum SalesMetricPeriod {
  Day = 'DAY',
  Week = 'WEEK',
  Month = 'MONTH',
  Quarter = 'QUARTER',
  Year = 'YEAR',
  Lifetime = 'LIFETIME',
}

export enum SalesReportSubOrganization {
  Athlete = 'ATHLETE',
  Team = 'TEAM',
  Region = 'REGION',
  Conference = 'CONFERENCE',
}

export enum OrganizationLevelV2 {
  Team = 'TEAM',
  Region = 'REGION',
  Conference = 'CONFERENCE',
  Company = 'COMPANY',
}

export enum CustomSalesReportType {
  Standard = 'STANDARD',
  UserDefined = 'USER_DEFINED',
}

export type CustomSalesReport = {
  __typename?: 'CustomSalesReport';
  name: Scalars['String'];
  visible: Scalars['Boolean'];
  salesReportType: SalesReportType;
  level: OrganizationLevelV2;
  area: Scalars['String'];
  period: SalesMetricPeriod;
  view: SalesReportSubOrganization;
  type: CustomSalesReportType;
  areaId: Scalars['String'];
};

export type CustomSalesReportConnection = {
  __typename?: 'CustomSalesReportConnection';
  reports: Array<CustomSalesReport>;
};

export type GetSavedReportsResult = {
  viewerCustomSalesReports: CustomSalesReportConnection;
};

export const getSavedReports = gql`
  query getReports {
    viewerCustomSalesReports {
      reports {
        name
        visible
        salesReportType
        level
        area
        areaId
        period
        view
        type
      }
    }
  }
`;

export const updateReports = gql`
  mutation addReport($clientMutationId: String!, $reports: [CustomSalesReportInput!]!) {
    userUpdateCustomSalesReports(input: { clientMutationId: $clientMutationId, reports: $reports }) {
      ... on CustomSalesReportsUpdateSuccess {
        reports {
          name
          visible
          type
          level
          area
          areaId
          period
          view
          salesReportType
        }
        clientMutationId
      }
    }
  }
`;
