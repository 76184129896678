import styled from '@emotion/styled';
import { createMuiTheme, ThemeProvider } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import { withStyles } from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import EventIcon from '@material-ui/icons/Event';
import { DatePicker } from '@material-ui/pickers';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import { DateTime } from 'luxon';
import { useEffect, useState } from 'react';

import { Black, Globals, Red } from '../../styles/constants/colors';
import { CalendarType } from './types';

const lightTheme = createMuiTheme({
  palette: {
    type: 'light',
    primary: {
      main: Black.LIGHT,
    },
    secondary: {
      main: Red.MAIN,
    },
    background: {
      default: Globals.WHITE,
      paper: Globals.WHITE,
    },
  },
  typography: {
    fontFamily: 'Trade Gothic Condensed',
    h4: {
      color: Globals.WHITE,
    },
    body1: {
      '&$selected': {
        backgroundColor: Globals.WHITE,
      },
    },
  },
  overrides: {
    MuiToolbar: {
      regular: {
        minHeight: 100,
      },
      root: {
        backgroundColor: Black.LIGHT,
      },
    },
    MuiTypography: {
      root: {
        textTransform: 'uppercase',
        textAlign: 'center',
      },
    },
    MuiDialog: {
      paperScrollPaper: {
        maxHeight: 457,
        minWidth: 310,
      },
    },
  },
});

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  min-height: 200px;
`;

const formatCustomDateRange = (dateRange: { start: string; end: string }) => {
  const startDate = DateTime.fromISO(dateRange.start).toFormat('MM/dd/yyyy');
  const endDate = DateTime.fromISO(dateRange.end).toFormat('MM/dd/yyyy');

  return `${startDate} - ${endDate}`;
};

const CustomRangeCalendar = ({
  onChange,
  selectedRange,
  open = false,
}: {
  open: boolean;
  selectedRange: {
    start: string;
    end: string;
  };
  onChange: (dateRange: { start: string; end: string }) => void;
}) => {
  const [startDate, handleStartDateChange] = useState<MaterialUiPickersDate>(DateTime.fromISO(selectedRange.start));
  const [endDate, handleEndDateChange] = useState<MaterialUiPickersDate>(DateTime.fromISO(selectedRange.end));

  const handleClose = () => {
    const start = startDate?.toISODate() || DateTime.local().toISODate();
    const end = endDate?.toISODate() || DateTime.local().toISODate();
    onChange({ start, end });
  };

  return (
    <ThemeProvider theme={lightTheme}>
      <Dialog onClose={handleClose} aria-labelledby="simple-dialog-title" open={open} maxWidth="xl">
        <Toolbar>
          <Typography variant="h4">Custom Date Range</Typography>
        </Toolbar>
        <DialogContent>
          <ContentContainer>
            <DatePicker
              autoOk
              variant="inline"
              inputVariant="outlined"
              label="START"
              format="MM/dd/yyyy"
              minDate={new Date('2007-01-01')}
              disableFuture
              value={startDate}
              onChange={date => handleStartDateChange(date)}
            />

            <DatePicker
              autoOk
              variant="inline"
              inputVariant="outlined"
              label="END"
              format="MM/dd/yyyy"
              minDate={startDate}
              disableFuture
              value={endDate}
              onChange={date => handleEndDateChange(date)}
            />
          </ContentContainer>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleClose} color="primary">
            Ok
          </Button>
        </DialogActions>
      </Dialog>
    </ThemeProvider>
  );
};

const CustomPicker: CalendarType = ({ selectedRange, onChange, outlined }) => {
  const [open, setOpen] = useState(false);
  const [selectedDateRange, setSelectedDateRange] = useState(selectedRange);

  useEffect(() => {
    setSelectedDateRange(selectedRange);
  }, [selectedRange.start, selectedRange.end]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleChange = (dateRange: { start: string; end: string }) => {
    setOpen(false);
    setSelectedDateRange(dateRange);
    onChange(dateRange);
  };

  return (
    <StyledFormControl>
      {outlined && <StyledLabel>DATES</StyledLabel>}
      <StyledButton
        variant={outlined ? 'outlined' : 'text'}
        color="inherit"
        onClick={handleClickOpen}
        endIcon={outlined && <StyledIcon />}
      >
        {formatCustomDateRange(selectedDateRange)}
      </StyledButton>
      <CustomRangeCalendar selectedRange={selectedDateRange} open={open} onChange={handleChange} />
    </StyledFormControl>
  );
};

const StyledIcon = styled(EventIcon)`
  color: rgba(0, 0, 0, 0.54);
  font-size: 1.5rem !important;
`;

const StyledButton = styled(Button)`
  height: 100%;
  justify-content: space-between;
  font-size: 1rem;
`;

const StyledLabel = styled(FormLabel)`
  position: absolute;
  display: block;
  background: white;
  z-index: 1;
  top: 0;
  left: 0;
  color: rgba(0, 0, 0, 0.54);
  padding: 0 5px;
  font-size: 1rem;
  font-family: Trade Gothic Condensed;
  font-weight: 400;
  line-height: 1;
  transform: translate(4px, -6px) scale(0.75);
`;

const StyledFormControl = withStyles(theme => ({
  root: {
    '& label': {
      color: theme.palette.grey[600],
    },
    '& .MuiOutlinedInput-root': {
      color: theme.palette.grey[600],
      backgroundColor: theme.palette.common.white,

      '& fieldset': {
        borderColor: theme.palette.grey[600],
      },
      '&:hover fieldset': {
        borderColor: theme.palette.grey[600],
      },
      '&.Mui-focused fieldset': {
        borderColor: theme.palette.grey[600],
      },
      '& .MuiOutlinedInput-input': {
        textTransform: 'uppercase',
        color: theme.palette.common.black,
        fontWeight: 'bolder',
      },
    },
  },
}))(FormControl);

export default CustomPicker;
