import styled from '@emotion/styled';
import IconButton from '@material-ui/core/IconButton';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import { DateTime } from 'luxon';
import { useEffect, useState } from 'react';

import { CUSTOM, DAY, MONTH, QUARTER, WEEK, YEAR } from '../../constants/periods';
import { range, startDateOfLifetime } from '../../utilities/dates';
import mixpanel from '../../utilities/mixpanel';
import { DayPicker, MonthPicker, YearPicker } from './';
import CustomRangePicker from './CustomRangePicker';
import QuarterPicker from './QuarterPicker';
import { CalendarType } from './types';
import WeekPicker from './WeekPicker';

const addPeriodStep = (dateRange: { start: string; end: string }, period: string) => {
  const startDate = DateTime.fromISO(dateRange.start)
    .plus({ [period]: 1 })
    .toISODate();
  const endDate = DateTime.fromISO(dateRange.end)
    .plus({ [period]: 1 })
    .toISODate();

  return {
    start: startDate,
    end: endDate,
  };
};

const subPeriodStep = (dateRange: { start: string; end: string }, period: string) => {
  const startDate = DateTime.fromISO(dateRange.start)
    .minus({ [period]: 1 })
    .toISODate();
  const endDate = DateTime.fromISO(dateRange.end)
    .minus({ [period]: 1 })
    .toISODate();

  return {
    start: startDate,
    end: endDate,
  };
};

const checkForBeforeBeginning = (dateRange: { start: string; end: string }, period: string) => {
  const backAStep = subPeriodStep(dateRange, period);
  const beginningOfTime = DateTime.fromISO(startDateOfLifetime());
  const nextStart = DateTime.fromISO(backAStep.start);

  return nextStart < beginningOfTime;
};

const checkForFutureStep = (dateRange: { start: string; end: string }, period: string) => {
  const forwardAStep = addPeriodStep(dateRange, period);
  const today = DateTime.local();
  const nextStart = DateTime.fromISO(forwardAStep.start);

  return nextStart > today;
};

const calendarMap: {
  [key: string]: CalendarType;
} = {
  [DAY]: DayPicker,
  [WEEK]: WeekPicker,
  [MONTH]: MonthPicker,
  [QUARTER]: QuarterPicker,
  [YEAR]: YearPicker,
  [CUSTOM]: CustomRangePicker,
};

const IncrementalPicker = ({
  period = 'DAY',
  dateRange = range.day(),
  onChange = date => console.log(date),
}: {
  period: string;
  dateRange?: { start: string; end: string };
  onChange?: (dateRange: { start: string; end: string }) => void;
}) => {
  const [selectDateRange, setSelectDateRange] = useState(dateRange);

  useEffect(() => {
    setSelectDateRange(dateRange);
  }, [dateRange.start, dateRange.end]);

  const handleIncrease = () => {
    const step = addPeriodStep(selectDateRange, period);
    setSelectDateRange(step);
    mixpanel.track(`Increased incremental date`, {
      'Start Date': step.start,
      'End Date': step.end,
      Period: period,
    });
    onChange(step);
  };
  const handleDecrease = () => {
    const step = subPeriodStep(selectDateRange, period);
    setSelectDateRange(step);
    mixpanel.track(`Decreased incremental date`, {
      'Start Date': step.start,
      'End Date': step.end,
      Period: period,
    });
    onChange(step);
  };

  const handleDateChange = (rangeChange: { start: string; end: string }) => {
    setSelectDateRange(rangeChange);
    mixpanel.track(`Changed date in incremental picker`, {
      'Start Date': rangeChange.start,
      'End Date': rangeChange.end,
    });
    onChange(rangeChange);
  };

  const CalendarComponent = calendarMap[period];

  return (
    <StyledPicker>
      <IconButton
        onClick={handleDecrease}
        disabled={period === CUSTOM || checkForBeforeBeginning(selectDateRange, period)}
      >
        <ArrowBackIosIcon fontSize="small" />
      </IconButton>
      <CalendarComponent selectedRange={selectDateRange} onChange={handleDateChange} />
      <IconButton onClick={handleIncrease} disabled={period === CUSTOM || checkForFutureStep(selectDateRange, period)}>
        <ArrowForwardIosIcon fontSize="small" />
      </IconButton>
    </StyledPicker>
  );
};

const StyledPicker = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;

  button {
    z-index: 2;
  }

  fieldset {
    border: none;
  }
`;

export default IncrementalPicker;
