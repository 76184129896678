import { useQuery } from '@apollo/client';
import styled from '@emotion/styled';
import { Typography } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import { useState } from 'react';

import { ContainerLoading } from '../../../components/loading';
import SectionHeader from '../../../components/section-header';
import { VIEWS } from '../../../constants/views';
import { athleteWeeklyWins, teamWeeklyWins, WeeklyWinsResults } from '../../../queries/weeklyWins';
import { startDateOfFiscalQuarters } from '../../../utilities/dates';
import mixpanel from '../../../utilities/mixpanel';
import ErrorComponent from '../../error-boundary/ErrorComponent';
import WinsSummary from './WinsSummary';
import WinsTableData from './WinsTableData';

type StyledButtonProps = {
  filled: number;
};

const WeekyWins = ({ entityId, type }: { entityId: string; type: string }) => {
  const [viewQuarter, setViewQuarter] = useState('Q1');
  const quarters = ['Q1', 'Q2', 'Q3', 'Q4'];
  const { Q1, Q2, Q3, Q4 } = startDateOfFiscalQuarters();
  const orgType = type !== VIEWS.ATHLETE;
  const athleteType = type === VIEWS.ATHLETE;
  const { data: orgData, error: orgError, loading: orgLoading } = useQuery<WeeklyWinsResults>(teamWeeklyWins, {
    variables: {
      entityId: entityId,
      thisFiscalQuarter1: Q1,
      thisFiscalQuarter2: Q2,
      thisFiscalQuarter3: Q3,
      thisFiscalQuarter4: Q4,
    },
    skip: athleteType,
  });
  const { data: athleteData, error: athleteError, loading: athleteLoading } = useQuery<WeeklyWinsResults>(
    athleteWeeklyWins,
    {
      variables: {
        entityId: entityId,
        thisFiscalQuarter1: Q1,
        thisFiscalQuarter2: Q2,
        thisFiscalQuarter3: Q3,
        thisFiscalQuarter4: Q4,
      },
      skip: !athleteType,
    },
  );

  const entityData = orgData ? orgData : athleteData;

  const summaryData = [
    {
      title: 'WEEKLY WINS',
      wins: (entityData?.entity.winLossMetrics && entityData?.entity.winLossMetrics[viewQuarter]?.weeklyWins) || 0,
      weeks: (entityData?.entity.winLossMetrics && entityData?.entity.winLossMetrics[viewQuarter]?.numberOfWeeks) || 0,
    },
    {
      title: 'AC',
      wins:
        (entityData?.entity.winLossMetrics && entityData?.entity.winLossMetrics[viewQuarter]?.accountCreatedWins) || 0,
      weeks: (entityData?.entity.winLossMetrics && entityData?.entity.winLossMetrics[viewQuarter]?.numberOfWeeks) || 0,
    },
    {
      title: 'WC',
      wins: (entityData?.entity.winLossMetrics && entityData?.entity.winLossMetrics[viewQuarter]?.welcomeCallWins) || 0,
      weeks: (entityData?.entity.winLossMetrics && entityData?.entity.winLossMetrics[viewQuarter]?.numberOfWeeks) || 0,
    },
  ];

  return (
    <div>
      <SectionHeader title="WEEKS / WINS" />
      {(orgType && orgError) || (athleteType && athleteError && <ErrorComponent />)}
      {(orgType && orgLoading && !orgData) || (athleteType && athleteLoading && !athleteData && <ContainerLoading />)}
      <WinsContainer>
        <WinsSummary data={summaryData} />
        <div>
          <ButtonContainer>
            <ButtonGroup color="secondary" aria-label="outlined secondary button group">
              {quarters.map(quarter => {
                return (
                  <StyledButton
                    key={quarter}
                    onClick={() => {
                      setViewQuarter(quarter);
                      mixpanel.track(`Set quarter on Weekly Wins ${quarter}`);
                    }}
                    filled={viewQuarter === quarter ? 1 : 0}
                  >
                    <Typography variant="body1">{quarter}</Typography>
                  </StyledButton>
                );
              })}
            </ButtonGroup>
          </ButtonContainer>
          <WinsTableData
            data={entityData?.entity.winLossMetrics && entityData?.entity.winLossMetrics[viewQuarter]?.weeksV2}
            type={type}
          />
        </div>
      </WinsContainer>
    </div>
  );
};

const WinsContainer = styled.div`
  padding: ${p => p.theme.spacing(2)}px;
  margin-bottom: ${p => p.theme.spacing(3)}px;

  ${p => p.theme.breakpoints.up('lg')} {
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  margin: ${p => p.theme.spacing(2)}px ${p => p.theme.spacing(1)}px;
`;

const StyledButton = styled(Button)<StyledButtonProps>`
  background-color: ${p => (p.filled > 0 ? p.theme.palette.secondary.main : '')};
  color: ${p => (p.filled > 0 ? p.theme.palette.primary.main : '')};

  &:hover {
    background-color: ${p => (p.filled > 0 ? p.theme.palette.secondary.main : '')};
  }

  ${p => p.theme.breakpoints.up('sm')} {
    padding: ${p => p.theme.spacing(0)}px ${p => p.theme.spacing(4)}px;
  }
`;

export default WeekyWins;
