import styled from '@emotion/styled';

const ErrorComponent = ({ text }: { text?: string }) => {
  return (
    <Container>
      <Title>{text || 'Something went wrong. Please reload the page and try again.'}</Title>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  padding: 50px 20px 20px 20px;
`;

const Title = styled.div`
  font-size: 24px;
`;

export default ErrorComponent;
