import styled from '@emotion/styled';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import FilterListIcon from '@material-ui/icons/FilterList';
import { useEffect, useState } from 'react';

import { MenuOptions } from '../dropdown';

const StyledIcon = styled(IconButton)`
  margin-right: 12px;
`;

const StyledMenu = styled(Menu)`
  .MuiMenu-list {
    background-color: ${p => p.theme.palette.text.primary};
  }
`;

const StyledMenuItem = styled(MenuItem)`
  background-color: ${p => p.theme.palette.text.primary};
  font-weight: bold;
  color: ${p => p.theme.palette.primary.main};
  &.Mui-selected {
    background-color: ${p => p.theme.palette.text.secondary};
  }
  &.Mui-selected:hover {
    background-color: ${p => p.theme.palette.text.secondary};
  }
  :hover {
    background-color: ${p => p.theme.palette.text.primary};
  }
`;

const FilterDropDown = ({
  filterSelected,
  options,
}: {
  filterSelected: string | undefined;
  options: MenuOptions[];
}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [selectedIndex, setSelectedIndex] = useState(0);

  const handleFilterClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleFilterItemClick = (event: React.MouseEvent<HTMLElement>, index: number) => {
    setSelectedIndex(index);
    setAnchorEl(null);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    if (filterSelected) {
      const filterIndex = options.findIndex(x => x.value === filterSelected);
      setSelectedIndex(filterIndex);
    }
  }, [filterSelected, options]);

  return (
    <>
      <StyledIcon onClick={handleFilterClick}>
        <FilterListIcon />
      </StyledIcon>
      <StyledMenu
        id="header-menu"
        elevation={0}
        anchorEl={anchorEl}
        keepMounted
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {options.map((option, index) => (
          <StyledMenuItem
            key={option.value}
            selected={index === selectedIndex}
            onClick={event => {
              handleFilterItemClick(event, index);
              if (option.onOptionClick) {
                option.onOptionClick(event);
              }
            }}
          >
            {option.display.toUpperCase()}
          </StyledMenuItem>
        ))}
      </StyledMenu>
    </>
  );
};

export default FilterDropDown;
