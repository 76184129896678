import { useQuery } from '@apollo/client';
import styled from '@emotion/styled';
import { Typography } from '@material-ui/core';

import { ContainerLoading } from '../../../components/loading';
import SectionHeader from '../../../components/section-header';
import { HeadcountResult, orgHeadcount } from '../../../queries/headcount';
import ErrorComponent from '../../error-boundary/ErrorComponent';

const Headcount = ({ entityId }: { entityId: string }) => {
  const { data, error, loading } = useQuery<HeadcountResult>(orgHeadcount, {
    variables: { entityId: entityId },
  });

  return (
    <div>
      {error && <ErrorComponent />}
      {loading && !data && <ContainerLoading />}
      <SectionHeader title="Headcount" />
      <Container>
        <div>
          <Typography variant="h3">{data?.entity.salesProfile.headCount.active}</Typography>
          <Typography variant="body2">ACTIVE</Typography>
        </div>
        <div>
          <Typography variant="h3">{data?.entity.salesProfile.headCount.productive}</Typography>
          <Typography variant="body2">PRODUCTIVE</Typography>
        </div>
      </Container>
    </div>
  );
};

const Container = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  padding: ${p => p.theme.spacing(3)}px;

  div {
    text-align: center;
    margin: 0 ${p => p.theme.spacing(3)}px;
  }

  h3 {
    font-family: constructa;
    font-weight: lighter;
  }
`;

export default Headcount;
