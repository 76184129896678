const numberSuffix = (num: number) => (num > 999 ? (num / 1000).toFixed(1) + 'k' : num);

const digitLength = (num: number) => {
  const digits = num > 0 ? num : 0;
  return Math.ceil(Math.log10(digits + 1));
};

const defaultNumberSize = { radius: 14, fontSize: 18 };

const numberSizeMap: { [key: number]: { radius: number; fontSize: number } } = {
  1: { radius: 14, fontSize: 18 },
  2: { radius: 14, fontSize: 18 },
  3: { radius: 14, fontSize: 18 },
  4: { radius: 16, fontSize: 16 },
  5: { radius: 18, fontSize: 16 },
};

const WhiteRabbit = ({ position, number }: { position?: { x: number; y: number }; number: number }) => {
  if (!position || !number) {
    return null;
  }

  const lengthOfDigits = digitLength(number);
  const { radius, fontSize } = lengthOfDigits ? numberSizeMap[lengthOfDigits] : defaultNumberSize;
  const textYPos = radius * 1.4;

  return (
    <g transform={`translate(${position.x - radius} ${position.y - radius}) scale(1.2)`}>
      <circle cx={radius} cy={radius} r={radius} fill="white" />
      <text
        x={radius}
        y={textYPos}
        fill="gray"
        fontFamily="HelveticaNeue-CondensedBold"
        fontSize={fontSize}
        textAnchor="middle"
      >
        {numberSuffix(number)}
      </text>
    </g>
  );
};

export default WhiteRabbit;
