import { gql } from '@apollo/client';

import { Maybe, Scalars } from '../apollo/types';

export type SalesEntityParentSearchResult = {
  id: Scalars['ID'];
  name: Scalars['String'];
  type: Scalars['String'];
};

export type SalesEntitySearchResult = {
  id: Scalars['ID'];
  name: Scalars['String'];
  type: Scalars['String'];
  parent?: Maybe<SalesEntityParentSearchResult>;
};

export type SalesEntityResultsConnection = {
  entities: Array<SalesEntitySearchResult>;
};

export type ScorecardSearchResults = {
  salesEntitiesByTerm: SalesEntityResultsConnection;
};

export const scorecardSearch = gql`
  query entitySearch($term: String!) {
    salesEntitiesByTerm(term: $term) {
      entities {
        id
        name
        type
        parent {
          id
          name
          type
        }
      }
    }
  }
`;
