import { gql } from '@apollo/client';

import { Maybe, Scalars } from '../apollo/types';

export type WinLoss = {
  __typename?: 'WinLoss';
  weeksV2?: Maybe<Array<Maybe<WinLossWeekV2>>>;
  numberOfWeeks?: Maybe<Scalars['Int']>;
  accountCreatedWins?: Maybe<Scalars['Int']>;
  welcomeCallWins?: Maybe<Scalars['Int']>;
  weeklyWins?: Maybe<Scalars['Int']>;
};

export type WinLossMetric = {
  __typename?: 'WinLossMetric';
  total?: Maybe<Scalars['Float']>;
  win?: Maybe<Scalars['Boolean']>;
};

export type WinLossWeekV2 = {
  __typename?: 'WinLossWeekV2';
  accountCreated?: Maybe<WinLossMetric>;
  welcomeCall?: Maybe<WinLossMetric>;
  leagueScore?: Maybe<WinLossMetric>;
  win?: Maybe<Scalars['Boolean']>;
};

export type WinLossType = { [key: string]: WinLoss };

export type WeeklyWinsResults = {
  entity: {
    winLossMetrics: WinLossType;
  };
};

export const athleteWeeklyWins = gql`
  query WinLoss(
    $entityId: String!
    $thisFiscalQuarter1: String!
    $thisFiscalQuarter2: String!
    $thisFiscalQuarter3: String!
    $thisFiscalQuarter4: String!
  ) {
    entity: employeeByBadgeId(badgeId: $entityId) {
      winLossMetrics: salesMetrics {
        Q1: winLoss(quarterStartDate: $thisFiscalQuarter1) {
          weeklyWins
          accountCreatedWins
          welcomeCallWins
          numberOfWeeks
          weeksV2 {
            accountCreated {
              total
              win
            }
            welcomeCall {
              total
              win
            }
            win
          }
        }
        Q2: winLoss(quarterStartDate: $thisFiscalQuarter2) {
          weeklyWins
          accountCreatedWins
          welcomeCallWins
          numberOfWeeks
          weeksV2 {
            accountCreated {
              total
              win
            }
            welcomeCall {
              total
              win
            }
            win
          }
        }
        Q3: winLoss(quarterStartDate: $thisFiscalQuarter3) {
          weeklyWins
          accountCreatedWins
          welcomeCallWins
          numberOfWeeks
          weeksV2 {
            accountCreated {
              total
              win
            }
            welcomeCall {
              total
              win
            }
            win
          }
        }
        Q4: winLoss(quarterStartDate: $thisFiscalQuarter4) {
          weeklyWins
          accountCreatedWins
          welcomeCallWins
          numberOfWeeks
          weeksV2 {
            accountCreated {
              total
              win
            }
            welcomeCall {
              total
              win
            }
            win
          }
        }
      }
    }
  }
`;

export const teamWeeklyWins = gql`
  query WinLoss(
    $entityId: String!
    $thisFiscalQuarter1: String!
    $thisFiscalQuarter2: String!
    $thisFiscalQuarter3: String!
    $thisFiscalQuarter4: String!
  ) {
    entity: salesOrganizationByOrganizationId(organizationId: $entityId) {
      winLossMetrics: salesMetrics {
        Q1: winLoss(quarterStartDate: $thisFiscalQuarter1) {
          weeklyWins
          numberOfWeeks
          weeksV2 {
            leagueScore {
              total
              win
            }
          }
        }
        Q2: winLoss(quarterStartDate: $thisFiscalQuarter2) {
          weeklyWins
          numberOfWeeks
          weeksV2 {
            leagueScore {
              total
              win
            }
          }
        }
        Q3: winLoss(quarterStartDate: $thisFiscalQuarter3) {
          weeklyWins
          numberOfWeeks
          weeksV2 {
            leagueScore {
              total
              win
            }
          }
        }
        Q4: winLoss(quarterStartDate: $thisFiscalQuarter4) {
          weeklyWins
          numberOfWeeks
          weeksV2 {
            leagueScore {
              total
              win
            }
          }
        }
      }
    }
  }
`;
