import { floor } from 'lodash';
import { DateTime } from 'luxon';

import { Maybe } from '../apollo/types';

export const startDateOfCurrentDay = () => {
  return DateTime.now().toISODate();
};

export const startDateOfCurrentMonth = () => {
  return DateTime.now().startOf('month').toISODate();
};

export const startDateOfCurrentYear = () => {
  return DateTime.now().startOf('year').toISODate();
};

export const startDateOfCurrentWeek = () => {
  return DateTime.now().startOf('week').toISODate();
};

export const startDateOfCurrentQuarter = () => {
  return DateTime.now().startOf('quarter').toISODate();
};

export const endDateOfCurrentWeek = () => {
  return DateTime.now().endOf('week').toISODate();
};

export const endDateOfCurrentQuarter = () => {
  return DateTime.now().endOf('quarter').toISODate();
};

export const endDateOfCurrentDay = () => {
  return startDateOfCurrentDay();
};

export const endDateOfCurrentMonth = () => {
  return DateTime.now().endOf('month').toISODate();
};

export const endDateOfCurrentYear = () => {
  return DateTime.now().endOf('year').toISODate();
};

export const getQuarterNumber = () => {
  return DateTime.now().quarter;
};

export const startDateOfLifetime = () => {
  return '2009-06-01';
};

export const daysLeftInQuarter = () => {
  const date1 = DateTime.now();
  const date2 = DateTime.now().endOf('quarter');
  const dayDiff = date2.diff(date1, ['days']);
  return dayDiff.days;
};

export const startDateOfFiscalQuarters = () => {
  const start = DateTime.now().startOf('year');

  return {
    Q1: start.toISODate(),
    Q2: start.plus({ quarters: 1 }).toISODate(),
    Q3: start.plus({ quarters: 2 }).toISODate(),
    Q4: start.plus({ quarters: 3 }).toISODate(),
  };
};

export const range: { [key: string]: () => { start: string; end: string } } = {
  day: () => ({
    start: startDateOfCurrentDay(),
    end: endDateOfCurrentDay(),
  }),
  week: () => ({
    start: startDateOfCurrentWeek(),
    end: endDateOfCurrentWeek(),
  }),
  month: () => ({
    start: startDateOfCurrentMonth(),
    end: endDateOfCurrentMonth(),
  }),
  quarter: () => ({
    start: startDateOfCurrentQuarter(),
    end: endDateOfCurrentQuarter(),
  }),
  year: () => ({
    start: startDateOfCurrentYear(),
    end: endDateOfCurrentYear(),
  }),
  allTime: () => ({
    start: startDateOfLifetime(),
    end: endDateOfCurrentDay(),
  }),
};

export const getPastQuarter = (quartersAgo: number) => {
  const numOfQuartersAgo = DateTime.now().minus({ quarters: quartersAgo });
  return numOfQuartersAgo.startOf('quarter').toISODate();
};

export const getPastQuarterDisplay = (date?: string | Maybe<string>) => {
  if (!date || date === undefined) return '';
  const quarterNumber = DateTime.fromISO(date).quarter;
  const year = DateTime.fromISO(date).year;
  return `Q${quarterNumber} - ${year}`;
};

export const quarterDiff = (start: string, end: string) => {
  if (!start || !end) {
    return -1;
  }
  const a = DateTime.fromISO(start);
  const b = DateTime.fromISO(end);
  const diff = b.diff(a, 'quarter').toObject().quarters;

  return diff ? floor(diff) : 0;
};

export const addQuarters = (date: string, quarters: number) => {
  return DateTime.fromISO(date).plus({ quarter: quarters }).toISODate();
};

export const subtractQuarters = (date: string, quarters: number) => {
  return DateTime.fromISO(date).minus({ quarter: quarters }).toISODate();
};

export const startOfQuarter = (date: string) => {
  return DateTime.fromISO(date).startOf('quarter').toISODate();
};
