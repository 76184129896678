import styled from '@emotion/styled';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { useEffect, useState } from 'react';

import { MenuOptions } from './types';

const DropDownMenu = ({ options, optionSelected }: { options: MenuOptions[]; optionSelected?: string }) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [selectedIndex, setSelectedIndex] = useState(0);

  const handleMenuClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuItemClick = (event: React.MouseEvent<HTMLElement>, index: number) => {
    setSelectedIndex(index);
    setAnchorEl(null);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    if (optionSelected) {
      // we key off display name in the breakdown page header since that is the only unique thing for a report
      const property = window.location.pathname === '/breakdown' ? 'display' : 'value';
      const filterIndex = options.findIndex(x => x[property] === optionSelected);
      setSelectedIndex(filterIndex);
    }
  }, [optionSelected, options]);

  const StyledMenu = styled(Menu)`
    .MuiMenu-list {
      background-color: ${p => p.theme.palette.text.primary};
    }
  `;

  const StyledMenuItem = styled(MenuItem)`
    background-color: ${p => p.theme.palette.text.primary};
    font-weight: bold;
    color: ${p => p.theme.palette.primary.main};
    &.Mui-selected {
      background-color: ${p => p.theme.palette.text.secondary};
    }
    &.Mui-selected:hover {
      background-color: ${p => p.theme.palette.text.secondary};
    }
    :hover {
      background-color: ${p => p.theme.palette.text.primary};
    }
  `;

  return (
    <>
      <Button onClick={handleMenuClick} endIcon={<ExpandMoreIcon />}>
        {options[selectedIndex] && options[selectedIndex].display}
      </Button>
      <StyledMenu
        id="header-menu"
        elevation={0}
        anchorEl={anchorEl}
        keepMounted
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {options.map((option, index) => (
          <StyledMenuItem
            key={`${option.value}-${index}`}
            selected={index === selectedIndex}
            onClick={event => {
              handleMenuItemClick(event, index);
              if (option.onOptionClick) {
                option.onOptionClick(event);
              }
            }}
          >
            {option.display ? option.display.toUpperCase() : option.value}
          </StyledMenuItem>
        ))}
      </StyledMenu>
    </>
  );
};

export default DropDownMenu;
