export const BASE_URLS: { [key: string]: { [key: string]: string } } = {
  CLIENT: {
    LOCAL: 'http://localhost:3000',
    DEV: 'https://devmaj-league-live.sunrundev.com',
    // STAGE: 'http://localhost:3001',
    STAGE: 'https://majstg-league-live.sunrun.com',
    PROD: 'https://league-live.sunrun.com',
  },
  SPLAT: {
    LOCAL: 'https://local-go.sunrundev.com:8080/',
    DEV: 'https://devmaj-go.sunrundev.com/',
    STAGE: 'https://majstg-go.sunrun.com/',
    RELCERT: 'https://relcert-go.sunrunstage.com/',
    PROD: 'https://go.sunrun.com/',
  },
  SALES_METRICS: {
    LOCAL: 'http://localhost:8080/',
    DEV: 'https://sales-metrics-gateway-4aojbys0.uc.gateway.dev/salesMetricsGraphQL',
    STAGE: 'https://sales-metrics-gateway-4aojbys0.uc.gateway.dev/salesMetricsGraphQL',
    PROD: 'https://sales-metrics-gateway-b5i70zx.uc.gateway.dev/salesMetricsGraphQL',
  },
};
