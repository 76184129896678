import { useQuery } from '@apollo/client';
import styled from '@emotion/styled';
import { ReactComponent as Combine } from 'assets/icons/combine.svg';
import { ContainerLoading } from 'components/loading';
import { athleteLevelLogos } from 'constants/leagueLevel';
import { VIEWS } from 'constants/views';
import ErrorComponent from 'pages/error-boundary/ErrorComponent';
import athleteQuery, { AthleteResult, LeagueLevelName } from 'queries/athlete';
import { FC } from 'react';

import { Maybe } from '../../apollo/types';
import { range, startDateOfCurrentQuarter, startDateOfCurrentWeek } from '../../utilities/dates';
import DashboardLevels from './DashboardLevels';
import DashboardProfile from './DashboardProfile';
import DashboardStats from './DashboardStats';
import LeaderboardQuickView from './LeaderboardQuickView';

type Props = {
  fullName: string;
  badgeId: string;
  combineIsActive?: boolean;
};

export const getBackgroundSvg = (level?: Maybe<LeagueLevelName>, combineActive?: boolean) => {
  if (combineActive) {
    return { Logo: Combine };
  }
  return athleteLevelLogos.find(x => x.level === level);
};

const OverviewEmployee: FC<Props> = ({ fullName, badgeId, combineIsActive = false }) => {
  const type = VIEWS.ATHLETE;
  const { loading, error, data } = useQuery<AthleteResult>(athleteQuery, {
    variables: {
      entityId: badgeId,
      currentWeekStartDate: startDateOfCurrentWeek(),
      currentQuarterStartDate: startDateOfCurrentQuarter(),
      rangeForWeek: range.week(),
    },
  });

  if (error) {
    return <ErrorComponent />;
  }

  if (loading && !data) {
    return <ContainerLoading />;
  }

  const BackgroundSvg = getBackgroundSvg(data?.entity?.salesMetrics?.league?.currentQuarter?.level, combineIsActive);

  return (
    <>
      <Container>
        <DashboardProfile
          combineIsActive={combineIsActive}
          leagueLevel={data?.entity?.salesMetrics?.league?.currentQuarter?.level}
          title={fullName}
          type={type}
        />
        <DashboardLevels
          combineIsActive={combineIsActive}
          entityId={badgeId}
          league={data?.entity?.salesMetrics?.league}
          type={type}
        />
        <DashboardStats
          combineIsActive={combineIsActive}
          combineDaysRemaining={data?.entity?.salesMetrics?.league?.combine.daysRemaining}
          compScore={data?.entity?.salesMetrics?.competitionScoreBySalesMetricPeriod?.score}
          leagueLevel={data?.entity?.salesMetrics?.league?.currentQuarter?.level}
          leagueRank={data?.entity?.salesProfile?.ranking?.leagueLevel}
          leagueScore={data?.entity?.salesMetrics?.league?.currentQuarter?.score}
          performanceIndicators={data?.entity?.salesMetrics?.performanceIndicatorsV2?.metrics}
          type={type}
        />
        {BackgroundSvg && <BackgroundSvg.Logo />}
      </Container>
      <LeaderboardQuickView />
    </>
  );
};

const Container = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  padding: ${p => p.theme.spacing(5)}px ${p => p.theme.spacing(3)}px;

  ${p => p.theme.breakpoints.down('sm')} {
    margin-bottom: 90px; // absolutely positioned level frequencies at smaller sizes
    padding: ${p => p.theme.spacing(5)}px 0px;
  }

  > svg {
    position: absolute;
    top: -50px;
    left: -50px;
    fill: black;
    width: 100%;
    height: 500px;
    z-index: -1;
    opacity: 0.3;
  }
`;

export default OverviewEmployee;
