import { gql } from '@apollo/client';

import { Maybe, Scalars } from '../apollo/types';

export type PerformanceIndicatorsCareerBest = {
  permitSubmitted?: Maybe<PerformanceIndicatorsCareerBestContractTypes>;
  install?: Maybe<PerformanceIndicatorsCareerBestContractTypes>;
  kilowattsInstalled?: Maybe<PerformanceIndicatorsCareerBestContractTypes>;
};

export type PerformanceIndicatorsCareerBestContractTypes = {
  total?: Maybe<PerformanceIndicatorsCareerBestValues>;
};

export type PerformanceIndicatorsCareerBestValues = {
  date?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['Float']>;
};

export type MilestoneMetrics = {
  total?: Maybe<Scalars['Float']>;
};

export type PerformanceIndicators = {
  permitSubmitted?: Maybe<MilestoneMetrics>;
  install?: Maybe<MilestoneMetrics>;
  kilowattsInstalled?: Maybe<MilestoneMetrics>;
};

export type PerformanceIndicatorsV2 = {
  byRange?: Maybe<PerformanceIndicators>;
  careerBest?: Maybe<PerformanceIndicatorsCareerBest>;
};

export type CareerMetricsResults = {
  entity: {
    metrics: {
      performanceIndicatorsV2: PerformanceIndicatorsV2;
    };
    lifetime: {
      performanceIndicatorsV2: PerformanceIndicatorsV2;
    };
  };
};

export const athleteCareerMetrics = gql`
  query careerMetrics($entityId: String!, $rangeForAllTime: DateRange!) {
    entity: employeeByBadgeId(badgeId: $entityId) {
      metrics: salesMetrics {
        performanceIndicatorsV2 {
          careerBest(period: QUARTER) {
            permitSubmitted {
              total {
                date
                value
              }
            }
            install {
              total {
                date
                value
              }
            }
            kilowattsInstalled {
              total {
                date
                value
              }
            }
          }
        }
      }
      lifetime: salesMetrics {
        performanceIndicatorsV2 {
          byRange(range: $rangeForAllTime) {
            permitSubmitted {
              total
            }
            install {
              total
            }
            kilowattsInstalled {
              total
            }
          }
        }
      }
    }
  }
`;

export const orgCareerMetrics = gql`
  query careerMetrics($entityId: String!, $rangeForAllTime: DateRange!) {
    entity: salesOrganizationByOrganizationId(organizationId: $entityId) {
      metrics: salesMetrics {
        performanceIndicatorsV2 {
          careerBest(period: QUARTER) {
            permitSubmitted {
              total {
                date
                value
              }
            }
            install {
              total {
                date
                value
              }
            }
            kilowattsInstalled {
              total {
                date
                value
              }
            }
          }
        }
      }
      lifetime: salesMetrics {
        performanceIndicatorsV2 {
          byRange(range: $rangeForAllTime) {
            permitSubmitted {
              total
            }
            install {
              total
            }
            kilowattsInstalled {
              total
            }
          }
        }
      }
    }
  }
`;
