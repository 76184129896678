import { gql } from '@apollo/client';

export const organizationPastSeven = gql`
  query metrics(
    $entityId: String!
    $range1: DateRange!
    $range2: DateRange!
    $range3: DateRange!
    $range4: DateRange!
    $range5: DateRange!
    $range6: DateRange!
    $range7: DateRange!
  ) {
    entity: salesOrganizationByOrganizationId(organizationId: $entityId) {
      salesMetrics {
        range1: performanceIndicators(range: $range1) {
          ...keyPerformanceIndicatorsOrgSeven
        }
        range2: performanceIndicators(range: $range2) {
          ...keyPerformanceIndicatorsOrgSeven
        }
        range3: performanceIndicators(range: $range3) {
          ...keyPerformanceIndicatorsOrgSeven
        }
        range4: performanceIndicators(range: $range4) {
          ...keyPerformanceIndicatorsOrgSeven
        }
        range5: performanceIndicators(range: $range5) {
          ...keyPerformanceIndicatorsOrgSeven
        }
        range6: performanceIndicators(range: $range6) {
          ...keyPerformanceIndicatorsOrgSeven
        }
        range7: performanceIndicators(range: $range7) {
          ...keyPerformanceIndicatorsOrgSeven
        }
      }
    }
  }
  fragment keyPerformanceIndicatorsOrgSeven on PerformanceIndicators {
    accountCreated {
      total
    }
    welcomeCall {
      total
    }
    permitSubmitted {
      total
    }
    installScheduled {
      total
    }
    install {
      total
    }
  }
`;

export const organizationPastFourteen = gql`
  query metrics(
    $entityId: String!
    $range1: DateRange!
    $range2: DateRange!
    $range3: DateRange!
    $range4: DateRange!
    $range5: DateRange!
    $range6: DateRange!
    $range7: DateRange!
    $range8: DateRange!
    $range9: DateRange!
    $range10: DateRange!
    $range11: DateRange!
    $range12: DateRange!
    $range13: DateRange!
    $range14: DateRange!
  ) {
    entity: salesOrganizationByOrganizationId(organizationId: $entityId) {
      salesMetrics {
        range1: performanceIndicators(range: $range1) {
          ...keyPerformanceIndicatorsOrgFourteen
        }
        range2: performanceIndicators(range: $range2) {
          ...keyPerformanceIndicatorsOrgFourteen
        }
        range3: performanceIndicators(range: $range3) {
          ...keyPerformanceIndicatorsOrgFourteen
        }
        range4: performanceIndicators(range: $range4) {
          ...keyPerformanceIndicatorsOrgFourteen
        }
        range5: performanceIndicators(range: $range5) {
          ...keyPerformanceIndicatorsOrgFourteen
        }
        range6: performanceIndicators(range: $range6) {
          ...keyPerformanceIndicatorsOrgFourteen
        }
        range7: performanceIndicators(range: $range7) {
          ...keyPerformanceIndicatorsOrgFourteen
        }
        range8: performanceIndicators(range: $range8) {
          ...keyPerformanceIndicatorsOrgFourteen
        }
        range9: performanceIndicators(range: $range9) {
          ...keyPerformanceIndicatorsOrgFourteen
        }
        range10: performanceIndicators(range: $range10) {
          ...keyPerformanceIndicatorsOrgFourteen
        }
        range11: performanceIndicators(range: $range11) {
          ...keyPerformanceIndicatorsOrgFourteen
        }
        range12: performanceIndicators(range: $range12) {
          ...keyPerformanceIndicatorsOrgFourteen
        }
        range13: performanceIndicators(range: $range13) {
          ...keyPerformanceIndicatorsOrgFourteen
        }
        range14: performanceIndicators(range: $range14) {
          ...keyPerformanceIndicatorsOrgFourteen
        }
      }
    }
  }
  fragment keyPerformanceIndicatorsOrgFourteen on PerformanceIndicators {
    accountCreated {
      total
    }
    welcomeCall {
      total
    }
    permitSubmitted {
      total
    }
    installScheduled {
      total
    }
    install {
      total
    }
  }
`;

export const orgPastTwentyOne = gql`
  query metrics(
    $entityId: String!
    $range1: DateRange!
    $range2: DateRange!
    $range3: DateRange!
    $range4: DateRange!
    $range5: DateRange!
    $range6: DateRange!
    $range7: DateRange!
    $range8: DateRange!
    $range9: DateRange!
    $range10: DateRange!
    $range11: DateRange!
    $range12: DateRange!
    $range13: DateRange!
    $range14: DateRange!
    $range15: DateRange!
    $range16: DateRange!
    $range17: DateRange!
    $range18: DateRange!
    $range19: DateRange!
    $range20: DateRange!
    $range21: DateRange!
  ) {
    entity: salesOrganizationByOrganizationId(organizationId: $entityId) {
      salesMetrics {
        range1: performanceIndicators(range: $range1) {
          ...keyPerformanceIndicatorsOrgTwentyOne
        }
        range2: performanceIndicators(range: $range2) {
          ...keyPerformanceIndicatorsOrgTwentyOne
        }
        range3: performanceIndicators(range: $range3) {
          ...keyPerformanceIndicatorsOrgTwentyOne
        }
        range4: performanceIndicators(range: $range4) {
          ...keyPerformanceIndicatorsOrgTwentyOne
        }
        range5: performanceIndicators(range: $range5) {
          ...keyPerformanceIndicatorsOrgTwentyOne
        }
        range6: performanceIndicators(range: $range6) {
          ...keyPerformanceIndicatorsOrgTwentyOne
        }
        range7: performanceIndicators(range: $range7) {
          ...keyPerformanceIndicatorsOrgTwentyOne
        }
        range8: performanceIndicators(range: $range8) {
          ...keyPerformanceIndicatorsOrgTwentyOne
        }
        range9: performanceIndicators(range: $range9) {
          ...keyPerformanceIndicatorsOrgTwentyOne
        }
        range10: performanceIndicators(range: $range10) {
          ...keyPerformanceIndicatorsOrgTwentyOne
        }
        range11: performanceIndicators(range: $range11) {
          ...keyPerformanceIndicatorsOrgTwentyOne
        }
        range12: performanceIndicators(range: $range12) {
          ...keyPerformanceIndicatorsOrgTwentyOne
        }
        range13: performanceIndicators(range: $range13) {
          ...keyPerformanceIndicatorsOrgTwentyOne
        }
        range14: performanceIndicators(range: $range14) {
          ...keyPerformanceIndicatorsOrgTwentyOne
        }
        range15: performanceIndicators(range: $range15) {
          ...keyPerformanceIndicatorsOrgTwentyOne
        }
        range16: performanceIndicators(range: $range16) {
          ...keyPerformanceIndicatorsOrgTwentyOne
        }
        range17: performanceIndicators(range: $range17) {
          ...keyPerformanceIndicatorsOrgTwentyOne
        }
        range18: performanceIndicators(range: $range18) {
          ...keyPerformanceIndicatorsOrgTwentyOne
        }
        range19: performanceIndicators(range: $range19) {
          ...keyPerformanceIndicatorsOrgTwentyOne
        }
        range20: performanceIndicators(range: $range20) {
          ...keyPerformanceIndicatorsOrgTwentyOne
        }
        range21: performanceIndicators(range: $range21) {
          ...keyPerformanceIndicatorsOrgTwentyOne
        }
      }
    }
  }
  fragment keyPerformanceIndicatorsOrgTwentyOne on PerformanceIndicators {
    accountCreated {
      total
    }
    welcomeCall {
      total
    }
    permitSubmitted {
      total
    }
    installScheduled {
      total
    }
    install {
      total
    }
  }
`;
