import * as React from 'react';

type State = {
  isOne: boolean;
};

type ContextState = {
  state: State;
};

export const OneContext = React.createContext<ContextState>((null as unknown) as ContextState);

const OneProvider: React.FC<React.ReactNode> = ({ children }) => {
  const [state, setState] = React.useState<State>({
    isOne: /[&?]one/g.test(window.location.search),
  });
  return <OneContext.Provider value={{ state }}>{children}</OneContext.Provider>;
};

export default OneProvider;
