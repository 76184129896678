import { createMuiTheme, ThemeProvider } from '@material-ui/core';
import { DatePicker, DatePickerView, KeyboardDatePicker } from '@material-ui/pickers';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import { DateTime } from 'luxon';
import { useEffect, useState } from 'react';
import { startDateOfLifetime } from 'utilities/dates';

import { DAY, MONTH, YEAR } from '../../constants/periods';
import { Black, Globals, Red } from '../../styles/constants/colors';
import { CalendarType } from './types';

export const createTheme = ({ color, outlined }: { color: string; outlined: boolean }) => {
  const noOutlineOverrides = outlined
    ? {}
    : {
        MuiOutlinedInput: {
          input: {
            padding: 0,
          },
        },
      };
  return createMuiTheme({
    palette: {
      type: 'light',
      primary: {
        main: Black.LIGHT,
      },
      secondary: {
        main: Red.MAIN,
      },
      background: {
        default: Globals.WHITE,
        paper: Globals.WHITE,
      },
    },
    typography: {
      fontFamily: 'Trade Gothic Condensed',
    },
    overrides: {
      MuiTypography: {
        root: {
          textTransform: 'uppercase',
        },
      },
      MuiInputBase: {
        root: {
          color,
        },
        input: {
          textAlign: 'center',
          textTransform: 'uppercase',
        },
      },
      ...noOutlineOverrides,
    },
  });
};

type periodPropsMapType = { [key: string]: { views: DatePickerView[]; openTo?: DatePickerView; format?: string } };
const periodPropsMap: periodPropsMapType = {
  [DAY]: { views: ['date'] },
  [MONTH]: { views: ['month'], openTo: 'month', format: 'MMM yyyy' },
  [YEAR]: { views: ['year'] },
};

type periodFormatterMapType = {
  DAY: (
    date: MaterialUiPickersDate,
  ) => {
    start: string;
    end: string;
  } | null;
  MONTH: (
    date: MaterialUiPickersDate,
  ) => {
    start: string;
    end: string;
  } | null;
  YEAR: (
    date: MaterialUiPickersDate,
  ) => {
    start: string;
    end: string;
  } | null;
};

const periodFormatterMap: periodFormatterMapType = {
  [DAY]: (date: MaterialUiPickersDate) =>
    date && { start: date.startOf('day').toISODate(), end: date.endOf('day').toISODate() },
  [MONTH]: (date: MaterialUiPickersDate) =>
    date && { start: date.startOf('month').toISODate(), end: date.endOf('month').toISODate() },
  [YEAR]: (date: MaterialUiPickersDate) =>
    date && { start: date.startOf('year').toISODate(), end: date.endOf('year').toISODate() },
};

const PickerMaker = (period: keyof periodFormatterMapType) => {
  const periodProps = periodPropsMap[period];
  const periodFormatter = periodFormatterMap[period];

  const GenericPicker: CalendarType = ({ selectedRange, onChange, color, outlined = false }) => {
    const theme = createTheme({ outlined, color: color || Globals.WHITE });
    const [selectedDate, setDateChange] = useState<MaterialUiPickersDate>(DateTime.fromISO(selectedRange.start));

    useEffect(() => {
      const newDate = DateTime.fromISO(selectedRange.start);
      setDateChange(newDate);
    }, [selectedRange]);

    const handleDateChange = (date: MaterialUiPickersDate) => {
      if (date) {
        const newDate = periodFormatter(date);
        if (newDate) {
          onChange(newDate);
          setDateChange(date);
        }
      }
    };

    const CalendarComponent = outlined ? KeyboardDatePicker : DatePicker;

    return (
      <ThemeProvider theme={theme}>
        <CalendarComponent
          {...periodProps}
          label={outlined && 'DATES'}
          inputVariant="outlined"
          minDate={new Date(startDateOfLifetime())}
          maxDate={new Date()}
          disableFuture
          value={selectedDate}
          onChange={handleDateChange}
        />
      </ThemeProvider>
    );
  };

  return GenericPicker;
};

export const DayPicker = PickerMaker(DAY);
export const MonthPicker = PickerMaker(MONTH);
export const YearPicker = PickerMaker(YEAR);
