import { useQuery } from '@apollo/client';
import styled from '@emotion/styled';

import { ContainerLoading } from '../../../components/loading';
import { VIEWS } from '../../../constants/views';
import { athleteLeagueScore, LeagueScoreResults, organizationLeagueScore } from '../../../queries/leagueScore';
import ErrorComponent from '../../error-boundary/ErrorComponent';
import Breakdown from './Breakdown';
import Summary from './Summary';

const ScoreComponent = ({
  entityId,
  period,
  startDate,
  type,
}: {
  entityId: string;
  period: string;
  startDate: string;
  type: string;
}) => {
  const orgType = type === VIEWS.OFFICE;
  const athleteType = type === VIEWS.ATHLETE;
  const { data: orgData, error: orgError, loading: orgLoading } = useQuery<LeagueScoreResults>(
    organizationLeagueScore,
    {
      variables: { entityId: entityId, period: period, startDate: startDate },
      skip: !orgType,
    },
  );
  const { data: athleteData, error: athleteError, loading: athleteLoading } = useQuery<LeagueScoreResults>(
    athleteLeagueScore,
    {
      variables: { entityId: entityId, period: period, startDate: startDate },
      skip: !athleteType,
    },
  );

  const entityData = orgData ? orgData : athleteData;

  return (
    <Score>
      {(orgType && orgError) || (athleteType && athleteError && <ErrorComponent />)}
      {(orgType && orgLoading && !orgData) || (athleteType && athleteLoading && !athleteData && <ContainerLoading />)}
      <Summary entityData={entityData} type={type} period={period} />
      <Breakdown entityData={entityData} />
    </Score>
  );
};

const Score = styled.div`
  h2,
  h4,
  h5 {
    font-family: constructa;
    font-weight: normal;
  }

  > div {
    padding: ${p => p.theme.spacing(2)}px;
  }

  ${p => p.theme.breakpoints.up('md')} {
    display: flex;
    justify-content: center;

    > div {
      padding: ${p => p.theme.spacing(2)}px ${p => p.theme.spacing(4)}px;
    }
  }
`;

export default ScoreComponent;
