import { gql } from '@apollo/client';

import { Maybe, Scalars } from '../apollo/types';

export enum LeagueLevelName {
  Combine = 'COMBINE',
  Club = 'CLUB',
  Letterman = 'LETTERMAN',
  Starter = 'STARTER',
  AllStar = 'ALL_STAR',
  AllAmerican = 'ALL_AMERICAN',
  Franchise = 'FRANCHISE',
}

export type MetricStandingCount = {
  __typename?: 'MetricStandingCount';
  powerPurchaseAgreementAndLease?: Maybe<Scalars['Float']>;
  cashAndLoan?: Maybe<Scalars['Float']>;
  total?: Maybe<Scalars['Float']>;
};

export type EmployeeContact = {
  __typename?: 'EmployeeContact';
  givenName?: Maybe<Scalars['String']>;
  familyName?: Maybe<Scalars['String']>;
  fullName?: Maybe<Scalars['String']>;
};

export type Employee = {
  __typename?: 'Employee';
  id?: Maybe<Scalars['ID']>;
  badgeId?: Maybe<Scalars['String']>;
  contact?: Maybe<EmployeeContact>;
};

export type MetricStanding = {
  __typename?: 'MetricStanding';
  rank?: Maybe<Scalars['Int']>;
  count?: Maybe<MetricStandingCount>;
};

export type CompetitionCompetitorsEdge = {
  __typename?: 'CompetitionCompetitorsEdge';
  leagueLevel?: Maybe<LeagueLevelName>;
  node?: Maybe<Employee>;
  LEAGUE_SCORE?: Maybe<MetricStanding>;
  COMPETITION_SCORE?: Maybe<MetricStanding>;
  ACCOUNT_CREATED?: Maybe<MetricStanding>;
  WELCOME_CALL?: Maybe<MetricStanding>;
  PERMIT_SUBMITTED?: Maybe<MetricStanding>;
  INSTALL_SCHEDULED?: Maybe<MetricStanding>;
  INSTALL?: Maybe<MetricStanding>;
  KILOWATTS_INSTALLED?: Maybe<MetricStanding>;
};

export type CompetitionCompetitorsConnection = {
  __typename?: 'CompetitionCompetitorsConnection';
  edges?: Maybe<Array<Maybe<CompetitionCompetitorsEdge>>>;
};

export type SalesStanding = {
  __typename?: 'SalesStanding';
  top?: Maybe<CompetitionCompetitorsConnection>;
  rivals?: Maybe<CompetitionCompetitorsConnection>;
};

export type LeagueCompetition = {
  __typename?: 'LeagueCompetition';
  salesStanding?: Maybe<SalesStanding>;
};

export type LeaderboardResults = {
  employee: {
    salesProfile: {
      leagueCompetition: LeagueCompetition;
    };
  };
};

export const LeagueRankQuery = gql`
  query LeaderboardAllAthletes(
    $leagueLevelQuarterDate: String!
    $startDate: String!
    $endDate: String!
    $selectedPeriod: SalesLeaderboardPeriod!
    $scope: OrganizationLevel
    $firstLimit: Int
    $leagueLevelFilter: LeagueLevelName
    $metricType: CompetitionStandingsMetricType!
    $managementFilter: LeagueCompetitionFilter
  ) {
    employee {
      salesProfile {
        leagueCompetition(
          scope: $scope
          period: { step: $selectedPeriod, range: { start: $startDate, end: $endDate } }
          leagueLevel: $leagueLevelFilter
          filter: $managementFilter
        ) {
          salesStanding(type: $metricType) {
            top(first: $firstLimit) {
              edges {
                ACCOUNT_CREATED: metricStanding(type: ACCOUNT_CREATED) {
                  ...Standings
                }
                INSTALL: metricStanding(type: INSTALL) {
                  ...Standings
                }
                INSTALL_SCHEDULED: metricStanding(type: INSTALL_SCHEDULED) {
                  ...Standings
                }
                KILOWATTS_INSTALLED: metricStanding(type: KILOWATTS_INSTALLED) {
                  ...Standings
                }
                PERMIT_SUBMITTED: metricStanding(type: PERMIT_SUBMITTED) {
                  ...Standings
                }
                WELCOME_CALL: metricStanding(type: WELCOME_CALL) {
                  ...Standings
                }
                LEAGUE_SCORE: metricStanding(type: LEAGUE_SCORE) {
                  ...Standings
                }
                leagueLevel(quarterDate: $leagueLevelQuarterDate)
                node {
                  badgeId
                  contact {
                    fullName
                    givenName
                    familyName
                  }
                }
              }
            }
            rivals(number: 10) {
              edges {
                ACCOUNT_CREATED: metricStanding(type: ACCOUNT_CREATED) {
                  ...Standings
                }
                INSTALL: metricStanding(type: INSTALL) {
                  ...Standings
                }
                INSTALL_SCHEDULED: metricStanding(type: INSTALL_SCHEDULED) {
                  ...Standings
                }
                KILOWATTS_INSTALLED: metricStanding(type: KILOWATTS_INSTALLED) {
                  ...Standings
                }
                PERMIT_SUBMITTED: metricStanding(type: PERMIT_SUBMITTED) {
                  ...Standings
                }
                WELCOME_CALL: metricStanding(type: WELCOME_CALL) {
                  ...Standings
                }
                LEAGUE_SCORE: metricStanding(type: LEAGUE_SCORE) {
                  ...Standings
                }
                leagueLevel(quarterDate: $leagueLevelQuarterDate)
                node {
                  badgeId
                  contact {
                    fullName
                    givenName
                    familyName
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  fragment Standings on MetricStanding {
    rank
    count {
      total
      powerPurchaseAgreementAndLease
      cashAndLoan
    }
  }
`;

export const CompScoreQuery = gql`
  query competitionScoreForAllAthletes(
    $leagueLevelQuarterDate: String!
    $startDate: String!
    $endDate: String
    $selectedPeriod: SalesLeaderboardPeriod!
    $scope: OrganizationLevel
    $firstLimit: Int
    $leagueLevelFilter: LeagueLevelName
    $managementFilter: LeagueCompetitionFilter
  ) {
    employee {
      salesProfile {
        leagueCompetition(
          scope: $scope
          period: { step: $selectedPeriod, range: { start: $startDate, end: $endDate } }
          leagueLevel: $leagueLevelFilter
          filter: $managementFilter
        ) {
          salesStanding(type: COMPETITION_SCORE) {
            top(first: $firstLimit) {
              edges {
                leagueLevel(quarterDate: $leagueLevelQuarterDate)
                COMPETITION_SCORE: metricStanding(type: COMPETITION_SCORE) {
                  count {
                    total
                  }
                  rank
                }
                ACCOUNT_CREATED: metricStanding(type: ACCOUNT_CREATED) {
                  count {
                    total
                  }
                }
                WELCOME_CALL: metricStanding(type: WELCOME_CALL) {
                  count {
                    powerPurchaseAgreementAndLease
                    cashAndLoan
                  }
                }
                node {
                  badgeId
                  contact {
                    fullName
                  }
                }
              }
            }
            rivals(number: 10) {
              edges {
                leagueLevel(quarterDate: $leagueLevelQuarterDate)
                COMPETITION_SCORE: metricStanding(type: COMPETITION_SCORE) {
                  count {
                    total
                  }
                  rank
                }
                ACCOUNT_CREATED: metricStanding(type: ACCOUNT_CREATED) {
                  count {
                    total
                  }
                }
                WELCOME_CALL: metricStanding(type: WELCOME_CALL) {
                  count {
                    powerPurchaseAgreementAndLease
                    cashAndLoan
                  }
                }
                node {
                  badgeId
                  contact {
                    fullName
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;
