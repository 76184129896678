import styled from '@emotion/styled';
import { Typography } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import Carousel from 'components/carousel';

import useWindowSize from '../../../utilities/windowSize';

const WinsSummary = ({ data }: { data: { title: string; wins: number; weeks: number }[] }) => {
  const theme = useTheme();
  const [width] = useWindowSize();

  const metricArray = data.map((summary, index) => {
    const mainNumber = summary.title === 'WEEKLY WINS' ? 'h2' : 'h3';
    const subHeaders = summary.title === 'WEEKLY WINS' ? 'h6' : 'body1';
    return (
      <Metric key={index}>
        <Typography variant="h6">{summary.title}</Typography>
        <Numbers>
          <div>
            <Typography variant={mainNumber}>{summary.wins}</Typography>
            <Typography variant={subHeaders}>WIN</Typography>
          </div>
          <div>
            <Typography variant={mainNumber}>{summary.weeks}</Typography>
            <Typography variant={subHeaders}>WEEKS</Typography>
          </div>
        </Numbers>
      </Metric>
    );
  });

  // carousel on mobile
  if (width <= theme.breakpoints.values.lg) {
    return (
      <CarouselContainer>
        <Carousel pages={metricArray} title="Weekly Wins" />
      </CarouselContainer>
    );
  }
  return <Container>{metricArray}</Container>;
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0 ${p => p.theme.spacing(3)}px;

  h6 {
    text-align: center;
    font-weight: lighter;
  }
`;

const Metric = styled.div`
  margin: ${p => p.theme.spacing(1)}px 0;
`;

const Numbers = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;

  h2,
  h3 {
    font-family: constructa;
    font-weight: normal;
  }

  h2 {
    font-size: 70px;
  }

  p {
    font-size: 18px;
    width: 45px;
  }

  div {
    position: relative;
    padding: 0 ${p => p.theme.spacing(4)}px;

    &:first-of-type {
      &:after {
        content: '';
        position: absolute;
        right: 0;
        transform: translateY(-50%);
        top: 50%;
        height: 60%;
        width: 1px;
        background-color: white;
      }
    }
  }
`;

const CarouselContainer = styled.div`
  margin: 0 ${p => p.theme.spacing(3)}px;
  text-align: center;

  h6 {
    text-align: center;
    font-weight: lighter;
  }
`;

export default WinsSummary;
