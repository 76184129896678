import { useQuery } from '@apollo/client';
import styled from '@emotion/styled';
import Typography from '@material-ui/core/Typography';
import MaterialTable from 'material-table';

import TableIcons from '../../../components/filter-table/TableIcons';
import { ContainerLoading } from '../../../components/loading';
import { COMBINE, DM, OFFICE, REGIONAL_DIRECTORS } from '../../../constants/breakdown';
import { LEAGUE_LEVEL_TO_DISPLAY } from '../../../constants/leagueLevel';
import { TimePeriod } from '../../../constants/periods';
import { VIEWS } from '../../../constants/views';
import { CompScoreQuery, LeaderboardResults } from '../../../queries/leaderboard';
import { Black, Globals, Gold } from '../../../styles/constants/colors';
import { startOfQuarter } from '../../../utilities/dates';
import { getLevelLogo } from '../../../utilities/getLevelLogo';
import useWindowSize from '../../../utilities/windowSize';
import ErrorComponent from '../../error-boundary/ErrorComponent';
import { LEADERS } from '..';

type HighlightProps = {
  highlight: string;
};

const CompScore = ({
  period,
  dates,
  scope = OFFICE,
  leagueLevel,
  variety,
}: {
  period: TimePeriod;
  dates: { start: string; end: string };
  scope?: string;
  leagueLevel: string | null;
  variety: string;
}) => {
  const [width] = useWindowSize();
  const isManagement = leagueLevel === DM || leagueLevel === REGIONAL_DIRECTORS;
  const leagueLevelFilter = isManagement ? null : leagueLevel;
  const managementFilter = isManagement ? leagueLevel : null;

  const variables = {
    leagueLevelQuarterDate: startOfQuarter(dates.end),
    startDate: dates.start,
    endDate: dates.end,
    selectedPeriod: period,
    scope: scope,
    firstLimit: scope === OFFICE ? null : 100,
    leagueLevelFilter: leagueLevelFilter,
    managementFilter: managementFilter,
  };

  const { data: resultsData, error, loading } = useQuery<LeaderboardResults>(CompScoreQuery, { variables: variables });

  const salesStanding = resultsData?.employee.salesProfile.leagueCompetition.salesStanding;
  const editableTableData =
    variety === LEADERS
      ? salesStanding?.top?.edges?.map(o => ({ ...o }))
      : salesStanding?.rivals?.edges?.map(o => ({ ...o }));

  if (error) {
    return <ErrorComponent />;
  }

  if (loading) {
    return <ContainerLoading />;
  }

  return (
    <>
      {editableTableData && editableTableData.length > 0 && (
        <ExtraWCHeader>
          <div>
            <span></span>
            <Typography variant="caption">WC</Typography>
          </div>
        </ExtraWCHeader>
      )}
      {editableTableData && (
        <MaterialTable
          columns={[
            {
              title: 'RANK',
              render: data => (
                <Rank
                  variant="h4"
                  highlight={
                    data?.COMPETITION_SCORE?.rank && data?.COMPETITION_SCORE?.rank <= 10 ? Gold.MAIN : Globals.WHITE
                  }
                >
                  {data.COMPETITION_SCORE?.rank}
                </Rank>
              ),
              sorting: false,
              width: 50,
              headerStyle: { color: Gold.MAIN },
            },
            {
              title: 'ATHLETE',
              headerStyle: { color: Gold.MAIN },
              render: data => {
                const logo = getLevelLogo(VIEWS.ATHLETE, data.leagueLevel === COMBINE, data.leagueLevel);
                const highlightColor =
                  data?.COMPETITION_SCORE?.rank && data?.COMPETITION_SCORE?.rank <= 10 ? Gold.MAIN : Globals.WHITE;
                return (
                  <Athlete highlight={highlightColor}>
                    <LeagueLevelLogo highlight={highlightColor}>{logo && <logo.Logo />}</LeagueLevelLogo>
                    <div>
                      <Typography variant="body1">{data.node?.contact?.fullName || data.node?.badgeId}</Typography>
                      <Typography variant="body2">
                        {data.leagueLevel && LEAGUE_LEVEL_TO_DISPLAY[data.leagueLevel].toUpperCase()}
                      </Typography>
                    </div>
                  </Athlete>
                );
              },
              sorting: false,
            },
            {
              title: 'SCORE',
              render: data => <CenteredNumber>{data.COMPETITION_SCORE?.count?.total}</CenteredNumber>,
              sorting: false,
              width: 75,
              headerStyle: { textAlign: 'center', color: Gold.MAIN, fontWeight: 'bold' },
              cellStyle: { borderRight: `1px solid ${Globals.WHITE}` },
            },
            {
              title: 'AC',
              render: data => <CenteredNumber>{data.ACCOUNT_CREATED?.count?.total}</CenteredNumber>,
              sorting: false,
              width: 75,
              headerStyle: { textAlign: 'center', color: Gold.MAIN },
            },
            {
              title: 'PPA',
              render: data => (
                <CenteredNumber>{data.WELCOME_CALL?.count?.powerPurchaseAgreementAndLease}</CenteredNumber>
              ),
              sorting: false,
              width: 75,
              headerStyle: { textAlign: 'center' },
            },
            {
              title: 'LOAN',
              render: data => <CenteredNumber>{data.WELCOME_CALL?.count?.cashAndLoan}</CenteredNumber>,
              sorting: false,
              width: 75,
              headerStyle: { textAlign: 'center' },
            },
          ]}
          data={editableTableData}
          icons={TableIcons}
          options={{
            fixedColumns: {
              left: width < 900 ? 2 : 0,
              right: 0,
            },
            showTitle: false,
            showEmptyDataSourceMessage: false,
            tableLayout: width < 900 ? 'fixed' : 'auto',
            paging: false,
            toolbar: false,
            search: false,
            draggable: false,
            emptyRowsWhenPaging: false,
            rowStyle: (_rowData, rowNum) => ({
              backgroundColor: rowNum % 2 === 0 ? Black.LIGHT : Black.LIGHTER,
            }),
          }}
        />
      )}
    </>
  );
};

const ExtraWCHeader = styled.div`
  display: flex;
  justify-content: flex-end;
  background-color: #111111;

  div {
    position: relative;
    bottom: -15px;
    z-index: 20;
    width: 150px;
    text-align: center;
  }

  span {
    background-color: #111111;
    color: ${p => p.theme.palette.primary.light};
  }

  span:last-of-type {
    padding: 0 ${p => p.theme.spacing(0.5)}px;
  }

  span:first-of-type {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: -1;
    height: 10px;
    width: 50%;
    margin: auto;
    border-left: 1px solid ${p => p.theme.palette.common.white};
    border-right: 1px solid ${p => p.theme.palette.common.white};
    border-top: 1px solid ${p => p.theme.palette.common.white};
  }
`;

const Rank = styled(Typography)<HighlightProps>`
  font-family: constructa;
  font-weight: lighter;
  color: ${p => p.highlight};
`;

const Athlete = styled.div<HighlightProps>`
  display: flex;
  align-items: center;
  text-transform: uppercase;
  color: ${p => p.highlight};

  p:first-of-type {
    font-size: 18px;
  }

  p:nth-of-type(2) {
    font-size: 12px;
  }

  div:last-of-type {
    flex: 1;
  }
`;

const LeagueLevelLogo = styled.div<HighlightProps>`
  border-radius: 50%;
  border: 1px solid ${p => p.highlight};
  width: 35px;
  height: 35px;
  margin-right: ${p => p.theme.spacing(1)}px;

  svg {
    fill: ${p => p.highlight};
  }
`;

const CenteredNumber = styled.div`
  text-align: center;
`;

export default CompScore;
