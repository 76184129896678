import { ApolloClient, ApolloProvider } from '@apollo/client';
import LuxonAdapter from '@date-io/luxon';
import { Global } from '@emotion/react';
import { ThemeProvider as EmotionThemeProvider } from '@emotion/react';
import { createMuiTheme, CssBaseline, StylesProvider, ThemeProvider as MuiThemeProvider } from '@material-ui/core';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { FC, useEffect, useLayoutEffect, useState } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { LogArgument } from 'rollbar';

import { createApolloClientAsync } from '../apollo';
import { ErrorFallback, logErrorHandler } from '../pages/error-boundary';
import { globalStyles } from '../styles';
import { LIGHT_THEME_OPTIONS } from '../styles/theme';
import { provideEnvironment } from '../utilities/env';
import Rollbar from '../utilities/rollbar';
import OneProvider from './OneProvider';

const lightTheme = createMuiTheme(LIGHT_THEME_OPTIONS);

const Providers: FC = props => {
  const { children } = props;
  const [client, setClient] = useState<ApolloClient<unknown> | null>(null);

  const [token, setToken] = useState<string>('');
  const env = provideEnvironment();

  useLayoutEffect(() => {
    if (env === 'LOCAL') {
      setToken('set token here for local dev');
      return;
    }
    window.parent.postMessage('iframe ready', '*');
    const handler = (event: any) => {
      try {
        if (event.data && event.data.type === 'EVENT:AUTH_TOKEN') {
          setToken(event.data.payload.token);
        } else if (typeof event.data === 'string' && event.data.includes('token') && JSON.parse(event.data).token) {
          setToken(JSON.parse(event.data).token);
        }
      } catch (e: LogArgument | any) {
        Rollbar.error('Unable to set token for app', e);
      }
    };

    window.addEventListener('message', handler);
    return () => window.removeEventListener('message', handler);
  }, []);

  useEffect(() => {
    async function getApolloClient() {
      const updatedClient = await createApolloClientAsync(token, env);
      setClient(updatedClient);
    }

    getApolloClient();
  }, [env, token]);

  if (!client) return null;

  return (
    <ErrorBoundary FallbackComponent={ErrorFallback} onError={logErrorHandler}>
      <StylesProvider injectFirst>
        <MuiThemeProvider theme={lightTheme}>
          <EmotionThemeProvider theme={lightTheme}>
            <MuiPickersUtilsProvider utils={LuxonAdapter}>
              <ApolloProvider client={client}>
                <OneProvider>
                  <CssBaseline />
                  <Global styles={globalStyles} />
                  {children}
                </OneProvider>
              </ApolloProvider>
            </MuiPickersUtilsProvider>
          </EmotionThemeProvider>
        </MuiThemeProvider>
      </StylesProvider>
    </ErrorBoundary>
  );
};

export default Providers;
