import { EventTracker } from '@devexpress/dx-react-chart';
import { Palette } from '@devexpress/dx-react-chart';
import { Chart, PieSeries, Tooltip } from '@devexpress/dx-react-chart-material-ui';
import styled from '@emotion/styled';
import { Typography } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import { fixedPrecision } from 'utilities/numbers';

import { PerformanceIndicators } from '../../../queries/contractType';

type PerformanceIndicatorsMinusType = keyof Omit<PerformanceIndicators, '__typename'>;

type ColorProps = {
  circleColor?: string;
  circleColorNumber?: number;
};

const Arrow = (props: Tooltip.ArrowProps) => {
  return (
    <ArrowContainer>
      <Tooltip.Arrow {...props} />
    </ArrowContainer>
  );
};

const Content = (props: Tooltip.ContentProps) => {
  return (
    <ContentContainer circleColorNumber={props.targetItem.point}>
      <span></span>
      <Typography variant="body1">{props.text}</Typography>
    </ContentContainer>
  );
};

const Sheet = (props: Tooltip.SheetProps) => {
  return (
    <SheetContainer>
      <Tooltip.Sheet {...props} />
    </SheetContainer>
  );
};

const DonutChart = ({ data, period }: { data?: PerformanceIndicators; period: string }) => {
  const theme = useTheme();
  const donutData: { name: PerformanceIndicatorsMinusType; shortHand: string }[] = [
    { name: 'accountCreated', shortHand: 'AC' },
    { name: 'welcomeCall', shortHand: 'WC' },
    { name: 'permitSubmitted', shortHand: 'PS' },
    { name: 'installScheduled', shortHand: 'IS' },
    { name: 'install', shortHand: 'IC' },
    { name: 'kilowattsInstalled', shortHand: 'kWI' },
  ];
  const yellow = theme.palette.warning.main;
  const green = theme.palette.info.main;
  const blue = theme.palette.success.main;
  const gray = theme.palette.text.secondary;
  const metricArray = ['cash', 'lease', 'loan', 'ppa'];
  const colorsArray = [gray, green, blue, yellow];

  return (
    <Container>
      <Typography variant="h6">{period}</Typography>
      <LegendContainer>
        {metricArray.map((metric, index) => (
          <LegendItem circleColor={colorsArray[index]} key={index}>
            <Typography variant="caption">{metric}</Typography>
          </LegendItem>
        ))}
      </LegendContainer>
      <ChartContainer>
        {donutData.map((donut, index) => {
          const dataTotal = fixedPrecision(data?.[donut.name]?.total, 0);
          const chartData = [
            { metric: 'cash', value: fixedPrecision(data?.[donut.name]?.cash, 1), color: colorsArray[index] },
            { metric: 'lease', value: fixedPrecision(data?.[donut.name]?.lease, 1), color: colorsArray[index] },
            { metric: 'loan', value: fixedPrecision(data?.[donut.name]?.loan, 1), color: colorsArray[index] },
            { metric: 'ppa', value: fixedPrecision(data?.[donut.name]?.ppa, 1), color: colorsArray[index] },
          ];

          return (
            <div key={index}>
              <Circle>
                {(!dataTotal || dataTotal === 0) && (
                  <EmptyCircle>
                    <div></div>
                  </EmptyCircle>
                )}
                {dataTotal !== null && dataTotal !== 0 && (
                  <Chart data={chartData} height={100} width={100}>
                    <Palette scheme={[gray, green, blue, yellow, '#FF4081', '#E040FB']} />
                    <PieSeries valueField="value" argumentField="metric" innerRadius={0.85} />
                    <EventTracker />
                    <Tooltip sheetComponent={Sheet} arrowComponent={Arrow} contentComponent={Content} />
                  </Chart>
                )}
                <Typography variant="h4">{dataTotal || 0}</Typography>
              </Circle>
              <Typography variant="body1">{donut.shortHand}</Typography>
            </div>
          );
        })}
      </ChartContainer>
    </Container>
  );
};

const Container = styled.div`
  > h6 {
    text-align: center;
    font-weight: lighter;
    margin: ${p => p.theme.spacing(2)}px 0 ${p => p.theme.spacing(1)}px;
  }
`;

const LegendContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: ${p => p.theme.spacing(3)}px;
`;

const LegendItem = styled.div<ColorProps>`
  padding-left: ${p => p.theme.spacing(1.5)}px;
  text-transform: uppercase;

  &:before {
    content: '';
    display: inline-block;
    height: 10px;
    width: 10px;
    border-radius: 50%;
    margin-right: 5px;
    background-color: ${p => p.circleColor};
  }
`;

const ChartContainer = styled.div`
  position: relative;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-bottom: ${p => p.theme.spacing(2)}px;

  p {
    text-align: center;
  }

  > div {
    &:not(:last-of-type) {
      margin-right: ${p => p.theme.spacing(2)}px;
    }
  }

  ${p => p.theme.breakpoints.down('sm')} {
    flex-wrap: wrap;

    > div {
      flex: 1 1 30%;

      &:not(:last-of-type) {
        margin-right: 0;
      }
    }
  }
`;

const EmptyCircle = styled.div`
  > div {
    height: 80px;
    width: 80px;
    margin: 10px;
    border-radius: 50%;
    border: 6px solid gray;

    ${p => p.theme.breakpoints.down('sm')} {
      margin: 10px auto;
    }
  }
`;

const Circle = styled.div`
  position: relative;

  > div {
    margin-left: auto;
    margin-right: auto;
  }

  h4 {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 100%;
    font-family: constructa;
    font-weight: normal;
    text-align: center;
  }
`;

const ArrowContainer = styled.div`
  > div {
    &:after {
      background-color: ${p => p.theme.palette.common.white};
      color: ${p => p.theme.palette.common.black};
    }
  }
`;

const SheetContainer = styled.div`
  > div {
    background-color: ${p => p.theme.palette.common.white};
    color: ${p => p.theme.palette.common.black};
  }
`;

const ContentContainer = styled.div<ColorProps>`
  span {
    display: inline-block;
    height: 10px;
    width: 10px;
    border-radius: 50%;
    margin-right: 5px;
    border: ${p => (p.circleColorNumber === 0 ? '1px solid gray' : 'none')};
    background-color: ${p => {
      const gray = p.theme.palette.text.secondary;
      const green = p.theme.palette.info.main;
      const blue = p.theme.palette.success.main;
      const yellow = p.theme.palette.warning.main;
      const colors = [gray, green, blue, yellow];

      return p.circleColorNumber && colors[p.circleColorNumber];
    }};
  }

  p {
    display: inline-block;
  }
`;

export default DonutChart;
