import { useQuery } from '@apollo/client';
import styled from '@emotion/styled';
import { Typography } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import { useEffect, useState } from 'react';

import FilterTable from '../../../components/filter-table';
import { ContainerLoading } from '../../../components/loading';
import { ALL_ATHLETES, ATHLETE } from '../../../constants/breakdown';
import { VIEWS } from '../../../constants/views';
import {
  athleteWeeklyWinsReport,
  teamWeeklyWinsReport,
  WeeklyWinsReportResult,
  WeeklyWinsReportRow,
} from '../../../queries/weeklyWinsReport';
import { fixedPrecision } from '../../../utilities/numbers';
import useWindowSize from '../../../utilities/windowSize';
import ErrorComponent from '../../error-boundary/ErrorComponent';
import { CustomizeOptions } from '..';

type HitGoalProps = {
  hitGoal?: string;
};

const WeeklyWins = ({
  areaName,
  customizeOptions,
  filterOptions,
  reportType,
  onRowClick,
  renderButtonCell,
  onReportDataLoaded,
}: {
  areaName: string | undefined;
  filterOptions: { name: string; value: string }[] | undefined;
  customizeOptions: CustomizeOptions;
  reportType: string;
  renderButtonCell: (
    view: string,
  ) => (tableData: {
    entity: {
      id: string;
      primaryDisplayName: string;
      secondaryDisplayName?: string | undefined;
      leagueLevel?: string | undefined;
      fullName?: string | undefined;
    };
  }) => JSX.Element;
  onRowClick: (
    event?: React.MouseEvent<Element, MouseEvent> | undefined,
    rowData?: any,
    toggleDetailPanel?: (panelIndex?: number | undefined) => void,
  ) => void;
  onReportDataLoaded: (data: any) => void;
}) => {
  const [width] = useWindowSize();
  const theme = useTheme();
  const [rowFilter, setRowFilter] = useState(ALL_ATHLETES);
  const orgType = customizeOptions.view !== VIEWS.ATHLETE;
  const athleteType = customizeOptions.view === VIEWS.ATHLETE;

  const variables = {
    range: customizeOptions.dates,
    entityId: customizeOptions.area,
    subEntityLevel: customizeOptions.view,
    rowFilter: rowFilter,
    reportType: reportType,
  };
  const { data: athleteData, error: athleteError, loading: athleteLoading } = useQuery<WeeklyWinsReportResult>(
    athleteWeeklyWinsReport,
    {
      variables: variables,
      skip: !athleteType,
    },
  );

  const { data: orgData, error: orgError, loading: orgLoading } = useQuery<WeeklyWinsReportResult>(
    teamWeeklyWinsReport,
    {
      variables: variables,
      skip: customizeOptions.view === ATHLETE,
    },
  );

  const entityData = orgData ? orgData : athleteData;
  const loading = (orgType && orgLoading && !orgData) || (athleteType && athleteLoading && !athleteData);

  useEffect(() => {
    if (entityData && !loading) {
      onReportDataLoaded(entityData?.weeklyWinsReport.rowsConnection.rows);
    }
  }, [entityData, loading]);

  if ((orgType && orgError) || (athleteType && athleteError)) {
    return <ErrorComponent />;
  }

  if (loading) {
    return <ContainerLoading />;
  }

  // need to make the data editable for material-ui
  const editable = entityData?.weeklyWinsReport.rowsConnection.rows?.map(o => ({ ...o }));
  const totals = entityData?.weeklyWinsReport?.rowsConnection?.totals;
  const totalsMapping = [
    totals?.leagueScore.actual,
    totals?.accountCreated.actual,
    totals?.welcomeCall.actual,
    totals?.permitSubmitted,
    totals?.installComplete,
    fixedPrecision(totals?.winPercentage) || 0,
  ];

  const formattedFilters =
    filterOptions &&
    filterOptions.map(option => ({
      display: option.name,
      value: option.value,
      onOptionClick: () => setRowFilter(option.value),
    }));

  const getTableCell = (actual: number, goal: number) => {
    return (
      <StyledCell>
        <GoalHighlight hitGoal={goal && actual >= goal ? theme.palette.secondary.main : ''}>
          <Typography variant="body1">{actual}</Typography>
        </GoalHighlight>
        {Boolean(goal) && <Typography variant="caption">/{goal}</Typography>}
      </StyledCell>
    );
  };

  return (
    <FilterTable
      columns={[
        {
          title: <div>{customizeOptions.view}</div>,
          render: (data: WeeklyWinsReportRow) => (
            <Title>
              <Typography variant="body1">
                {data.entity.primaryDisplayName} {data.entity.secondaryDisplayName}
              </Typography>
            </Title>
          ),
          customSort: (a: WeeklyWinsReportRow, b: WeeklyWinsReportRow) =>
            a.entity.primaryDisplayName && b.entity.primaryDisplayName
              ? a.entity.primaryDisplayName.localeCompare(b.entity.primaryDisplayName)
              : 0,
          customFilterAndSearch: (term, rowData) => rowData.entity.primaryDisplayName.indexOf(term) !== -1,
        },
        {
          render: renderButtonCell(customizeOptions.view),
          disableClick: true,
          width: width > 900 ? 200 : 70,
        },
        {
          title: (
            <StyledHeader>
              <div>LS</div>
              <Typography variant="caption">/GOAL</Typography>
            </StyledHeader>
          ),
          render: (data: WeeklyWinsReportRow) => getTableCell(data.leagueScore.actual, data.leagueScore.goal),
          customSort: (a: WeeklyWinsReportRow, b: WeeklyWinsReportRow) => a.leagueScore.actual - b.leagueScore.actual,
          headerStyle: { textAlign: 'center' },
        },
        {
          title: (
            <StyledHeader>
              <div>AC</div>
              <Typography variant="caption">/GOAL</Typography>
            </StyledHeader>
          ),
          field: 'entity.fullName',
          render: (data: WeeklyWinsReportRow) => getTableCell(data.accountCreated.actual, data.accountCreated.goal),
          customSort: (a: WeeklyWinsReportRow, b: WeeklyWinsReportRow) =>
            a.accountCreated.actual - b.accountCreated.actual,
          headerStyle: { textAlign: 'center' },
        },
        {
          title: (
            <StyledHeader>
              <div>WC</div>
              <Typography variant="caption">/GOAL</Typography>
            </StyledHeader>
          ),
          field: 'welcomeCall.actual',
          render: (data: WeeklyWinsReportRow) => getTableCell(data.welcomeCall.actual, data.welcomeCall.goal),
          customSort: (a: WeeklyWinsReportRow, b: WeeklyWinsReportRow) => a.welcomeCall.actual - b.welcomeCall.actual,
          headerStyle: { textAlign: 'center' },
        },
        {
          title: 'CAP',
          render: (data: WeeklyWinsReportRow) => <StyledCell>{data.permitSubmitted}</StyledCell>,
          customSort: (a: WeeklyWinsReportRow, b: WeeklyWinsReportRow) => a.permitSubmitted - b.permitSubmitted,
          headerStyle: { textAlign: 'center' },
        },
        {
          title: 'IC',
          render: (data: WeeklyWinsReportRow) => <StyledCell>{data.installComplete}</StyledCell>,
          customSort: (a: WeeklyWinsReportRow, b: WeeklyWinsReportRow) => a.installComplete - b.installComplete,
          headerStyle: { textAlign: 'center' },
        },
        {
          title: 'WIN%',
          field: 'winPercentage',
          render: (data: WeeklyWinsReportRow) => (
            <StyledCell>{`${fixedPrecision(data.winPercentage) || 0}%`}</StyledCell>
          ),
          customSort: (a: WeeklyWinsReportRow, b: WeeklyWinsReportRow) => a.winPercentage - b.winPercentage,
          headerStyle: { textAlign: 'center' },
        },
      ]}
      data={editable}
      title={areaName}
      filterOptions={formattedFilters}
      filterSelected={rowFilter}
      onRowClick={onRowClick}
      totalsRow={
        <tfoot>
          <RowStyle>
            <td>TOTALS</td>
            <SpacerCell />
            {totals &&
              totalsMapping.map((total, index) => {
                return (
                  <td key={index}>
                    <StyledCell>{total}</StyledCell>
                  </td>
                );
              })}
          </RowStyle>
        </tfoot>
      }
    />
  );
};

const Title = styled.div`
  p {
    text-transform: uppercase;
  }
`;

const StyledHeader = styled.div`
  line-height: 12px;
  text-align: center;
`;

const StyledCell = styled.div`
  text-align: center;
  padding-right: 18px; // to make everything centered, 18px is the width of the header column sorting svg arrow
`;

const GoalHighlight = styled.div<HitGoalProps>`
  p {
    display: inline-block;
    padding: 0px ${p => p.theme.spacing(2)}px;
    font-weight: ${p => (p.hitGoal ? 'bold' : 'normal')};
    background-color: ${p => p.hitGoal};
    border-radius: 4px;
    color: ${p => (p.hitGoal ? p.theme.palette.common.black : p.theme.palette.common.white)};
    line-height: 20px;
  }
`;

const SpacerCell = styled.td`
  background-color: ${p => p.theme.palette.primary.dark};
`;

const RowStyle = styled.tr`
  border-top: 1px solid ${p => p.theme.palette.common.white};
  border-bottom: 1px solid ${p => p.theme.palette.common.white};
  td {
    padding: 16px;

    &:first-of-type {
      font-weight: bold;
      font-size: 16px;
      background-color: ${p => p.theme.palette.primary.dark};
    }
  }
`;

export default WeeklyWins;
