import { useQuery } from '@apollo/client';
import styled from '@emotion/styled';
import { Hidden } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import MaterialTable from 'material-table';
import { useState } from 'react';

import TableIcons from '../../../components/filter-table/TableIcons';
import { ContainerLoading } from '../../../components/loading';
import { COMBINE, DM, OFFICE, REGIONAL_DIRECTORS } from '../../../constants/breakdown';
import { LEAGUE_LEVEL_TO_DISPLAY } from '../../../constants/leagueLevel';
import { TimePeriod } from '../../../constants/periods';
import { VIEWS } from '../../../constants/views';
import { CompetitionCompetitorsEdge, LeaderboardResults, LeagueRankQuery } from '../../../queries/leaderboard';
import { Black, Globals, Gold } from '../../../styles/constants/colors';
import { startOfQuarter } from '../../../utilities/dates';
import { getLevelLogo } from '../../../utilities/getLevelLogo';
import { fixedPrecision } from '../../../utilities/numbers';
import useWindowSize from '../../../utilities/windowSize';
import ErrorComponent from '../../error-boundary/ErrorComponent';
import { LEADERS } from '..';

type HighlightProps = {
  highlight: string;
};

type BoldProps = {
  bold: number;
};

const ACCOUNT_CREATED = 'ACCOUNT_CREATED';
const INSTALL = 'INSTALL';
const INSTALL_SCHEDULED = 'INSTALL_SCHEDULED';
const KILOWATTS_INSTALLED = 'KILOWATTS_INSTALLED';
const PERMIT_SUBMITTED = 'PERMIT_SUBMITTED';
const WELCOME_CALL = 'WELCOME_CALL';
const LEAGUE_SCORE = 'LEAGUE_SCORE';

const CompScore = ({
  period,
  dates,
  scope = OFFICE,
  leagueLevel,
  variety,
}: {
  period: TimePeriod;
  dates: { start: string; end: string };
  scope?: string;
  leagueLevel: string | null;
  variety: string;
}) => {
  const [width] = useWindowSize();
  const [metricType, setMetricType] = useState(LEAGUE_SCORE);
  const isManagement = leagueLevel === DM || leagueLevel === REGIONAL_DIRECTORS;
  const leagueLevelFilter = isManagement ? null : leagueLevel;
  const managementFilter = isManagement ? leagueLevel : null;

  const variables = {
    leagueLevelQuarterDate: startOfQuarter(dates.end),
    startDate: dates.start,
    endDate: dates.end,
    selectedPeriod: period,
    scope: scope,
    firstLimit: scope === OFFICE ? null : 100,
    metricType: metricType,
    leagueLevelFilter: leagueLevelFilter,
    managementFilter: managementFilter,
  };

  const { data: resultsData, error, loading } = useQuery<LeaderboardResults>(LeagueRankQuery, { variables: variables });

  const salesStanding = resultsData?.employee.salesProfile.leagueCompetition.salesStanding;
  const editableTableData =
    variety === LEADERS
      ? salesStanding?.top?.edges?.map(o => ({ ...o }))
      : salesStanding?.rivals?.edges?.map(o => ({ ...o }));

  const getType = (data: CompetitionCompetitorsEdge) => {
    switch (metricType) {
      case ACCOUNT_CREATED:
        return data.ACCOUNT_CREATED;
      case INSTALL:
        return data.INSTALL;
      case INSTALL_SCHEDULED:
        return data.INSTALL_SCHEDULED;
      case KILOWATTS_INSTALLED:
        return data.KILOWATTS_INSTALLED;
      case PERMIT_SUBMITTED:
        return data.PERMIT_SUBMITTED;
      case WELCOME_CALL:
        return data.WELCOME_CALL;
      case LEAGUE_SCORE:
      default:
        return data.LEAGUE_SCORE;
    }
  };

  if (error) {
    return <ErrorComponent />;
  }

  if (loading) {
    return <ContainerLoading />;
  }

  return (
    <>
      {editableTableData && (
        <MaterialTable
          columns={[
            {
              title: 'RANK',
              render: data => {
                const type = getType(data);
                return (
                  <Rank variant="h4" highlight={type?.rank && type?.rank <= 10 ? Gold.MAIN : Globals.WHITE}>
                    {type?.rank}
                  </Rank>
                );
              },
              width: 50,
              headerStyle: { color: Gold.MAIN },
              customSort: (a, b) => {
                const typeA = getType(a);
                const typeB = getType(b);
                if (typeA?.rank && typeB?.rank) {
                  return typeB?.rank - typeA?.rank;
                }
                return 0;
              },
            },
            {
              title: 'ATHLETE',
              width: 85,
              headerStyle: { color: Gold.MAIN },
              render: data => {
                const logo = getLevelLogo(VIEWS.ATHLETE, data.leagueLevel === COMBINE, data.leagueLevel);
                const type = getType(data);
                const highlightColor = type?.rank && type?.rank <= 10 ? Gold.MAIN : Globals.WHITE;
                return (
                  <Athlete highlight={highlightColor}>
                    <Hidden smDown>
                      <LeagueLevelLogo highlight={highlightColor}>{logo && <logo.Logo />}</LeagueLevelLogo>
                    </Hidden>
                    <div>
                      <Typography variant="body1">{data.node?.contact?.fullName || data.node?.badgeId}</Typography>
                      <Typography variant="body2">
                        {data.leagueLevel && LEAGUE_LEVEL_TO_DISPLAY[data.leagueLevel].toUpperCase()}
                      </Typography>
                    </div>
                  </Athlete>
                );
              },
              sorting: false,
            },
            {
              title: <ColumnHeader onClick={() => setMetricType(LEAGUE_SCORE)}>LS</ColumnHeader>,
              render: data => (
                <CenteredNumber bold={metricType === LEAGUE_SCORE ? 1 : 0} variant="body1">
                  {data.LEAGUE_SCORE?.count?.total}
                </CenteredNumber>
              ),
              headerStyle: {
                textAlign: 'center',
                color: Gold.MAIN,
                fontWeight: metricType === LEAGUE_SCORE ? 'bold' : 'normal',
              },
              sorting: false,
              width: 75,
            },
            {
              title: <ColumnHeader onClick={() => setMetricType(ACCOUNT_CREATED)}>AC</ColumnHeader>,
              render: data => (
                <CenteredNumber bold={metricType === ACCOUNT_CREATED ? 1 : 0} variant="body1">
                  {data.ACCOUNT_CREATED?.count?.total}
                </CenteredNumber>
              ),
              headerStyle: {
                textAlign: 'center',
                color: Gold.MAIN,
                fontWeight: metricType === ACCOUNT_CREATED ? 'bold' : 'normal',
              },
              sorting: false,
              width: 75,
            },
            {
              title: <ColumnHeader onClick={() => setMetricType(WELCOME_CALL)}>WC</ColumnHeader>,
              render: data => (
                <CenteredNumber bold={metricType === WELCOME_CALL ? 1 : 0} variant="body1">
                  {data.WELCOME_CALL?.count?.total}
                </CenteredNumber>
              ),
              headerStyle: {
                textAlign: 'center',
                color: Gold.MAIN,
                fontWeight: metricType === WELCOME_CALL ? 'bold' : 'normal',
              },
              sorting: false,
              width: 75,
            },
            {
              title: <ColumnHeader onClick={() => setMetricType(PERMIT_SUBMITTED)}>CAP</ColumnHeader>,
              render: data => (
                <CenteredNumber bold={metricType === PERMIT_SUBMITTED ? 1 : 0} variant="body1">
                  {data.PERMIT_SUBMITTED?.count?.total}
                </CenteredNumber>
              ),
              headerStyle: {
                textAlign: 'center',
                color: Gold.MAIN,
                fontWeight: metricType === PERMIT_SUBMITTED ? 'bold' : 'normal',
              },
              sorting: false,
              width: 75,
            },
            {
              title: <ColumnHeader onClick={() => setMetricType(INSTALL_SCHEDULED)}>IS</ColumnHeader>,
              render: data => (
                <CenteredNumber bold={metricType === INSTALL_SCHEDULED ? 1 : 0} variant="body1">
                  {data.INSTALL_SCHEDULED?.count?.total}
                </CenteredNumber>
              ),
              headerStyle: {
                textAlign: 'center',
                color: Gold.MAIN,
                fontWeight: metricType === INSTALL_SCHEDULED ? 'bold' : 'normal',
              },
              sorting: false,
              width: 75,
            },
            {
              title: <ColumnHeader onClick={() => setMetricType(INSTALL)}>IC</ColumnHeader>,
              render: data => (
                <CenteredNumber bold={metricType === INSTALL ? 1 : 0} variant="body1">
                  {data.INSTALL?.count?.total}
                </CenteredNumber>
              ),
              headerStyle: {
                textAlign: 'center',
                color: Gold.MAIN,
                fontWeight: metricType === INSTALL ? 'bold' : 'normal',
              },
              sorting: false,
              width: 75,
            },
            {
              title: <ColumnHeader onClick={() => setMetricType(KILOWATTS_INSTALLED)}>KWI</ColumnHeader>,
              render: data => (
                <CenteredNumber bold={metricType === KILOWATTS_INSTALLED ? 1 : 0} variant="body1">
                  {fixedPrecision(data.KILOWATTS_INSTALLED?.count?.total) || 0}
                </CenteredNumber>
              ),
              headerStyle: {
                textAlign: 'center',
                color: Gold.MAIN,
                fontWeight: metricType === KILOWATTS_INSTALLED ? 'bold' : 'normal',
              },
              sorting: false,
              width: 75,
            },
          ]}
          data={editableTableData}
          icons={TableIcons}
          options={{
            fixedColumns: {
              left: width < 900 ? 2 : 0,
              right: 0,
            },
            showTitle: false,
            showEmptyDataSourceMessage: false,
            tableLayout: width < 900 ? 'fixed' : 'auto',
            paging: false,
            toolbar: false,
            search: false,
            draggable: false,
            emptyRowsWhenPaging: false,
            rowStyle: (_rowData, rowNum) => ({
              backgroundColor: rowNum % 2 === 0 ? Black.LIGHT : Black.LIGHTER,
            }),
          }}
        />
      )}
    </>
  );
};

const Rank = styled(Typography)<HighlightProps>`
  font-family: constructa;
  font-weight: lighter;
  color: ${p => p.highlight};
`;

const Athlete = styled.div<HighlightProps>`
  display: flex;
  align-items: center;
  text-transform: uppercase;
  color: ${p => p.highlight};

  p:first-of-type {
    font-size: 18px;
  }

  p:nth-of-type(2) {
    font-size: 12px;
  }

  div:last-of-type {
    flex: 1;
  }
`;

const LeagueLevelLogo = styled.div<HighlightProps>`
  border-radius: 50%;
  border: 1px solid ${p => p.highlight};
  width: 35px;
  height: 35px;
  margin-right: ${p => p.theme.spacing(1)}px;

  svg {
    fill: ${p => p.highlight};
  }
`;

const ColumnHeader = styled.div`
  cursor: pointer;
`;

const CenteredNumber = styled(Typography)<BoldProps>`
  text-align: center;
  font-weight: ${p => (p.bold ? 'bolder' : 'normal')};
`;

export default CompScore;
