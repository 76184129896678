import { gql } from '@apollo/client';

import { Maybe, Scalars } from '../apollo/types';

export type SalesEntity = {
  id: Scalars['String'];
  primaryDisplayName: Scalars['String'];
  secondaryDisplayName?: Scalars['String'];
};

export type SalesReportRow = {
  entity: SalesEntity;
};

export type SalesReportRowConnection = {
  rows?: Maybe<Array<Maybe<SalesReportRow>>>;
};

export type PaceGoal = {
  __typename?: 'PaceGoal';
  pace?: Maybe<Scalars['Float']>;
  goal: Scalars['Float'];
  actual: Scalars['Float'];
};

export type WeeklyWinsReportRow = SalesReportRow & {
  __typename?: 'WeeklyWinsReportRow';
  entity: SalesEntity;
  leagueScore: PaceGoal;
  accountCreated: PaceGoal;
  welcomeCall: PaceGoal;
  permitSubmitted: Scalars['Int'];
  installComplete: Scalars['Int'];
  winPercentage: Scalars['Float'];
  winTotals: Scalars['Int'];
};

export type WeeklyWinsTotals = {
  __typename?: 'WeeklyWinsTotals';
  leagueScore: PaceGoal;
  accountCreated: PaceGoal;
  welcomeCall: PaceGoal;
  permitSubmitted: Scalars['Int'];
  installComplete: Scalars['Int'];
  winPercentage: Scalars['Float'];
};

export type WeeklyWinsReportRowConnection = SalesReportRowConnection & {
  __typename?: 'WeeklyWinsReportRowConnection';
  rows?: Maybe<Array<Maybe<WeeklyWinsReportRow>>>;
  totals?: Maybe<WeeklyWinsTotals>;
};

export type WeeklyWinsReportResult = {
  weeklyWinsReport: {
    rowsConnection: WeeklyWinsReportRowConnection;
  };
};

export const athleteWeeklyWinsReport = gql`
  query AthleteWeeklyWinsReport(
    $range: DateRange!
    $entityId: String!
    $subEntityLevel: SalesReportSubOrganization!
    $rowFilter: SalesReportRowFilter!
  ) {
    weeklyWinsReport: salesReport(
      reportType: WEEKLY_WINS
      range: $range
      entityId: $entityId
      subEntityLevel: $subEntityLevel
      rowFilter: $rowFilter
      active: true
    ) {
      rowsConnection {
        ... on WeeklyWinsReportRowConnection {
          rows {
            entity {
              id
              primaryDisplayName
              ... on SalesEntityIndividual {
                secondaryDisplayName
                fullName
              }
            }
            leagueScore {
              goal
              actual
            }
            accountCreated {
              goal
              actual
            }
            welcomeCall {
              goal
              actual
            }
            permitSubmitted
            installComplete
            winPercentage
          }
          totals {
            leagueScore {
              goal
              actual
            }
            accountCreated {
              goal
              actual
            }
            welcomeCall {
              goal
              actual
            }
            permitSubmitted
            installComplete
            winPercentage
          }
        }
      }
    }
  }
`;

export const teamWeeklyWinsReport = gql`
  query TeamWeeklyWinsReport(
    $range: DateRange!
    $entityId: String!
    $subEntityLevel: SalesReportSubOrganization!
    $rowFilter: SalesReportRowFilter!
  ) {
    weeklyWinsReport: salesReport(
      reportType: WEEKLY_WINS
      range: $range
      entityId: $entityId
      subEntityLevel: $subEntityLevel
      rowFilter: $rowFilter
      active: true
    ) {
      rowsConnection {
        ... on WeeklyWinsReportRowConnection {
          rows {
            entity {
              id
              primaryDisplayName
            }
            leagueScore {
              goal
              actual
            }
            accountCreated {
              goal
              actual
            }
            welcomeCall {
              goal
              actual
            }
            permitSubmitted
            installComplete
            winPercentage
          }
          totals {
            leagueScore {
              goal
              actual
            }
            accountCreated {
              goal
              actual
            }
            welcomeCall {
              goal
              actual
            }
            permitSubmitted
            installComplete
            winPercentage
          }
        }
      }
    }
  }
`;
