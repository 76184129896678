import styled from '@emotion/styled';

import Rollbar from '../../utilities/rollbar';

export const logErrorHandler = (error: Error, info: { componentStack: string }) => {
  Rollbar.error(`Logging error: ${error}, Info: ${info}`);
};

export const ErrorFallback = ({ error: { message } }: { error: { message: string } }) => {
  return (
    <Container>
      <div role="alert">
        <Title>Something went wrong. Please reload the page and try again.</Title>
        <p>{message}</p>
      </div>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  height: 100vh;

  p {
    text-align: center;
  }
`;

const Title = styled.div`
  color: ${p => p.theme.palette?.text?.primary || '#FFF'};
  font-size: 36px;
  text-align: center;
`;
