import styled from '@emotion/styled';
import { createMuiTheme, ThemeProvider } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import { withStyles } from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import EventIcon from '@material-ui/icons/Event';
import { DateTime } from 'luxon';
import { useEffect, useState } from 'react';

import { Black, Globals, Red } from '../../styles/constants/colors';
import { getPastQuarterDisplay, quarterDiff, range, startOfQuarter, subtractQuarters } from '../../utilities/dates';
import { CalendarType } from './types';

const lightTheme = createMuiTheme({
  palette: {
    type: 'light',
    primary: {
      main: Black.LIGHT,
    },
    secondary: {
      main: Red.MAIN,
    },
    background: {
      default: Globals.WHITE,
      paper: Globals.WHITE,
    },
  },
  typography: {
    fontFamily: 'Trade Gothic Condensed',
    h4: {
      color: Globals.WHITE,
    },
    body1: {
      '&$selected': {
        backgroundColor: Globals.WHITE,
      },
    },
  },
  overrides: {
    MuiToolbar: {
      regular: {
        minHeight: 100,
      },
      root: {
        backgroundColor: Black.LIGHT,
      },
    },
    MuiTypography: {
      root: {
        textTransform: 'uppercase',
        textAlign: 'center',
      },
    },
    MuiDialog: {
      paperScrollPaper: {
        maxHeight: 457,
        minWidth: 310,
      },
    },
    MuiButton: {
      iconSizeMedium: {
        fontSize: 24,
      },
    },
  },
});

export const makeQuarters = (start: string, end: string) => {
  const numberOfQuartersToRender = quarterDiff(start, end) + 1;
  const lastQuarter = startOfQuarter(end);

  return Array.from({ length: numberOfQuartersToRender }).map((_, i) => subtractQuarters(lastQuarter, i));
};

const QuarterCalendar = ({
  onChange,
  selectedRange,
  open = false,
}: {
  open: boolean;
  selectedRange: {
    start: string;
    end: string;
  };
  onChange: (dateRange: { start: string; end: string }) => void;
}) => {
  const { start: allTimeStart, end: allTimeEnd } = range.allTime();
  const quarters = makeQuarters(allTimeStart, allTimeEnd);
  const defaultQuarter = DateTime.fromISO(selectedRange.start).toISODate();
  const [selectedQuarter, setSelectedQuarter] = useState(defaultQuarter);

  const handleClose = () => {
    const quarterRange = {
      start: DateTime.fromISO(selectedQuarter).toISODate(),
      end: DateTime.fromISO(selectedQuarter).endOf('quarter').toISODate(),
    };
    onChange(quarterRange);
  };

  const handleListItemClick = (value: string) => {
    setSelectedQuarter(value);
  };

  return (
    <ThemeProvider theme={lightTheme}>
      <Dialog onChange={handleClose} aria-labelledby="simple-dialog-title" open={open} maxWidth="xl">
        <Toolbar>
          <Typography variant="h4">{getPastQuarterDisplay(selectedQuarter)}</Typography>
        </Toolbar>
        <DialogContent>
          <List>
            {quarters.map(quarter => (
              <ListItem
                button
                onClick={() => handleListItemClick(quarter)}
                key={quarter}
                selected={quarter === selectedQuarter}
              >
                <ListItemText primary={getPastQuarterDisplay(quarter)} />
              </ListItem>
            ))}
          </List>
        </DialogContent>
        <DialogActions>
          <Button color="primary" onClick={handleClose}>
            Cancel
          </Button>
          <Button onClick={handleClose} color="primary">
            Ok
          </Button>
        </DialogActions>
      </Dialog>
    </ThemeProvider>
  );
};

const QuarterPicker: CalendarType = ({ selectedRange, onChange, outlined, color }) => {
  const defaultQuarter = DateTime.fromISO(selectedRange.start).toISODate();
  const [open, setOpen] = useState(false);
  const [selectedQuarter, setSelectedQuarter] = useState(defaultQuarter);
  const [selectedDateRange, setSelectedDateRange] = useState(selectedRange);

  useEffect(() => {
    const newQuarter = DateTime.fromISO(selectedRange.start).toISODate();
    setSelectedQuarter(newQuarter);
    setSelectedDateRange(selectedRange);
  }, [selectedRange]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = (dateRange: { start: string; end: string }) => {
    const quarter = DateTime.fromISO(dateRange.start).toISODate();
    onChange(dateRange);
    setSelectedQuarter(quarter);
    setSelectedDateRange(dateRange);
    setOpen(false);
  };

  return (
    <StyledFormControl>
      {outlined && <StyledLabel>DATES</StyledLabel>}
      <StyledButton
        variant={outlined ? 'outlined' : 'text'}
        color="inherit"
        onClick={handleClickOpen}
        endIcon={outlined && <StyledIcon />}
      >
        {getPastQuarterDisplay(selectedQuarter)}
      </StyledButton>
      <QuarterCalendar selectedRange={selectedDateRange} open={open} onChange={handleClose} />
    </StyledFormControl>
  );
};

const StyledIcon = styled(EventIcon)`
  color: rgba(0, 0, 0, 0.54);
  font-size: 1.5rem !important;
`;

const StyledButton = styled(Button)`
  height: 100%;
  justify-content: space-between;
  font-size: 1rem;
`;

const StyledLabel = styled(FormLabel)`
  position: absolute;
  display: block;
  background: white;
  z-index: 1;
  top: 0;
  left: 0;
  color: rgba(0, 0, 0, 0.54);
  padding: 0 5px;
  font-size: 1rem;
  font-family: Trade Gothic Condensed;
  font-weight: 400;
  line-height: 1;
  transform: translate(4px, -6px) scale(0.75);
`;

const StyledFormControl = withStyles(theme => ({
  root: {
    '.MuiButton-iconSizeMedium > *:first-child': {
      fontSize: '24px',
    },
    '& label': {
      color: theme.palette.grey[600],
    },
    '& .MuiOutlinedInput-root': {
      color: theme.palette.grey[600],
      backgroundColor: theme.palette.common.white,

      '& fieldset': {
        borderColor: theme.palette.grey[600],
      },
      '&:hover fieldset': {
        borderColor: theme.palette.grey[600],
      },
      '&.Mui-focused fieldset': {
        borderColor: theme.palette.grey[600],
      },
      '& .MuiOutlinedInput-input': {
        textTransform: 'uppercase',
        color: theme.palette.common.black,
        fontWeight: 'bolder',
      },
    },
  },
}))(FormControl);

export default QuarterPicker;
