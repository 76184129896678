import styled from '@emotion/styled';
import { Typography } from '@material-ui/core';

import LinedTable from '../../../components/linedTable';
import { CompetitionMetrics } from '../../../queries/competitionScore';

const Score = ({ data, period }: { data?: CompetitionMetrics; period: string }) => {
  const breakdownTable = [
    {
      name: 'AC',
      count: data?.accountCreated?.total?.count || 0,
      modifier: data?.accountCreated?.total?.multiplier || 1,
      score: data?.accountCreated?.total?.score || 0,
    },
    {
      name: 'PPA',
      count: data?.welcomeCall?.ppaAndLease?.count || 0,
      modifier: data?.welcomeCall?.ppaAndLease?.multiplier || 1,
      score: data?.welcomeCall?.ppaAndLease?.score || 0,
    },
    {
      name: 'LOAN',
      count: data?.welcomeCall?.loanAndCash?.count || 0,
      modifier: data?.welcomeCall?.loanAndCash?.multiplier || 1,
      score: data?.welcomeCall?.loanAndCash?.score || 0,
    },
  ];

  return (
    <Container>
      <MainScore>
        <Typography variant="h6">{period}</Typography>
        <Typography variant="h2">{data?.score}</Typography>
        <Typography variant="h6">SCORE</Typography>
      </MainScore>
      <BreakdownContainer>
        <Typography variant="body1">SCORE BREAKDOWN</Typography>
        <TableContainer>
          <LinedTable
            lineItems={breakdownTable.map(lineItem => {
              return {
                left: <Typography variant="body1">{lineItem.name}</Typography>,
                right: (
                  <Calculation>
                    <Typography variant="h5">{lineItem?.count}</Typography>
                    <Typography variant="body2">x{lineItem?.modifier}</Typography>
                    <Typography variant="body2">=</Typography>
                    <Typography variant="h5">{lineItem?.score}</Typography>
                  </Calculation>
                ),
              };
            })}
          />
          <TableDetail>
            <Typography variant="body1">WC</Typography>
          </TableDetail>
        </TableContainer>
        <Total>
          <Typography variant="body1">TOTAL</Typography>
          <Typography variant="h4">{data?.score}</Typography>
        </Total>
      </BreakdownContainer>
    </Container>
  );
};

const Container = styled.div`
  h2,
  h4,
  h5 {
    font-family: constructa;
    font-weight: normal;
  }

  h2 {
    font-size: 70px;
  }

  > div {
    padding: ${p => p.theme.spacing(2)}px ${p => p.theme.spacing(4.5)}px;

    > h6 {
      text-align: center;
      font-weight: lighter;
    }
  }

  ${p => p.theme.breakpoints.up('md')} {
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

const MainScore = styled.div`
  text-align: center;
`;

const BreakdownContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  max-width: 400px;

  ${p => p.theme.breakpoints.down('sm')} {
    margin: auto;
  }

  > p {
    font-weight: bold;
    text-align: left;
    margin-bottom: ${p => p.theme.spacing(1)}px;
  }

  > div p {
    text-align: center;
    font-size: 18px;
  }
`;

const Calculation = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin: 0 ${p => p.theme.spacing(2)}px;

  p {
    font-family: constructa;
    font-weight: normal;
    margin-bottom: ${p => p.theme.spacing(0.25)}px;
  }
`;

const Total = styled.div`
  display: flex;
  align-items: flex-end;
  margin: ${p => p.theme.spacing(1)}px ${p => p.theme.spacing(3)}px 0 auto;

  p {
    margin-right: ${p => p.theme.spacing(1)}px;
    margin-bottom: ${p => p.theme.spacing(0.25)}px;
  }
`;

const TableContainer = styled.div`
  position: relative;
`;

const TableDetail = styled.div`
  position: absolute;
  z-index: -1;
  border: 1px solid ${p => p.theme.palette.common.white};
  left: -20px;
  bottom: 6px;
  height: 70px;
  display: flex;
  align-items: center;

  p {
    position: relative;
    left: -10px;
    background-color: ${p => p.theme.palette.background.default};
  }

  &:after {
    content: '';
    position: absolute;
    right: -10px;
    height: calc(100% + 4px);
    width: 100%;
    background-color: ${p => p.theme.palette.background.default};
  }
`;

export default Score;
