import {
  ACTIVE,
  ALL_AMERICAN,
  ALL_ATHLETES,
  ALL_STAR,
  ALL_TEAMS,
  ATHLETE,
  CLUB,
  COMBINE,
  D1,
  D2,
  D3,
  DM,
  FLAGSHIP,
  FRANCHISE,
  INACTIVE,
  LETTERMAN,
  MINOR,
  MY_RECRUITS,
  OFFICE,
  REGIONAL_DIRECTORS,
  STARTER,
  TEAM,
} from '../../constants/breakdown';
import { PERMISSIONS } from '../../constants/permissions';

const filtersForTeams = [
  { name: 'All Teams', value: ALL_ATHLETES }, // this seems odd but it works
  { name: 'Flagship', value: FLAGSHIP },
  { name: 'D1', value: D1 },
  { name: 'D2', value: D2 },
  { name: 'D3', value: D3 },
  { name: 'Minor', value: MINOR },
];

const filtersForAthletes = [
  { name: 'All Athletes', value: ALL_ATHLETES },
  { name: 'Active', value: ACTIVE },
  { name: 'Inactive', value: INACTIVE },
  { name: 'Franchise', value: FRANCHISE },
  { name: 'All-American', value: ALL_AMERICAN },
  { name: 'All-Star', value: ALL_STAR },
  { name: 'Starter', value: STARTER },
  { name: 'Letterman', value: LETTERMAN },
  { name: 'Club', value: CLUB },
  { name: 'Combine', value: COMBINE },
];

const filtersForAthletesPlusRecruits = [...filtersForAthletes, { name: 'My Recruits', value: MY_RECRUITS }];

const filtersForAthletesPlusManagers = [
  ...filtersForAthletesPlusRecruits,
  { name: 'DM', value: DM },
  { name: 'Regional Directors', value: REGIONAL_DIRECTORS },
];

const getReportFilters = (reportType: string, view?: string, groups?: string[]) => {
  // report filters should only show if view is team or below
  if (view !== ATHLETE && view !== TEAM && view !== OFFICE) {
    return;
  }

  // don't show team filters on my recruits
  if (reportType === MY_RECRUITS && view !== ATHLETE) {
    return;
  }

  // show basic league level filters for my recruits report type, athlete view
  if (reportType === MY_RECRUITS && view === ATHLETE) {
    return filtersForAthletes;
  }

  // teams/office filters
  if (view === TEAM || view === OFFICE) {
    return filtersForTeams;
  }

  // add 2 filters if user is a VP or manager
  if (groups && (groups.includes(PERMISSIONS.COMPANY) || groups.includes(PERMISSIONS.CONFERENCE))) {
    return filtersForAthletesPlusManagers;
  }

  return filtersForAthletesPlusRecruits;
};

export default getReportFilters;
