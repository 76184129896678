export enum TimePeriod {
  Day = 'DAY',
  Week = 'WEEK',
  Month = 'MONTH',
  Quarter = 'QUARTER',
  Year = 'YEAR',
  Custom = 'CUSTOM',
  AllTime = 'LIFETIME',
}

export const DAY = TimePeriod.Day;
export const WEEK = TimePeriod.Week;
export const MONTH = TimePeriod.Month;
export const QUARTER = TimePeriod.Quarter;
export const YEAR = TimePeriod.Year;
export const CUSTOM = TimePeriod.Custom;
export const ALL_TIME = TimePeriod.AllTime;
