import { gql } from '@apollo/client';

import { Maybe, Scalars } from '../apollo/types';

export type MilestoneMetrics = {
  __typename?: 'MilestoneMetrics';
  ppa?: Maybe<Scalars['Float']>;
  loan?: Maybe<Scalars['Float']>;
  lease?: Maybe<Scalars['Float']>;
  cash?: Maybe<Scalars['Float']>;
  total?: Maybe<Scalars['Float']>;
};

export type PerformanceIndicators = {
  __typename?: 'PerformanceIndicators';
  accountCreated?: Maybe<MilestoneMetrics>;
  welcomeCall?: Maybe<MilestoneMetrics>;
  permitSubmitted?: Maybe<MilestoneMetrics>;
  installScheduled?: Maybe<MilestoneMetrics>;
  install?: Maybe<MilestoneMetrics>;
  kilowattsInstalled?: Maybe<MilestoneMetrics>;
};

export enum ContractTypeName {
  Ppa = 'PPA',
  Lease = 'LEASE',
  Loan = 'LOAN',
  Cash = 'CASH',
  Total = 'TOTAL',
}

export type ContractTypeResults = {
  entity: {
    salesMetrics: {
      currentDay: PerformanceIndicators;
      currentWeek: PerformanceIndicators;
      currentMonth: PerformanceIndicators;
      currentQuarter: PerformanceIndicators;
      currentYear: PerformanceIndicators;
      lifetime: PerformanceIndicators;
    };
  };
};

export const athleteContractType = gql`
  query performanceIndicatorsForAthlete(
    $entityId: String!
    $rangeForDay: DateRange!
    $rangeForWeek: DateRange!
    $rangeForMonth: DateRange!
    $rangeForQuarter: DateRange!
    $rangeForYear: DateRange!
    $rangeForAllTime: DateRange!
  ) {
    entity: employeeByBadgeId(badgeId: $entityId) {
      salesMetrics {
        currentDay: performanceIndicators(range: $rangeForDay) {
          ...indicators
        }
        currentWeek: performanceIndicators(range: $rangeForWeek) {
          ...indicators
        }
        currentMonth: performanceIndicators(range: $rangeForMonth) {
          ...indicators
        }
        currentQuarter: performanceIndicators(range: $rangeForQuarter) {
          ...indicators
        }
        currentYear: performanceIndicators(range: $rangeForYear) {
          ...indicators
        }
        lifetime: performanceIndicators(range: $rangeForAllTime) {
          ...indicators
        }
      }
    }
  }
  fragment indicators on PerformanceIndicators {
    accountCreated {
      ...metrics
    }
    welcomeCall {
      ...metrics
    }
    permitSubmitted {
      ...metrics
    }
    installScheduled {
      ...metrics
    }
    install {
      ...metrics
    }
    kilowattsInstalled {
      ...metrics
    }
  }
  fragment metrics on MilestoneMetrics {
    ppa
    loan
    lease
    cash
    total
  }
`;
export const organizationContractType = gql`
  query MainMetrics(
    $entityId: String!
    $rangeForDay: DateRange!
    $rangeForWeek: DateRange!
    $rangeForMonth: DateRange!
    $rangeForQuarter: DateRange!
    $rangeForYear: DateRange!
    $rangeForAllTime: DateRange!
  ) {
    entity: salesOrganizationByOrganizationId(organizationId: $entityId) {
      salesMetrics {
        currentDay: performanceIndicators(range: $rangeForDay) {
          ...indicators
        }
        currentWeek: performanceIndicators(range: $rangeForWeek) {
          ...indicators
        }
        currentMonth: performanceIndicators(range: $rangeForMonth) {
          ...indicators
        }
        currentQuarter: performanceIndicators(range: $rangeForQuarter) {
          ...indicators
        }
        currentYear: performanceIndicators(range: $rangeForYear) {
          ...indicators
        }
        lifetime: performanceIndicators(range: $rangeForAllTime) {
          ...indicators
        }
      }
    }
  }
  fragment indicators on PerformanceIndicators {
    accountCreated {
      ...metrics
    }
    welcomeCall {
      ...metrics
    }
    permitSubmitted {
      ...metrics
    }
    installScheduled {
      ...metrics
    }
    install {
      ...metrics
    }
    kilowattsInstalled {
      ...metrics
    }
  }
  fragment metrics on MilestoneMetrics {
    ppa
    loan
    lease
    cash
    total
  }
`;
