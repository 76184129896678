import styled from '@emotion/styled';
import { Typography } from '@material-ui/core';
import { VIEWS } from 'constants/views';
import { fixedPrecision } from 'utilities/numbers';

import { Maybe } from '../../apollo/types';

type MetricProps = {
  paceColor?: string;
  statusColor?: string;
};

const Summary = ({
  actual,
  combineIsActive,
  goal,
  pace,
  paceColor,
  statusColor,
  type,
}: {
  actual?: Maybe<number>;
  combineIsActive?: boolean;
  goal?: Maybe<number>;
  pace?: Maybe<number>;
  paceColor?: string;
  statusColor?: string;
  type: string;
}) => {
  const getMetric = () => {
    if (type === VIEWS.ATHLETE && combineIsActive) {
      return 'WC';
    }
    if (type === VIEWS.ATHLETE) {
      return 'ICP';
    }
    return '';
  };

  return (
    <SummaryContainer>
      <div>
        <Metric statusColor={statusColor}>
          <Typography variant="h6">
            {fixedPrecision(actual) || 0}
            <span>{getMetric()}</span>
          </Typography>
        </Metric>
        {(!combineIsActive || type !== VIEWS.ATHLETE) && (
          <>
            <Typography variant="h5">:</Typography>
            <Metric paceColor={paceColor}>
              <Typography variant="h6">
                {fixedPrecision(pace) || 0}
                <span>{getMetric()}</span>
              </Typography>
            </Metric>
          </>
        )}
        <Typography variant="h5">:</Typography>
        <Metric>
          <Typography variant="h6">
            {fixedPrecision(goal) || 0}
            <span>{getMetric()}</span>
          </Typography>
        </Metric>
      </div>
      <Typography variant="body1">
        {!combineIsActive || type !== VIEWS.ATHLETE ? 'ACTL : PACE : GOAL' : 'ACTL : GOAL'}
      </Typography>
    </SummaryContainer>
  );
};

const SummaryContainer = styled.div`
  margin-top: 0px;
  text-align: center;

  div {
    display: flex;
    justify-content: center;
  }

  h5 {
    margin: 0 5px;
  }
`;

const Metric = styled.div<MetricProps>`
  color: ${p => {
    if (p.statusColor) {
      return p.statusColor;
    }

    if (p.paceColor) {
      return p.paceColor;
    }

    return p.theme.palette.common.white;
  }};
  span {
    font-size: 16px;
  }
`;

export default Summary;
