import { SalesReportType } from 'queries/customerReports';

// report type
export const LEVEL_UP = SalesReportType.LevelUpOrganization;
export const ALL_STATS = SalesReportType.AllStats;
export const ALL_STATS_LEASE = SalesReportType.AllStatsLease;
export const ALL_STATS_PPA = SalesReportType.AllStatsPpa;
export const ALL_STATS_SPA = SalesReportType.AllStatsSpa;
export const LEVEL_UP_INDIVIDUAL = SalesReportType.LevelUpIndividual;
export const LEVEL_UP_ORGANIZATION = SalesReportType.LevelUpOrganization;
export const WEEKLY_WINS = SalesReportType.WeeklyWins;

// row filter
export const ALL_ATHLETES = 'ALL_ATHLETES';
export const MY_RECRUITS = 'MY_RECRUITS';
export const CLUB = 'CLUB';
export const COMBINE = 'COMBINE';
export const LETTERMAN = 'LETTERMAN';
export const STARTER = 'STARTER';
export const ALL_STAR = 'ALL_STAR';
export const ALL_AMERICAN = 'ALL_AMERICAN';
export const FRANCHISE = 'FRANCHISE';
export const DM = 'DM';
export const REGIONAL_DIRECTORS = 'REGIONAL_DIRECTORS';
export const ALL_TEAMS = 'ALL_TEAMS';
export const D3 = 'D3';
export const D2 = 'D2';
export const D1 = 'D1';
export const FLAGSHIP = 'FLAGSHIP';
export const NONE = 'NONE';
export const MINOR = 'MINOR';
export const ACTIVE = 'ACTIVE';
export const INACTIVE = 'INACTIVE';

// levels
export const COMPANY = 'COMPANY';
export const CONFERENCE = 'CONFERENCE';
export const REGION = 'REGION';
export const TEAM = 'TEAM';
export const ATHLETE = 'ATHLETE';
export const OFFICE = 'OFFICE';
export const DIVISION = 'DIVISION';

export const ORG_TYPE_NAME_MAP: { [key: string]: string } = {
  TEAM: OFFICE,
  CONFERENCE: DIVISION,
};

export const getCorrectOrgType = (type: string) => ORG_TYPE_NAME_MAP[type] || type;

export const organizationLevels: string[] = [COMPANY, CONFERENCE, REGION, TEAM, ATHLETE];
