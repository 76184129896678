import styled from '@emotion/styled';
import ButtonBase from '@material-ui/core/ButtonBase';
import IconButton from '@material-ui/core/IconButton';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import { Children, ReactNode, useState } from 'react';
import Slider from 'react-slick';

import mixpanel from '../../utilities/mixpanel';
import Dot from './Dot';

type ResponsiveSettings = {
  breakpoint: number;
  settings: {
    slidesToShow?: number;
    centerPadding?: string;
    centerMode?: boolean;
  };
};

const defaultPages = [
  <div style={{ height: 100, textAlign: 'center', padding: 100, backgroundColor: 'red' }}>PANE 1</div>,
  <div style={{ height: 100, textAlign: 'center', padding: 100, backgroundColor: 'green' }}>PANE 2</div>,
  <div style={{ height: 100, textAlign: 'center', padding: 100, backgroundColor: 'blue' }}>PANE 3</div>,
  <div style={{ height: 100, textAlign: 'center', padding: 100, backgroundColor: 'orange' }}>PANE 4</div>,
  <div style={{ height: 250, textAlign: 'center', padding: 100, backgroundColor: 'violet' }}>PANE 5</div>,
  <div style={{ height: 100, textAlign: 'center', padding: 100, backgroundColor: 'indigo' }}>PANE 6</div>,
  <div style={{ height: 100, textAlign: 'center', padding: 100, backgroundColor: 'yellow' }}>PANE 7</div>,
];

const Carousel = ({
  pages = defaultPages,
  centerMode = false,
  infinite = false,
  slidesToShow = 1,
  initialSlide = 0,
  responsiveSettings = [],
  title,
}: {
  pages?: ReactNode[];
  infinite?: boolean;
  centerMode?: boolean;
  slidesToShow?: number;
  initialSlide?: number;
  responsiveSettings?: ResponsiveSettings[];
  title?: string;
}) => {
  let reactSliderEl: Slider | null;
  const pagesCountArray = Children.toArray(pages).filter(page => page) || [];
  const [selectedIndex, setSelectedIndex] = useState(initialSlide);

  const createArrowHandler = (direction: string) => () => {
    if (direction === 'BACK') {
      reactSliderEl?.slickPrev();
      if (title) {
        mixpanel.track(`Clicked carousel previous on ${title}`);
      }
    } else {
      reactSliderEl?.slickNext();
      if (title) {
        mixpanel.track(`Clicked carousel next on ${title}`);
      }
    }
  };

  const createHandlerDotClick = (index: number) => () => {
    reactSliderEl?.slickGoTo(index);
  };

  const settings = {
    dots: false,
    arrows: false,
    speed: 500,
    centerMode,
    infinite,
    initialSlide,
    slidesToShow,
    responsive: responsiveSettings,
    beforeChange: (current: number, next: number) => setSelectedIndex(next),
  };

  return (
    <div>
      <Slider {...settings} ref={c => (reactSliderEl = c)}>
        {pages}
      </Slider>
      <Controls>
        <IconButton onClick={createArrowHandler('BACK')} disabled={!infinite && selectedIndex === 0}>
          <ArrowBackIosIcon />
        </IconButton>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          {pagesCountArray?.map((_, index) => (
            <ButtonBase
              key={`Dot-${index}`}
              onClick={createHandlerDotClick(index)}
              style={{ marginRight: 5, marginLeft: 5 }}
            >
              <Dot selected={index === selectedIndex} />
            </ButtonBase>
          ))}
        </div>
        <IconButton
          onClick={createArrowHandler('FORWARD')}
          disabled={!infinite && selectedIndex === pagesCountArray.length - 1}
        >
          <ArrowForwardIosIcon />
        </IconButton>
      </Controls>
    </div>
  );
};

const Controls = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

export default Carousel;
