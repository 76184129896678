import { gql } from '@apollo/client';

type Managers = {
  email: string;
  fullName: string;
};

export type OrganizationByIdResult = {
  salesOrganizationByOrganizationId: {
    id: string;
    displayName: string;
    type: string;
    salesProfile: {
      league: {
        leagueQuarterResult: {
          level: string;
        };
      };
    };
    managers: Managers[];
  };
};

export default gql`
  query Organizations($quarterDate: String!, $organizationId: String!) {
    salesOrganizationByOrganizationId(organizationId: $organizationId) {
      id
      displayName
      type
      salesProfile {
        league {
          leagueQuarterResult(quarterDate: $quarterDate) {
            level
          }
        }
      }
      managers {
        email
        fullName
      }
    }
  }
`;
