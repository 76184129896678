import { useQuery } from '@apollo/client';
import styled from '@emotion/styled';
import { ALL_ATHLETES, ATHLETE } from 'constants/breakdown';
import { VIEWS } from 'constants/views';
import { useState } from 'react';
import { useEffect } from 'react';

import FilterTable from '../../../components/filter-table';
import { ContainerLoading } from '../../../components/loading';
import { AllStatsReportResult, athleteAllStatsReport, orgAllStatsReport } from '../../../queries/allStatsReport';
import { fixedPrecision } from '../../../utilities/numbers';
import useWindowSize from '../../../utilities/windowSize';
import ErrorComponent from '../../error-boundary/ErrorComponent';
import { CustomizeOptions } from '..';

const AllStats = ({
  areaName,
  customizeOptions,
  filterOptions,
  reportType,
  renderButtonCell,
  onRowClick,
  onReportDataLoaded,
}: {
  areaName: string | undefined;
  filterOptions: { name: string; value: string }[] | undefined;
  customizeOptions: CustomizeOptions;
  reportType: string;
  renderButtonCell: (
    view: string,
  ) => (tableData: {
    entity: {
      id: string;
      primaryDisplayName: string;
      secondaryDisplayName?: string | undefined;
      leagueLevel?: string | undefined;
      fullName?: string | undefined;
    };
  }) => JSX.Element;
  onRowClick: (
    event?: React.MouseEvent<Element, MouseEvent> | undefined,
    rowData?: any,
    toggleDetailPanel?: (panelIndex?: number | undefined) => void,
  ) => void;
  onReportDataLoaded: (data: any) => void;
}) => {
  const [width] = useWindowSize();
  const [rowFilter, setRowFilter] = useState(ALL_ATHLETES);
  const orgType = customizeOptions.view !== VIEWS.ATHLETE;
  const athleteType = customizeOptions.view === VIEWS.ATHLETE;

  const variables = {
    range: customizeOptions.dates,
    entityId: customizeOptions.area,
    subEntityLevel: customizeOptions.view,
    rowFilter: rowFilter,
    reportType: reportType,
  };
  const { data: athleteData, error: athleteError, loading: athleteLoading } = useQuery<AllStatsReportResult>(
    athleteAllStatsReport,
    {
      variables: variables,
      skip: !athleteType,
    },
  );

  const { data: orgData, error: orgError, loading: orgLoading } = useQuery<AllStatsReportResult>(orgAllStatsReport, {
    variables: variables,
    skip: customizeOptions.view === ATHLETE,
  });

  const entityData = orgData ? orgData : athleteData;
  const loading = (orgType && orgLoading && !orgData) || (athleteType && athleteLoading && !athleteData);

  useEffect(() => {
    if (entityData && !loading) {
      onReportDataLoaded(entityData?.activeSalesReport.rowsConnection.rows);
    }
  }, [entityData, loading]);

  if ((orgType && orgError) || (athleteType && athleteError)) {
    return <ErrorComponent />;
  }

  if (loading) {
    return <ContainerLoading />;
  }

  // need to make the data editable for material-ui
  const editable = entityData?.activeSalesReport?.rowsConnection?.rows?.map(o => ({ ...o }));
  const totals = entityData?.activeSalesReport?.rowsConnection?.totals;
  const formattedFilters =
    filterOptions &&
    filterOptions.map(option => ({
      display: option.name,
      value: option.value,
      onOptionClick: () => setRowFilter(option.value),
    }));

  const totalsMapping = [
    totals?.accountCreated,
    totals?.welcomeCall,
    totals?.permitSubmitted,
    totals?.installScheduled,
    totals?.installComplete,
    totals?.leagueLevelPoints,
    fixedPrecision(totals?.kilowattsInstalled) || 0,
    totals?.permitSubmittedPercentage,
  ];

  return (
    <FilterTable
      columns={[
        {
          title: <div>{customizeOptions.view}</div>,
          render: data => <Primary>{`${data.entity?.fullName || data.entity.primaryDisplayName}`}</Primary>,
          customSort: (a, b) =>
            a.entity.primaryDisplayName && b.entity.primaryDisplayName
              ? a.entity.primaryDisplayName.localeCompare(b.entity.primaryDisplayName)
              : 0,
          customFilterAndSearch: (term, rowData) => rowData.entity.primaryDisplayName.indexOf(term) !== -1,
          width: width > 900 ? 200 : 70,
        },
        {
          render: renderButtonCell(customizeOptions.view),
          disableClick: true,
          sorting: false,
          width: width > 900 ? 200 : 60,
        },
        { title: 'AC', field: 'accountCreated' },
        { title: 'WC', field: 'welcomeCall' },
        { title: 'CAP', field: 'permitSubmitted' },
        { title: 'IS', field: 'installScheduled' },
        { title: 'IC', field: 'installComplete' },
        { title: 'ICP', field: 'leagueLevelPoints' },
        {
          title: 'kWI',
          field: 'kilowattsInstalled',
          render: data => fixedPrecision(data['kilowattsInstalled']) || 0,
          customSort: (a, b) => a.kilowattsInstalled - b.kilowattsInstalled,
        },
        {
          title: '%',
          field: 'permitSubmittedPercentage',
          render: data => fixedPrecision(data['permitSubmittedPercentage']) || 0,
          customSort: (a, b) => a.permitSubmittedPercentage - b.permitSubmittedPercentage,
        },
      ]}
      data={editable}
      title={areaName}
      filterOptions={formattedFilters}
      filterSelected={rowFilter}
      onRowClick={onRowClick}
      totalsRow={
        <tfoot>
          <RowStyle>
            <td>TOTALS</td>
            <SpacerCell />
            {totals &&
              totalsMapping.map((total, index) => {
                return <td key={index}>{fixedPrecision(total) || 0}</td>;
              })}
          </RowStyle>
        </tfoot>
      }
    />
  );
};

const Primary = styled.div`
  flex: 2;
  text-transform: uppercase;
`;

const RowStyle = styled.tr`
  border-top: 1px solid ${p => p.theme.palette.common.white};
  border-bottom: 1px solid ${p => p.theme.palette.common.white};
  background-color: ${p => p.theme.palette.primary.dark};
  td {
    padding: 16px;

    &:first-of-type {
      font-weight: bold;
      font-size: 16px;
      background-color: ${p => p.theme.palette.primary.dark};
    }
  }
`;

const SpacerCell = styled.td`
  background-color: ${p => p.theme.palette.primary.dark};
`;

export default AllStats;
