import { ApolloClient, Resolvers } from '@apollo/client';

import EmployeeQuery from '../queries/employee';
import { Employee, SalesOrganization, SalesProfile } from './types';

type UpdateEmployeePayload = {
  employee: Employee;
};

const resolvers: Resolvers = {
  Mutation: {
    clearUser: (_root: unknown, _payload: unknown, { cache }: ApolloClient<unknown>) => {
      const data = {
        user: {
          __typename: 'Employee',
          badgeId: null,
          groups: [],
          salesOrganization: {
            __typename: 'SalesOrganization',
            ancestryConnection: {
              __typename: 'AncestryConnection',
              organizations: [],
            },
          },
          salesProfile: {
            __typename: 'SalesProfile',
            primarySellingLocation: null,
            organizationLevels: [],
          },
          contact: {
            __typename: 'Contact',
            email: null,
            fullName: null,
            familyName: null,
            givenName: null,
            phone: null,
          },
        },
      };

      cache.writeQuery({
        query: EmployeeQuery,
        data,
      });

      return data;
    },

    updateUser: (_root: unknown, { employee }: UpdateEmployeePayload, { cache }: ApolloClient<unknown>) => {
      const salesProfile: SalesProfile = {
        __typename: 'SalesProfile',
        league: {
          __typename: 'LeagueMetrics',
          combine: {
            __typename: 'CombineUserResults',
            isActive: employee.salesProfile?.league?.combine?.isActive ?? false,
            startDate: employee.salesProfile?.league?.combine?.startDate ?? null,
          },
        },
        organizationLevels: employee.salesProfile?.organizationLevels ?? [],
      };

      const primarySellingLocation = employee.salesProfile?.primarySellingLocation?.ancestryConnection?.organizations;
      const organizations = employee.salesProfile?.primarySellingLocation?.ancestryConnection?.organizations?.map<SalesOrganization>(
        org => ({
          __typename: 'SalesOrganization',
          id: org?.id ?? '',
          displayName: org?.displayName,
          type: org?.type,
          parent: {
            __typename: 'SalesOrganization',
            id: org?.parent?.id ?? '',
          },
        }),
      );

      if (primarySellingLocation) {
        salesProfile.primarySellingLocation = {
          id: employee.salesProfile!.primarySellingLocation!.id,
          __typename: 'SalesOrganization',
          ancestryConnection: {
            __typename: 'OrganizationAncestryConnection',
            organizations,
          },
        };
      }

      const data = {
        employee: {
          __typename: 'Employee',
          badgeId: employee.badgeId,
          groups: employee.groups,
          salesOrganization: {
            __typename: 'SalesOrganization',
            ancestryConnection: {
              __typename: 'AncestryConnection',
              organizations: (employee.salesOrganization?.ancestryConnection?.organizations ?? []).map(
                organization => ({
                  __typename: 'Organization',
                  id: organization?.id,
                  primaryLocation: organization?.primaryLocation,
                  displayName: organization?.displayName,
                  type: organization?.type,
                  parent: {
                    __typename: 'SalesOrganization',
                    id: organization?.parent?.id,
                  },
                }),
              ),
            },
          },
          salesProfile,
          contact: {
            __typename: 'Contact',
            email: employee.contact?.email,
            fullName: employee.contact?.fullName,
            familyName: employee.contact?.familyName,
            givenName: employee.contact?.givenName,
            phone: employee.contact?.phone,
          },
        },
      };

      cache.writeQuery({
        query: EmployeeQuery,
        data,
      });

      return data;
    },
  },
};

export default resolvers;
