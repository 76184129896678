import { gql } from '@apollo/client';

import { Maybe, Scalars } from '../apollo/types';

export type HeadCount = {
  active?: Maybe<Scalars['Int']>;
};

export type MilestoneMetrics = {
  __typename?: 'MilestoneMetrics';
  total?: Maybe<Scalars['Float']>;
};

export type PerformanceIndicators = {
  accountCreated?: Maybe<MilestoneMetrics>;
  welcomeCall?: Maybe<MilestoneMetrics>;
  permitSubmitted?: Maybe<MilestoneMetrics>;
  installScheduled?: Maybe<MilestoneMetrics>;
  install?: Maybe<MilestoneMetrics>;
  kilowattsInstalled?: Maybe<MilestoneMetrics>;
};

export type LeagueScore = {
  __typename?: 'LeagueScore';
  totals?: Maybe<LeagueScoreTotals>;
};

export type LeagueScoreTotals = {
  __typename?: 'LeagueScoreTotals';
  score?: Maybe<Scalars['Float']>;
};

export type AthleteAveragesResult = {
  entity: {
    salesProfile: {
      headCount: HeadCount;
    };
    salesMetrics: {
      league: {
        leagueScore: LeagueScore;
      };
      performanceIndicators: PerformanceIndicators;
    };
  };
};

export const athleteAverages = gql`
  query PerAthleteAverages($entityId: String!, $rangeForQuarter: DateRange!, $quarterStartDate: String!) {
    entity: salesOrganizationByOrganizationId(organizationId: $entityId) {
      salesProfile {
        headCount {
          active
        }
      }
      salesMetrics {
        league {
          leagueScore(salesMetricPeriod: QUARTER, startDate: $quarterStartDate) {
            totals {
              score
            }
          }
        }
        performanceIndicators(range: $rangeForQuarter) {
          accountCreated {
            total
          }
          welcomeCall {
            total
          }
          permitSubmitted {
            total
          }
          installScheduled {
            total
          }
          install {
            total
          }
          kilowattsInstalled {
            total
          }
        }
      }
    }
  }
`;
