import styled from '@emotion/styled';
import { Typography } from '@material-ui/core';
import { FC } from 'react';

import { ReactComponent as TheBigsLogo } from '../../assets/icons/CompetitionTheBigs.svg';

const BlockedBigsMessage: FC = () => {
  return (
    <Content>
      <div>
        <TheBigsLogo />
        <Typography variant="h4">
          There is a competition and numbers are temporarily hidden on purpose. For now, pretend like you're down by 20.
        </Typography>
        <Typography variant="h6">
          Reminder: League Live is not down. Please do not create any service desk tickets.
        </Typography>
      </div>
    </Content>
  );
};

const Content = styled.div`
  position: relative;
  padding: 20px;
  text-align: center;

  div {
    max-width: 900px;
    margin: auto;
    padding-top: 50px;
  }

  h4 {
    margin-bottom: 20px;
  }

  svg {
    height: 100px;
    fill: white;
  }
`;

export default BlockedBigsMessage;
