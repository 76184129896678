import { Maybe } from '../apollo/types';

export const getOrdinalNumber = (number?: number) => {
  if (!number) {
    return null;
  }

  return ['st', 'nd', 'rd'][((((number + 90) % 100) - 10) % 10) - 1] || 'th';
};

export const DEFAULT_PRECISION = 2;

export const fixedPrecision = (number?: Maybe<number>, precision = DEFAULT_PRECISION) => {
  if (!number || isNaN(number)) {
    return null;
  }

  if (isNaN(precision)) {
    precision = DEFAULT_PRECISION;
  }

  return Number.parseFloat(number.toFixed(precision));
};
