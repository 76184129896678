import styled from '@emotion/styled';
import { ReactComponent as Clock } from 'assets/icons/clock.svg';
import DropDownMenu from 'components/dropdown';
import { ReactNode, useState } from 'react';

import Carousel from '../../../components/carousel';
import SectionHeader from '../../../components/section-header';
import { DAY, MONTH, QUARTER, WEEK, YEAR } from '../../../constants/periods';
import { PAST_FOURTEEN, PAST_SEVEN, PAST_TWENTY_ONE } from '../../../constants/trendsDuration';
import { VIEWS } from '../../../constants/views';
import mixpanel from '../../../utilities/mixpanel';
import AthleteData from './AthleteData';
import { getRangesForDuration } from './helpers';
import OrgData from './OrgData';

const Trends = ({ entityId, type }: { entityId: string; type: string }) => {
  let pagesArray: ReactNode[] = [];
  const [duration, setDuration] = useState(PAST_SEVEN.value);
  const durationOptions = [
    {
      value: String(PAST_SEVEN.value),
      display: PAST_SEVEN.display,
      onOptionClick: () => {
        setDuration(PAST_SEVEN.value);
        mixpanel.track(`Trends duration changed to ${PAST_SEVEN.value}`);
      },
    },
    {
      value: String(PAST_FOURTEEN.value),
      display: PAST_FOURTEEN.display,
      onOptionClick: () => {
        setDuration(PAST_FOURTEEN.value);
        mixpanel.track(`Trends duration changed to ${PAST_FOURTEEN.value}`);
      },
    },
    {
      value: String(PAST_TWENTY_ONE.value),
      display: PAST_TWENTY_ONE.display,
      onOptionClick: () => {
        setDuration(PAST_TWENTY_ONE.value);
        mixpanel.track(`Trends duration changed to ${PAST_TWENTY_ONE.value}`);
      },
    },
  ];

  const aggregations = [
    { period: DAY, range: getRangesForDuration(DAY, duration) },
    { period: WEEK, range: getRangesForDuration(WEEK, duration) },
    { period: MONTH, range: getRangesForDuration(MONTH, duration) },
    { period: QUARTER, range: getRangesForDuration(QUARTER, duration) },
    { period: YEAR, range: getRangesForDuration(YEAR, duration) },
  ];

  pagesArray = aggregations.map((agg, index) => {
    return (
      <div key={index}>
        {type === VIEWS.ATHLETE && Object.keys(agg.range).length !== 0 && (
          <AthleteData duration={duration} entityId={entityId} period={agg.period} ranges={agg.range} />
        )}
        {type !== VIEWS.ATHLETE && Object.keys(agg.range).length !== 0 && (
          <OrgData duration={duration} entityId={entityId} period={agg.period} ranges={agg.range} />
        )}
      </div>
    );
  });

  return (
    <div>
      <SectionHeader
        title="Trends"
        noSides={false}
        rightContent={
          <DropDownContainer>
            <Clock />
            <DropDownMenu options={durationOptions} />
          </DropDownContainer>
        }
      />
      <CarouselContainer>
        <Carousel pages={pagesArray} title="Trends" />
      </CarouselContainer>
    </div>
  );
};

const CarouselContainer = styled.div`
  ${p => p.theme.breakpoints.up('md')} {
    padding: 20px;
  }
`;

const DropDownContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;

  svg {
    height: 18px;
    width: 18px;
    fill: ${p => p.theme.palette.common.white};
  }
`;

export default Trends;
