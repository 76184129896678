import { findKey, isEqual, partial } from 'lodash';

import { BASE_URLS } from '../constants/baseUrls';

export const provideOrigin = () => {
  const { location } = window;
  const { origin, protocol, hostname, port } = location;

  const portStr = port ? `:${port}` : '';
  // if window.location.origin is not available (ex., in IE),
  // we construct it from protocol, hostname, & port:
  return origin || `${protocol}//${hostname}${portStr}`;
};

export const provideEnvironment = () => {
  const origin = provideOrigin();
  const env = findKey(BASE_URLS.CLIENT, partial(isEqual, origin)); // https://github.com/lodash/lodash/issues/528
  return env || 'PROD';
};
